
export const AMC_SEARCH_CLEAN = 'AMC_SEARCH_CLEAN';
export const AMC_SEARCH_ERROR = 'AMC_SEARCH_ERROR';
export const AMC_SEARCH_BEGIN = 'AMC_SEARCH_BEGIN';
export const AMC_SEARCH_END = 'AMC_SEARCH_END';

export const AMC_GET_BEGIN = 'AMC_GET_BEGIN';
export const AMC_GET_END = 'AMC_GET_END';

export const AMC_UPDATE_BEGIN = 'AMC_UPDATE_BEGIN';
export const AMC_UPDATE_END = 'AMC_UPDATE_END';

export const AMC_VALIDATE_BEGIN = 'AMC_VALIDATE_BEGIN';
export const AMC_VALIDATE_ERROR = 'AMC_VALIDATE_ERROR';
export const AMC_VALIDATE_END = 'AMC_VALIDATE_END';

export const AMC_ABORT_BEGIN = 'AMC_ABORT_BEGIN';
export const AMC_ABORT_END = 'AMC_ABORT_END';

export const AMC_CLEAR_ITEM_CHANGED = 'AMC_CLEAR_ITEM_CHANGED';
export const AMC_ITEM_CHANGED = 'AMC_ITEM_CHANGED';

export const AMC_ERROR = 'AMC_ERROR';
export const AMC_END_EDITING = 'AMC_END_EDITING';

export const AMC_SET_CRITERIA = 'AMC_SET_CRITERIA';
export const AMC_SET_COMPLEX_CRITERIA = 'AMC_SET_COMPLEX_CRITERIA';
export const AMC_SET_SEARCHMODE = 'AMC_SET_SEARCHMODE';
export const AMC_WAITING_ACTION = 'AMC_WAITING_ACTION';
export const AMC_WAITING_ACTION_END = 'AMC_WAITING_ACTION_END';
//-------------------------------------------------------------------
//  Actions Project
//-------------------------------------------------------------------
export const PROJECT_SEARCH_CLEAN = 'PROJECT_SEARCH_CLEAN';
export const PROJECT_SEARCH_ERROR = 'PROJECT_SEARCH_ERROR';
export const PROJECT_SEARCH_BEGIN = 'PROJECT_SEARCH_BEGIN';
export const PROJECT_SEARCH_END = 'PROJECT_SEARCH_END';

export const PROJECT_GET_BEGIN = 'PROJECT_GET_BEGIN';
export const PROJECT_GET_END = 'PROJECT_GET_END';

export const PROJECT_UPDATE_BEGIN = 'PROJECT_UPDATE_BEGIN';
export const PROJECT_UPDATE_END = 'PROJECT_UPDATE_END';

export const PROJECT_VALIDATE_BEGIN = 'PROJECT_VALIDATE_BEGIN';
export const PROJECT_VALIDATE_ERROR = 'PROJECT_VALIDATE_ERROR';
export const PROJECT_VALIDATE_END = 'PROJECT_VALIDATE_END';

export const PROJECT_ABORT_BEGIN = 'PROJECT_ABORT_BEGIN';
export const PROJECT_ABORT_END = 'PROJECT_ABORT_END';

export const PROJECT_THIRDPARTY_CHANGED = 'PROJECT_THIRDPARTY_CHANGED';
export const PROJECT_CUSTOMER_CHANGED = 'PROJECT_CUSTOMER_CHANGED';
export const PROJECT_SUPPLIER_CHANGED = 'PROJECT_SUPPLIER_CHANGED';

export const PROJECT_ERROR = 'PROJECT_ERROR';
export const PROJECT_END_EDITING = 'PROJECT_END_EDITING';

export const PROJECT_SET_CRITERIA = 'PROJECT_SET_CRITERIA';
export const PROJECT_WAITING_ACTION = 'PROJECT_WAITING_ACTION';
export const PROJECT_WAITING_ACTION_END = 'PROJECT_WAITING_ACTION_END';

//-------------------------------------------------------------------
//  Actions Customer contract
//-------------------------------------------------------------------
export const CUSTOMERCONTRACT_SEARCH_CLEAN = 'CUSTOMERCONTRACT_SEARCH_CLEAN';
export const CUSTOMERCONTRACT_SEARCH_ERROR = 'CUSTOMERCONTRACT_SEARCH_ERROR';
export const CUSTOMERCONTRACT_SEARCH_BEGIN = 'CUSTOMERCONTRACT_SEARCH_BEGIN';
export const CUSTOMERCONTRACT_SEARCH_END = 'CUSTOMERCONTRACT_SEARCH_END';

export const CUSTOMERCONTRACT_GET_BEGIN = 'CUSTOMERCONTRACT_GET_BEGIN';
export const CUSTOMERCONTRACT_GET_END = 'CUSTOMERCONTRACT_GET_END';

export const CUSTOMERCONTRACT_UPDATE_BEGIN = 'CUSTOMERCONTRACT_UPDATE_BEGIN';
export const CUSTOMERCONTRACT_UPDATE_END = 'CUSTOMERCONTRACT_UPDATE_END';

export const CUSTOMERCONTRACT_VALIDATE_BEGIN = 'CUSTOMERCONTRACT_VALIDATE_BEGIN';
export const CUSTOMERCONTRACT_VALIDATE_ERROR = 'CUSTOMERCONTRACT_VALIDATE_ERROR';
export const CUSTOMERCONTRACT_VALIDATE_END = 'CUSTOMERCONTRACT_VALIDATE_END';

export const CUSTOMERCONTRACT_ABORT_BEGIN = 'CUSTOMERCONTRACT_ABORT_BEGIN';
export const CUSTOMERCONTRACT_ABORT_END = 'CUSTOMERCONTRACT_ABORT_END';

export const CUSTOMERCONTRACT_THIRDPARTY_CHANGED = 'CUSTOMERCONTRACT_THIRDPARTY_CHANGED';
export const CUSTOMERCONTRACT_CUSTOMER_CHANGED = 'CUSTOMERCONTRACT_CUSTOMER_CHANGED';
export const CUSTOMERCONTRACT_SUPPLIER_CHANGED = 'CUSTOMERCONTRACT_SUPPLIER_CHANGED';

export const CUSTOMERCONTRACT_ERROR = 'CUSTOMERCONTRACT_ERROR';
export const CUSTOMERCONTRACT_END_EDITING = 'CUSTOMERCONTRACT_END_EDITING';

export const CUSTOMERCONTRACT_SET_CRITERIA = 'CUSTOMERCONTRACT_SET_CRITERIA';
export const CUSTOMERCONTRACT_WAITING_ACTION = 'CUSTOMERCONTRACT_WAITING_ACTION';
export const CUSTOMERCONTRACT_WAITING_ACTION_END = 'CUSTOMERCONTRACT_WAITING_ACTION_END';

//-------------------------------------------------------------------
//  Actions Job
//-------------------------------------------------------------------
export const JOB_SEARCH_CLEAN = 'JOB_SEARCH_CLEAN';
export const JOB_SEARCH_ERROR = 'JOB_SEARCH_ERROR';
export const JOB_SEARCH_BEGIN = 'JOB_SEARCH_BEGIN';
export const JOB_SEARCH_END = 'JOB_SEARCH_END';

export const JOB_GET_BEGIN = 'JOB_GET_BEGIN';
export const JOB_GET_END = 'JOB_GET_END';

export const JOB_UPDATE_BEGIN = 'JOB_UPDATE_BEGIN';
export const JOB_UPDATE_END = 'JOB_UPDATE_END';

export const JOB_VALIDATE_BEGIN = 'JOB_VALIDATE_BEGIN';
export const JOB_VALIDATE_ERROR = 'JOB_VALIDATE_ERROR';
export const JOB_VALIDATE_END = 'JOB_VALIDATE_END';

export const JOB_ABORT_BEGIN = 'JOB_ABORT_BEGIN';
export const JOB_ABORT_END = 'JOB_ABORT_END';

export const JOB_THIRDPARTY_CHANGED = 'JOB_THIRDPARTY_CHANGED';
export const JOB_CUSTOMER_CHANGED = 'JOB_CUSTOMER_CHANGED';
export const JOB_SUPPLIER_CHANGED = 'JOB_SUPPLIER_CHANGED';

export const JOB_ERROR = 'JOB_ERROR';
export const JOB_END_EDITING = 'JOB_END_EDITING';

export const JOB_SET_CRITERIA = 'JOB_SET_CRITERIA';
export const JOB_WAITING_ACTION = 'JOB_WAITING_ACTION';
export const JOB_WAITING_ACTION_END = 'JOB_WAITING_ACTION_END';

//-------------------------------------------------------------------
//  Actions Worker
//-------------------------------------------------------------------
export const WORKER_SEARCH_CLEAN = 'WORKER_SEARCH_CLEAN';
export const WORKER_SEARCH_ERROR = 'WORKER_SEARCH_ERROR';
export const WORKER_SEARCH_BEGIN = 'WORKER_SEARCH_BEGIN';
export const WORKER_SEARCH_END = 'WORKER_SEARCH_END';

export const WORKER_GET_BEGIN = 'WORKER_GET_BEGIN';
export const WORKER_GET_END = 'WORKER_GET_END';

export const WORKER_UPDATE_BEGIN = 'WORKER_UPDATE_BEGIN';
export const WORKER_UPDATE_END = 'WORKER_UPDATE_END';

export const WORKER_VALIDATE_BEGIN = 'WORKER_VALIDATE_BEGIN';
export const WORKER_VALIDATE_ERROR = 'WORKER_VALIDATE_ERROR';
export const WORKER_VALIDATE_END = 'WORKER_VALIDATE_END';

export const WORKER_ABORT_BEGIN = 'WORKER_ABORT_BEGIN';
export const WORKER_ABORT_END = 'WORKER_ABORT_END';

export const WORKER_THIRDPARTY_CHANGED = 'WORKER_THIRDPARTY_CHANGED';
export const WORKER_CUSTOMER_CHANGED = 'WORKER_CUSTOMER_CHANGED';
export const WORKER_SUPPLIER_CHANGED = 'WORKER_SUPPLIER_CHANGED';

export const WORKER_ERROR = 'WORKER_ERROR';
export const WORKER_END_EDITING = 'WORKER_END_EDITING';

export const WORKER_SET_CRITERIA = 'WORKER_SET_CRITERIA';
export const WORKER_WAITING_ACTION = 'WORKER_WAITING_ACTION';
export const WORKER_WAITING_ACTION_END = 'WORKER_WAITING_ACTION_END';