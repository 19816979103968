import React, { useState, useRef } from "react";

// @material-ui/core components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from "@material-ui/core";
import { Add, Cancel, MoreVert, Save } from "@material-ui/icons";
import { ImageBlobLoader } from "components/Common/ImageBlobLoader";
import { FileHelper } from "services/common/FileHelper";

//lingui
import { Trans } from "@lingui/macro";

//helpers
import uuidv4 from "uuid/v4"; // Generate GUID

//file extensions
import file_doc from "assets/img/fileExtensions/file_doc.svg";
import file_pdf from "assets/img/fileExtensions/file_pdf.svg";
import file_ppt from "assets/img/fileExtensions/file_ppt.svg";
import file_xls from "assets/img/fileExtensions/file_xls.svg";
import file_zip from "assets/img/fileExtensions/file_zip.svg";
import file_unknown from "assets/img/fileExtensions/file_unknown.svg";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { ApimDelete, ApimUploadFile } from "components/Common/ApimSender";
import { useEffect } from "react";

const TabSiteMedia = function({ site, setSite, isEditable }) {
  const mediaClasses = useStyles();
  const fileInput = useRef(null);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const allowedExtensions = ["doc", "docx", "jpg", "jpeg", "gif", "png", "pdf", "ppt", "pptx", "rar", "svg", "xls", "xlsx", "zip"];
  const blobContainer = "sitemedias";
  const [showMedia, setShowMedia] = useState(null);

  useEffect(() => {
    if (error) {
      alert(error);
    }
  }, [error]);

  useEffect(() => {
    if (file) {
      setUploadIsLoading(true);
      ApimUploadFile(
        `api/UploadFile/Upload/${blobContainer}`,
        file,
        newFile => {
          setSite({
            ...site,
            medias: [
              ...site.medias,
              {
                action: "INSERT",
                locationId: site.id,
                url: newFile.split("|")[0],
                raw: file,
                label: ""
              }
            ]
          });
          setUploadIsLoading(false);
        },
        e => {
          setUploadIsLoading(false);
          setError("An error occurred while sending the file!");
        }
      );
    }
  }, [file]);

  const handleUpload = e => {
    // e.preventDefault();
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setError("No file selected");
      return;
    }

    if (selectedFile.size > 1000000) {
      setError("The file size must be lower than 1 Mb");
      return;
    }

    if (!allowedExtensions.some(item => selectedFile.name.endsWith(item))) {
      setError("The file must have one of extensions listed above:\n" + allowedExtensions.join(", "));
      return;
    }

    setFile(selectedFile);
    e.target.value = null;
  };

  // function handleUpload(e) {
  //   const selectedFile = e.target.files[0];

  //   alert("selectedFile>>>>>", selectedFile);

  //   if (e.target.files.length) {
  //     let file = e.target.files[0];
  //     let split = file.name.split(".");
  //     let extension = split[split.length - 1].toLowerCase();
  //     if (!allowedExtensions.some(a => a === extension)) {
  //       alert("The file must have one of extensions listed above:\n" + allowedExtensions.join(", "));
  //       return;
  //     }
  //     if (file.size > 1000000) {
  //       alert("The file size must be lower than 1 Mb");
  //       return;
  //     }

  //     setSite({
  //       ...site,
  //       medias: [
  //         ...site.medias,
  //         {
  //           action: "INSERT",
  //           locationId: site.id,
  //           url: URL.createObjectURL(file),
  //           raw: file,
  //           label: ""
  //         }
  //       ]
  //     });
  //     e.target.value = null;
  //   }
  // }

  function handleMediaChange(media) {
    setSite({
      ...site,
      medias: site.medias.map(m => {
        return m.localId === media.localId ? { ...media, action: media.action === "INSERT" ? "INSERT" : "UPDATE" } : m;
      })
    });
  }

  function handleMediaDelete(media) {
    ApimDelete(`api/UploadFile/${blobContainer}/${media.url}`);

    if (media.action === "INSERT") {
      setSite({
        ...site,
        medias: site.medias.filter(m => m.localId !== media.localId)
      });
    } else {
      setSite({
        ...site,
        medias: [
          ...site.medias.filter(m => m.localId !== media.localId),
          {
            ...media,
            action: "DELETE"
          }
        ]
      });
    }
  }

  function renderThumbnail(imgSrc, media) {
    return (
      <>
        <img
          src={imgSrc}
          alt={media.label}
          style={{ height: 120, margin: "auto" }}
          onClick={() => {
            if (media.action === "INSERT") return;
            DownloadFile(blobContainer, media.url);
          }}
        />
      </>
    );
  }

  return (
    <div className={mediaClasses.root}>
      <GridList cellHeight={180} className={mediaClasses.gridList} cols={3}>
        {site.medias
          .filter(m => m.action !== "DELETE")
          .map(media => {
            media.localId = uuidv4();
            const fileName = !!media.raw ? media.raw.name : media.url;
            const splitted = fileName.split(".");
            const extension = splitted[splitted.length - 1].toLowerCase();
            var miniature;
            switch (extension) {
              case "gif":
              case "jpg":
              case "jpeg":
              case "png":
              case "svg":
                miniature =
                  media.action === "INSERT" ? (
                    <img src={media.url} alt={media.label} onClick={() => setShowMedia(media)} />
                  ) : (
                    <ImageBlobLoader
                      container={blobContainer}
                      fileName={media.url}
                      alt={media.label}
                      onClick={(e, url) => setShowMedia({ ...media, url: url })}
                      className="MuiGridListTile-imgFullWidth"
                    />
                  );
                break;
              case "doc":
              case "docx":
                miniature = renderThumbnail(file_doc, media);
                break;
              case "pdf":
                miniature = renderThumbnail(file_pdf, media);
                break;
              case "rar":
              case "zip":
                miniature = renderThumbnail(file_zip, media);
                break;
              case "ppt":
              case "pptx":
                miniature = renderThumbnail(file_ppt, media);
                break;
              case "xls":
              case "xlsx":
                miniature = renderThumbnail(file_xls, media);
                break;
              default:
                miniature = renderThumbnail(file_unknown, media);
                break;
            }

            return (
              <GridListTile key={media.localId}>
                {miniature}
                <SiteMedia
                  media={media}
                  setMedia={handleMediaChange}
                  deleteMedia={handleMediaDelete}
                  mediaClasses={mediaClasses}
                  isEditable={isEditable}
                />
              </GridListTile>
            );
          })}
        {isEditable ? (
          <GridListTile>
            <Tooltip title={<Trans>Media_Add</Trans>}>
              <Fab aria-label="Add media" className={mediaClasses.fab} onClick={() => fileInput.current.click()}>
                <Add />
              </Fab>
            </Tooltip>
            <input ref={fileInput} type="file" style={{ display: "none" }} onChange={handleUpload} accept={allowedExtensions.join(",")} />
          </GridListTile>
        ) : (
          <></>
        )}
      </GridList>
      <Dialog open={!!showMedia} onClose={() => setShowMedia(null)} aria-labelledby="showMediaTitle" maxWidth="md">
        <DialogTitle id="showMediaTitle">{!!showMedia ? showMedia.label : ""}</DialogTitle>
        <DialogContent>{!!showMedia ? <img src={showMedia.url} alt={showMedia.label} /> : ""}</DialogContent>
      </Dialog>
    </div>
  );
};

function DownloadFile(container, fileName) {
  var helper = new FileHelper();
  helper.Download(container, fileName, blob => {
    var fileParts = fileName.split(".");
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/" + fileParts[fileParts.length - 1] });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  });
}

const SiteMedia = function({ media, setMedia, deleteMedia, isEditable, mediaClasses }) {
  const [mediaState, setMediaState] = useState(media.action === "INSERT" && !media.edited ? "EDIT" : "VIEW");
  const [mediaLabel, setMediaLabel] = useState(media.label);
  const [menuAnchor, setMenuAnchor] = useState(null);

  switch (mediaState) {
    case "EDIT":
      return (
        <GridListTileBar
          title={<TextField value={mediaLabel} onChange={e => setMediaLabel(e.target.value)} className={mediaClasses.textField} />}
          actionIcon={
            <>
              <IconButton
                aria-label={`info about ${media.label}`}
                className={mediaClasses.iconButton}
                onClick={() => {
                  setMediaState("VIEW");
                  setMedia({ ...media, label: mediaLabel, edited: true });
                }}
              >
                <Save />
              </IconButton>
              <IconButton
                aria-label={`info about ${media.label}`}
                className={mediaClasses.iconButton}
                onClick={() => {
                  media.action === "INSERT" && !media.edited ? deleteMedia(media) : setMediaState("VIEW");
                }}
              >
                <Cancel />
              </IconButton>
            </>
          }
        />
      );
    default:
      return (
        <GridListTileBar
          title={media.label}
          actionIcon={
            isEditable ? (
              <>
                <IconButton
                  aria-label={`info about ${media.label}`}
                  className={mediaClasses.iconButton}
                  onClick={e => setMenuAnchor(e.currentTarget)}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={() => setMenuAnchor(null)}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "40ch"
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setMediaState("EDIT");
                      setMenuAnchor(null);
                    }}
                  >
                    <Trans>Media_Edit</Trans>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      deleteMedia(media);
                      setMenuAnchor(null);
                    }}
                  >
                    <Trans>Media_Delete</Trans>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <></>
            )
          }
        />
      );
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: 450
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#00acc1",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#009AAD"
    }
  },
  iconButton: {
    color: "#EEEEEE"
  },
  textField: {
    backgroundColor: "#FFFFFF"
  }
}));

export default withStyles(thirdPartySearchCriteriaStyle)(TabSiteMedia);
