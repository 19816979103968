import { Trans } from "@lingui/macro";
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  TextField
} from "@material-ui/core";
import { SearchOutlined, ExpandMore, ChevronRight } from "@material-ui/icons";
import { TreeView, TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { formatISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as HistoryActions from "store/History/HistoryActions";
import * as StructureActions from "module/structure/store/actions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardMessage from "components/Card/CardMessage";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import DateSelector from "components/Common/DateSelector";
import Button from "components/CustomButtons/Button.jsx";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { StructureGetTree, StructureNew } from "../actions/StructureActions";
import { isNullOrEmpty } from "../../tools";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { HasRight } from "services/user/UserHelper";

const Tree = ({ structureId, treeType, defaultLang, classes }) => {
  const [state, setState] = useState({ isLoading: false, error: null });
  const [criteria, setCriteria] = useState({ dateRef: formatISO(new Date()) });
  const [filterText, setFilterText] = useState("");
  const [context, setContext] = useState(null);
  const [createState, setCreateState] = useState(null);
  const dispatch = useDispatch();

  const createStructure = (structure, typeToCreate) => {
    setCreateState({ isLoading: true });
    StructureNew(
      structure.structureType,
      structure.identifiant,
      typeToCreate,
      s => {
        dispatch(StructureActions.OpenStructure(null, null, s));
        setCreateState(null);
      },
      e => setCreateState({ isLoading: false, error: e })
    );
  };
  const openStructure = structure => {
    dispatch(StructureActions.OpenStructure(structure.structureType, structure.identifiant));
    dispatch(HistoryActions.addHistoryStructure(structure));
  };

  useEffect(() => {
    if (structureId && structureId !== 0) {
      launchSearch();
    }
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => (state.structures ? treeFilter(filterText, state.structures, state.relations) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [filterText]);

  const launchSearch = () => {
    setState({ ...state, isLoading: true });

    StructureGetTree(
      treeType,
      criteria.dateRef,
      result => {
        treeLoad(result);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  const treeLoad = result => {
    let structures = result.structures;
    let relations = result.relations;

    let parents = allParents(structureId, relations);
    let children = allChildren(structureId, relations);
    relations = [...new Set([...parents, ...children])];

    treeFilter("", structures, relations);
  };

  const treeFilter = (text, structures, relations) => {
    let expanded = [
      structureId.toString(),
      ...allParents(structureId, relations).map(t => {
        return t.fromId;
      })
    ];
    let filteredRelations = relations;

    if (text.length > 2) {
      let sanitizedText = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      let regex = new RegExp(sanitizedText, "i");
      expanded = structures
        .filter(s => regex.test(s.label))
        .map(s => {
          return allParents(s.id, relations).map(t => {
            return t.fromId;
          });
        })
        .join(",")
        .split(",");
      //filteredStructures = filteredStructures.filter(s => expanded.some(e => e === s.id));
      //filteredRelations = filteredRelations.filter(r => expanded.some(s => s === r.toId));
    }

    let nodes = treeBuilder("1", structures, filteredRelations, text);
    setFilterText(text);
    setState({
      ...state,
      isLoading: false,
      structures: structures,
      relations: relations,
      treeNodes: { nodes: nodes, expanded: [...new Set(expanded)] }
    });
  };

  const treeBuilder = (structureId, structures, relations, filterText) => {
    var s = structures.find(s => s.id === structureId);
    if (!s) {
      return;
    }
    var map = relations.filter(r => r.fromId === s.id).map(r => treeBuilder(r.toId, structures, relations, filterText));
    var id = s.id;
    var nodeLabel = s.label;
    if (!isNullOrEmpty(filterText)) {
      // Split on highlight term and include term into parts, ignore case
      let sanitizedText = filterText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const parts = nodeLabel.split(new RegExp(`(${sanitizedText})`, "gi"));
      nodeLabel = (
        <span>
          {parts.map((part, i) => (
            <span key={i} style={{ backgroundColor: part.toLowerCase() === filterText.toLowerCase() ? "yellow" : "white" }}>
              {part}
            </span>
          ))}
        </span>
      );
    }
    var label = (
      <>
        <Chip size="small" variant="outlined" label={s.structureType} />
        <span> {nodeLabel}</span>
      </>
    );

    return (
      <TreeItem key={id} nodeId={id} label={label} onDoubleClick={e => handleOpenStructure(e, s)} onContextMenu={e => handleRightClick(e, s)}>
        {map}
      </TreeItem>
    );
  };

  const handleRightClick = (event, structure) => {
    event.stopPropagation();
    event.preventDefault();
    setContext({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      menu: buildContextMenu(structure)
    });
  };

  const buildContextMenu = structure => {
    let listItemChildren = [];
    if (HasRight("structure.edit")) {
      let possibleChildren;
      switch (structure.structureType) {
        case "DIR":
          possibleChildren = ["DIR", "ERT"];
          break;
        case "ERT":
          possibleChildren = ["AREA", "DIV", "SJ", "UE"];
          break;
        case "AREA":
          possibleChildren = ["DIV", "UE"];
          break;
        case "DIV":
          possibleChildren = ["UE"];
          break;
        case "SJ":
          possibleChildren = ["SJ", "CDG", "UE", "COMP"];
          break;
        case "CDG":
          possibleChildren = ["UE"];
          break;
        case "UE":
          possibleChildren = ["COMP", "UP", "EP"];
          break;
        case "COMP":
          possibleChildren = ["UP"];
          break;
        case "EP":
        case "UP":
        default:
          possibleChildren = [];
          break;
      }

      listItemChildren = possibleChildren.map((c, i) => {
        let title = "";
        if (c === "DIR") {
          title = <Trans>Structure_Tree_NewDIR</Trans>;
        } else if (c === "ERT") {
          title = <Trans>Structure_Tree_NewERT</Trans>;
        } else if (c === "AREA") {
          title = <Trans>Structure_Tree_NewAREA</Trans>;
        } else if (c === "DIV") {
          title = <Trans>Structure_Tree_NewDIV</Trans>;
        } else if (c === "SJ") {
          title = <Trans>Structure_Tree_NewSJ</Trans>;
        } else if (c === "CDG") {
          title = <Trans>Structure_Tree_NewCDG</Trans>;
        } else if (c === "UE") {
          title = <Trans>Structure_Tree_NewUE</Trans>;
        } else if (c === "COMP") {
          title = <Trans>Structure_Tree_NewCOMP</Trans>;
        } else if (c === "UP") {
          title = <Trans>Structure_Tree_NewUP</Trans>;
        } else if (c === "EP") {
          title = <Trans>Structure_Tree_NewEP</Trans>;
        }
        return (
          <ListItem key={`create_${c}`} button onClick={e => handleCreateStructure(e, structure, c)}>
            <ListItemText primary={title} />
          </ListItem>
        );
      });
    }

    if (listItemChildren.length > 0) {
      listItemChildren = [<Divider />, ...listItemChildren];
    }

    return (
      <>
        <ListItem key="create_open" button onClick={e => handleOpenStructure(e, structure)}>
          <ListItemText primary={<Trans>Structure_Tree_Open</Trans>} primaryTypographyProps={{ style: { fontWeight: "bold" } }} />
        </ListItem>
        {listItemChildren}
      </>
    );
  };

  const handleOpenStructure = (event, structure) => {
    event.stopPropagation();
    event.preventDefault();
    setContext(null);
    openStructure(structure);
  };

  const handleCreateStructure = (event, parent, structureType) => {
    event.stopPropagation();
    event.preventDefault();
    setContext(null);
    createStructure(parent, structureType);
  };

  var treeView = "";
  if (state.isLoading) {
    treeView = <CircularProgress />;
  } else if (state.error) {
    treeView = <CardError error={state.error} />;
  } else if (state.treeNodes) {
    treeView = (
      <Card>
        <CardHeader>
          <TextField label="Filtre" defaultValue={filterText} onChange={e => setFilterText(e.target.value)} variant="outlined" />
        </CardHeader>
        <CardBody>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={state.treeNodes.expanded}
            onNodeToggle={(e, nodeIds) => setState({ ...state, treeNodes: { ...state.treeNodes, expanded: nodeIds } })}
          >
            {state.treeNodes.nodes}
          </TreeView>
        </CardBody>
      </Card>
    );
  } else {
    treeView = <CardMessage message={<Trans>NoResult</Trans>} />;
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={9} sm={9} md={9}>
          <Paper>{treeView}</Paper>
        </GridItem>
        <GridItem xs={3} sm={3} md={3}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <SearchOutlined />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  launchSearch();
                }}
                autoComplete="false"
              >
                <div>
                  <DateSelector
                    value={criteria.dateRef}
                    label="DateRef"
                    onChange={d => setCriteria({ ...criteria, dateRef: d })}
                    isEditable
                    defaultLang={defaultLang}
                  />
                </div>
                <div className={classes.flexRight}>
                  <Button type="submit" color="info">
                    <Trans> Search </Trans>
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Popover
        open={Boolean(context)}
        anchorReference="anchorPosition"
        anchorPosition={context ? { top: context.mouseY, left: context.mouseX } : undefined}
        onClose={() => setContext(null)}
      >
        <List component="nav">{context ? context.menu : ""}</List>
      </Popover>
      {createState ? (
        <Dialog
          open
          onClose={() => {
            if (!createState.isLoading) setCreateState(null);
          }}
        >
          <DialogContent>{createState.isLoading ? <CircularProgress /> : <CardError error={createState.error} />}</DialogContent>
          <DialogActions>
            {createState.isLoading ? (
              <></>
            ) : (
              <Button onClick={() => setCreateState(null)}>
                <Trans>Close</Trans>
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

function allParents(structureId, relations) {
  if (structureId === 1) return [];

  let filteredRelations = relations.filter(r => r.toId === structureId.toString());
  var directParents = [];
  for (var i = 0; i < filteredRelations.length; i++) {
    let f = filteredRelations[i];
    directParents.push(f);
    let parents = allParents(f.fromId, relations);
    for (var j = 0; j < parents.length; j++) {
      let p = parents[j];
      directParents.push(p);
    }
  }

  return directParents;
}

function allChildren(structureId, relations) {
  var filteredRelations = relations.filter(s => s.fromId === structureId.toString());
  var directChildren = [];
  for (var i = 0; i < filteredRelations.length; i++) {
    let r = filteredRelations[i];
    directChildren.push(r);
    let children = allChildren(r.toId, relations);
    for (var j = 0; j < children.length; j++) {
      let p = children[j];
      directChildren.push(p);
    }
  }

  return directChildren;
}

export default withStyles(thirdPartySearchCriteriaStyle)(Tree);
