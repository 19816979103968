import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const HubbleParamsSearch = ({ hubbleParams, value, setValue }) => {
    return (<>
        <Autocomplete
            options={hubbleParams}
            clearOnEscape
            getOptionLabel={(option) => option.code}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            value={value ? hubbleParams.find(o => o.code === value.code) : null}
            groupBy={v => v.group}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.code, inputValue);
                const parts = parse(option.code, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    </>);
};

export default HubbleParamsSearch;