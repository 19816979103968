import React from "react";
import { AccountCircleOutlined, MailOutline, SyncAltOutlined } from "@material-ui/icons/";
import ProfileDelegations from "../module/profileDelegation/ProfileDelegations";
import UserNotification from "../views/Profil/UserNotification";
import Profil from "../views/Profil/Profil";
//lingui
import { Trans } from "@lingui/macro";

const profilRoutes = [
  {
    path: "/profile/myProfile",
    name: <Trans> My Profile</Trans>,
    short: <Trans>My Profile</Trans>,
    icon: AccountCircleOutlined,
    component: Profil
  },
  {
    path: "/profile/userNotifications",
    name: <Trans>User Notifications</Trans>,
    short: <Trans>User Notifications</Trans>,
    icon: MailOutline,
    component: UserNotification
  },
  {
    path: "/profile/profileDelegations",
    name: <Trans>Profile Delegations</Trans>,
    short: <Trans>Profile Delegations</Trans>,
    icon: SyncAltOutlined,
    component: ProfileDelegations
  }
];

export default profilRoutes;
