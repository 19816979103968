import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

const colCodeEndpoint = "api/ColCode";

export function SetCriterias(criterias) {
  return dispatch => {
    dispatch({
      type: Actions.STRUCTURE_COLCODE_SET_CRITERIAS,
      criterias: criterias
    });
  };
}

export function SearchColCode() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.STRUCTURE_COLCODE_BEGIN_ACTION
    });

    const criterias = getState().ST_ColCode.criterias;

    ApimGet(
      colCodeEndpoint + ConvertCriteriaToQueryString(criterias),
      responseJson => {
        dispatch({
          type: Actions.STRUCTURE_COLCODE_GET_ALL,
          result: responseJson
        });

        dispatch({
          type: Actions.STRUCTURE_COLCODE_END_ACTION
        });
      },
      error => {
        dispatch({
          type: Actions.STRUCTURE_COLCODE_END_ACTION
        });
      }
    );
  };
}

export function GetColCode(id, callbackFn) {
  return dispatch => {
    ApimGet(
      colCodeEndpoint + "/" + id,
      responseJson => {
        if (callbackFn) callbackFn(responseJson);
        dispatch({
          type: Actions.STRUCTURE_COLCODE_GET_EXISTING,
          item: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.STRUCTURE_COLCODE_END_ACTION
        });
      }
    );
  };
}

export function CrupdateColCode(item, callbackFn, errorFn) {
  return dispatch => {
    dispatch({
      type: Actions.STRUCTURE_COLCODE_BEGIN_ACTION
    });

    ApimPost(
      colCodeEndpoint,
      item,
      responseJson => {
        var id = responseJson?.id ?? item.id;
        dispatch({
          type: Actions.STRUCTURE_COLCODE_SAVE_END,
          id: id
        });
        callbackFn();
      },
      error => {
        errorFn(error.response.data);
        dispatch({
          type: Actions.STRUCTURE_COLCODE_SAVE_ERROR
        });
      }
    );
  };
}

export function QueueColCodeExport(callbackFn, errorFn) {
  return dispatch => {
    dispatch({
      type: Actions.STRUCTURE_COLCODE_BEGIN_ACTION
    });

    ApimGet(
      colCodeEndpoint + "/export",
      response => {
        callbackFn();
        dispatch({
          type: Actions.STRUCTURE_COLCODE_END_ACTION
        });
      },
      error => {
        errorFn(error.response.data);
        dispatch({
          type: Actions.STRUCTURE_COLCODE_END_ACTION
        });
      }
    );
  };
}

export function GetColCodeHistory(id, callbackFn, errorFn) {
  ApimGet(`${colCodeEndpoint}/${id}/histories`, c => callbackFn(c), e => errorFn(e));
}

function ConvertCriteriaToQueryString(criteria) {
  var queryParams = [];

  //ColCodeSearchCriteria
  if (!isNullOrEmpty(criteria.colCode)) queryParams.push("CodeCol=" + criteria.colCode);
  if (!isNullOrEmpty(criteria.structureId)) queryParams.push("StructureId=" + criteria.structureId);
  if (!isNullOrEmpty(criteria.isDemat)) queryParams.push("IsDemat=" + criteria.isDemat);
  if (!isNullOrEmpty(criteria.structureIdentifier)) queryParams.push("structureIdentifier=" + criteria.structureIdentifier);
  if (!isNullOrEmpty(criteria.label)) queryParams.push("Label=" + criteria.label);
  if (!isNullOrEmpty(criteria.validAtDate)) queryParams.push("ValidAtDate=" + criteria.validAtDate);

  //PagingAndOrderProperties
  if (!isNullOrEmpty(criteria.startPage)) queryParams.push("StartPage=" + criteria.startPage);
  if (!isNullOrEmpty(criteria.pageSize)) queryParams.push("PageSize=" + criteria.pageSize);
  if (!isNullOrEmpty(criteria.orderProperty)) queryParams.push("OrderProperty=" + criteria.orderProperty);
  if (!isNullOrEmpty(criteria.isDescendingOrder)) queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);

  return !isArrayNullOrEmpty(queryParams) ? "?" + queryParams.join("&") : "";
}
