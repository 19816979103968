import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Card, CardHeader, TextareaAutosize } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import { Trans } from "@lingui/macro";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { HasRight } from "services/user/UserHelper";
import HelpInfoListings from 'module/HelpInfo/HelpInfoListings';

const styles = {
  media: {
    height: "100px",
    width: "320px",
    paddingTop: "56.25%", // 16:9,
    marginTop: "30"
  }
};

const HelpInfoView = () => {


  return (
    <div>
      <HelpInfoListings />
    </div>
  );
};


export default withStyles(adminStyle)(HelpInfoView);
