import { ApimGet } from "components/Common/ApimSender";

var OcpApimSubscriptionKey = "";
var ApiMdmColasEndPoint = "";

export default class JobEmployeeHelper {
  constructor() {
    OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
    ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;
  }

  ///---------------------------------------------------
  /// Search ThirdParties
  /// * criteria : search criterias
  /// * endSearchThirdParties : method called after result is received
  ///---------------------------------------------------
  GetByLogin(login) {
    return ApimGet("api/JobEmployees/" + login);
  }
}
