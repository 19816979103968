import React, { useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField, withStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { Trans } from "@lingui/macro";
import componentStyle from "assets/jss/mdmcolas/common/componentStyle";
import { isNull } from "tools";

const DialogBox = ({ dialogBox, setDialogBox }) => {
  const [textBoxValue, setTextBoxValue] = useState(null);

  if (!dialogBox) return <></>;

  var dialogContent = (
    <>
      {dialogBox.message && <div>{dialogBox.message}</div>}
      {dialogBox.textbox && (
        <div>
          <TextField
            label={dialogBox.textbox.title}
            multiline
            rows={dialogBox.textbox.rows ? dialogBox.textbox.rows : 4}
            value={textBoxValue}
            onChange={e => setTextBoxValue(e.target.value)}
            fullWidth
          />
        </div>
      )}
      {dialogBox.content && <div>{dialogBox.content}</div>}
    </>
  );

  let buttons = <></>;
  if (dialogBox.type === "yesNo") {
    buttons = (
      <>
        <Button onClick={() => (dialogBox.yes ? dialogBox.yes() : setDialogBox(null))} color="info">
          <Trans> Yes </Trans>
        </Button>
        <Button onClick={() => (dialogBox.no ? dialogBox.no() : setDialogBox(null))}>
          <Trans> No </Trans>
        </Button>
      </>
    );
  } else if (dialogBox.type === "okCancel") {
    buttons = (
      <>
        <Button onClick={() => (dialogBox.ok ? dialogBox.ok(textBoxValue) : setDialogBox(null))} color="info">
          <Trans> Ok </Trans>
        </Button>
        <Button onClick={() => (dialogBox.cancel ? dialogBox.cancel() : setDialogBox(null))}>
          <Trans> Cancel </Trans>
        </Button>
      </>
    );
  } else if (dialogBox.type === "cancel") {
    buttons = (
      <>
        <Button onClick={() => (dialogBox.cancel ? dialogBox.cancel() : setDialogBox(null))}>
          <Trans> Cancel </Trans>
        </Button>
      </>
    );
  } else if (dialogBox.type === "empty") {
  } else if (dialogBox.type === "loading") {
    dialogContent = (
      <>
        <div style={{ margin: "auto" }}>
          <Trans>Loading</Trans>
          <br />
          <CircularProgress />
        </div>
      </>
    );
  } else {
    buttons = (
      <>
        <Button onClick={() => (dialogBox.ok ? dialogBox.ok(textBoxValue) : setDialogBox(null))} color="info">
          <Trans> Ok </Trans>
        </Button>
      </>
    );
  }

  return (
    <Dialog
      open
      onClose={() =>
        !isNull(dialogBox.canDismiss) && !dialogBox.canDismiss
          ? {
              /* skip */
            }
          : dialogBox.cancel
          ? dialogBox.cancel()
          : dialogBox.no
          ? dialogBox.no()
          : setDialogBox(null)
      }
      maxWidth={dialogBox.width ? dialogBox.width : "sm"}
      fullWidth
    >
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
};

export default withStyles(componentStyle)(DialogBox);
