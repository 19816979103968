import { Trans } from "@lingui/macro";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment, DeleteOutline, Edit } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BaseSelect from "components/Common/BaseSelector";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { StructureSj } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull } from "tools";
import { CrupdateCustomerSubsidairyLink, DeleteCustomerSubsidairyLink } from "../../actions/Customer.Actions";
import { CrupdateSubsidairyLink } from "../../actions/Supplier.Actions";
import DialogCustomerSj from "./DialogCustomerSj";
import { GetAllSubsidairies } from "../../actions/Supplier.Actions";
import { removeHistoryJob } from "store/History/HistoryActions";

const TabCustomerSj = function({ setCustomer, customer, isEditable, allSjs, availableSjs, defaultLang }) {
  var [customerSjIndex, setCustomerSjIndex] = useState(null);
  var [columbusSubs, setColumbusSubs] = useState([]);
  var [openDialog, setOpenDialog] = useState(false);
  var [newSjIdentifier, setNewSjIdentifier] = useState(null);
  var subsidiaries = customer.customerSubsidiaries;

  useEffect(() => {
    GetAllSubsidairies()
      .then(r => {
        setColumbusSubs(r);
      })
      .catch(e => console.log(e));
  }, []);

  ///------------------------------
  /// Crée le composant graphique pour l'ajout d'un lien Customer/SJ
  ///------------------------------
  function showAddSj() {
    if (isArrayNullOrEmpty(columbusSubs)) return <CircularProgress size={30} />;
    return (
      <div>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Add link</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11}>
                <BaseSelect
                  items={columbusSubs}
                  displayedProperties={{
                    keyProperty: "code",
                    valueProperty: "label"
                  }}
                  transformValue={item => {
                    return item.code + " - " + item.label;
                  }}
                  value={newSjIdentifier}
                  onChange={event => {
                    setNewSjIdentifier(event.value);
                  }}
                  isSearchable={true}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <IconButton aria-label="Add" onClick={() => addSj()}>
                  <Add />
                </IconButton>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }

  function addSj() {
    if (isNull(newSjIdentifier)) return;
    if (customer.customerSubsidiaries.some(s => s.subsidiaryCode === newSjIdentifier)) return;
    let newSubsidairy = { customerId: 0, subsidiaryCode: newSjIdentifier, appSourceId: "" };
    let subsidairies = customer.customerSubsidiaries;
    setCustomer({ ...customer, customerSubsidiaries: [...subsidairies, newSubsidairy] });
  }

  function removeSubsidairy(subsidairyCode) {
    if (isNull(subsidairyCode)) return;
    let subsidairies = customer.customerSubsidiaries.filter(s => s.subsidiaryCode !== subsidairyCode);
    setCustomer({ ...customer, customerSubsidiaries: subsidairies });
    DeleteCustomerSubsidairyLink(customer.thirdPartyId, subsidairyCode);
  }

  function showDetail(sjIndex) {
    setOpenDialog(true);
    setCustomerSjIndex(sjIndex);
  }

  function convertDataToReactTable() {
    if (isNull(subsidiaries)) return [];

    return subsidiaries.map(subsidairy => {
      var icon = "";
      if (subsidairy.action !== "NONE") {
        var iconStatus = "";
        var iconTitle = "";
        if (subsidairy.action === "INSERT") {
          iconTitle = <Trans> InsertSj </Trans>;
          iconStatus = <Add />;
        } else if (subsidairy.action === "UPDATE") {
          iconTitle = <Trans> UpdateSj </Trans>;
          iconStatus = <Edit />;
        }

        // icon = <Tooltip title={iconTitle}>{iconStatus}</Tooltip>;
      }

      return {
        subsidairyCode: subsidairy.subsidiaryCode,
        appSourceId: subsidairy.appSourceId,
        actions: (
          // we've added some custom button actions
          <div className="">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                let itemIndex = subsidairy.subsidiaryCode;
                showDetail(itemIndex);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>
            {icon}
            <IconButton
              onClick={() => {
                removeSubsidairy(subsidairy.subsidiaryCode);
              }}
            >
              <DeleteOutline />
            </IconButton>
          </div>
        )
      };
    });
  }

  var showAddSj = !!isEditable ? showAddSj() : "";
  return (
    <div>
      {showAddSj}
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Existing links</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={convertDataToReactTable()}
            columns={[
              {
                Header: <Trans>Actions</Trans>,
                accessor: "actions",
                sortable: false,
                filterable: false
              },
              {
                Header: <Trans>Sj</Trans>,
                accessor: "subsidairyCode",
                filterable: true
              },
              {
                Header: <Trans>Subsidairy_AppSourceId</Trans>,
                accessor: "appSourceId",
                filterable: true,
                headerStyle: { textAlign: "left" }
              }
            ]}
            defaultPageSize={5}
            showPaginationBottom={true}
            showPageSizeOptions={false}
            className="-striped -highlight"
            showFilters={true}
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
      <DialogCustomerSj
        openDialog={openDialog}
        setOpenDialog={v => setOpenDialog(v)}
        customerSjIndex={customerSjIndex}
        availableSjs={availableSjs}
        sjs={allSjs}
        isEditable={isEditable}
        defaultLang={defaultLang}
      />
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    // customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null,
    availableSjs: state.AuthenticationReducer.user.structureTpc.sjs,
    allSjs: state.MasterValueReducer[StructureSj]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabCustomerSj));
