import * as Actions from "../actions/types";
import LocalStorageHelper from "services/common/LocalStorageHelper";

var localStorageHelper = new LocalStorageHelper();
var getPref = localStorageHelper.get("Preferences");

const initialState = {
  criterias: { pageSize: 10 },
  searchMode: !!getPref && !!getPref.Pref && !!getPref.Pref.Search ? getPref.Pref.Search : "Simple",
  searchResult: !!getPref && !!getPref.Pref && !!getPref.Pref.Result ? getPref.Pref.Result : "Table",
  searchScope: "",
  results: null,
  error: null
};

export default function TP_SearchReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TP_COPERNIC_SET_SEARCH_MODE:
      return {
        ...state,
        searchMode: action.searchMode
      };

    case Actions.TP_COPERNIC_SET_SEARCH_CRITERIA:
      return {
        ...state,
        criterias: action.criterias
      };

    case Actions.TP_COPERNIC_SET_SEARCH_COMPLEX_REQUEST:
      return {
        ...state,
        complexCriterias: action.complexCriterias,
        complexQuery: action.complexQuery
      };

    case Actions.TP_COPERNIC_SEARCH_CLEAN:
      return {
        ...initialState,
        searchScope: action.searchScope
      };
    case Actions.TP_COPERNIC_SEARCH_BEGIN:
      return {
        ...state,
        searchScope: action.searchScope,
        results: [],
        error: null,
        isSearching: true
      };
    case Actions.TP_COPERNIC_SEARCH_END:
      return {
        ...state,
        searchScope: action.searchScope,
        results: action.results,
        isSearching: false
      };
    case Actions.TP_COPERNIC_SEARCH_ERROR:
      return {
        ...state,
        error: action.error
      };
    case Actions.TP_COPERNIC_PAYMENT_CHAR_CLOSE:
      // remove last
      return {
        ...state,
        openDialogDetail: null
      };
    case Actions.TP_COPERNIC_PAYMENT_CHAR_OPEN:
      return {
        ...state,
        openDialogDetail: {
          type: "paymentCharacteristic",
          id: action.paymentCharacteristic.identifier
        }
      };
    default:
      return state;
  }
}
