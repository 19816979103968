import React, {useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";

import { Trans } from "@lingui/macro";
import { AntTab, AntTabs, TabPanel } from 'components/Common/Tabs';
import { Grid } from '@material-ui/core';
import PartThirdParties from './main/PartThirdParties';
import PartGeneral from './main/PartGeneral';
import PartCompanies from './main/PartCompanies';

function CountryPartMain({country, setCountry, masterValues, defaultLang}) {
    const [tabIdx, setTabIdx] = useState(0)

    var tabGeneral = (<Trans> Admin_Country_General </Trans>);
    var tabThirdParties = (<Trans> Admin_Country_ThirdParties </Trans>);
    var tabCompanies = (<Trans> Admin_Country_Companies </Trans>);

    return (
        <Grid container>
            <Grid item xs={2} sm={2} md={2}>
                <AntTabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabIdx}
                    onChange={(e, value) => setTabIdx(value)}
                    aria-label="Vertical tabs supplier"
                >
                    <AntTab label={tabGeneral} />
                    <AntTab label={tabThirdParties} />
                    <AntTab label={tabCompanies} />
                </AntTabs>
            </Grid>
            <Grid item xs={10} sm={10} md={10} style={{padding: 20}}>
                <TabPanel value={tabIdx} index={0}>
                    <PartGeneral country={country} setCountry={setCountry} masterValues={masterValues} defaultLang={defaultLang} />
                </TabPanel>
                <TabPanel value={tabIdx} index={1}>
                    <PartThirdParties country={country} setCountry={setCountry} defaultLang={defaultLang} />
                </TabPanel>
                <TabPanel value={tabIdx} index={2}>
                    <PartCompanies country={country} setCountry={setCountry} defaultLang={defaultLang} />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default withStyles(adminStyle)(CountryPartMain);