import React, { useState } from "react";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";

// tabs
import TabSiteGeneral from "./SiteDetail/TabSiteGeneral";
import TabSiteActivities from "./SiteDetail/TabSiteActivities";
import TabSiteAnnexe from "./SiteDetail/TabSiteAnnexe";
import TabSiteMedia from "./SiteDetail/TabSiteMedia";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

//lingui
import { Trans } from "@lingui/macro";
import TabSiteHistory from "./SiteDetail/TabSiteHistory";

function SiteGeneral({ site, setSite, defaultLang, isEditable, classes, errors }) {
  const [tabIdx, setTabIdx] = useState(0);

  var tabGeneral = <Trans>General</Trans>;
  var tabActivities = <Trans>Activities</Trans>;
  var tabAnnexe = <Trans>Site Annexe</Trans>;
  var tabMedia = <Trans>Site Media</Trans>;
  var tabHistory = <Trans>History</Trans>;

  return (
    <div className="divContainer">
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={tabGeneral} />
              <AntTab label={tabActivities} />
              <AntTab label={tabAnnexe} />
              <AntTab label={tabMedia} />
              <AntTab label={tabHistory} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabSiteGeneral site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} errors={errors} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabSiteActivities site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} errors={errors} classes={classes}/>
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabSiteAnnexe site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <TabSiteMedia site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={4}>
              <TabSiteHistory site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SiteGeneral);
