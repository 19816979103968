import React, { useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";

import { Trans } from "@lingui/macro";
import { connect } from 'react-redux';
import { AntTab, AntTabs, TabPanel } from 'components/Common/Tabs';
import PartStates from './regions/PartStates';
import PartCounties from './regions/PartCounties';
import { Grid } from '@material-ui/core';

function CountryPartRegion({ country, setCountry, masterValues, defaultLang }) {
    const [tabIdx, setTabIdx] = useState(0)

    var tabStates = (<Trans> Admin_Country_States </Trans>);
    var tabCounties = (<Trans> Admin_Country_Counties </Trans>);

    return (
        <Grid container>
            <Grid item xs={2} sm={2} md={2}>
                <AntTabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabIdx}
                    onChange={(e, value) => setTabIdx(value)}
                    aria-label="Vertical tabs supplier"
                >
                    <AntTab label={tabStates} />
                    <AntTab label={tabCounties} />
                </AntTabs>
            </Grid>
            <Grid item xs={10} sm={10} md={10} style={{ padding: 20 }}>
                <TabPanel value={tabIdx} index={0}>
                    <PartStates
                        country={country}
                        setCountry={setCountry}
                        masterValues={masterValues}
                        defaultLang={defaultLang}
                    />
                </TabPanel>
                <TabPanel value={tabIdx} index={1}>
                    <PartCounties
                        counties={country.counties}
                        setCounties={c => setCountry({ ...country, counties: c })} />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        masterValues: state.MasterValueReducer
    };
};

export default connect(mapStateToProps)(withStyles(adminStyle)(CountryPartRegion));