import React from "react";
import MainArea from "./part/detail/area/MainArea";
import MainCdg from "./part/detail/cdg/MainCdg";
import MainComp from "./part/detail/comp/MainComp";
import MainDir from "./part/detail/dir/MainDir";
import MainDiv from "./part/detail/div/MainDiv";
import MainEp from "./part/detail/ep/MainEp";
import MainErt from "./part/detail/ert/MainErt";
import MainUe from "./part/detail/ue/MainUe";
import MainUp from "./part/detail/up/MainUp";
import MainSj from "./part/detail/sj/MainSj";
import CardError from "components/Card/CardError";

const StructureDetailContent = ({ structure, setStructure, masterValues, defaultLang, errors, isEditable, isTagEditable }) => {
  let content;
  if (structure.structureTypeCode === "AREA") {
    content = (
      <MainArea
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "CDG") {
    content = (
      <MainCdg
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "COMP") {
    content = (
      <MainComp
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "DIR") {
    content = (
      <MainDir
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "DIV") {
    content = (
      <MainDiv
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "EP") {
    content = (
      <MainEp
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
      />
    );
  } else if (structure.structureTypeCode === "ERT") {
    content = (
      <MainErt
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
        isTagEditable={isTagEditable}
      />
    );
  } else if (structure.structureTypeCode === "SJ") {
    content = (
      <MainSj
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
        isTagEditable={isTagEditable}
      />
    );
  } else if (structure.structureTypeCode === "UE") {
    content = (
      <MainUe
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
        isTagEditable={isTagEditable}
      />
    );
  } else if (structure.structureTypeCode === "UP") {
    content = (
      <MainUp
        structure={structure}
        setStructure={setStructure}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={errors}
        isEditable={isEditable}
        isTagEditable={isTagEditable}
      />
    );
  } else {
    content = <CardError error={{ message: "This structureType is not implemented: " + structure.structureTypeCode + "." }} />;
  }

  return (
    <div className="divContainer">
      <form autoComplete="off">{content}</form>
    </div>
  );
};

export default StructureDetailContent;
