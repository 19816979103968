import React from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { isArrayNullOrEmpty } from "../../../tools";

import { RootStore } from "store/RootStore";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Selector from "components/Common/Selector";
import { FormLabel } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import { TpBenificiaryType } from "store/MasterValue/MasterValueTypes";

const TabBeneficiaryGeneral = function({ beneficiaryCtx, isEditable, onValueChanged, defaultLang }) {
  var beneficiary = beneficiaryCtx.beneficiary;

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Common</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <MasterValueAutocomplete
                    mvType={TpBenificiaryType}
                    isEditable={isEditable}
                    selectedValue={beneficiary.benificiaryTypeCode}
                    onValueChange={c => onValueChanged({ ...beneficiary, benificiaryTypeCode: c })}
                    label={<Trans>Type</Trans>}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    beneficiaryCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].beneficiaryCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onValueChanged: beneficiary => {
      dispatch(Actions.EditBeneficiary(beneficiary));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabBeneficiaryGeneral));
