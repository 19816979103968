import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React from "react";
// core components
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// react component for creating dynamic tables
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

const style = theme => ({
  head: {
    color: theme.palette.common.white,
    backgroundColor: "#f73378",
    fontSize: 15
  },
  margin: {
    marginTop: "50px"
  },
  iconButton: {
    padding: 10
  }
});

class StructuresResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsHeader: this.props.columnsHeader,
      newCode: null
    };
  }

  ConvertDatas() {
    if (!this.props.datas) return [];

    return this.props.datas;
  }

  render() {
    var errorProperty = false;
    if (this.state.errorNewCode) errorProperty = true;

    const { classes, pageSize } = this.props;
    const defaultPageSize = pageSize === null || pageSize === undefined ? 10 : pageSize;
    return (
      <Card className={classes.margin}>
        <CardHeader icon>
          <div className="blockHeaderResult">
            <div className="blockHeaderResultItem">
              <IconButton className={classes.iconButton} aria-label="Menu">
                <LibraryBooks color="secondary" />
              </IconButton>
            </div>

            <div className="blockHeaderResultItem">
              <Typography ariant="display2">
                <Trans> Result Search MasterValue </Trans>
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <ReactTable
            className={classes.margin}
            data={this.ConvertDatas()}
            columns={this.props.columnsHeader}
            defaultPageSize={defaultPageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
    );
  }
}

StructuresResult.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(StructuresResult);
