import { Trans } from "@lingui/macro";
import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert } from "@material-ui/lab";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import cx from "classnames";
import DialogBox from "components/DialogBox/DialogBox";
import Muted from "components/Typography/Muted";
import * as StructureActions from "module/structure/store/actions";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import Button from "../../../components/CustomButtons/Button";
import { SiteUpdate, SiteGetDetailed } from "../actions/SiteActions";
import CardError from "../components/CardError";
import { CloseSite } from "../store/actions";
import SiteDetailContent from "./SiteDetailContent";
import { LoadMasterValues, LocationActivityValue, LocationActivityType } from "store/MasterValue/MasterValueTypes";
import { OpenWorkflow } from "module/workflow/store/actions";

function SiteDetail({ siteId, defaultLang, userInfo, openStructure, openWorkflow, classes }) {
  const initialState = { isLoading: false, isEditable: false };
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [site, setSite] = useState();
  var [dialogBox, setDialogBox] = useState(null);

  useEffect(() => {
    setState(initialState);
    setSite();
  }, [siteId]);

  if (!siteId) {
    return <></>;
  }

  const hasChanged = () => {
    // technique pour retirer la propriété localId pour éviter un faux-positif
    var local = {
      ...site,
      structures: site.structures.map(s => {
        return (({ localId, ...o }) => o)(s);
      })
    };

    if (local.activities) {
      local.activities = local.activities.sort((a, b) => a.activityCode.localeCompare(b.activityCode));
    }
    if(local.activityChars){
      local.activityChars = local.activityChars.sort((a, b) => a.locationActivityIdentifier.localeCompare(b.locationActivityIdentifier) || a.locationActivityTypeCode.localeCompare(b.locationActivityTypeCode));
    }

    let backup;
    if (state.backup) {
      backup = JSON.parse(state.backup);
      if (backup.activities) {
        backup.activities = backup.activities.sort((a, b) => a.activityCode.localeCompare(b.activityCode));
      }
      if(backup.activityChars){
        backup.activityChars = backup.activityChars.sort((a, b) => a.locationActivityIdentifier.localeCompare(b.locationActivityIdentifier) || a.locationActivityTypeCode.localeCompare(b.locationActivityTypeCode));
      }
    }
    return JSON.stringify(local) !== JSON.stringify(backup);
  };

  const detailPanel =
    classes.detailPanel +
    " " +
    cx({
      [classes.detailPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
    });

  const closeDetail = confirmClose => {
    if (hasChanged() && !confirmClose) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeDetail(true) });
    } else {
      setDialogBox(null);
      dispatch(CloseSite(siteId));
    }
  };

  const validateSite = site => {
    // REgex pour valider les coordonnées GPS
    const regexGPS = /^-?[\d]{1,3}[.][\d]+$/;
    var err = [];

    if (site.gpsCoordinates.latitude) {
      if (!regexGPS.test(site.gpsCoordinates.latitude)) {
        err.push({ code: "latitude", messageFr: "Latitude doit être décimale", messageEn: "Latitude must be decimal" });
      }
    } else {
      err.push({ code: "latitude", messageFr: "Latitude est requis", messageEn: "Latitude is required" });
    }

    if (site.gpsCoordinates.longitude) {
      if (!regexGPS.test(site.gpsCoordinates.longitude)) {
        err.push({ code: "longitude", messageFr: "Longitude doit être décimale", messageEn: "Longitude must be decimal" });
      }
    } else {
      err.push({ code: "longitude", messageFr: "Longitude est requis", messageEn: "Longitude is required" });
    }

    // if (!isNullOrEmpty(site)) {
    //   if (!isNullOrEmpty(site.gpsCoordinates)) {
    //     if (isNaN(site.gpsCoordinates.latitude)) {
    //       err.push({ code: "latitude", messageFr: "Latitude doit être décimale", messageEn: "Latitude must be decimal" });
    //     }
    //     if (isNaN(site.gpsCoordinates.longitude)) {
    //       err.push({ code: "longitude", messageFr: "Longitude doit être décimale", messageEn: "Longitude must be decimal" });
    //     }
    //   }
    // }
    setErrors(err);
    return err;
  };

  const saveSite = (confirmSave, comment) => {
    if (userInfo.needApprobation && !confirmSave) {
      setDialogBox({
        type: "okCancel",
        textbox: { title: <Trans> WF_AuthorComment </Trans>, rows: 2 },
        message: (
          <div>
            <Trans> WF_ApprobationWillSent </Trans>
            <Trans> WF_ItemWillBeLocked </Trans>
          </div>
        ),
        ok: text => {
          saveSite(true, text);
          setDialogBox(null);
        }
      });
      return;
    }

    setState({ ...state, isLoading: true, error: null, validationErrors: null });
    SiteUpdate(
      {
        location: { ...site, structures: site.structures.filter(s => s.action !== "DELETE"), medias: site.medias.filter(s => s.action !== "DELETE") },
        wkfComment: comment
      },
      s => {
        if (userInfo.needApprobation) {
          dispatch(CloseSite(siteId));
          openWorkflow("LocationCrupdate", s);
        }
        else {
          SiteGetDetailed(
            siteId,
            siteResult => {
              setState({
                ...state,
                isLoading: false,
                backup: JSON.stringify(siteResult),
                isEditable:
                (siteResult?.workflowLockId ?? 0) === 0 &&
                  (userInfo.isAdmin ||
                  (userInfo.canEdit &&
                    userInfo.structures.sjs.some(sj => sj.countryIdentifier === siteResult.address_CountryCode) &&
                    userInfo.structures.erts.some(ert => siteResult.structures.find(er => er.ertIdentifier === ert.identifier)))
          )});
            },
            e => setState({ ...state, isLoading: false, error: e })
          );
        }
      },
      e => setState({ ...state, isLoading: false, error: e, validationErrors: null }),
      e => setState({ ...state, isLoading: false, error: null, validationErrors: e })
    );
  };

  var dialogTitle = <>{siteId}</>;
  var dialogContent = <></>;
  var dialogActions = <></>;
  let lockedBanner = <></>;
  if (site?.workflowLockId ?? 0 !== 0) {
    lockedBanner = (
      <Alert severity="warning">
        <Trans> WF_LockedUntilApprobation </Trans>
      </Alert>
    );
  }
  if (state.isLoading) {
    dialogContent = <CircularProgress />;
  } else if (state.error) {
    dialogContent = <CardError error={state.error} />;
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeDetail(false)} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );
  } else if (!site) {
    setState({ ...state, isLoading: true });

    SiteGetDetailed(
      siteId,
      siteResult => {
        setState({
          ...state,
          isLoading: false,
          backup: JSON.stringify(siteResult),
          isEditable:
          (siteResult?.workflowLockId ?? 0) === 0 &&
            (userInfo.isAdmin ||
            (userInfo.canEdit &&
              userInfo.structures.sjs.some(sj => sj.countryIdentifier === siteResult.address_CountryCode) &&
              userInfo.structures.erts.some(ert => siteResult.structures.find(er => er.ertIdentifier === ert.identifier)))
    )});
        LoadMasterValues([LocationActivityType, LocationActivityValue]);
        setSite(siteResult);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  } else {
    var btnSave = "";
    if (hasChanged()) {
      btnSave = (
        <Button
          onClick={() => {
            var err = validateSite(site);
            if (isArrayNullOrEmpty(err)) saveSite();
          }}
          color="info"
        >
          <Trans> Save </Trans>
        </Button>
      );
    }

    var btnReload = "";
    btnReload = (
      <Button
        onClick={() => {
          setState({ ...state, validationErrors: null, error: null });
          setSite(null);
        }}
      >
        <Trans> Reload </Trans>
      </Button>
    );

    dialogContent = (
      <div className={detailPanel}>
        {lockedBanner}
        <SiteDetailContent
          site={site}
          setSite={setSite}
          isEditable={state.isEditable}
          defaultLang={defaultLang}
          openStructure={openStructure}
          errors={errors}
        />
      </div>
    );
    dialogActions = (
      <DialogActions>
        {btnSave}
        {btnReload}
        <Button onClick={() => closeDetail(false)} color={btnSave === "" ? "info" : ""}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );

    dialogTitle = (
      <span>
        {site.name} ({site.id})
      </span>
    );
  }

  var dialog = (
    <Dialog open={true} onClose={() => closeDetail(false)} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">
          <Muted>{dialogTitle}</Muted>
        </Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {dialogContent}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );

  return (
    <>
      {dialog}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapDispatchToProps = dispatch => {
  return {
    openStructure: (structureType, identifiant) => {
      dispatch(StructureActions.OpenStructure(structureType, identifiant));
    },
    openWorkflow: (workflowTypeCode, workflowId) => {
      dispatch(OpenWorkflow(workflowTypeCode, workflowId));
    }
  };
};

const mapStateToProps = state => {
  return {
    userInfo: {
      structures: state.AuthenticationReducer.user.structureSites,
      isAdmin: HasRight("site.admin"),
      canEdit: HasRight("site.edit"),
      needApprobation: HasRight("site.edit") && !HasRight("site.approve")
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(structureStyle)(SiteDetail));
