import * as Actions from "./types";
import { ApimPost } from "components/Common/ApimSender";

export function MigrateCustomers(migrationParameter) {
  return dispatch => {
    dispatch({
      type: Actions.ADMIN_BEGIN_ACTION
    });

    ApimPost(
      "api/TpCopernic/Customers/Migrate",
      migrationParameter,
      responseJson => {
        dispatch({
          type: Actions.ADMIN_END_ACTION,
          result: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.ADMIN_ERROR,
          error: error.response.data
        });
      }
    );
  };
}

export function GetSuppliers(parameters, callbackFn, errorFn) {
  return ApimPost("api/TpCopernic/Suppliers/Migrate/Before?StartPage=1&PageSize=10", parameters, c => callbackFn(c), e => errorFn(e));
}

export function GetCustomers(parameters, callbackFn, errorFn) {
  return ApimPost("api/TpCopernic/Customers/Migrate/Before?StartPage=1&PageSize=10", parameters, c => callbackFn(c), e => errorFn(e));
}

export function MigrateSuppliers(migrationParameter) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.ADMIN_BEGIN_ACTION
    });

    ApimPost(
      "api/TpCopernic/Suppliers/Migrate",
      migrationParameter,
      responseJson => {
        dispatch({
          type: Actions.ADMIN_END_ACTION,
          result: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.ADMIN_ERROR,
          error: error.response.data
        });
      }
    );
  };
}

export function CleanBus(cleaningParameters) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.ADMIN_BEGIN_ACTION
    });

    ApimPost(
      "api/Jobs/CleanBusAndIdErp",
      cleaningParameters,
      responseJson => {
        dispatch({
          type: Actions.ADMIN_END_ACTION,
          result: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.ADMIN_ERROR,
          error: error.response.data
        });
      }
    );
  };
}
