import GlobalHealth from "module/supervision/containers/GlobalHealth";
import LogsExplorer from "module/supervision/containers/LogsExplorer";
import Favorite from "@material-ui/icons/Favorite";
import { Search } from "@material-ui/icons";

const supervisionRoutes = [
  {
    path: "/supervision/globalHealth",
    name: "Global Health",
    short: "Supervision",
    icon: Favorite,
    useAsComponentSiteInMenu: true,
    component: GlobalHealth
  },
  ,
  {
    path: "/supervision/logsExplorer",
    name: "Logs Explorer",
    short: "Supervision",
    icon: Search,
    useAsComponentSiteInMenu: true,
    component: LogsExplorer
  }
];

export default supervisionRoutes;
