import React from 'react';
import { Trans } from "@lingui/macro";
import { Card, CardContent, CardHeader } from '@material-ui/core';

export default function AdminDashboard() {
    return (
        <Card>
            <CardHeader>
                <Trans> Admin_Dashboard_Title </Trans>
            </CardHeader>
            <CardContent>

            </CardContent>
        </Card>
    );
}