import * as Actions from "module/admin/store/actions/Migration.Actions";
import * as Types from "module/admin/store/actions/types";
import React from "react";
import { connect } from "react-redux";

import { CircularProgress } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table-6";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import MigrationResult from "./MigrationResult";
import MigrationStructureSelection from "./MigrationStructureSelection";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/admin/adminStyle.jsx";

//lingui
import { Trans } from "@lingui/macro";

function CustomerMigration({ admin, launchMigration, cleanResult, classes }) {
  const [migrationParameters, setMigrationParameters] = React.useState(null);

  const [customerToShow, setCustomerToShow] = React.useState(null);

  var working = admin.working;
  var migrationReport = admin.result;
  var error = admin.error;

  var componentResult = "";
  if (working) {
    componentResult = <CircularProgress />;
  } else if (!!migrationReport && typeof migrationReport !== "undefined") {
    componentResult = <MigrationResult migrationReport={migrationReport} />;
  }

  var launchButton = "";
  if (!working) {
    launchButton = (
      <Button
        type="submit"
        color="info"
        onClick={() => {
          launchMigration(migrationParameters);
        }}
      >
        <Trans>Launch Migration</Trans>
      </Button>
    );
  }

  function changeParameters(parameters) {
    cleanResult();
    setMigrationParameters(parameters);
    customerInformations(parameters);
  }
  const reactTableColumns = [
    {
      Header: <Trans>Customer</Trans>,
      accessor: "customer"
    },
    {
      Header: <Trans>identifier</Trans>,
      accessor: "identifier"
    },
    {
      Header: <Trans>sjCode</Trans>,
      accessor: "sjCode"
    }
  ];

  var customerToShowResult = "";
  var pageSize = 10;
  var itemsToShow = "";

  const customerInformations = parameters => {
    working = false;
    Actions.GetCustomers(
      parameters,
      result => {
        setCustomerToShow(result.items);
      },
      e => {
        error = e;
        working = true;
      }
    );
    working = true;
  };
  if (!customerToShow || customerToShow.length === 0) {
    itemsToShow = [];
  } else {
    customerToShow.forEach(c => {
      itemsToShow = c.map(r => {
        return {
          customer: r.aN8Iris2,
          identifier: r.identifier,
          sjCode: r.sjCode
        };
      });
    });
    customerToShowResult = <ReactTable data={itemsToShow} columns={reactTableColumns} defaultPageSize={pageSize} showPaginationBottom={true} />;
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <MigrationStructureSelection onChange={changeParameters} withUe={false} />
        <div className={classes.flexRight}>{launchButton}</div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {customerToShowResult}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {componentResult}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    admin: state.Admin_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    launchMigration: migrationParameters => {
      dispatch(Actions.MigrateCustomers(migrationParameters));
    },
    cleanResult: () => {
      dispatch({
        type: Types.ADMIN_CLEANRESULT
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerMigration));
