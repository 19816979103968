import { Trans } from "@lingui/macro";
import { Button, Checkbox, Chip, FormControlLabel, FormLabel, Tooltip, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check, Lock, LockOpen } from "@material-ui/icons";
import altaresImage from "assets/img/externalrepositories/altares.png";
import infolegaleImage from "assets/img/externalrepositories/infolegale.png";
import infolegaleScoreImage from "assets/img/externalrepositories/infolegaleScore.png";
import logoBouygues from "assets/img/thirdPartyCategories/logo_bouygues.svg";
import logoColas from "assets/img/thirdPartyCategories/logo_colas.png";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { displayWarning } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { CommonLanguage, TpCompanyComplianceScore, TpIndustryCode, TpThirdPartyCategory } from "store/MasterValue/MasterValueTypes";
import { emptyIfNull, isArrayNullOrEmpty, isNull, isNullOrEmpty } from "tools";
import { OpenCompany } from "../../companies/store/actions";
import * as Actions from "../../store/actions";
import GroupAutocomplete from "module/tpColumbus/groups/GroupAutocomplete";

const TabThirdPartyGeneral = function({ thirdPartyCtx, masterValues, defaultLang, onThirdPartyValueChanged, openCompany, classes, errors }) {
  var thirdParty = thirdPartyCtx.thirdParty;
  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : thirdPartyCtx.isEditable;
  var isEditableGroup = thirdPartyCtx.isEditableGroup;
  var isManual = !thirdParty.isFromInfolegal && !thirdParty.isFromAltares;

  var isCurrentUserAdmin = false;
  if (HasRight("application.edit")) isCurrentUserAdmin = true;

  var disableHeadQuarter = !isEditable || !isCurrentUserAdmin || (!isManual && !thirdParty.isFromAltares);

  var externalRepository;
  if (thirdParty.isFromInfolegal) {
    var externalRepositoryScore = "";
    if (!isNull(thirdParty.company) && !isNullOrEmpty(thirdParty.company.externalRepositoryScore)) {
      var score = thirdParty.company.externalRepositoryScore;
      var textColor = "#000000";
      if (score >= 17) textColor = "#17b760";
      else if (score >= 13) textColor = "#a7d972";
      else if (score >= 9) textColor = "#caca00";
      else if (score >= 6) textColor = "#ffc617";
      else if (score >= 3) textColor = "#ff7b23";
      else if (score >= 1) textColor = "#ff0000";

      externalRepositoryScore = (
        <Tooltip
          title={
            <>
              <img src={infolegaleScoreImage} alt="" />
            </>
          }
          placement="bottom"
        >
          <Chip
            variant="outlined"
            size="small"
            style={{ color: textColor, borderColor: textColor }}
            label={
              <>
                <Trans> Score</Trans> {score}
              </>
            }
          />
        </Tooltip>
      );
    }
    let externalRepositoryCompliance = "";
    if (thirdParty.company && thirdParty.company.compliance) {
      let chipColor = "#CCC";
      let mvScoreCode = thirdParty.company.compliance.scoreCode;
      let mvScore = masterValues[TpCompanyComplianceScore].find(s => s.code === mvScoreCode);
      let chipText = <></>;
      if (mvScore) {
        chipText = GetMasterValueLabel(mvScore, defaultLang);
      }
      if (mvScoreCode === "GREEN") {
        chipColor = "#00CC00";
      } else if (mvScoreCode === "ORANGE") {
        chipColor = "#FFA500";
      } else if (mvScoreCode === "RED") {
        chipColor = "#CC0000";
      }

      externalRepositoryCompliance = (
        <Chip
          variant="outlined"
          size="small"
          style={{ color: chipColor, borderColor: chipColor }}
          label={
            <>
              <Trans>TP_Compliance_Score</Trans> {chipText}
            </>
          }
          onClick={() => openCompany(thirdParty.companyIdentifier, 3)}
        />
      );
    }

    externalRepository = (
      <span>
        <img src={infolegaleImage} width="60px" alt="External Respository src" />
        {externalRepositoryScore}
        {externalRepositoryCompliance}
      </span>
    );
  } else if (thirdParty.isFromAltares) {
    externalRepository = <img src={altaresImage} width="40px" alt="External Respository src" />;
  } else {
    externalRepository = <Trans>ManualSource</Trans>;
  }

  var tpCategory = thirdParty.thirdPartyCategoryCode;
  var tpCategoryField = "";

  if ((isEditable && thirdParty.countryCode !== "FR") || isCurrentUserAdmin) {
    tpCategoryField = (
      <MasterValueAutocomplete
        isEditable
        isRequired
        mvType={TpThirdPartyCategory}
        selectedValue={thirdParty.thirdPartyCategoryCode}
        onValueChange={value => onThirdPartyValueChanged({ ...thirdParty, thirdPartyCategoryCode: value })}
      />
    );
  } else {
    if (tpCategory === "BOU")
      tpCategoryField = (
        <span>
          <img src={logoBouygues} width="60px" alt="Bouygues" /> {GetMasterValueLabel(thirdParty.thirdPartyCategory, defaultLang)}
        </span>
      );
    else if (tpCategory === "COL")
      tpCategoryField = (
        <span>
          <img src={logoColas} width="60px" alt="Colas" /> {GetMasterValueLabel(thirdParty.thirdPartyCategory, defaultLang)}
        </span>
      );
    else if (thirdParty.thirdPartyCategoryCode) {
      let tpCat = masterValues[TpThirdPartyCategory].find(t => t.code === thirdParty.thirdPartyCategoryCode);
      tpCategoryField = <span>{GetMasterValueLabel(tpCat, defaultLang)}</span>;
    }
  }

  var industryCodeToDisplay = thirdParty.industryCodes;
  if (
    !isNull(thirdParty.country) &&
    !isNull(thirdParty.country.codeIndustryCode) &&
    !industryCodeToDisplay.some(code => code.industryCodeIdentifier === thirdParty.country.codeIndustryCode)
  ) {
    industryCodeToDisplay.push({
      industryCodeIdentifier: thirdParty.country.codeIndustryCode
    });
  }

  const industryCodes = industryCodeToDisplay.map((item, key) => {
    var masterValue = masterValues[TpIndustryCode].find(m => m.code === item.industryCodeIdentifier);
    if (isNull(masterValue)) return "";

    var label = GetMasterValueLabel(masterValue, defaultLang);
    return (
      <div key={key}>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <Tooltip title={label}>
              <FormLabel>{item.industryCodeIdentifier}</FormLabel>
            </Tooltip>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {item.value}
          </GridItem>
        </GridContainer>
      </div>
    );
  });

  var companyButton = "";
  if (thirdParty.companyIdentifier > 0) {
    companyButton = (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans> Company </Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Button onClick={() => openCompany(thirdParty.companyIdentifier)}>
              <Trans> Link </Trans>
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }

  var statusIcon = (
    <IconButton
      disabled={!isCurrentUserAdmin}
      onClick={() =>
        onThirdPartyValueChanged({
          ...thirdParty,
          thirdPartyStatusCode: thirdParty.thirdPartyStatusCode !== null && thirdParty.thirdPartyStatusCode === "Closed" ? "Active" : "Closed"
        })
      }
      data-fieldName="thirdPartyStatusCode"
    >
      {thirdParty.thirdPartyStatusCode !== null && thirdParty.thirdPartyStatusCode === "Closed" ? (
        <Lock style={{ color: "red" }} />
      ) : (
        <LockOpen style={{ color: "green" }} />
      )}
    </IconButton>
  );
  const checkError = code => errors && errors.some(e => e.code === code);

  return (
    <div>
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>General informations</Trans>
                    {statusIcon}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {companyButton}
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>RS Colas</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          {displayWarning(thirdParty.warnings, "General/LongName")}
                          {/* Ce champ ne peut être édité car dépend de la compagnie */}
                          <FieldText
                            isEditable={isEditable}
                            id="longName"
                            value={thirdParty.customCompanyLongName}
                            onChange={e => {
                              var value = e.target.value;
                              if (value.length > 40) value = value.substring(0, 40);
                              onThirdPartyValueChanged({ ...thirdParty, customCompanyLongName: value });
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Group</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <GroupAutocomplete
                        groupId={thirdParty.groupId}
                        setGroupId={gId => onThirdPartyValueChanged({ ...thirdParty, groupId: gId })}
                        isEditable={isEditableGroup}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>NC Colas</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <FieldText
                            isEditable={isEditable}
                            id="longNameColas"
                            value={emptyIfNull(thirdParty.customLongName)}
                            onChange={e => {
                              var value = e.target.value;
                              if (value.length > 40) value = value.substring(0, 40);
                              onThirdPartyValueChanged({ ...thirdParty, customLongName: value });
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {displayWarning(thirdParty.warnings, "General/IsHeadQuarter")}
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={thirdParty.isHeadQuarter}
                            disabled={disableHeadQuarter}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, isHeadQuarter: e.target.checked })}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={<Trans>HeadQuarter</Trans>}
                      />
                      <IconButton
                        disabled={disableHeadQuarter}
                        onClick={() => onThirdPartyValueChanged({ ...thirdParty, isHeadQuarterForced: !thirdParty.isHeadQuarterForced })}
                        data-fieldName="isHeadQuarterForced"
                        data-fieldValue={thirdParty.isHeadQuarterForced ? "yes" : "no"}
                      >
                        {thirdParty.isHeadQuarterForced ? <Lock /> : <LockOpen />}
                      </IconButton>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Mark</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <FieldText
                            isEditable={isEditable && isManual}
                            id="mark"
                            value={emptyIfNull(thirdParty.mark)}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, mark: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Language</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <MasterValueAutocomplete
                            isEditable={isEditable}
                            isRequired
                            mvType={CommonLanguage}
                            selectedValue={thirdParty.languageCode}
                            onValueChange={value => onThirdPartyValueChanged({ ...thirdParty, languageCode: value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Country</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <CountryAutocomplete
                            countryCode={thirdParty.countryCode}
                            onChange={value => onThirdPartyValueChanged({ ...thirdParty, countryCode: value })}
                            defaultLang={defaultLang}
                            isEditable={isEditable}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Company informations</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel error={checkError("MIG")}>
                        <Trans>MIG</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      {/* A transformer en composant dédié */}
                      <FieldText
                        isEditable={isCurrentUserAdmin}
                        id="mig"
                        value={emptyIfNull(thirdParty.migCode)}
                        onChange={e => onThirdPartyValueChanged({ ...thirdParty, migCode: e.target.value })}
                        error={checkError("MIG")}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>RS Official</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      {/* Ce champ ne peut être édité car dépend de la compagnie */}
                      <FieldText
                        isEditable="false"
                        id="longNameOfficial"
                        value={thirdParty.company !== null && thirdParty.company !== undefined ? thirdParty.company.originalLongName : ""}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={5} sm={5} md={5}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Legal identifiers</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>ThirdPartyCategory</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          {tpCategoryField}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Source</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          {externalRepository}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Legal Identifier</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <FieldText
                            isEditable={false}
                            id="legalIdentifier"
                            value={emptyIfNull(thirdParty.legalIdentifier)}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, legalIdentifier: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Tax Code</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          {displayWarning(thirdParty.warnings, "General/TaxCode")}
                          <FieldText
                            isEditable={false}
                            id="taxCode"
                            value={emptyIfNull(thirdParty.taxCode)}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, taxCode: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Additional Identifier</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          {displayWarning(thirdParty.warnings, "General/AdditionalIdentifier")}
                          <FieldText
                            isEditable={false}
                            id="addtionalIdentifier"
                            value={emptyIfNull(thirdParty.addtionalIdentifier)}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, addtionalIdentifier: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>DUNS</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <FieldText
                            isEditable={
                              !thirdParty.isFromAltares &&
                              !thirdParty.isFromInfolegal &&
                              !!thirdParty.countryCode &&
                              thirdParty.countryCode !== "FR" &&
                              (!thirdParty.thirdPartyCategoryCode || thirdParty.thirdPartyCategoryCode !== "PAR") &&
                              isCurrentUserAdmin
                            }
                            id="duns"
                            value={emptyIfNull(thirdParty.duns)}
                            onChange={e => onThirdPartyValueChanged({ ...thirdParty, duns: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Industry code</Trans>
                  </h4>
                </CardHeader>
                <CardBody>{industryCodes}</CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onThirdPartyValueChanged: thirdParty => {
      dispatch(Actions.EditThirdParty(thirdParty));
    },
    openCompany: (companyId, tabIndex) => {
      dispatch(OpenCompany(companyId, tabIndex));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyGeneral));
