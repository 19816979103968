import { addDays, format, isWeekend, isEqual, parse, parseISO } from "date-fns";

export function isNull(value) {
  return value === null || value === undefined;
}

export function isArray(value) {
  return !isNull(value) && Array.isArray(value);
}

export function isArrayNullOrEmpty(value) {
  return value === null || value === undefined || value.length === 0;
}

export function isObjectNullOrEmpty(value) {
  return value === null || value === undefined;
}

export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === "";
}

export function emptyIfNull(value) {
  return value === null || value === undefined ? "" : value;
}

export function nullIfEmpty(value) {
  return value === "" ? null : value;
}

export function isFunction(value) {
  return !isNull(value) && value instanceof Function;
}

export function isObject(value) {
  return typeof value === "object";
}

export function isString(value) {
  return typeof value === "string";
}

export function isNum(val) {
  return !isNaN(val);
}

export function distinct(array) {
  return [...new Set(array)];
}

export function distinctBy(array, property) {
  return [...new Map(array.map(item => [item[property], item])).values()];
}

export function sortBy(array, property) {
  if (isArrayNullOrEmpty(array)) return [];
  return array.sort((a, b) => a[property].localeCompare(b[property]));
}

export function dateToUtc(datetimeValue) {
  if (!datetimeValue) return null;

  const dateParsed = new Date(datetimeValue);
  const year = dateParsed.getFullYear();
  const month = dateParsed.getMonth() + 1;
  const date = dateParsed.getDate();

  const stringDate = year + "-" + (month < 10 ? "0" : "") + month + "-" + (date < 10 ? "0" : "") + date + "T00:00:00";

  return stringDate;
}

export function nextOpenDay(date) {
  var dateYear = date.getFullYear();
  var closedDays = getClosedDays(dateYear);
  while (isWeekend(date) || closedDays.some(d => isEqual(d, date))) {
    date = addDays(date, 1);
    if (dateYear < date.getFullYear()) {
      dateYear++;
      closedDays = getClosedDays(dateYear);
    }
  }
  return date;
}

const getClosedDays = function(year) {
  /* Algorithme d'Oudin */
  var G = year % 19;
  var C = Math.floor(year / 100);
  var H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
  var I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
  var J = (year * 1 + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
  var L = I - J;
  var month = 3 + Math.floor((L + 40) / 44);
  var day = L + 28 - 31 * Math.floor(month / 4);

  var paques = new Date(year, month - 1, day);
  return [
    // Nouvel an
    new Date(year, 0, 1),
    // Fête du travail
    new Date(year, 4, 1),
    // Victoire 1945
    new Date(year, 4, 8),
    // Fête nationale
    new Date(year, 6, 14),
    // Assomption
    new Date(year, 7, 15),
    // Toussaint
    new Date(year, 10, 1),
    // Armistice
    new Date(year, 10, 11),
    // Noël
    new Date(year, 11, 25),
    // Pâques
    paques,
    // lundi de Pâques
    addDays(paques, 1),
    // jeudi de l'Ascension
    addDays(paques, 39),
    // Pentecôte
    addDays(paques, 49),
    // lundi de Pentecôte
    addDays(paques, 50)
  ];
};

export function formatToLongDate(date, lang) {
  if (!date) return "";
  var formatLang = lang.toUpperCase() === "FR" ? "dd MMM yyyy" : "MMM dd yyyy";
  if (typeof date === "object") {
    return format(date, formatLang);
  }
  return format(parseISO(date), formatLang);
}

export function formatDate(date, lang) {
  if (!date) return "";
  var formatLang = lang.toUpperCase() === "FR" ? "dd/MM/yyyy" : "MM/dd/yyyy";
  if (typeof date === "object") {
    return format(date, formatLang);
  }
  return format(parseISO(date), formatLang);
}

export function getFormatDate(lang) {
  return lang.toUpperCase() === "EN" ? "MM/dd/yyyy" : "dd/MM/yyyy";
}

export function toDate(dateString, lang) {
  if (!dateString) return null;

  var formatLang = "dd/MM/yyyy";
  if (lang.toUpperCase() === "EN") {
    formatLang = "MM/dd/yyyy";
  }
  return parse(dateString, formatLang, new Date());
}

export function secondsToTimespan(sec_num) {
  //sec_num = parseInt(sec_num, 10); // don't forget the second param
  sec_num = Math.abs(sec_num);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
}

export function isIbanValid(iban) {
  iban = iban.replace(/[\s-]/g, "");
  if (!iban.match(/^[\dA-Z]+$/)) return false;
  var len = iban.length;
  iban = iban.substr(4) + iban.substr(0, 4);
  for (var s = "", i = 0; i < len; i += 1) s += parseInt(iban.charAt(i), 36);
  for (var m = s.substr(0, 15) % 97, s = s.substr(15); s; s = s.substr(13)) m = (m + s.substr(0, 13)) % 97;
  return m === 1;
}

export function isBacsAccountValid(account) {
  account = account.replace(/[\s-]/g, "");
  return isNum(account) && account.length === 8;
}

export function isEftAccountValid(account) {
  account = account.replace(/[\s-]/g, "");
  return isNum(account) && account.length <= 11;
}

export function isFikAccountValid(account) {
  account = account.replace(/[\s-]/g, "");
  return isNum(account) && account.length === 8;
}

export function isCbnkAccountValid(account) {
  account = account.replace(/[\s-]/g, "");
  // (préfixe optionnel avec 1 à 6 digits + trait) + 1 à 10 digits
  return account.match(/^(\d{1,6}-)?(\d{1,10})$/g);
}

export function isBacsBankValid(bank) {
  bank = bank.replace(/[\s-]/g, "");
  return isNum(bank) && bank.length === 6;
}

export function isEftBankValid(bank) {
  bank = bank.replace(/[\s-]/g, "");
  return isNum(bank) && bank.length === 6;
}

export function isFikBankValid(bank) {
  bank = bank.replace(/[\s-]/g, "");
  return isNullOrEmpty(bank) || bank === "FIK";
}

export function isCbnkBankValid(bank) {
  bank = bank.replace(/[\s-]/g, "");
  return isNum(bank) && bank.length === 4;
}

export function isBankCodeValid(bank, countryCode) {
  bank = bank.replace(/[\s-]/g, "");
  var INVALID_BANK_CODE_REGEX = new RegExp("[^a-zA-Z0-9]");

  return !isNullOrEmpty(bank) && !INVALID_BANK_CODE_REGEX.test(bank) && bank.length <= 8 && bank.slice(4, 6) === countryCode;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function upperFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
