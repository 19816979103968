import * as Actions from "module/amc/store/actions/JobPhase.Actions";
import React from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";

// custom components
import Button from "components/CustomButtons/Button.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Assignment, Delete } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import PopoverConfirm from "../../../../../../components/Common/PopoverConfirm";

function JobPhaseListTab({ amc, job, selectItem, deletePhase, phases, itemChanged }) {
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndexnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  function ConvertDatas(items) {
    if (!items || items.length === 0) return [];

    var datas = {
      tabItems: []
    };
    items.map((prop, key) => {
      datas.tabItems.push({
        ...prop,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                selectItem(prop);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>{" "}
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                if (!!prop.identifier && typeof prop.identifier !== "undefined") setSelectedIndexnchorEl("identifier:" + prop.identifier);
                else setSelectedIndexnchorEl("localId:" + prop.localId);
                setAnchorEl(e.currentTarget);
                //if (!!getItem && typeof getItem !== "undefined")
                //    getItem(prop.id);
              }}
              color="danger"
              className="like"
            >
              <Delete />
            </Button>{" "}
            <PopoverConfirm
              anchor={anchorEl}
              confirmHandler={() => {
                var index = -1;
                if (selectedIndex.startsWith("identifier"))
                  index = items
                    .map(function(e) {
                      return e.identifier;
                    })
                    .indexOf(parseInt(selectedIndex.split(":")[1]));
                else if (selectedIndex.startsWith("localId"))
                  index = items
                    .map(function(e) {
                      return e.localId;
                    })
                    .indexOf(selectedIndex.split(":")[1]);

                if (index > -1) {
                  items.splice(index, 1);
                }
                deletePhase({
                  ...job,
                  jobPhases: items
                });
                setAnchorEl(null);
              }}
              abortHandler={() => {
                setAnchorEl(null);
              }}
            />
          </div>
        )
      });
    });

    return datas;
  }

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 100,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Code</Trans>,
      accessor: "code",
      filterable: true
    },
    {
      Header: <Trans>Label</Trans>,
      accessor: "label",
      filterable: true
    },
    {
      Header: <Trans>Segmentation level 1</Trans>,
      accessor: "segmentationLevel1Code",
      filterable: true
    }
  ];

  var items = ConvertDatas(phases);

  return (
    <div style={{ width: "99%" }}>
      <ReactTable
        data={items.tabItems}
        columns={reactTableColumns}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
        defaultPageSize={5}
      />
    </div>
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    },
    selectItem: item => {
      dispatch(Actions.SelectItem(item));
    },
    deletePhase: (job, phases) => {
      dispatch(Actions.DeletePhase(job, phases));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhaseListTab));
