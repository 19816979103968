import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Project.Actions";
import React from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import ProjectSearchCriteriaComplex from "./ProjectSearch/ProjectSearchCriteriaComplex";
import ProjectSearchCriteriaExtended from "./ProjectSearch/ProjectSearchCriteriaExtended";
import ProjectSearchCriteriaSimple from "./ProjectSearch/ProjectSearchCriteriaSimple";

function ProjectSearchCriteria({ showSearchCriteriaOnSideBar, amc, setCriterias, setSearchMode, search }) {
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var defaultSearchMode = "Simple";
  if (!!getPref && !!getPref.Pref && !!getPref.Pref.Search) {
    defaultSearchMode = getPref.Pref.Search;
  }

  const criterias = amc.criterias["PROJECT"];
  const searchMode = !amc ? defaultSearchMode : amc.searchMode["PROJECT"];

  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  var searchTitle = "";

  var searchComponent = "";
  switch (searchMode) {
    case "Simple":
      searchComponent = <ProjectSearchCriteriaSimple showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar} />;
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      searchComponent = <ProjectSearchCriteriaExtended showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar} />;
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    case "Complex":
      searchComponent = <ProjectSearchCriteriaComplex />;
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Complex Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + searchMode);
      break;
  }

  function launchSearch(e) {
    e.preventDefault();
    search();
  }

  function cleanSearchCriterias(e) {
    e.preventDefault();
    var newCriterias = { pageSite: criterias.pageSite, criteriaFullText: "", criteriaMdmId: "" };
    setCriterias(newCriterias);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
            <div className={blockHeaderSearchClasses.join(" ")}>
              <div className="blockHeaderSearchItem">
                <ToggleButtonGroup value={searchMode} exclusive onChange={(e, mode) => setSearchMode(mode)}>
                  <ToggleButton value="Simple">
                    <ViewDay />
                  </ToggleButton>
                  <ToggleButton value="Extend">
                    <ViewHeadline />
                  </ToggleButton>
                  <ToggleButton value="Complex">
                    <ViewQuilt />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="blockHeaderSearchItem">
                <Typography ariant="display1">{searchTitle}</Typography>
              </div>
            </div>
          </CardHeader>
          <CardBody>{searchComponent}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    setSearchMode: searchMode => {
      dispatch(Actions.SetSearchMode(searchMode));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectSearchCriteria));
