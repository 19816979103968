import { ApimGet, ApimPost, ApimPostDownloadFile } from "components/Common/ApimSender";
import { format } from "date-fns";
import { isArray, isArrayNullOrEmpty, isNullOrEmpty } from "tools";

export function ProductSearch(criterias, callbackFn, errorFn) {
  const queryString = ConvertCriteriaToQueryString(criterias);
  return ApimGet("api/Products/search" + queryString, r => callbackFn(r), e => errorFn(e));
}

export function ProductCrupdate(product, callbackFn, errorFn, validationErrorFn) {
  ApimPost(
    "api/Products",
    product,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

// export function GetProductIds(criterias, callbackFn, errorFn) {
//   const queryString = ConvertCriteriaToQueryString(criterias);
//   return ApimGet("api/Products/GetIdsP" + queryString, r => callbackFn(r), e => errorFn(e));
// }

export function GetProduct(id, callbackFn, errorFn) {
  return ApimGet("api/Products/" + id, r => callbackFn(r), e => errorFn(e));
}

export function ProductExport(ids, callbackFn, errorFn) {
  var now = format(new Date(), "yyyyMMddHHmmss");
  ApimPostDownloadFile("api/Products/exportCsv", ids, "Products_" + now + ".csv", callbackFn, errorFn);
}

export function GetProductHistory(id, callbackFn, errorFn) {
  return ApimGet("api/Products/" + id + "/History", r => callbackFn(r), e => errorFn(e));
}
export function GetThirdPartyHistory(id, callbackFn, errorFn) {
  // ApiHistoryGet("api/Changes/get/Products/" + id, c => callbackFn(c), e => errorFn(e));
}

export function GetProductByCodeAndId(code, identifier, callbackFn, errorFn) {
  return ApimGet("api/Products/" + code + "/" + identifier, r => callbackFn(r), e => errorFn(e));
}

function ConvertCriteriaToQueryString(criteria) {
  if (isNullOrEmpty(criteria)) {
    return "?";
  }

  if (!isNullOrEmpty(criteria.freeSearch)) {
    return "?FreeSearch=" + criteria.freeSearch;
  }

  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.code)) {
    queryParams.push("Code=" + criteria.code);
  }
  if (!isNullOrEmpty(criteria.genCode)) {
    queryParams.push("GenCode=" + criteria.genCode);
  }
  if (!isNullOrEmpty(criteria.commercialLabel)) {
    queryParams.push("CommercialLabel=" + criteria.commercialLabel);
  }
  if (!isNullOrEmpty(criteria.technicalLabel)) {
    queryParams.push("TechnicalLabel=" + criteria.technicalLabel);
  }
  if (!isNullOrEmpty(criteria.structureIdentifier)) {
    queryParams.push("StructureIdentifier=" + criteria.structureIdentifier);
  }
  if (!isNullOrEmpty(criteria.structureType)) {
    queryParams.push("StructureType=" + criteria.structureType);
  }
  if (!isNullOrEmpty(criteria.keyword)) {
    queryParams.push("Keyword=" + criteria.keyword);
  }
  if (!isNullOrEmpty(criteria.segmentationLevel3)) {
    queryParams.push("SegmentationLevel3=" + criteria.segmentationLevel3);
  }
  if (!isNullOrEmpty(criteria.segmentationLevel4)) {
    queryParams.push("SegmentationLevel4=" + criteria.segmentationLevel4);
  }
  if (!isNullOrEmpty(criteria.includeDeletedItems)) {
    queryParams.push("IncludeDeletedItems=" + criteria.includeDeletedItems);
  }
  if (!isNullOrEmpty(criteria.sleepingDate)) {
    queryParams.push("SleepingDate=" + criteria.sleepingDate);
  }
  if (!isNullOrEmpty(criteria.lastModificationDate)) {
    queryParams.push("LastModificationDate=" + criteria.lastModificationDate);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
