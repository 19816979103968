import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import { Button } from "@material-ui/core";
import { Popover } from "@material-ui/core";

function PopoverConfirm({ anchor, confirmHandler, abortHandler }) {
  const open = Boolean(anchor);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={abortHandler}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center"
      }}
    >
      <div>
        <Button onClick={confirmHandler}>Confirm</Button>
        <Button onClick={abortHandler}>Abort</Button>
      </div>
    </Popover>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(PopoverConfirm);
