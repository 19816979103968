import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function CountryCrupdate(country, callbackFn, errorFn) {
    ApimPost(
        'api/Countries',
        country,
        (c) => callbackFn(c),
        (e) => errorFn(e)
    );
}

export function CountryGetAll(callbackFn, errorFn) {
    return ApimGet(
        'api/Countries?PageSize=500',
        (countries) => callbackFn(countries),
        (e) => errorFn(e));
}

export function CountryGetByCode(code, callbackFn, errorFn) {
    return ApimGet(
        'api/Countries/'+code,
        (country) => callbackFn(country),
        (e) => errorFn(e));
}

export function CountiesGetAll(countryCode, callbackFn, errorFn) {
    return ApimGet(
        'api/Countries/' + countryCode + "/counties",
        (countries) => callbackFn(countries),
        (e) => errorFn(e));
}

export function StatesGetAll(countryCode, callbackFn, errorFn) {
    return ApimGet(
        'api/Countries/' + countryCode + "/states",
        (countries) => callbackFn(countries),
        (e) => errorFn(e));
}