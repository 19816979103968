import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { isArrayNullOrEmpty, isNull } from "../../tools";

import Button from "../../../components/CustomButtons/Button";

import CardError from "components/Card/CardError";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import { Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, TextField, SnackbarContent } from "@material-ui/core";
import { AddAlert, Check, CheckCircleOutlined } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import { HasOneOfRights } from "services/user/UserHelper";

function ThirdPartyAccountDatas({ thirdPartyCtx, accountCtx, transformIntoAccount, classes, defaultLang }) {
  var dispatch = useDispatch();
  var thirdParty = thirdPartyCtx.thirdParty;
  var [isFirstTime, setIsFirstTime] = useState(true);
  var [newCookie, setNewCookie] = useState(null);
  const infoNonDiffusable = "INFORMATION NON DIFFUSIBLE";
  useEffect(() => {
    if (isFirstTime) {
      if (isNull(accountCtx)) {
        dispatch(Actions.GetAccount(thirdParty.identifier));
      }
      setIsFirstTime(false);
    }
  });

  if (!isNull(accountCtx)) {
    if (!isNull(accountCtx.error)) return <CardError error={accountCtx.error} />;
    if (accountCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (accountCtx.saveStatus === "SUCCESS_RELOAD") {
      setTimeout(() => dispatch(Actions.OpenThirdParty(accountCtx.account.thirdPartyId, thirdParty.identifier, "ThirdPartyAccountDatas")), 2000);
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  if (isArrayNullOrEmpty(accountCtx.account)) {
    var innerElement;

    if (
      (thirdParty.thirdPartyStatusCode !== "Closed" || thirdPartyCtx.isEditableClosed) &&
      HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]) &&
      thirdParty.customLongName.toUpperCase() !== infoNonDiffusable
    ) {
      innerElement = (
        <center>
          <TextField
            margin="dense"
            id="newCookie"
            type="text"
            fullWidth
            onChange={event => {
              setNewCookie(event.target.value);
            }}
            helperText="Cookie to create"
          />
          <Button
            type="submit"
            color="info"
            className={classes.registerButton}
            onClick={() => transformIntoAccount({ thirdPartyId: thirdParty.identifier, cookie: newCookie }, thirdParty)}
          >
            <Trans> Transform ThirdParty into Account </Trans>
          </Button>
        </center>
      );
    } else {
      innerElement = (
        <SnackbarContent message={<Trans>You don't have rights to transform this third party into a account.</Trans>} icon={AddAlert} color="info" />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : accountCtx.isEditable;
  var item = accountCtx.account;
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Common</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} sm={3} md={3}>
                    <FormLabel>
                      <Trans>Cookie</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={9} sm={9} md={9}>
                    <TextField disabled={!isEditable} margin="dense" id="longName" type="text" fullWidth value={item.cookie} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={item.isActive}
                          disabled={!isEditable}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Actif</Trans>}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const GridContainerStyle = {
  mainGrid: {
    marginTop: "40px"
  }
};
const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    accountCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].accountCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    transformIntoAccount: (account, thirdParty) => {
      dispatch(Actions.SaveAccount(account, thirdParty, true));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyAccountDatas));
