import { Trans } from "@lingui/macro";
import { CircularProgress, Grid, SnackbarContent } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { AddAlert } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CardError from "components/Card/CardError";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { HasRight } from "services/user/UserHelper";
import { isNull } from "tools";
import { CustomerGet } from "../../actions/Customer.Actions";
import TabCustomerGeneral from "./TabCustomerGeneral";
import TabCustomerHistory from "./TabCustomerHistory";
import TabCustomerSj from "./TabCustomerSj";

const TabCustomer = ({ thirdParty, setThirdParty, classes, defaultLang }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [backup, setBackup] = useState(null);
  const [error, setError] = useState(null);

  const customer = thirdParty.customer;
  let setCustomer = s => {
    const { hasChanged, ...su } = s;
    setThirdParty({ ...thirdParty, customer: { ...s, hasChanged: !backup ? false : JSON.stringify(su) !== backup } });
  };

  useEffect(() => {
    if (customer && !backup) {
      let { hasChanged, ...s } = customer;
      setBackup(JSON.stringify(s));
    }
  }, [customer, backup]);

  if (error) {
    return <CardError error={error} />;
  }

  var generalIcon = "";
  if (isNull(thirdParty.customer?.thirdPartyId)) {
    var innerElement;

    if (!backup && thirdParty.identifier > 0) {
      CustomerGet(thirdParty.identifier)
        .then(s => {
          setCustomer(s);
        })
        .catch(e => setError(e));
      innerElement = <CircularProgress />;
    } else if (thirdParty.thirdPartyStatusCode !== "Closed" && HasRight("tp_columbus.edit")) {
      innerElement = (
        <Button
          type="submit"
          color="info"
          className={classes.registerButton}
          onClick={() =>
            setThirdParty({
              ...thirdParty,
              customer: { hasChanged: true, thirdPartyId: thirdParty.identifier, scopeCode: "REG", customerSubsidiaries: [] }
            })
          }
        >
          <Trans> Transform ThirdParty into Customer </Trans>
        </Button>
      );
    } else {
      innerElement = (
        <SnackbarContent message={<Trans>You don't have rights to transform this third party into a customer.</Trans>} icon={AddAlert} color="info" />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = HasRight("tp_columbus.edit");
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs customer"
            >
              <AntTab
                label={
                  <>
                    {generalIcon}
                    <Trans>General</Trans>
                  </>
                }
              />
              <AntTab label={<Trans>Sjs linked</Trans>} />
              <AntTab label={<Trans>History</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabCustomerGeneral customer={customer} setCustomer={setCustomer} defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabCustomerSj customer={customer} setCustomer={setCustomer} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabCustomerHistory customer={customer} setCustomer={setCustomer} defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};

const GridContainerStyle = {
  mainGrid: {
    marginBottom: "40px",
    marginTop: "40px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabCustomer);
