import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import Muted from "../Typography/Muted.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ReactSelect from 'react-select';
import { isNullOrEmpty} from 'tools';

// style
import 'assets/scss/material-dashboard-pro-react/react-web-tabs.css';
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

//Lingui
import { Trans } from '@lingui/macro';

class BaseSelector extends React.Component {
    constructor(props) {
        super(props);

        var isEditable = this.props.isEditable !== null && this.props.isEditable !== undefined ? this.props.isEditable : true;
        this.state = {
            isEditable: isEditable,
            displayedProperties: this.props.displayedProperties,
            value: this.props.value
        };
        this.onChange = this.onChange.bind(this);
        this.transformLabel = this.transformLabel.bind(this);
    }

    onChange = event => {
        if (this.props.onChange !== null && this.props.onChange !== undefined)
            this.props.onChange(event);
    }

    transformLabel(item) {
        if (this.props.transformValue !== undefined)
            return this.props.transformValue(item);
        else
            return item[this.state.displayedProperties.valueProperty];
    }

    render() {
        const ReactSelectStyle = {
            menu: (provided) => ({
                ...provided,
                zIndex: 999
            })
        };

        var options;
        if (this.props.items !== null && this.props.items !== undefined) {
            options = this.props.items.map((item) => {
                var key = item[this.state.displayedProperties.keyProperty];
                return { value: key, label: this.transformLabel(item) }
            });
        }
        else
            return (<span>No available data</span>);

        var isClearable = (this.props.isClearable !== null && this.props.isClearable !== undefined) ? this.props.isClearable : false;
        var isSearchable = (this.props.isSearchable !== null && this.props.isSearchable !== undefined) ? this.props.isSearchable : false;

        if (this.state.isEditable) {
            if (this.props.label !== null && this.props.label !== undefined) {
                return (
                    <FormControl>
                        <InputLabel shrink htmlFor={this.props.id}>
                            <Trans>{this.props.label}</Trans>
                        </InputLabel>
                        <ReactSelect
                            id={this.props.id}
                            options={options}
                            styles={ReactSelectStyle}
                            //error={this.props.error}
                            value={options.filter(({ value }) => value === this.state.value)}
                            onChange={this.onChange}
                            components={this.props.components}
                            isSearchable={isSearchable}
                            isClearable={isClearable}
                            menuPlacement="auto"
                        />
                    </FormControl>
                );
            }
            else {
                return (
                    <ReactSelect
                        options={options}
                        styles={ReactSelectStyle}
                        //error={this.props.error}
                        value={options.filter(({ value }) => value === this.props.value)}
                        components={this.props.components}
                        onChange={this.onChange}
                        isSearchable={isSearchable}
                        isClearable={isClearable}
                        menuPlacement="auto"
                    />
                );
            }
        }
        else {
            var value = this.props.value === null || this.props.value === undefined || this.props.value === "" ? <em><Trans>Empty</Trans></em> : this.props.value;

            if (!isNullOrEmpty(value)) {
                var temp = this.props.items.find((item) => { return item.code === value });
                if (!isNullOrEmpty(temp)) value = this.transformLabel(temp);
            }

            if (this.props.label !== null && this.props.label !== undefined) {
                return (
                    <FormControl>
                        <InputLabel shrink>
                            <Trans>{this.props.label}</Trans>
                        </InputLabel>
                        <Muted>{value}</Muted>
                    </FormControl>
                );
            }
            else {
                return (
                    <Muted>{value}</Muted>
                );
            }
        }
    }
}

export default withStyles(thirdPartySearchCriteriaStyle)(BaseSelector);