import { Trans } from "@lingui/macro";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import FieldText from "components/Common/FieldText";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty, isNull, nullIfEmpty } from "tools";

const DialogCustomerRoutage = function ({ openDialog, setOpenDialog, customerCtx, code, onValueChanged }) {
  const [detail, setDetail] = useState(null);
  const [backupDetail, setBackupDetail] = useState(null);

  var customer = customerCtx.customer;
  const isEditable = customerCtx.isEditable;

  useEffect(() => {
    if (!isNull(code)) {
      var d = customer.routageCodes.find(s => s.routageCode === code);
      setDetail(d.routageCodes);
      setBackupDetail(JSON.stringify(d.routageCodes));
    }
  }, (code));

  if (isNull(code) || isNull(detail)) return <div />;

  const save = function (routage) {
    if (!isNull(routage)) {
      var c = customerCtx.customer;
      c.routageCodes.forEach((item) => {
        if (item.routageCodes.code === routage.code) {
          item.routageCodes.label = routage.label;
          item.routageCodes.type = routage.type;
          item.routageCodes.isActive = routage.isActive;
        }
      }
      );
      onValueChanged(c);
      setOpenDialog(false);
    }
  }
  var btnSave = "";
  if (backupDetail !== JSON.stringify(detail)) {
    btnSave = (
      <Button onClick={() => save(detail)} color="info" >
        <Trans> Save </Trans>
      </Button>
    );
  }

  return (
    <>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Trans>Detail</Trans>
        </DialogTitle>
        <DialogContent>
          <div>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Routage</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={false}
                  id="code"
                  value={detail.code}
                  onChange={e => setDetail({ ...detail, code: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Label</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable}
                  id="type"
                  value={detail.label}
                  onChange={e => setDetail({ ...detail, label: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Type</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable}
                  id="type"
                  value={detail.type}
                  onChange={e => setDetail({ ...detail, type: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Is Active</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Checkbox
                  disabled={!isEditable}
                  id="isActive"
                  checked={detail.isActive}
                  onChange={e =>
                    setDetail({
                      ...detail,
                      isActive: e.target.checked
                    })
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <DialogActions>
          {btnSave}
          <Button onClick={() => setOpenDialog(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null
  };
};

export default connect(
  mapStateToProps,
)(withStyles(thirdPartySearchCriteriaStyle)(DialogCustomerRoutage));
