import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { Delete, Error } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { IdentifiedUserAutocomplete } from "components/Autocomplete/IdentifiedUserAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import DateSelector from "components/Common/DateSelector";
import Selector from "components/Common/Selector";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { addDays, compareAsc, format, parseISO } from "date-fns";
import { isValidDate } from "tools";
import { USERANDRIGHT_PROFILEDELEGATION_END_ACTION, USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL } from "./store/types";
import { Crupdate, Get, Search } from "./store/ProfileDelegation.Actions";
import { HasRight, GetUserByLogin } from "services/user/UserHelper";

function ProfileDelegationDetail({delegationCtx, currentUser, setDetail, endEditing, crupdate, currentUserProfiles, userRights, classes}) {
  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [state, setState] = useState({ isOpenDelegation: true });
  const [errors, setErrors] = useState([]);
  const [profilesToDeleg, setProfilesToDeleg] = useState([]);

  let delegation = delegationCtx.item;
  const isNew = !delegation || !delegation.id || delegation.id === 0;

  const dateStart = delegation && delegation.dateStart ? new Date(delegation.dateStart) : '';
  const dateEnd = delegation && delegation.dateEnd ? new Date(delegation.dateEnd) : '';

  const minDate = new Date();
  const maxDate = addDays(isNew ? new Date() : dateStart, 30);

  const canEditDelete =
    !isNew &&
    ((delegation.delegatorId === currentUser.identifier || userRights.isAdmin || userRights.isSupplierAdmin) &&
      compareAsc(dateEnd, minDate) >= 0 &&
      compareAsc(dateEnd, maxDate) <= 0);

  useEffect(() => {
    setState({
      ...state,
      isOpenDelegation:
        isNew ||
        (!hasBeenModified &&
          (delegation.delegatorId === currentUser.identifier || userRights.isAdmin || userRights.isSupplierAdmin) &&
          compareAsc(dateEnd, minDate) >= 0 &&
          compareAsc(dateEnd, maxDate) <= 0),
    });
    if (isNew && delegationCtx.showDetail) {
      delegation.id = 0;
      delegation.delegatorId = currentUser.identifier;
    }
  }, [delegationCtx.showDetail]);

  useEffect(() => {
    if (delegation) {
      if (currentUser.identifier === delegation.delegatorId) {
        setProfilesToDeleg((isNew ? currentUserProfiles.filter(p => p.isDelegable === true) : currentUserProfiles).map(p => { return { id: p.profileIdentifier, name: p.profileIdentifier } }));
      } else if (delegation.delegatorId) {
        GetUserByLogin(delegation.delegatorId, user => {
          if (user && user.profiles) {
            setProfilesToDeleg(user.profiles.filter(p => p.isDelegable === true).map(p => { return { id: p.profileIdentifier, name: p.profileIdentifier } }));
          }
        });
      }
      else {
        setProfilesToDeleg([]);
      }
    }
  }, [delegation && delegation.delegatorId]);

  function changeValue(value, propertyField) {
    if (state.isOpenDelegation) {
      delegation[propertyField] = value;
      setDetail({
        ...delegation
      });
      setHasBeenModified(true);
    }
  }

  function changeDateValue(value, propertyField) {
    const date = new Date(value);
    if (value && isValidDate(date) && date >= minDate && date <= maxDate) {
      changeValue(value, propertyField);
    } else {
      changeValue('', propertyField);
    }
  }

  function changeDelegate(value) {
    changeValue(value, 'delegateId');
  }

  function close() {
    setHasBeenModified(false);
    setErrors([]);
    setState({ isFirstLoad: true });
    endEditing();
  }
  let content = '';
  if (delegationCtx.showDetail) {
    const hasErrors = errors && errors.length > 0;
    let label = "";

    if (isNew) {
      if (delegation.dateStart === undefined){ delegation.dateStart = format(minDate, 'yyyy-MM-dd');}
      if (delegation.dateEnd === undefined) {delegation.dateEnd = format(maxDate, 'yyyy-MM-dd');}
      label = ( <span> <Trans>New Profile Delegation</Trans> </span>);
    } else {
      if (delegation.dateStart) {
        delegation.dateStart = format(parseISO(delegation.dateStart), 'yyyy-MM-dd');
      }

      if (!state.isOpenDelegation && delegation.dateEnd) {
        delegation.dateEnd = format(parseISO(delegation.dateEnd), 'yyyy-MM-dd');
      }
      label = ( <span> <Trans>Profile Delegation</Trans> {delegation.id} </span>);

    }

    let errorCard = '';
    if (hasErrors) {
      errorCard = (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {errors.map((t, idx) => (
                <div key={idx}>
                  <div style={{ lineHeight: '0.5em' }}>
                    <Error style={{ verticalAlign: 'middle', color: 'red' }} />
                    <span style={{ verticalAlign: 'middle', color: 'red', marginRight: '5px' }}>
                      <strong>{t.descriptionEn}</strong>
                    </span>
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </GridItem>
      );
    }

    let validateButton = '';
    if (state.isOpenDelegation && hasBeenModified) {
      validateButton = (
        <Button
          color="info"
          onClick={() => {
            crupdate(
              delegation,
              () => {
                setErrors([]);
                if (delegation.dateStart > minDate) {
                  close();
                } else {
                  setState({ ...state, isFirstLoad: true });
                }
                setHasBeenModified(false);
              },
              e => setErrors(e)
            );
          }}
        >
          <Trans>Validate</Trans>
        </Button>
      );
    }

    const deleteButton = canEditDelete ? (
      <Button
        onClick={() => {
          changeValue(format(minDate, 'yyyy-MM-dd'), 'dateEnd');
          crupdate(
            delegation,
            () => {
              setErrors([]);
              close();
            },
            e => setErrors(e)
          );
        }}
        size="small"
        color="danger"
      >
        <Delete />
        <Trans>Delete</Trans>
      </Button>
    ) : (
      ''
    );

    content = delegationCtx.isPerforming ? (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={true}
          onClose={close}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            <div>{label}</div>
          </DialogTitle>
          <DialogContent style={{ ...DialogInlineStyleDetail.dialogContent }} dividers={true}>
            <center>
              <CircularProgress />
            </center>
          </DialogContent>
        </Dialog>
      </div>
    ) : (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={true}
          onClose={close}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">
            <div>{label}</div>
          </DialogTitle>
          <DialogContent style={{ ...DialogInlineStyleDetail.dialogContent }} dividers={true}>
            <div style={{ width: '98%' }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card style={{ ...CardInlineStyle.card }}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Profile Code</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          <Selector
                            items={profilesToDeleg}
                            displayedProperties={{
                              keyProperty: 'id',
                              valueProperty: 'name',
                            }}
                            value={delegation.codeProfile}
                            isEditable={isNew}
                            onChange={e => changeValue(e.target.value, 'codeProfile')}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Delegator login</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          {isNew && (userRights.isAdmin || userRights.isSupplierAdmin) ? (
                            <IdentifiedUserAutocomplete login={delegation.delegatorId} onChange={e => changeValue(e,'delegatorId')} />
                          ) : (
                            <TextField
                              margin="dense"
                              id="delegatorId"
                              type="text"
                              fullWidth
                              required
                              disabled={!isNew}
                              value={delegation.delegatorId}
                              onChange={e => changeValue(e.target.value, 'delegatorId')}
                            />
                          )}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Delegate login</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          {isNew ? (
                            <IdentifiedUserAutocomplete login={delegation.delegateId} onChange={e => changeDelegate(e)} />
                          ) : (
                            <TextField
                              margin="dense"
                              id="delegateId"
                              type="text"
                              fullWidth
                              disabled
                              value={delegation.delegateId}
                            />
                          )}
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Date start</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          <DateSelector
                            value={delegation.dateStart}
                            isEditable={isNew}
                            openDaysOnly={false}
                            onChange={e => changeDateValue(e, 'dateStart')}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultLang={currentUser.language}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Date end</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          <DateSelector
                            value={delegation.dateEnd}
                            isEditable={isNew || canEditDelete}
                            openDaysOnly={false}
                            onChange={e => changeDateValue(e, 'dateEnd')}
                            minDate={minDate}
                            maxDate={maxDate}
                            defaultLang={currentUser.language}
                          />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Comment</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={7} sm={7} md={7}>
                          <TextField
                            margin="dense"
                            id="commentaire"
                            type="text"
                            fullWidth
                            required
                            disabled={!isNew && !canEditDelete}
                            value={delegation.commentaire}
                            onChange={e => changeValue(e.target.value, 'commentaire')}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
                {errorCard}
              </GridContainer>
            </div>
          </DialogContent>
          <DialogActions>
            {validateButton}
            {deleteButton}
            <div style={{ flex: '1 0 0' }} />
            <Button onClick={close} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return <div>{content}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    delegationCtx: state.UR_ProfileDelegation,
    currentUser: state.AuthenticationReducer.user,
    userRights: {
      isAdmin: HasRight("application.admin"),
      isSupplierAdmin: HasRight("thirdparty_supplier.edit"),
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDetail: item => {
      dispatch({
        type: USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL,
        item: item
      });
    },
    get: id => {
      dispatch(Get(id));
    },
    endEditing: item => {
      dispatch({
        type: USERANDRIGHT_PROFILEDELEGATION_END_ACTION,
        item: null
      });
      dispatch(Search());
    },
    crupdate: (item, callbackFn, errorFn) => {
      dispatch(Crupdate(item, callbackFn, errorFn));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ProfileDelegationDetail));
