import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormLabel, Button, Dialog, DialogActions, DialogContent, Tooltip, DialogTitle, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check, Assignment, History } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TpCustomerNature, TpCustomerStatus, TpGroup, TpScope, TpSecurization } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import { isArrayNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";
// react component for creating dynamic tables
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import DialogCustomerRoutage from "./DialogCustomerRoutage";
import DialogCustomerAddressage from "./DialogCustomerAddressage";
import HistoRoutageCodes from "./HistoRoutageCodes";
import HistoAddressageCodes from "./HistoAddressageCodes";

const TabCustomerGeneral = function ({ customerCtx, isEditable, onValueChanged, availableUes, masterValues, classes/* , setRoutage */, setAdressage }) {
  var customer = customerCtx.customer;
  const [ues, setUes] = useState([]);
  var [routageCode, setRoutageCode] = useState(null);
  var [openDialogRoutage, setOpenDialogRoutage] = useState(false);
  var [addressageCode, setAddressageCode] = useState(null);
  var [openDialogAddressage, setOpenDialogAddressage] = useState(false);
  var [openHistoRoutage, setOpenHistoRoutage] = useState(false);
  var [openHistoAddressage, setOpenHistoAddressage] = useState(false);

  useEffect(() => {
    let lst = availableUes;
    if (!isEditable) {
      var ue = masterValues.StructureUe.find(ue => ue.identifier === customer.ueIdentifier);
      lst = ue ? [ue] : [];
    }
    setUes(lst);
  }, []);

  var errorDematMail;
  var error = customerCtx.error;
  if (!isNullOrEmpty(error)) {
    if (isArrayNullOrEmpty(error.response.data) || !error.response.data.some(d => d.severity !== "Error")) {
      return <CardError error={error} />;
    } else {
      var errorData = error.response.data;
      for (var i = 0; i < errorData.length; i++) {
        if (errorData[i].code === "CUSTOMER_INVALID_DEMATMAIL") {
          errorDematMail = errorData[i];
        }
      }
    }
  }

  var btnHistoRoutage = <></>;
  if (customer && customer.routageCodes) {
    btnHistoRoutage = (
      <Tooltip title={<Trans>History</Trans>} placement="bottom">
        <span>
          <Button id="routageHisto" aria-label="Histo" onClick={() => setOpenHistoRoutage(true)}>
            <History fontSize="small" />
            {/* &nbsp;<Trans>History</Trans> */}
          </Button>
        </span>
      </Tooltip>
    );
  }
  var btnHistoAddressage = <></>;
  if (customer && customer.addressageCodes) {
    btnHistoAddressage = (
      <Tooltip title={<Trans>History</Trans>} placement="bottom">
        <span>
          <Button id="addressageHisto" aria-label="Histo" onClick={() => setOpenHistoAddressage(true)}>
            <History fontSize="small" />
          </Button>
        </span>
      </Tooltip>
    );
  }
  const openCustomerRoutage = function (code) {
    setRoutageCode(code);
    setOpenDialogRoutage(true);
  };
  const openCustomerAddressage = function (code) {
    setAddressageCode(code);
    setOpenDialogAddressage(true);
  };

  function convertRoutagesDataToReactTable() {
    if (!customer.routageCodes) customer.routageCodes = [];
    var res =
      customer.routageCodes
        .filter(r => r.routageCodes.isActive)
        .map((prop, key) => {
          return {
            code: prop.routageCodes.code,
            label: prop.routageCodes.label,
            actions: (
              <div key={key}>
                <Button justIcon round simple onClick={() => openCustomerRoutage(prop.routageCodes.code)} color="info" className="like">
                  <Assignment />
                </Button>
              </div>
            )
          };
        });
    return res;
  }
  function convertAddressagesDataToReactTable() {
    if (!customer.addressageCodes) customer.addressageCodes = [];
    var toDay = new Date();
    var res = customer.addressageCodes
      .filter(a => a.addressageCodes.isPrincipal && new Date(a.addressageCodes.startDate) <= toDay
        && (isNullOrEmpty(a.addressageCodes.endDate) || new Date(a.addressageCodes.endDate) >= toDay))
      .map((prop, key) => {
        return {
          code: prop.addressageCodes.code,
          isPrincipal: prop.addressageCodes.isPrincipal,
          actions: (
            <div key={key}>
              <Button justIcon round simple onClick={() => openCustomerAddressage(prop.addressageCodes.code)} color="info" className="like">
                <Assignment />
              </Button>
            </div>
          )
        };
      });
    return res;
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Common</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Nature</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpCustomerNature}
                        prefixCode
                        selectedValue={customer.customerNatureCode}
                        onValueChange={value => onValueChanged({ ...customer, customerNatureCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Scope</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete isEditable={false} mvType={TpScope} prefixCode selectedValue={customer.scopeCode} />
                    </GridItem>

                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Group</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpGroup}
                        prefixCode
                        selectedValue={customer.groupCode}
                        onValueChange={value => onValueChanged({ ...customer, groupCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Securitization</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpSecurization}
                        prefixCode
                        selectedValue={customer.securizationCode}
                        onValueChange={value => onValueChanged({ ...customer, securizationCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Code Col</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={isEditable}
                        id="codeCol"
                        value={customer.codeCol}
                        onChange={e => onValueChanged({ ...customer, codeCol: e.target.value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Ue</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <ItemAutoComplete
                        isEditable={isEditable}
                        isRequired
                        items={ues.map(ue => {
                          return { ...ue, code: ue.identifier };
                        })}
                        selectedValue={customer.ueIdentifier}
                        onValueChange={v => onValueChanged({ ...customer, ueIdentifier: v })}
                        formatText={ue => `${ue.identifier} - ${ue.oldIdentifier} - ${ue.label}`}
                        maxResultNumber={100}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Dematerialisation</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <div>
                    <h4>
                      <Trans>Addressage Codes</Trans>
                      {btnHistoAddressage}
                    </h4>

                    <ReactTable
                      data={convertAddressagesDataToReactTable()}
                      columns={[
                        {
                          Header: <Trans>Code</Trans>,
                          accessor: "code"
                        },
                        {
                          Header: <Trans>Is Principal</Trans>,
                          accessor: "isPrincipal",
                          Cell: row => {
                            return (
                              <div>
                                <Checkbox
                                  disabled
                                  checked={row.original.isPrincipal} />
                              </div>
                            );
                          }
                        },
                        {
                          Header: <Trans>Actions</Trans>,
                          accessor: "actions",
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      defaultPageSize={1}
                      showPaginationBottom={false}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      showFilters={false}
                      style={ReactTableStyle.main}
                    />
                    <h4>
                      <Trans>Routage Codes</Trans>
                      {btnHistoRoutage}
                    </h4>
                    <ReactTable
                      data={convertRoutagesDataToReactTable()}
                      columns={[
                        {
                          Header: <Trans>Code</Trans>,
                          accessor: "code"
                        },
                        {
                          Header: <Trans>Label</Trans>,
                          accessor: "label"
                        },
                        {
                          Header: <Trans>Actions</Trans>,
                          accessor: "actions",
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      defaultPageSize={1}
                      showPaginationBottom={false}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      showFilters={false}
                      style={ReactTableStyle.main}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Status</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Status</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpCustomerStatus}
                        prefixCode
                        selectedValue={customer.customerStatusCode}
                        onValueChange={value => onValueChanged({ ...customer, customerStatusCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}></GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={customer.isActif}
                            disabled={!isEditable}
                            onChange={e => onValueChanged({ ...customer, isActif: e.target.checked })}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={<Trans> supplier isActive </Trans>}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <DialogCustomerRoutage openDialog={openDialogRoutage} setOpenDialog={v => setOpenDialogRoutage(v)} code={routageCode} onValueChanged={onValueChanged} />
      <DialogCustomerAddressage openDialog={openDialogAddressage} setOpenDialog={v => setOpenDialogAddressage(v)} code={addressageCode} onValueChanged={onValueChanged} />

      {openHistoRoutage === true ? (
        <Dialog open onClose={() => setOpenHistoRoutage(false)} fullWidth={true} maxWidth="md">
          <DialogContent>
            <HistoRoutageCodes />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHistoRoutage(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}

      {openHistoAddressage === true ? (
        <Dialog open onClose={() => setOpenHistoAddressage(false)} fullWidth={true} maxWidth="md">
          <DialogContent>
            <HistoAddressageCodes />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenHistoAddressage(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};
const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null,
    masterValues: state.MasterValueReducer,
    availableUes: state.AuthenticationReducer.user?.structureTpc?.ues
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onValueChanged: customer => {
      dispatch(Actions.EditCustomer(customer));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabCustomerGeneral));
