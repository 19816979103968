import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//helpers
import SiteMap from "./SiteMap";
import { SiteMapPositions } from "./SiteMapPositions";

const TabSiteMap = function({ site }) {
  var gps = SiteMapPositions(site.address_CountryCode);
  const map = (
    <SiteMap
      position={gps.position}
      zoom={gps.zoom}
      markers={[
        {
          name: site.name,
          line1: site.address_Line1,
          line2: site.address_Line2,
          zipCode: site.address_ZipCode,
          city: site.address_City,
          longitude: site.gpsCoordinates?.longitude,
          latitude: site.gpsCoordinates?.latitude,
          showPopup: true
        }
      ]}
    />
  );

  return <div>{map}</div>;
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabSiteMap);
