import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "@lingui/macro";
import { Button, CircularProgress, IconButton, Typography, Tab, Tabs } from "@material-ui/core";
import { FilterList, Add, Delete } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import DialogBox from "components/DialogBox/DialogBox";
import { connect } from "react-redux";
import { isArray } from "tools";
import { GroupGetAll, GroupDelete } from "./actions/Group.Actions";
import GroupDetail from "./part/detail/GroupDetail";
import CardFooter from "components/Card/CardFooter";
import ThirdPartyListing from "../thirdParties/ThirdPartyListing";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ThirdPartyCopernicListing from "module/tpCopernic/thirdParties/ThirdPartyListing";
import { TabPanel } from "components/Common/Tabs";

const GroupListing = ({ userInfo }) => {
  const [viewState, setViewState] = useState({
    isLoading: false,
    error: undefined,
    dialogBox: undefined,
    groups: [],
    selectedGroup: undefined,
    filterGroup: "",
    pageIndex: 0,
    pageSize: 10
  });
  const [filterText, setFilterText] = useState("");
  const [dialogBox, setDialogBox] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  let isColsAudit = useMemo(() => userInfo.profiles.some(p => p.profileIdentifier.endsWith("MDM_COLS_AUDIT")), [userInfo.profiles]);

  const reloadGroups = reset => {
    setViewState({ ...viewState, isLoading: true });
    GroupGetAll()
      .then(groups =>
        setViewState({
          ...viewState,
          isLoading: false,
          groups,
          selectedGroup: reset ? undefined : viewState.selectedGroup
        })
      )
      .catch(error =>
        setViewState({
          ...viewState,
          isLoading: false,
          error
        })
      );
  };

  const createGroup = parent => {
    setViewState({
      ...viewState,
      selectedGroup: {
        id: 0,
        label: "newGroup",
        countryCode: parent?.countryCode ?? userInfo?.country,
        parentId: parent?.id ?? 0,
        hasChanged: true
      }
    });
  };

  const deleteGroup = (g, confirm) => {
    if (!confirm) {
      setViewState({ ...viewState, dialogBox: { type: "yesNo", message: <Trans> ConfirmDelete </Trans>, yes: () => deleteGroup(g, true) } });
    } else {
      setViewState({ ...viewState, dialogBox: null });
      GroupDelete(g)
        .then(() => reloadGroups(true))
        .catch(e => setViewState({ ...viewState, error: e }));
    }
  };

  useEffect(() => {
    reloadGroups(true);
  }, []);

  const getPath = group => {
    if (!group || group.parentId === 0) return "";
    const parent = viewState.groups.find(g => g.id === group.parentId);
    if (!parent) return "";
    const pPath = getPath(parent);
    if (pPath === "") return parent.label;
    return pPath + " / " + parent.label;
  };

  const filteredGroups = useMemo(() => {
    return viewState.filterGroup === ""
      ? viewState.groups
      : viewState.groups.filter(g => g.label.toLowerCase().includes(viewState.filterGroup.toLowerCase()));
  }, [viewState.groups, viewState.filterGroup]);
  const pageCount = useMemo(() => Math.ceil(filteredGroups.length / viewState.pageSize), [filteredGroups.length, viewState.pageSize]);

  var banner = <></>;
  if (viewState.error && isArray(viewState.error.response.data) && viewState.error.response.data !== undefined) {
    banner = viewState.error.response.data.map((e, idx) => (
      <Alert severity="error" key={"error_" + idx}>
        {userInfo.language === "FR" ? e.descriptionFr : e.descriptionEn}
      </Alert>
    ));
  } else if (viewState.error) return <CardError error={viewState.error} />;

  if (viewState.isLoading) return <CircularProgress />;

  return (
    <>
      {banner}
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardBody>
              <form
                style={{ display: "flex" }}
                onSubmit={e => {
                  e.preventDefault();
                  setViewState({ ...viewState, filterGroup: filterText, pageIndex: 0 });
                }}
              >
                <FieldText
                  label={<Trans>Filter</Trans>}
                  isEditable
                  id="filterText"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                  style={{ flex: 1 }}
                />
                <IconButton onClick={() => setViewState({ ...viewState, filterGroup: filterText, pageIndex: 0, selectedGroup: undefined })}>
                  <FilterList />
                </IconButton>
              </form>
            </CardBody>
          </Card>
          {createGroup && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" size="sm" onClick={createGroup} color="info" className="like">
                <Add />
                <Trans>New Group</Trans>
              </Button>
            </div>
          )}
          <Card>
            <CardBody>
              {filteredGroups
                .slice(viewState.pageIndex * viewState.pageSize, viewState.pageIndex * viewState.pageSize + viewState.pageSize)
                .map((group, idx) => {
                  var path = getPath(group) ?? "";
                    return (
                      <>
                        <div
                          style={{
                            backgroundColor: viewState.selectedGroup?.id === group.id ? "yellow" : "initial",
                            borderBottom: "1px groove",
                            marginBottom: "1px"
                          }}
                          onMouseEnter={e => {
                            if (viewState.selectedGroup?.id !== group.id) {
                              e.currentTarget.style.backgroundColor = "grey";
                            }
                          }}
                          onMouseLeave={e => {
                            if (viewState.selectedGroup?.id !== group.id) {
                              e.currentTarget.style.backgroundColor = "initial";
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                            marginBottom="0px"
                          >
                            <Button onClick={() => setViewState({ ...viewState, selectedGroup: group })} 
                                  style={{padding:"1px"}}>{group.label}</Button>
                            <Typography variant="caption" color="initial" fontSize="small">
                              {group.thirdPartyCount}
                              {createGroup && (
                                <IconButton
                                  onClick={e => {
                                    e.stopPropagation();
                                    createGroup(group);
                                  }}
                                  style={{padding:"1px"}}
                                >
                                  <Add fontSize="small"  />
                                </IconButton>
                              )}
                              {group.id > 0 && !filteredGroups.some(s => s.parentId === group.id) && (
                                <IconButton
                                  onClick={e => {
                                    e.stopPropagation();
                                    group.id === 0 ? setViewState({ ...viewState, selectedGroup: null }) : deleteGroup(group, false);
                                  }}
                                  style={{padding:"1px"}}
                                >
                                  <Delete fontSize="small"  />
                                </IconButton>
                              )}
                            </Typography>
                          </div>
                          <div 
                            style={{
                              marginBottom:"0px"
                            }}
                          >
                            <Typography variant="caption" color="textSecondary" fontSize="small" style={{ marginLeft: "10px", padding:"2px" }}>
                              {path}
                            </Typography>
                          </div>
                        </div>
                      </>
                    );
                })}
            </CardBody>
            <CardFooter style={{ display: "flex", justifyItems: "center" }}>
              {viewState.pageIndex > 0 ? (
                <Button onClick={() => setViewState({ ...viewState, pageIndex: viewState.pageIndex - 1 })}>
                  <Trans>Previous</Trans>
                </Button>
              ) : (
                <span></span>
              )}
              <Typography variant="subtitle1" style={{ marginLeft: "5px", marginRight: "5px" }}>
                {viewState.pageIndex + 1} / {pageCount}
              </Typography>
              {viewState.pageIndex + 1 < pageCount ? (
                <Button variant="outlined" onClick={() => setViewState({ ...viewState, pageIndex: viewState.pageIndex + 1 })}>
                  <Trans>Next</Trans>
                </Button>
              ) : (
                <span></span>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <GroupDetail
            group={viewState.selectedGroup}
            setGroup={g => {
              setViewState({ ...viewState, selectedGroup: g });
            }}
            reloadGroups={reloadGroups}
            isGroupEditable={!isColsAudit}
          />
        </GridItem>
        {viewState.selectedGroup && viewState.selectedGroup?.id !== 0 && (
          <GridItem xs={12}>
            <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs supplier">
              <Tab label="Columbus" key="ColumbusTpTab" />
              <Tab label="Copernic" key="CopernicTab" />
            </Tabs>

            <TabPanel value={tabIdx} index={0}>
              <ThirdPartyListing groupId={viewState.selectedGroup?.id} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <ThirdPartyCopernicListing groupId={viewState.selectedGroup?.id} />
            </TabPanel>
          </GridItem>
        )}
      </GridContainer>
      {viewState.dialogBox && <DialogBox dialogBox={viewState.dialogBox} setDialogBox={s => setViewState({ ...viewState, dialogBox: s })} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.AuthenticationReducer?.user
  };
};

export default connect(mapStateToProps)(GroupListing);
