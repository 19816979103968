import * as Actions from "./types";

export function OpenThirdParty(thirdPartyId, activeTabName) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COLUMBUS_THIRDPARTY_OPEN,
      thirdPartyId,
      activeTabName
    });
  };
}

export function CloseThirdParty() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COLUMBUS_THIRDPARTY_CLOSE
    });
  };
}
