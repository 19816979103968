import React, { useMemo } from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { displayWarning, emptyIfNull, isArrayNullOrEmpty, isNull, getErrorStateFromErrorMessages } from "../../../tools";

import { FormControlLabel, FormLabel, Switch } from "@material-ui/core";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";

const TabThirdPartyAddress = ({ thirdPartyCtx, onThirdPartyValueChanged, defaultLang, errors }) => {
  var thirdParty = useMemo(() => thirdPartyCtx.thirdParty, [thirdPartyCtx]);
  var viewState = useMemo(() => {
    var isManual = !thirdParty.isFromInfolegal && !thirdParty.isFromAltares;
    var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : thirdPartyCtx.isEditable;
    var isAddressOverridden = !isNull(thirdParty.overridden_Address_CountryCode);
    return {
      isManual,
      isEditable,
      isFieldEditable: isEditable && (isManual || isAddressOverridden),
      isAddressOverridden
    };
  }, [thirdPartyCtx, thirdParty]);

  return (
    <GridContainer>
      <GridItem xs={7} sm={7} md={7}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>RegisteredAddress</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            {!viewState.isManual && viewState.isEditable && (
              <FormControlLabel
                control={
                  <Switch
                    checked={viewState.isAddressOverridden}
                    onChange={() => {
                      if (viewState.isAddressOverridden) {
                        onThirdPartyValueChanged({
                          ...thirdParty,
                          address_Line1: thirdParty.overridden_Address_Line1,
                          address_Line2: thirdParty.overridden_Address_Line2,
                          address_ZipCode: thirdParty.overridden_Address_ZipCode,
                          address_City: thirdParty.overridden_Address_City,
                          address_StateCode: thirdParty.overridden_Address_StateCode,
                          address_CountyCode: thirdParty.overridden_Address_CountyCode,
                          address_CountryCode: thirdParty.overridden_Address_CountryCode,
                          gpsCoordinates: {
                            latitude: thirdParty.overridden_GpsCoordinates.latitude,
                            longitude: thirdParty.overridden_GpsCoordinates.longitude
                          },
                          overridden_Address_Line1: null,
                          overridden_Address_Line2: null,
                          overridden_Address_ZipCode: null,
                          overridden_Address_City: null,
                          overridden_Address_StateCode: null,
                          overridden_Address_CountyCode: null,
                          overridden_Address_CountryCode: null,
                          overridden_GpsCoordinates: { latitude: null, longitude: null }
                        });
                      } else {
                        onThirdPartyValueChanged({
                          ...thirdParty,
                          overridden_Address_Line1: thirdParty.address_Line1,
                          overridden_Address_Line2: thirdParty.address_Line2,
                          overridden_Address_ZipCode: thirdParty.address_ZipCode,
                          overridden_Address_City: thirdParty.address_City,
                          overridden_Address_StateCode: thirdParty.address_StateCode,
                          overridden_Address_CountyCode: thirdParty.address_CountyCode,
                          overridden_Address_CountryCode: thirdParty.address_CountryCode,
                          overridden_GpsCoordinates: thirdParty.gpsCoordinates
                        });
                      }
                    }}
                    name="checkedA"
                  />
                }
                label={<Trans>ThirdParty_Address_Override</Trans>}
              />
            )}
            <CardAddress
              address={{
                line1: thirdParty.address_Line1,
                line2: thirdParty.address_Line2,
                zipCode: thirdParty.address_ZipCode,
                city: thirdParty.address_City,
                countryCode: thirdParty.address_CountryCode,
                countyCode: thirdParty.address_CountyCode,
                stateCode: thirdParty.address_StateCode,
                gpsCoordinates: thirdParty.gpsCoordinates
              }}
              setAddress={address => {
                onThirdPartyValueChanged({
                  ...thirdParty,
                  address_Line1: address.line1,
                  address_Line2: address.line2,
                  address_ZipCode: address.zipCode,
                  address_City: address.city,
                  address_CountryCode: address.countryCode,
                  address_CountyCode: address.countyCode,
                  address_StateCode: address.stateCode,
                  gpsCoordinates: address.gpsCoordinates
                });
              }}
              isFieldEditable={viewState.isFieldEditable}
              defaultLang={defaultLang}
              errors={errors}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={5} sm={5} md={5}>
        {viewState.isAddressOverridden && (
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Address_From_ExternalRepositories</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <CardAddress
                address={{
                  line1: thirdParty.overridden_Address_Line1,
                  line2: thirdParty.overridden_Address_Line2,
                  zipCode: thirdParty.overridden_Address_ZipCode,
                  city: thirdParty.overridden_Address_City,
                  countryCode: thirdParty.overridden_Address_CountryCode,
                  countyCode: thirdParty.overridden_Address_CountyCode,
                  stateCode: thirdParty.overridden_Address_StateCode,
                  gpsCoordinates: thirdParty.overridden_GpsCoordinates
                }}
                isFieldEditable={false}
                defaultLang={defaultLang}
              />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
};

const CardAddress = ({ address, setAddress, isFieldEditable, warnings, defaultLang, errors }) => {
  const gpsNull = { latitude: null, longitude: null };

  return (
    <GridContainer>
      <GridItem xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>Line 1</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        {displayWarning(warnings, "Address/Line1")}
        <FieldText
          isEditable={isFieldEditable}
          id="line1"
          value={emptyIfNull(address.line1)}
          onChange={e => {
            var value = e.target.value;
            if (value.length > 40) value = value.substring(0, 40);
            setAddress({ ...address, line1: value, gpsCoordinates: gpsNull });
          }}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>Line 2</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        {displayWarning(warnings, "Address/Line2")}
        <FieldText
          isEditable={isFieldEditable}
          id="line2"
          value={emptyIfNull(address.line2)}
          onChange={e => {
            var value = e.target.value;
            if (value.length > 40) value = value.substring(0, 40);
            setAddress({ ...address, line2: value, gpsCoordinates: gpsNull });
          }}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>City</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        <CityZipAutocomplete
          countryCode={address.countryCode}
          zipCode={address.zipCode}
          city={address.city}
          countyCode={address.countyCode}
          stateCode={address.stateCode}
          onZipCityChange={(zipCode, city) => {
            setAddress({ ...address, zipCode: zipCode, city: city, gpsCoordinates: gpsNull });
          }}
          onCountyStateChange={countyState => {
            setAddress({ ...address, stateCode: countyState.stateCode, countyCode: countyState.countyCode, gpsCoordinates: gpsNull });
          }}
          isEditable={isFieldEditable}
          defaultLang={defaultLang}
          zipCodeError={getErrorStateFromErrorMessages(errors, "AddressZipCode")}
          cityError={getErrorStateFromErrorMessages(errors, "AddressCity")}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>Country</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        <CountryAutocomplete
          countryCode={address.countryCode}
          onChange={codeIso2 => {
            if (address.countryCode !== codeIso2 && (address.countryCode === "FR" || codeIso2 === "FR"))
              setAddress({
                ...address,
                zipCode: null,
                city: null,
                countyCode: null,
                stateCode: null,
                countryCode: codeIso2,
                gpsCoordinates: gpsNull
              });
            else setAddress({ ...address, countryCode: codeIso2, gpsCoordinates: gpsNull });
          }}
          defaultLang={defaultLang}
          isEditable={isFieldEditable}
          error={getErrorStateFromErrorMessages(errors, "AddressCountry")}
        />
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>LatitudeLongitude</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        <GpsAutocomplete
          isEditable={isFieldEditable}
          defaultLang={defaultLang}
          source={{
            address_City: address.city,
            address_CountryCode: address.countryCode,
            address_Line1: address.line1,
            address_Line2: address.line2,
            address_ZipCode: address.zipCode,
            gpsCoordinates: address.gpsCoordinates
          }}
          setSource={c => {
            setAddress({
              ...address,
              city: c.address_City,
              countryCode: c.address_CountryCode,
              line1: c.address_Line1,
              line2: c.address_Line2,
              zipCode: c.address_ZipCode,
              gpsCoordinates: c.gpsCoordinates
            });
          }}
        />
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onThirdPartyValueChanged: thirdParty => {
      dispatch(Actions.EditThirdParty(thirdParty));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyAddress));
