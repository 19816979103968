import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/JobPhase.Actions";
import React from "react";
import { connect } from "react-redux";

function JobPhaseIdentifierAndSourcePart({ amc, itemChanged, isReadonly }) {
  var currentJob = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var item = !!amc.currentItem["JOBPHASE"] ? amc.currentItem["JOBPHASE"] : {};

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Identifiers and sources</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Code</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="label"
              value={item.code}
              onChange={e => itemChanged(currentJob, { ...item, code: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Label</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="label"
              value={item.label}
              onChange={e => itemChanged(currentJob, { ...item, label: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Id Erp</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="idErp"
              value={item.idErp}
              onChange={e => itemChanged(currentJob, { ...item, idErp: e.target.value })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: (job, item) => {
      dispatch(Actions.ItemChanged(job, item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhaseIdentifierAndSourcePart));
