import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { Card, CardContent } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Trans } from "@lingui/macro";
import { extractErrorMessage } from "../../../../tools";
import HeaderField from "../common/HeaderField";
import PartMagnitude from "../common/PartMagnitude";

const Header = ({ structure, setStructure, isEditable, masterValues, defaultLang, errors }) => {
  const div = structure.div;
  const setDiv = v => setStructure({ ...structure, div: v });

  const cardLeft = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer>
            <HeaderField isEditable={false} value={structure.identifiant} label={<Trans>Structure_Identifiant</Trans>} />
            <HeaderField
              isEditable={isEditable}
              value={structure.libelle}
              onChange={e => setStructure({ ...structure, libelle: e.target.value })}
              label={<Trans>Structure_Libelle</Trans>}
              error={extractErrorMessage(errors, "STR_LIBELLE", defaultLang)}
              showErrorTooltip
            />
            <HeaderField
              isEditable={isEditable}
              value={div.idJdeIss}
              onChange={e => setDiv({ ...div, idJdeIss: e.target.value })}
              label={<Trans>Structure_IdJdeIss</Trans>}
              fieldType="number"
              error={extractErrorMessage(errors, "DIV_IDJDEISS", defaultLang)}
              showErrorTooltip
            />
          </GridContainer>
        </CardContent>
      </Card>
    </>
  );

  const cardRight = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer></GridContainer>
        </CardContent>
      </Card>
    </>
  );

  return (
    <GridContainer>
      <GridItem xs={6} sm={6} md={6}>
        {cardLeft}
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        {cardRight}
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "white"
  },
  tpCard: {
    margin: "10px 0"
  },
  tpCardHeader: {
    padding: "8px 16px"
  },
  tpCardContent: {
    padding: "8px 16px",
    fontSize: "0.8rem"
  }
};

export default withStyles(structureStyle)(Header);
