import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//style
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/components/ProfilStyle.jsx";
// components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// @material-ui/core
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

//icon
import { Delete, Save, Close } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

//
import LocalStorgeHelper from "../../services/common/LocalStorageHelper";
import InfoGeneralDetails from "./components/InfoGeneralDetails";
import DetailPreference from "./components/DetailPreference";
//**Lingui**
import { Trans } from "@lingui/macro";
import ThirdPartyPreference from "./components/ThirdPartyPreference";
import { isNull } from "../../tools";
import { ChangeUserLanguage } from "../../services/user/UserHelper";
import { setUserProfiles } from "store/Authentication/Actions";

class Profil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Preference: {
        InfoGeneral: {
          Connexion: false,
          Checked: "",
          Langue: "",
          Module: ""
        },
        Pref: {
          Search: "",
          Result: "",
          number: 0,
          ResultPageSize: 10
        },
        ThirdPartyPref: {
          checkErrors: false
        }
      },
      language: this.props.defaultLang,
      openSave: false,
      openCancel: false
    };
  }

  //Handlebutton
  handelInfo = InfoGeneral => {
    var currentPreference = this.state.Preference;
    currentPreference.InfoGeneral = InfoGeneral;
    this.setState({ Preference: currentPreference });
  };
  handelPref = Pref => {
    var currentPreference = this.state.Preference;
    currentPreference.Pref = Pref;
    this.setState({ Preference: currentPreference });
  };
  handleThirdPartyPref = ThirdPartyPref => {
    var currentPreference = this.state.Preference;
    currentPreference.ThirdPartyPref = ThirdPartyPref;
    this.setState({ Preference: currentPreference });
  };
  //button Save
  handleClickSave = event => {
    event.preventDefault();
    var preference = this.state.Preference;
    var localStorageHelper = new LocalStorgeHelper();
    localStorageHelper.Save(preference);
    this.props.setDefaultLang(this.state.language);
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  //button cancel
  handleClickCancel = event => {
    event.preventDefault();

    var InitialPreference = this.state.Preference;
    InitialPreference.InfoGeneral.Connexion = false;
    InitialPreference.InfoGeneral.Checked = "Non";
    InitialPreference.InfoGeneral.Module = "Report";
    InitialPreference.Pref.Result = "Table";
    InitialPreference.Pref.Search = "Simple";
    InitialPreference.ThirdPartyPref.checkErrors = false;

    this.setState({ Preference: InitialPreference });
    var localStorageHelper = new LocalStorgeHelper();
    localStorageHelper.Save(this.state.Preference);
    this.setState({ openCancel: true });
  };

  handleCancelClose = () => {
    this.setState({ openCancel: false });
  };
  handleSaveClose = () => {
    this.setState({ openSave: false });
  };

  ///-----------------------------------------------------------
  ///  récupperer les préférences d'utilisateur de localStorage
  ///-----------------------------------------------------------
  componentWillMount() {
    var localStorageHelper = new LocalStorgeHelper();
    var getPref = localStorageHelper.get("Preferences");
    var PreferenceLocalStorage = this.state.Preference;

    if (getPref !== null) {
      if (isNull(getPref.ThirdPartyPref)) {
        getPref.ThirdPartyPref = { checkErrors: false };
      }

      this.setState({ Preference: getPref });
    } else {
      PreferenceLocalStorage.InfoGeneral.Connexion = false;
      PreferenceLocalStorage.InfoGeneral.Checked = "Non";
      PreferenceLocalStorage.InfoGeneral.Country = "FR";
      PreferenceLocalStorage.InfoGeneral.Module = "Report";
      PreferenceLocalStorage.Pref.Result = "Table";
      PreferenceLocalStorage.Pref.Search = "Simple";
      PreferenceLocalStorage.ThirdPartyPref.checkErrors = false;
      this.setState({ Preference: PreferenceLocalStorage });
    }
  }
  render() {
    const { classes } = this.props;
    const profil = require("../../assets/img/Profil.png");
    return (
      <GridContainer direction="row" justify="center" alignItems="center" alignContent="center" classeName={classes.position}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <img src={profil} alt="profil img" width="60px" />
              <Trans>Profil Setting</Trans>
            </CardHeader>
            <CardBody>
              <GridContainer classeName={classes.root}>
                <InfoGeneralDetails
                  InfoGeneral={this.state.Preference.InfoGeneral}
                  handelInfo={this.handelInfo}
                  defaultLang={this.state.language}
                  setDefaultLang={lang => this.setState({ language: lang })}
                />
                <DetailPreference Preference={this.state.Preference.Pref} handelPref={this.handelPref} />
                <ThirdPartyPreference preferences={this.state.Preference.ThirdPartyPref} handleThirdPartyPref={this.handleThirdPartyPref} />
                <GridItem className={classes.Margin}>
                  <Button variant="extendedFab" type="submit" className={classes.ButtonSave} onClick={this.handleClickSave}>
                    <Save className={classes.rightIcon} />
                    <Trans>Save</Trans>
                  </Button>
                  <Button variant="extendedFab" className={classes.ButtonCancel} type="submit" color="default" onClick={this.handleClickCancel}>
                    <Delete className={classes.leftIcon} />
                    <Trans> Reinit </Trans>
                  </Button>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    open={this.state.openSave}
                    autoHideDuration={4000}
                    onClose={this.handleSaveClose}
                    ContentProps={{
                      "aria-describedby": "message-id"
                    }}
                    message={
                      <span id="message-id">
                        <Trans>Preferences saved</Trans>
                      </span>
                    }
                    action={[
                      <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleSaveClose}>
                        <Close />
                      </IconButton>
                    ]}
                  />
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    open={this.state.openCancel}
                    autoHideDuration={4000}
                    className={classes.Success}
                    onClose={this.handleCancelClose}
                    ContentProps={{
                      "aria-describedby": "message-id"
                    }}
                    message={
                      <span id="message-id">
                        <Trans>Preferences canceled</Trans>
                      </span>
                    }
                    action={[
                      <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.handleCancelClose}>
                        <Close />
                      </IconButton>
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
Profil.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDefaultLang: lang => {
      ChangeUserLanguage(lang, user => dispatch(setUserProfiles(user)), error => console.log(error));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Profil));
