import React from "react";
import PropTypes from "prop-types";
//style
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/components/ProfilStyle.jsx";
//components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// @material-ui/core
import Switch from "@material-ui/core/Switch";

//lingui
import { Trans } from "@lingui/macro";

class ThirdPartyPreference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: props.preferences
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = event => {
    var preferences = this.state.preferences;
    preferences.checkErrors = !preferences.checkErrors;
    this.setState({ preferences });
    this.props.handleThirdPartyPref(preferences);
  };

  render() {
    const { classes } = this.props;

    return (
      <GridItem xs={6} sm={6} md={6}>
        <Card>
          <CardHeader color="success">
            <h5>
              <Trans>ThirdParty Preferences</Trans>
            </h5>
          </CardHeader>
          <CardBody>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <label className={classes.LabelSize}>
                  {" "}
                  <Trans> ThirdParty CheckErrors </Trans>{" "}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Switch disableRipple checked={this.state.preferences.checkErrors === true} onChange={this.handleChange} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}
ThirdPartyPreference.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(ThirdPartyPreference);
