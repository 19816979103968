import { Trans } from "@lingui/macro";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";

const styles = {
  media: {
    height: "100px",
    width: "320px",
    paddingTop: "56.25%", // 16:9,
    marginTop: "30"
  }
};

class Help extends React.Component {
  componentWillMount() {}

  routeTo(url) {
    window.open(url);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h3>
          <Trans>Portails client et fournisseur</Trans>
        </h3>
        <GridContainer>
          <GridItem xs={2}></GridItem>
          <GridItem>
            <Card
              className={classes.root}
              onClick={() =>
                this.routeTo(
                  "https://e4mdmcolassa.blob.core.windows.net/public/Nouveau Portail Clients-1080p-19774db9-2bd9-49c2-9e2a-ef5bab2df110.mp4"
                )
              }
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("assets/images/play.gif")}
                  title={<Trans>Portail client</Trans>}
                  style={styles.media}
                />
                <CardContent>
                  <Trans>Présentation du référentiel des clients</Trans>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={2}></GridItem>
          <GridItem>
            <Card
              className={classes.root}
              onClick={() =>
                this.routeTo(
                  "https://e4mdmcolassa.blob.core.windows.net/public/Pr%C3%A9sentation%20du%20r%C3%A9f%C3%A9rentiel%20des%20fournisseurs.mp4"
                )
              }
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("assets/images/play.gif")}
                  title={<Trans>Portail client</Trans>}
                  style={styles.media}
                />
                <CardContent>
                  <Trans>Présentation du référentiel des fournisseurs</Trans>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>

        <h3>
          <Trans>Référencement des fournisseurs</Trans>
        </h3>
        <GridContainer>
          <GridItem xs={2}></GridItem>
          <GridItem>
            <Card
              className={classes.root}
              onClick={() =>
                this.routeTo("https://e4mdmcolassa.blob.core.windows.net/public/Le%20r%C3%A9f%C3%A9rencement%20fournisseur%20-%20Demandeur.mp4")
              }
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("assets/images/play.gif")}
                  title={<Trans>Portail client</Trans>}
                  style={styles.media}
                />
                <CardContent>
                  <Trans>Référencement des fournisseurs - Demandeur</Trans>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
          <GridItem xs={2}></GridItem>
          <GridItem>
            <Card
              className={classes.root}
              onClick={() =>
                this.routeTo("https://e4mdmcolassa.blob.core.windows.net/public/Le%20r%C3%A9f%C3%A9rencement%20fournisseur%20-%20Valideur.mp4")
              }
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require("assets/images/play.gif")}
                  title={<Trans>Portail client</Trans>}
                  style={styles.media}
                />
                <CardContent>
                  <Trans>Référencement des fournisseurs - Valideur</Trans>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Help.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Help);
