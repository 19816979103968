import React from "react";
import * as Actions from "module/amc/store/actions/Job.Actions";
import { connect, useDispatch } from "react-redux";

// custom components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import JobPhaseListTab from "./Parts/JobPhaseListTab";

function JobPhasesTab({ amc, addPhase }) {
  const dispatch = useDispatch();

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var warnings = amc.errors["JOB"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <GridContainer>
          <GridItem xs={11} sm={11} md={11}>
            <h4>
              <Trans>Phases</Trans>
            </h4>
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <IconButton onClick={addPhase}>
              <Add style={{ color: "white" }} />
            </IconButton>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <JobPhaseListTab
          job={item}
          phases={
            !!item.jobPhases
              ? item.jobPhases.sort((a, b) => {
                  let comparison = 0;
                  if (a.code > b.code) {
                    comparison = 1;
                  } else if (a.code < b.code) {
                    comparison = -1;
                  }
                  return comparison;
                })
              : []
          }
        />
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    addPhase: () => {
      dispatch(Actions.AddPhase());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhasesTab));
