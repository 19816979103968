import React from "react";
import { RootStore } from "../../../../store/RootStore";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import SiteContact from "../SiteDetail/SiteContact";

//lingui
import { Trans } from "@lingui/macro";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { LocationContactType } from "store/MasterValue/MasterValueTypes";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function SiteStep3Contact({ site, setSite, masterValues, defaultLang }) {
  var cardContact = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Site_Create_Contact</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <SiteContact
          isEditable={true}
          contacts={site.contacts}
          setContacts={c => setSite({ ...site, contacts: c })}
          contactTypes={masterValues[LocationContactType]}
          defaultLang={defaultLang}
        />
      </CardBody>
    </Card>
  );

  return <>{cardContact}</>;
}

export default withStyles(thirdPartySearchCriteriaStyle)(SiteStep3Contact);
