import { Trans } from "@lingui/macro";
import { Checkbox, FormLabel, IconButton, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Lock, LockOpen } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { StructureSj, TpPaymentMethod, TpPaymentPeriod } from "store/MasterValue/MasterValueTypes";
import { nullIfEmpty } from "tools";

const DialogSupplierSj = function({ isEditable, isEditablePayPeriod, supplierSj, setSupplierSj, allSjs, availableSjs, classes }) {
  const sj = useMemo(() => {
    if (!supplierSj) return null;
    return allSjs.find(s => s.identifier === supplierSj.sjCode);
  }, [supplierSj]);

  if (!supplierSj) return <></>;

  return (
    <>
      <div>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Sj</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={false}
              id="sjCodeDetail"
              value={supplierSj.sjCode}
              onChange={e => setSupplierSj({ ...supplierSj, sjCode: nullIfEmpty(e.target.value) })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Label</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Typography>{sj.label}</Typography>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Code Iris3</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="iris3Detail"
              value={supplierSj.aN8Iris3}
              onChange={e => setSupplierSj({ ...supplierSj, aN8Iris3: nullIfEmpty(e.target.value) })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Code Iris2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="iris2Detail"
              value={supplierSj.aN8Iris2}
              onChange={e => setSupplierSj({ ...supplierSj, aN8Iris2: nullIfEmpty(e.target.value) })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Code Non Iris</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="nonIrisDetail"
              value={supplierSj.nonIrisId}
              onChange={e => setSupplierSj({ ...supplierSj, nonIrisId: nullIfEmpty(e.target.value) })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Cookie</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="cookieDetail"
              value={supplierSj.cookie}
              onChange={e => setSupplierSj({ ...supplierSj, cookie: nullIfEmpty(e.target.value) })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Old Identifier</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Typography>{sj.oldIdentifier}</Typography>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>New Identifier</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Typography>{sj.newIdentifier}</Typography>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Payment Period</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <MasterValueAutocomplete
              isEditable={isEditable || (isEditablePayPeriod && availableSjs.some(userSj => userSj.identifier === supplierSj.sjCode))}
              mvType={TpPaymentPeriod}
              selectedValue={supplierSj.paymentPeriodCode}
              onValueChange={code => setSupplierSj({ ...supplierSj, paymentPeriodCode: code, paymentPeriodForced: true })}
              prefixCode
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1} className={classes.forceNoPadding}>
            <IconButton
              disabled={!isEditable}
              onClick={() => setSupplierSj({ ...supplierSj, paymentPeriodForced: !supplierSj.paymentPeriodForced })}
              data-fieldName="paymentPeriodForced"
              data-fieldValue={supplierSj.paymentPeriodForced ? "yes" : "no"}
            >
              {supplierSj.paymentPeriodForced ? <Lock /> : <LockOpen />}
            </IconButton>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Payment Method</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <MasterValueAutocomplete
              isEditable={isEditable}
              mvType={TpPaymentMethod}
              selectedValue={supplierSj.paymentMethodCode}
              onValueChange={code => setSupplierSj({ ...supplierSj, paymentMethodCode: code })}
              prefixCode
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1} className={classes.forceNoPadding}>
            <IconButton
              disabled={!isEditable}
              onClick={() => setSupplierSj({ ...supplierSj, paymentMethodForced: !supplierSj.paymentMethodForced })}
              data-fieldName="paymentMethodForced"
              data-fieldValue={supplierSj.paymentMethodForced ? "yes" : "no"}
            >
              {supplierSj.paymentMethodForced ? <Lock /> : <LockOpen />}
            </IconButton>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>IsEligibleToDemat</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <Checkbox
              disabled={!isEditable}
              id="isEligibleToDematDetail"
              checked={supplierSj.isEligibleToDemat}
              onChange={e =>
                setSupplierSj({
                  ...supplierSj,
                  isEligibleToDemat: e.target.checked
                })
              }
            />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    availableSjs: state.AuthenticationReducer.user.structureTps.sjs,
    allSjs: state.MasterValueReducer[StructureSj]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(DialogSupplierSj));
