import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function SearchDunsMonitoring(criterias) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_DUNSMONITORING_BEGIN_ACTION
    });

    ApimGet(
      "api/TpCopernic/DunsMonitorings" + ConvertCriteriaToQueryString(criterias),
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_DUNSMONITORING_GET_ALL,
          result: responseJson
        });

        dispatch({
          type: Actions.TP_COPERNIC_DUNSMONITORING_END_ACTION
        });
      },
      () => {
        dispatch({
          type: Actions.TP_COPERNIC_DUNSMONITORING_END_ACTION
        });
      }
    );
  };
}

export function RemoveDunsFromMonitoring(item, searchCriteria) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_DUNSMONITORING_BEGIN_ACTION
    });

    ApimPost(
      "api/TpCopernic/DunsMonitorings",
      item,
      () => {
        dispatch(SearchDunsMonitoring(searchCriteria));
      },
      () => {
        dispatch({
          type: Actions.TP_COPERNIC_DUNSMONITORING_END_ACTION
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.duns)) queryParams.push("Duns=" + criteria.duns);

  if (!isNullOrEmpty(criteria.status)) queryParams.push("DunsMonitoringStatus=" + criteria.status);

  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
