import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

import WorkflowHeaderLink from "module/workflow/components/WorkflowHeaderLink";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Category from "@material-ui/icons/Category";
import Domain from "@material-ui/icons/Domain";
import Build from "@material-ui/icons/Build";
import AccountTree from "@material-ui/icons/AccountTree";
import Equalizer from "@material-ui/icons/Equalizer";

import headerLinksStyle from "../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
//lingui
import { Trans } from "@lingui/macro";
import { AddComment, DeviceHub, LocationOn, InfoOutlined } from "@material-ui/icons";
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { HasRight } from "services/user/UserHelper";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    profiles: []
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleThirdPartyClick = () => {
    this.setState({ tpOpen: !this.state.tpOpen });
  };
  handleThirdPartyClose = () => {
    this.setState({ tpOpen: false });
  };

  RenderDashBoardToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Dashboard</Trans>}>
        <IconButton color="default" aria-label="Dashboard" className={classes.buttonLink} component={Link} to="/">
          <Dashboard className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Dashboard</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderAmcToolTip(allowAmc) {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Amc</Trans>}>
        <IconButton color="default" aria-label="Dashboard" className={classes.buttonLink} component={Link} to="/amc/projects">
          <AccountTree className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Amc</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderSupervisionToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Supervision</Trans>}>
        <IconButton color="default" aria-label="Dashboard" className={classes.buttonLink} component={Link} to="/supervision/globalHealth">
          <Equalizer className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Supervision</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderSiteToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Site</Trans>}>
        <IconButton color="default" aria-label="Dashboard" className={classes.buttonLink} component={Link} to="/sites/sites">
          <LocationOn className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Sites</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderStructureToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Structures</Trans>}>
        <IconButton color="default" aria-label="Structures" className={classes.buttonLink} component={Link} to="/structures/structures">
          <DeviceHub className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Structures</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderWorkflowToolTip() {
    const { classes } = this.props;
    return <WorkflowHeaderLink />;
  }

  RenderProductsToolTip() {
    const { classes } = this.props;
    return (
      <>
        <Tooltip title={<Trans>Products</Trans>}>
          <IconButton color="default" aria-label="Products" className={classes.buttonLink} component={Link} to="/products">
            <Category className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span className={classes.linkText}>{<Trans>Products</Trans>}</span>
            </Hidden>
          </IconButton>
        </Tooltip>
      </>
    );
  }

  RenderThirdPartyToolTip() {
    const { classes } = this.props;

    let viewColumbus = HasRight("tp_columbus.view");
    let viewCopernic = HasRight("thirdparty_customer.view") || HasRight("thirdparty_workflow.edit") || HasRight("thirdparty_supplier.view");
    if (!viewColumbus && !viewCopernic) {
      return <></>;
    }

    if (!viewColumbus && viewCopernic) {
      return (
        <Tooltip title={<Trans> Third Party / Customer / Supplier </Trans>}>
          <IconButton color="default" aria-label="ThirdParties" className={classes.buttonLink} component={Link} to="/tpCopernic/ThirdPartySearch">
            <Domain className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span className={classes.linkText}>{"ThirdParties"}</span>
            </Hidden>
          </IconButton>
        </Tooltip>
      );
    }

    if (viewColumbus && !viewCopernic) {
      return (
        <Tooltip title={<Trans> Third Party / Customer / Supplier </Trans>}>
          <IconButton color="default" aria-label="ThirdParties" className={classes.buttonLink} component={Link} to="/tpColumbus/ThirdPartySearch">
            <Domain className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span className={classes.linkText}>{"ThirdParties"}</span>
            </Hidden>
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <span style={{ position: "relative" }}>
        <Tooltip title={<Trans> Third Party / Customer / Supplier </Trans>}>
          <IconButton
            color="default"
            aria-label="ThirdParties"
            aria-owns={this.state.tpOpen ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleThirdPartyClick}
            className={classes.buttonLink}
          >
            <Domain className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span className={classes.linkText}>{"ThirdParties"}</span>
            </Hidden>
          </IconButton>
        </Tooltip>
        <Popper
          open={Boolean(this.state.tpOpen)}
          anchorEl={this.state.tpOpen}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !this.state.tpOpen,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: "0 0 0", marginTop: "20px" }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleThirdPartyClose}>
                  <MenuList role="menu">
                    <MenuItem component={Link} to="/tpCopernic/ThirdPartySearch" onClick={this.handleThirdPartyClose}>
                      <Trans>HeaderLinks_TpCopernic</Trans>
                    </MenuItem>
                    <MenuItem component={Link} to="/tpColumbus/ThirdPartySearch" onClick={this.handleThirdPartyClose}>
                      <Trans>HeaderLinks_TpColumbus</Trans>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </span>
    );
  }

  RenderAdminToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans> Administrator </Trans>}>
        <IconButton color="default" aria-label="Nace Admin" className={classes.buttonLink} component={Link} to="/admin/dashboard">
          <Build className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Administrator</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  RenderSupervisionToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Supervision</Trans>}>
        <IconButton color="default" aria-label="Dashboard" className={classes.buttonLink} component={Link} to="/supervision/flowsHealth">
          <Equalizer className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Supervision</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }

  
  RenderHelpToolTip() {
    const { classes } = this.props;
    return (
      <Tooltip title={<Trans>Help</Trans>}>
        <IconButton color="default" aria-label="Help" className={classes.buttonLink} component={Link} to="/helpInfo">
          <InfoOutlined className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{<Trans>Help</Trans>}</span>
          </Hidden>
        </IconButton>
      </Tooltip>
    );
  }
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    var dashboardToolTip = this.RenderDashBoardToolTip();
    var productsToolTip = "";
    if (HasRight("product.view")) productsToolTip = this.RenderProductsToolTip();
    var thirdpartyToolTip = this.RenderThirdPartyToolTip();
    var siteToolTip = this.RenderSiteToolTip();
    var structureToolTip = this.RenderStructureToolTip();
    var workflowToolTip = "";
    if (
      HasRight("thirdparty_customer.view") ||
      HasRight("thirdparty_workflow.edit") ||
      HasRight("thirdparty_supplier.view") ||
      HasRight("thirdparty_workflow.view")
    )
      workflowToolTip = this.RenderWorkflowToolTip();
    var jobToolTip = "";
    if (HasRight("amc.view")) jobToolTip = this.RenderAmcToolTip();
    var adminToolTip = "";
    var supervisionToolTip = "";
    if (HasRight("application.edit")) {
      adminToolTip = this.RenderAdminToolTip();
      supervisionToolTip = this.RenderSupervisionToolTip();
    }
    if (HasRight("application.admin")) {
      adminToolTip = this.RenderAdminToolTip();
    }
    var helpToolTip = this.RenderHelpToolTip();

    return (
      <div>
        {dashboardToolTip}
        {siteToolTip}
        {structureToolTip}
        {productsToolTip}
        {thirdpartyToolTip}
        {jobToolTip}
        {adminToolTip}
        {supervisionToolTip}
        {workflowToolTip}
        {helpToolTip}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
