import { Trans } from "@lingui/macro";
import { CircularProgress, Grid, SnackbarContent } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { AddAlert } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CardError from "components/Card/CardError";
import HistoryContainer from "module/history/containers/HistoryContainer";
import React, { useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "tools";
import { GetThirdPartyHistory } from "../../actions/ThirdPartyActions";

const TabThirdPartyHistory = function({ thirdParty }) {
  const [state, setState] = useState({ isLoading: false });

  if (thirdParty.identifier === 0) {
    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <SnackbarContent message={<Trans>ThirdParty_Creation_PleaseSave</Trans>} icon={AddAlert} color="info" />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    GetThirdPartyHistory(
      thirdParty.identifier,
      h => setState({ isLoading: false, history: h }),
      e => setState({ isLoading: false, history: [], error: e })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer currentItem={thirdParty} items={state.history} />;
};

const GridContainerStyle = {
  mainGrid: {
    marginBottom: "40px",
    marginTop: "40px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyHistory));
