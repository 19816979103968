import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Warning } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import TabThirdPartyAddress from "./TabThirdPartyAddress";
import TabThirdPartyContact from "./TabThirdPartyContact";
import TabThirdPartyGeneral from "./TabThirdPartyGeneral";
import TabThirdPartyHistory from "./TabThirdPartyHistory";
import TabThirdPartySource from "./TabThirdPartySource";

function TabThirdParty({ thirdParty, setThirdParty, isEditable, masterValues, defaultLang, isGroupEditable }) {
  const [tabIdx, setTabIdx] = useState(0);
  const [backup] = useState(JSON.stringify(thirdParty));

  let setTp = tp => {
    const { hasChanged, customer, supplier, ...t } = tp;
    setThirdParty({ ...tp, hasChanged: JSON.stringify(t) !== backup });
  };

  var warnings = thirdParty.warnings ?? [];
  var tabGeneral = <Trans>General</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "General"))
    tabGeneral = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabGeneral}
      </span>
    );
  var tabAddress = <Trans>Address</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Address"))
    tabAddress = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabAddress}
      </span>
    );
  var tabCommunication = <Trans>Communication</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Communication"))
    tabCommunication = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabCommunication}
      </span>
    );
  var tabSource = <Trans>Source</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Source"))
    tabSource = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabSource}
      </span>
    );
  var tabHistory = <Trans>History</Trans>;

  return (
    <div className="divContainer">
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={tabGeneral} />
              <AntTab label={tabAddress} />
              <AntTab label={tabCommunication} />
              <AntTab label={tabSource} />
              <AntTab label={tabHistory} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabThirdPartyGeneral
                thirdParty={thirdParty}
                setThirdParty={setTp}
                masterValues={masterValues}
                isEditable={isEditable}
                defaultLang={defaultLang}
                isGroupEditable={isGroupEditable}
              />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabThirdPartyAddress thirdParty={thirdParty} setThirdParty={setTp} isEditable={isEditable} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabThirdPartyContact thirdParty={thirdParty} setThirdParty={setTp} isEditable={isEditable} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <TabThirdPartySource
                thirdParty={thirdParty}
                setThirdParty={setTp}
                masterValues={masterValues}
                isEditable={isEditable}
                defaultLang={defaultLang}
              />
            </TabPanel>
            <TabPanel value={tabIdx} index={4}>
              <TabThirdPartyHistory thirdParty={thirdParty} setThirdParty={setTp} isEditable={isEditable} defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabThirdParty);
