import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import Notifications from "@material-ui/icons/Notifications";

import { Trans } from "@lingui/macro";

import headerLinksStyle from "../../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import { IconButton } from "@material-ui/core";
import { WorkflowCount } from "../store/actions/Workflow.Actions";

function WorkflowHeaderLink({ classes }) {
  const [open, setOpen] = useState(false);
  const [nbItem, setNbItem] = useState(0);

  useEffect(() => {
    WorkflowCount(nb => {
      setNbItem(nb);
    });
  }, []);

  var handleClick = () => {
    setOpen(true);
  };

  return (
    <Tooltip title={<Trans>Notification</Trans>}>
      <IconButton
        color="default"
        aria-label="Notifications"
        aria-owns={open ? "menu-list" : null}
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.buttonLink}
        component={Link}
        to="/workflows"
      >
        <Notifications />
        <span className={classes.notifications}>{nbItem}</span>
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>{<Trans>Notification</Trans>}</span>
        </Hidden>
      </IconButton>
    </Tooltip>
  );
}

export default withStyles(headerLinksStyle)(WorkflowHeaderLink);
