import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import { Trans } from "@lingui/macro";
import PartThirdParties from "../common/PartThirdParties";
import PartLocations from "../common/PartLocations";
import FieldText from "components/Common/FieldText";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import { Domain, LocationOn } from "@material-ui/icons";
import { extractErrorMessage } from "module/tools";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { FormControlLabel, Switch } from "@material-ui/core";

const TabGeneral = ({ structure, setStructure, masterValues, defaultLang, isEditable, errors }) => {
  const up = structure.up;
  const setUp = v => setStructure({ ...structure, up: v });

  const compareThirdParty = compareFn => {
    var tp = structure.thirdParties.find(tp => !!tp.isMain);
    if (tp && tp.thirdParty && !compareFn(tp.thirdParty)) return <Trans>Structure_DifferentFromThirdPartyData</Trans>;

    return null;
  };

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Structure_Part_General</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>LegalIdentifier</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={up.legalIdentifier}
                    warning={compareThirdParty(tp => tp.legalIdentifier === up.legalIdentifier)}
                    onChange={e => setUp({ ...up, legalIdentifier: e.target.value })}
                    error={extractErrorMessage(errors, "UP_LEGALID", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>TaxCode</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={up.taxCode}
                    warning={compareThirdParty(tp => tp.taxCode === up.taxCode)}
                    onChange={e => setUp({ ...up, taxCode: e.target.value })}
                    error={extractErrorMessage(errors, "UP_TAXCODE", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Line1</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={up.address_Line1}
                    warning={compareThirdParty(tp => tp.address_Line1 === up.address_Line1)}
                    onChange={e => setUp({ ...up, address_Line1: e.target.value })}
                    error={extractErrorMessage(errors, "UP_ADDR_LINE1", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Line2</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={up.address_Line2}
                    warning={compareThirdParty(tp => tp.address_Line2 === up.address_Line2)}
                    onChange={e => setUp({ ...up, address_Line2: e.target.value })}
                    error={extractErrorMessage(errors, "UP_ADDR_LINE2", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>City</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <CityZipAutocomplete
                    countryCode={up.address_CountryCode}
                    zipCode={up.address_ZipCode}
                    city={up.address_City}
                    countyCode={up.address_CountyCode}
                    stateCode={up.address_StateCode}
                    onZipCityChange={(zipCode, city) => {
                      setUp({ ...up, address_ZipCode: zipCode, address_City: city });
                    }}
                    onCountyStateChange={countyState => {
                      setUp({ ...up, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
                    }}
                    isEditable={isEditable}
                    size="small"
                    defaultLang={defaultLang}
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Country</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <CountryAutocomplete
                    countryCode={up.address_CountryCode}
                    onChange={codeIso2 => {
                      if (up.address_CountryCode !== codeIso2 && (up.address_CountryCode === "FR" || codeIso2 === "FR"))
                        setUp({ ...up, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                      else setUp({ ...up, address_CountryCode: codeIso2 });
                    }}
                    defaultLang={defaultLang}
                    isEditable={isEditable}
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>LatitudeLongitude</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <GpsAutocomplete
                    isEditable={isEditable}
                    source={up}
                    setSource={c => {
                      setUp(c);
                    }}
                    showMap
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch size="small" checked={up.isMobile} disabled={!isEditable} onChange={() => setUp({ ...up, isMobile: !up.isMobile })} />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_UP_IsMobile</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Domain />
            <Trans>Structure_Part_ThirdParties</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <PartThirdParties
            structure={structure}
            setStructure={setStructure}
            copyDataFromTp={data => {
              setUp({ ...up, ...data });
            }}
            isEditable={isEditable}
            masterValues={masterValues}
            defaultLang={defaultLang}
            errors={errors}
          />
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <LocationOn /> <Trans>Structure_Part_Locations</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <PartLocations structure={structure} setStructure={setStructure} isEditable={isEditable} defaultLang={defaultLang} errors={errors} />
        </CardBody>
      </Card>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(structureStyle)(TabGeneral);
