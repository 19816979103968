import React from 'react';

// tabs
import TabSiteMap from './SiteDetail/TabSiteMap';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function SiteMap({ site, setSite, defaultLang }) {
    return (
        <div className='divContainer'>
            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <TabSiteMap site={site} setSite={setSite} defaultLang={defaultLang} />
            </form>
        </div >
    );
};

export default withStyles(thirdPartySearchCriteriaStyle)(SiteMap);