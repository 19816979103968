import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// style for this view
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
// linguiJs
import { Trans } from "@lingui/macro";
import DateSelector from "components/Common/DateSelector.jsx";
import { Switch } from "@material-ui/core";
import OldStructureAutocomplete from "components/Autocomplete/OldStructureAutocomplete";

function SearchCriteriaExtended({ criteria, setCriteria, cleanSearch, launchSearch, showSearchCriteriaOnSideBar, classes, defaultLang }) {
  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  return (
    <GridContainer>
      <form onSubmit={launchSearch} autoComplete="off">
        <GridContainer>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Code_Product</Trans>}
              id="CriteriaCode"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  code: e.target.value
                })
              }
              value={!!criteria.code ? criteria.code : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Generated_Code</Trans>}
              id="criteriaGenCod"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  genCode: e.target.value
                })
              }
              value={!!criteria.genCode ? criteria.genCode : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Segmentation_Level_3</Trans>}
              id="criteriaSegLev3"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  segmentationLevel3: e.target.value
                })
              }
              value={!!criteria.segmentationLevel3 ? criteria.segmentationLevel3 : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Segmentation_Level_4</Trans>}
              id="criteriaSegLevel4"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  segmentationLevel4: e.target.value
                })
              }
              value={!!criteria.segmentationLevel4 ? criteria.segmentationLevel4 : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Commercial_Label</Trans>}
              id="criteriaCommercialLabel"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  commercialLabel: e.target.value
                })
              }
              value={!!criteria.commercialLabel ? criteria.commercialLabel : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>Technical_Label</Trans>}
              id="criteriaTechnicalLabel"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  technicalLabel: e.target.value
                })
              }
              value={!!criteria.technicalLabel ? criteria.technicalLabel : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <CustomInput
              labelText={<Trans>KeyWord</Trans>}
              id="criteriaKeyWord"
              onChange={e =>
                setCriteria({
                  ...criteria,
                  keyword: e.target.value
                })
              }
              value={!!criteria.keyword ? criteria.keyword : ""}
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <OldStructureAutocomplete
              structureTypes={["UP", "UE", "SJ", "ERT"]}
              value={{ structureId: criteria.structureIdentifier, structureType: criteria.structureType }}
              onChange={(structureId, structureType) =>
                setCriteria({
                  ...criteria,
                  structureIdentifier: structureId,
                  structureType: structureType
                })
              }
              defaultLang={defaultLang}
            />
            {/* <OldStructureAutocomplete
              value={selectedStructure}
              onChange={(structureId, structureType) =>
                setCriteria({
                  ...criteria,
                  criteriaStructureId: structureId ? structureId : null,
                  criteriaStructureType: structureType ? structureType : null
                })
              }
              defaultLang={defaultLang}
            /> */}
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <FormControlLabel
              style={{ marginTop: 15, marginLeft: 0 }}
              value="start"
              control={
                <Switch
                  size="small"
                  checked={criteria.includeDeletedItems}
                  onChange={() =>
                    setCriteria({
                      ...criteria,
                      includeDeletedItems: !criteria.includeDeletedItems
                    })
                  }
                />
              }
              label={<Trans>Include_Deleted_Products</Trans>}
              labelPlacement="start"
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <DateSelector
              clearable
              label={<Trans>Sleeping_Date</Trans>}
              value={!!criteria.sleepingDate ? criteria.sleepingDate : null}
              isEditable={true}
              onChange={e => setCriteria({ ...criteria, sleepingDate: e })}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
            <DateSelector
              clearable
              label={<Trans>Last_Modification_Date</Trans>}
              value={!!criteria.lastModificationDate ? criteria.lastModificationDate : null}
              isEditable={true}
              onChange={e => setCriteria({ ...criteria, lastModificationDate: e })}
              defaultLang={defaultLang}
            />
          </GridItem>
        </GridContainer>
        <div>
          <div className={classes.flexRight}>
            <Button onClick={cleanSearch}>
              <Trans> Clean </Trans>
            </Button>
            <Button type="submit" color="info">
              <Trans>Search</Trans>
            </Button>
          </div>
        </div>
      </form>
    </GridContainer>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriaExtended);
