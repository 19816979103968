import { PublicClientApplication } from "@azure/msal-browser";
import { apimLoginRequest, msalConfig } from "services/authentication/MsalAuth";

var OcpApimSubscriptionKey = "";
var ApiMdmColasEndPoint = "";

export class FileHelper {
  constructor() {
    OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
    ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;
  }

  Upload(container, file, endUpload) {
    const msalInstance = new PublicClientApplication(msalConfig);

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
    ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

    msalInstance
      .acquireTokenSilent({
        ...apimLoginRequest,
        account: msalInstance.getActiveAccount()
      })
      .then(apiTokenResponse => {
        let token = apiTokenResponse.accessToken;
        let url = ApiMdmColasEndPoint + "api/UploadFile/Upload/" + container;
        let headers = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
          Authorization: "Bearer " + token
        };
        let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
        if (authJwt) {
          headers = { ...headers, AuthJwt: authJwt.token };
        }

        const formdata = new FormData();
        formdata.append("file", file, file.name);

        var requestOptions = {
          method: "POST",
          headers: headers,
          body: formdata,
          redirect: "follow"
        };

        fetch(url, requestOptions)
          .then(r => {
            if (r.status === 200) {
              return r.json();
            } else if (r.status === 204) {
              return [];
            }
          })
          .then(responseJson => {
            if (!!endUpload) endUpload(responseJson);
          })
          .catch(error => {
            console.error(error);
          });
      });
  }

  Download(container, file, endDownload) {
    const msalInstance = new PublicClientApplication(msalConfig);

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
    ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

    msalInstance
      .acquireTokenSilent({
        ...apimLoginRequest,
        account: msalInstance.getActiveAccount()
      })
      .then(apiTokenResponse => {
        let token = apiTokenResponse.accessToken;
        let url = ApiMdmColasEndPoint + "api/UploadFile/" + container + "/" + file;
        let headers = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
          Authorization: "Bearer " + token
        };
        let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
        if (authJwt) {
          headers = { ...headers, AuthJwt: authJwt.token };
        }

        var requestOptions = {
          method: "Get",
          headers: headers
        };

        fetch(url, requestOptions)
          .then(r => {
            if (r.status === 200) {
              return r.blob();
            }
          })
          .then(responseJson => {
            if (!!endDownload) endDownload(responseJson);
          })
          .catch(error => {
            console.error(error);
          });
      });
  }
}
