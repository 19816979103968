import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { TabPanel } from "components/Common/Tabs";
import React, { useEffect, useState } from "react";
import { ApimGet, ApimPost } from "components/Common/ApimSender";
import CardError from "../components/Card/CardError";
import { isArrayNullOrEmpty } from "../tools";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const SupervisionDialog = ({ objectType, objectId, query }) => {
  const [tabIdx, setTabIdx] = useState(0);
  return (
    <>
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs supplier">
        {objectType && objectId ? <Tab label="Historique" /> : <></>}
        {query ? <Tab label="Notifications" /> : <></>}
      </Tabs>
      {objectType && objectId ? (
        <TabPanel value={tabIdx} index={0}>
          <TabHistorique objectType={objectType} objectId={objectId} />
        </TabPanel>
      ) : (
        <></>
      )}
      {query ? (
        <TabPanel value={tabIdx} index={1}>
          <TabAppInsight query={query} />
        </TabPanel>
      ) : (
        <></>
      )}
    </>
  );
};

const TabHistorique = ({ objectType, objectId }) => {
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => {
    let url = "";
    if (objectType === "supplier") {
      url = `api/TpCopernic/Suppliers/${objectId}/history`;
    } else if (objectType === "supplierSj") {
      let split = objectId.split("|");
      url = `api/TpCopernic/Suppliers/${split[0]}/sjs/${split[1]}/history`;
    } else if (objectType === "paymentCharacteristic") {
      let split = objectId.split("|");
      url = `api/TpCopernic/Suppliers/${split[0]}/PaymentCharacteristics/${split[1]}/history`;
    } else {
      setState({ error: { message: "undefined objectType" } });
      return;
    }

    setState({ isLoading: true });
    ApimGet(url, c => setState({ isLoading: false, result: c }), e => setState({ isLoading: false, error: e }));
  }, [objectType, objectId]);

  const classes = useStyles();
  if (!!state.isLoading) {
    return <CircularProgress />;
  } else if (state.error) {
    return <CardError error={state.error} />;
  } else if (isArrayNullOrEmpty(state.result)) {
    return <Typography>Aucun résultat</Typography>;
  }

  return state.result.map(r => (
    <Accordion key={r.rowKey}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>
          {r.lastModificationDate} - {r.lastModificationUser}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <pre>{JSON.stringify(r, null, 2)}</pre>
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));
};

const TabAppInsight = ({ query }) => {
  const [delay, setDelay] = useState("30d");
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => {
    setState({ isLoading: true });
    ApimPost(
      "api/publicSupervision/query",
      {
        type: "DEPENDENCY",
        filter: query + ` and timestamp > ago(${delay})`
      },
      c => setState({ isLoading: false, result: c }),
      e => setState({ isLoading: false, error: e })
    );
  }, [query]);

  const classes = useStyles();
  if (!!state.isLoading) {
    return <CircularProgress />;
  } else if (state.error) {
    return <CardError error={state.error} />;
  } else if (isArrayNullOrEmpty(state.result)) {
    return <Typography>Aucun résultat</Typography>;
  }

  return state.result.map(r => (
    <Accordion key={r.id}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>
          {r.name} - {r.timeStamp}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <code>{r.data}</code>
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));
};

export default SupervisionDialog;
