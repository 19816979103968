import { Trans } from "@lingui/macro";
import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloudOff from "@material-ui/icons/CloudOff";
import StructuresResult from "components/Administrator/Structures/StructuresResult";
import StructuresSearch from "components/Administrator/Structures/StructuresSearch";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import OldStructureAutocomplete from "components/Autocomplete/OldStructureAutocomplete";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { ApimGet, ApimPost } from "components/Common/ApimSender";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import Selector from "components/Common/Selector.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { CommonCountry, StructureSj, StructureUe, TpPaymentMethod } from "store/MasterValue/MasterValueTypes";
import { RootStore } from "store/RootStore";
import ActionsCell from "./ActionsCell";

class Structures extends React.Component {
  constructor(props) {
    super(props);

    var defaultLang = "EN";
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    if (getPref !== null) {
      defaultLang = getPref.InfoGeneral.Langue === "fr" ? "FR" : "EN";
    }

    var baseResultColumnsHeader = [
      {
        Header: "",
        filterable: false,
        getProps: this.getActionProps,
        Cell: ActionsCell,
        Width: 100
      },
      {
        Header: <Trans>Identifier</Trans>,
        accessor: "identifier",
        filterable: true,
        Width: 100
      },
      {
        Header: <Trans>Old Identifier</Trans>,
        accessor: "oldIdentifier",
        filterable: true,
        Width: 150
      },
      {
        Header: <Trans>Label</Trans>,
        accessor: "label",
        filterable: true
      },
      {
        Header: <Trans>Is Enabled</Trans>,
        accessor: "isEnabled",
        Cell: rowInfo => {
          return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isEnabled} />;
        },
        filterable: true
      }
    ];
    this.state = {
      showResult: false,
      showNoResult: false,
      showWaiter: false,
      masterValues: null,
      editing: null,
      masterValuesTypes: [
        { value: "ERTS", label: "Erts", columns: baseResultColumnsHeader },
        {
          value: "SJS",
          label: "Sjs",
          columns: baseResultColumnsHeader.concat([
            {
              Header: <Trans>New Identifier</Trans>,
              accessor: "newIdentifier",
              filterable: true
            },
            {
              Header: <Trans>Ert</Trans>,
              accessor: "ertIdentifier",
              Cell: rowInfo => {
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  return (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={this.state.editing.ertIdentifier}
                      onChange={event => {
                        this.handleItemPropertyChange(event, "ertIdentifier");
                      }}
                    />
                  );
                } else {
                  return <label>{rowInfo.original.ertIdentifier}</label>;
                }
              },
              filterable: true
            },
            {
              Header: <Trans>Mig</Trans>,
              accessor: "label",
              Cell: rowInfo => {
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  return (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={this.state.editing.migCode}
                      onChange={event => {
                        this.handleItemPropertyChange(event, "migCode");
                      }}
                    />
                  );
                } else {
                  return <label>{rowInfo.original.migCode}</label>;
                }
              },
              filterable: true
            },
            {
              Header: <Trans>Country Code</Trans>,
              accessor: "countryIdentifier",
              filterable: true,
              Cell: rowInfo => {
                var countries = RootStore.getState().MasterValueReducer[CommonCountry];
                var isEditable = this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier;
                var currentItem = isEditable ? this.state.editing : rowInfo.original;
                return (
                  <Selector
                    items={countries}
                    displayedProperties={{
                      keyProperty: "codeIso2",
                      valueProperty: "label_" + defaultLang
                    }}
                    transformValue={item => {
                      if (item !== null && item !== undefined) return item.codeIso2 + " - " + GetMasterValueLabel(item, defaultLang);
                      else return "";
                    }}
                    value={currentItem.countryIdentifier}
                    isEditable={isEditable}
                    onChange={event => {
                      this.handleItemPropertyChange(event, "countryIdentifier");
                    }}
                  />
                );
              }
            },
            {
              Header: <Trans>PaymentMethodDefault</Trans>,
              accessor: "paymentMethodDefaultCode",
              filterable: true,
              Cell: rowInfo => {
                var paymentMethods = RootStore.getState().MasterValueReducer[TpPaymentMethod];
                var isEditable = this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier;
                var currentItem = isEditable ? this.state.editing : rowInfo.original;
                return (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={currentItem.paymentMethodDefaultCode}
                    onChange={event => {
                      this.handleItemPropertyChange(event, "paymentMethodDefaultCode");
                    }}
                  />
                );
              }
            },
            {
              Header: <Trans>PaymentMethodAlt</Trans>,
              accessor: "paymentMethodAltCode",
              filterable: true,
              Cell: rowInfo => {
                var paymentMethods = RootStore.getState().MasterValueReducer[TpPaymentMethod];
                var isEditable = this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier;
                var currentItem = isEditable ? this.state.editing : rowInfo.original;
                return (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={currentItem.paymentMethodAltCode}
                    onChange={event => {
                      this.handleItemPropertyChange(event, "paymentMethodAltCode");
                    }}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow CC Iris 2</Trans>,
              accessor: "isCustomerContractIris2",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isCustomerContractIris2;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isCustomerContractIris2;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isCustomerContractIris2");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow CC Iris 3</Trans>,
              accessor: "isCustomerContractIris3",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isCustomerContractIris3;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isCustomerContractIris3;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isCustomerContractIris3");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Customer Iris 2</Trans>,
              accessor: "isCustomerIris2",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isCustomerIris2;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isCustomerIris2;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isCustomerIris2");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Customer Iris 3</Trans>,
              accessor: "isCustomerIris3",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isCustomerIris3;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isCustomerIris3;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isCustomerIris3");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Job Iris 2</Trans>,
              accessor: "isJobIris2",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isJobIris2;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isJobIris2;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isJobIris2");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Job Iris 3</Trans>,
              accessor: "isJobIris3",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isJobIris3;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isJobIris3;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isJobIris3");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Supplier Iris 2</Trans>,
              accessor: "isSupplierIris2",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isSupplierIris2;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isSupplierIris2;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isSupplierIris2");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Flow Supplier Iris 3</Trans>,
              accessor: "isSupplierIris3",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isSupplierIris3;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isSupplierIris3;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isSupplierIris3");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>Iris</Trans>,
              accessor: "isIris",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isIris;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isIris;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isIris");
                    }}
                    checked={checked}
                  />
                );
              }
            },
            {
              Header: <Trans>IsExtourneCompteCourant</Trans>,
              accessor: "isExtourneCompteCourant",
              filterable: true,
              Cell: rowInfo => {
                var checked = rowInfo.original.isExtourneCompteCourant;
                if (this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier) {
                  checked = this.state.editing.isExtourneCompteCourant;
                }
                return (
                  <Checkbox
                    type="checkbox"
                    className="checkbox"
                    onClick={event => {
                      this.handleItemBooleanPropertyChange(event, "isExtourneCompteCourant");
                    }}
                    checked={checked}
                  />
                );
              }
            }
          ])
        },
        {
          value: "UES",
          label: "Ues",
          columns: baseResultColumnsHeader.concat([
            {
              Header: "Association Chefs",
              filterable: false,
              getProps: this.getActionProps,
              Width: 100,
              Cell: rowInfo => {
                return <div>Ok</div>;
              }
            },
            {
              Header: <Trans>Sj</Trans>,
              accessor: "sjIdentifier",
              filterable: true,
              Cell: rowInfo => {
                var items = RootStore.getState().MasterValueReducer[StructureSj];
                var isEditable = this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier;
                var currentItem = isEditable ? this.state.editing : rowInfo.original;
                return (
                  <OldStructureAutocompleteWithDefaultValue
                    label={"Structures"}
                    structures={items}
                    selectedValue={currentItem.sjIdentifier}
                    onValueChange={value => {
                      this.handleItemPropertyChange({ target: { value } }, "sjIdentifier");
                    }}
                    readOnly={!isEditable}
                  />
                );
              }
            }
          ])
        },
        {
          value: "UPS",
          label: "Ups",
          columns: baseResultColumnsHeader.concat([
            {
              Header: <Trans>Ue</Trans>,
              accessor: "ueIdentifier",
              filterable: true,
              Cell: rowInfo => {
                var items = RootStore.getState().MasterValueReducer[StructureUe];
                var isEditable = this.state.editing !== null && this.state.editing.identifier === rowInfo.original.identifier;
                var currentItem = isEditable ? this.state.editing : rowInfo.original;
                return (
                  <OldStructureAutocompleteWithDefaultValue
                    label={"Structures"}
                    structures={items}
                    selectedValue={currentItem.ueIdentifier}
                    onValueChange={value => {
                      this.handleItemPropertyChange({ target: { value } }, "ueIdentifier");
                    }}
                    readOnly={!isEditable}
                  />
                );
              }
            }
          ])
        }
      ],
      pageSize: 10
    };

    this.handleAddRow = this.handleAddRow.bind(this);
  }

  handleItemPropertyChange = (event, propertyName) => {
    var currentItem = this.state.editing;
    if (currentItem !== null && currentItem !== undefined) {
      currentItem[propertyName] = event.target.value;
      this.setState({ editing: currentItem });
    }
  };

  handleItemBooleanPropertyChange = (event, propertyName) => {
    var currentItem = this.state.editing;
    if (currentItem !== null && currentItem !== undefined) {
      currentItem[propertyName] = event.target.checked;
      this.setState({ editing: currentItem });
    }
  };

  editHandler = item => {
    this.setState({ editing: item });
  };

  cancelHandler = () => {
    this.setState({ editing: null });
  };

  saveHandler = () => {
    ApimPost("api/" + this.state.selectedMasterValue.value, this.state.editing);
    this.setState({ editing: null });
  };

  getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: this.state.editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => this.editHandler(rowProps.original),
        onCancel: () => this.cancelHandler(),
        onSave: () => this.saveHandler()
      }
    }) ||
    {};

  ///-----------------------------------------------------------
  ///   rechercher des codes naces
  ///-----------------------------------------------------------
  handleSearch = selectedMasterValue => {
    this.setState({
      showResult: false,
      showNoResult: false,
      showWaiter: true,
      showDetail: false,
      selectedMasterValue: selectedMasterValue,
      masterValues: {}
    });

    ApimGet("api/" + selectedMasterValue.value + "/all", SearchResult => {
      if (!SearchResult) {
        this.setState({
          showResult: false,
          showNoResult: true,
          showWaiter: false
        });
      } else {
        this.setState({
          showWaiter: false,
          showNoResult: false,
          showResult: true,
          masterValues: SearchResult
        });
      }
    });
  };

  handleAddRow(newCode) {
    var newItem = { code: newCode };
    var items = this.state.masterValues;
    this.setState({ masterValues: [newItem].concat(items), editing: newItem });
  }

  componentWillMount() {}

  render() {
    const { classes } = this.props;
    const img = require("../../assets/img/disk-search.svg");
    var resultContent = "";
    var noResultContent = "";
    var waiterContent = "";
    if (this.state.showResult)
      resultContent = (
        <StructuresResult
          columnsHeader={this.state.selectedMasterValue.columns}
          datas={this.state.masterValues}
          handleDetail={this.handleDetail}
          pageSize={this.state.pageSize}
          pageIndex={this.state.page}
          pages={this.state.pages}
          selectedMasterValue={this.state.selectedMasterValue}
          addRow={this.handleAddRow}
        />
      );
    if (this.state.showNoResult)
      noResultContent = (
        <div>
          <center>
            <CloudOff />
          </center>
        </div>
      );
    if (this.state.showWaiter)
      waiterContent = (
        <center>
          <CircularProgress />
        </center>
      );

    return (
      <GridContainer direction="row" justify="center" alignItems="center" alignContent="center" classeName={classes.position}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <div direction="row">
                <ListItem>
                  <ListItemIcon>
                    <img src={img} width="70px" />
                  </ListItemIcon>
                  <ListItemText primary={<Trans> Structure Search </Trans>} />
                </ListItem>
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer alignContent="center" classeName={classes.root}>
                <GridItem xs={12} sm={12} md={12}>
                  <StructuresSearch
                    handleSearch={this.handleSearch}
                    selectedMasterValue={this.state.selectedMasterValue}
                    masterValuesTypes={this.state.masterValuesTypes}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {resultContent}
                  {noResultContent}
                  {waiterContent}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Structures.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles()(Structures);
