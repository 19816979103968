import React, { useEffect, useState } from "react";
import SupervisionGlobalContainer from "../common/SupervisionGlobalContainer";
import SupervisionGlobalProcess from "../common/SupervisionGlobalProcess";
import { isNullOrEmpty } from "tools";
import { SearchAppInsightDatasForGlobal } from "services/report/ReportHelper";

function NotificationPart() {
  const [status, setStatus] = useState("SEARCHING");

  useEffect(() => {
    SearchAppInsightDatasForGlobal("ProcessColfluxNotificationCreation", 2, datas => {
      if (!isNullOrEmpty(datas) && datas.length > 0) {
        setStatus("OK");
      } else setStatus("EMPTY");
    });
  }, []);

  return (
    <>
      <SupervisionGlobalContainer name="Notifications">
        <>
          <SupervisionGlobalProcess name="Statut du service" status={status} />
        </>
      </SupervisionGlobalContainer>
    </>
  );
}

export default NotificationPart;
