import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tooltip, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import Muted from "components/Typography/Muted";
import { GroupGet, GroupSearch } from "./actions/Group.Actions";

const GroupAutocomplete = ({ isEditable, groupId, setGroupId, label, err }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [optionValue, setOptionValue] = React.useState(null);

  React.useEffect(() => {
    if (groupId > 0) {
      setLoading(true);

      GroupGet(groupId)
        .then(response => {
          setOptionValue(response);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    } else {
      setOptionValue(null);
    }
  }, [groupId]);

  React.useEffect(() => {
    if (inputValue.length < 3) return;

    // debounce calls up to 1 second, then call the searchGroup function
    const timer = setTimeout(searchGroup, 1000);
    return () => clearTimeout(timer);
  }, [inputValue]);

  const searchGroup = () => {
    setLoading(true);

    GroupSearch(inputValue)
      .then(response => {
        setOptions(response);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  if (isEditable) {
    return (
      <Autocomplete
        id="groupAutocomplete"
        // getOptionSelected={(option, value) => option.label === value.label}
        getOptionLabel={option => option.label}
        options={options}
        loading={loading}
        value={optionValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(_, newValue) => {
          setGroupId(newValue?.id);
        }}
        renderOption={option => {
          return (
            <div>
              <Typography variant="body1">{option.label}</Typography>
              <Typography variant="caption" color="textSecondary">
                {option.path}&nbsp;
              </Typography>
            </div>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {error ? (
                    <Tooltip title={error.message}>
                      <Error color="error" />
                    </Tooltip>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
        label={label}
        error={err}
          />
        )}
      />
    );
  }

  if (loading) return <CircularProgress size={30} />;
  if (error)
    return (
      <Muted>
        <Error /> {error.message}
      </Muted>
    );

  return <Muted>{optionValue?.label ?? "Ø"}</Muted>;
};

export default GroupAutocomplete;
