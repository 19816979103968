import React from "react";
//style
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/components/ProfilStyle.jsx";
//components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { Chip, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { Add, Delete, Edit, Save, Undo } from "@material-ui/icons";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import CardError from "components/Card/CardError";
import { ApimPostPromise } from "components/Common/ApimSender";
import Selector from "components/Common/Selector";
import Button from "components/CustomButtons/Button";
import { GetUserNotifications, ChangeUserNotifications } from "services/user/UserHelper";
import { StructureErt } from "store/MasterValue/MasterValueTypes";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";

//lingui
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import CardFooter from "components/Card/CardFooter";

const UserNotification = () => {
  const [viewState, setViewState] = React.useState({
    isLoading: false,
    error: undefined,
    currentNotifType: undefined,
    userNotifs: [],
    hasChanged: false
  });

  const notifTypes = [
    {
      code: "STRUCT_ERT",
      label: i18n._(t`NotifType_StructErt`)
    },
    {
      code: "STRUCT_SJ",
      label: i18n._(t`NotifType_StructSj`)
    },
    {
      code: "STRUCT_UE",
      label: i18n._(t`NotifType_StructUe`)
    },
    {
      code: "STRUCT_UP",
      label: i18n._(t`NotifType_StructUp`)
    },
    {
      code: "STRUCT_EP",
      label: i18n._(t`NotifType_StructEp`)
    }
  ];

  React.useEffect(() => {
    setViewState({ ...viewState, isLoading: true, error: undefined, userNotifs: [], hasChanged: false });
    GetUserNotifications()
      .then(userNotifs => {
        setViewState({ ...viewState, isLoading: false, error: undefined, userNotifs: userNotifs });
      })
      .catch(error => {
        setViewState({ ...viewState, isLoading: false, error: error });
      });
  }, []);

  const addNotifType = notifType => {
    if (notifType && !viewState.userNotifs.some(n => n.userNotificationTypeCode === notifType)) {
      setViewState({
        ...viewState,
        userNotifs: [...viewState.userNotifs, { userNotificationTypeCode: notifType, perimeter: "" }],
        currentNotifType: undefined,
        hasChanged: true
      });
    }
  };

  const saveNotifTypes = () => {
    setViewState({ ...viewState, isLoading: true, error: undefined });
    ChangeUserNotifications(viewState.userNotifs)
      .then(() => {
        GetUserNotifications()
          .then(userNotifs => {
            setViewState({ ...viewState, isLoading: false, error: undefined, userNotifs: userNotifs, hasChanged: false });
          })
          .catch(error => {
            setViewState({ ...viewState, isLoading: false, error: error });
          });
      })
      .catch(error => {
        setViewState({ ...viewState, isLoading: false, error: error });
      });
  };

  if (viewState.isLoading) {
    return <CircularProgress />;
  }

  if (viewState.error) {
    return <CardError error={viewState.error} />;
  }

  return (
    <>
      <Card style={{ width: "800px" }}>
        <CardHeader>
          <h5>
            <Trans>NotificationPreferences</Trans>
          </h5>
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <ItemAutoComplete
                items={notifTypes}
                selectedValue={viewState.currentNotifType}
                onValueChange={v => setViewState({ ...viewState, currentNotifType: v })}
                isEditable
                fullWidth
              />
            </div>
            <IconButton onClick={() => addNotifType(viewState.currentNotifType)} aria-label="Select">
              <Add />
            </IconButton>
          </div>
        </CardBody>
      </Card>
      <div>
        <UserNotificationList
          notifs={viewState.userNotifs}
          setNotifs={userNotifs => setViewState({ ...viewState, userNotifs: userNotifs, hasChanged: true })}
          notifTypes={notifTypes}
        />
      </div>
      <div>
        <Button onClick={saveNotifTypes} aria-label="Save" color="primary" disabled={!viewState.hasChanged}>
          <Save />
          <Trans>Save</Trans>
        </Button>
      </div>
    </>
  );
};

const UserNotificationList = ({ notifs, setNotifs, notifTypes }) => {
  const [viewState, setViewState] = React.useState({
    editIndex: -1,
    editNotif: undefined,
    editCurrentPerimeter: undefined
  });

  React.useEffect(() => {
    setViewState({ ...viewState, editIndex: -1, editNotif: undefined, editCurrentPerimeter: undefined });
  }, [notifs]);

  return (
    <div>
      {notifs.map((notif, index) => {
        if (index === viewState.editIndex) {
          const perimeters = viewState.editNotif.perimeter?.split(",") ?? [""];
          return (
            <Card style={{ width: "800px" }}>
              <CardBody style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <div>{notifTypes.find(n => n.code === notif.userNotificationTypeCode)?.label}</div>
                  <div>
                    <MasterValueAutocomplete
                      isEditable
                      mvType={StructureErt}
                      selectedValue={perimeters.filter(s => s !== "")}
                      onValueChange={value => {
                        setViewState({
                          ...viewState,
                          editNotif: {
                            ...viewState.editNotif,
                            perimeter: value.filter(v => v !== null).join(",")
                          }
                        });
                      }}
                      size="small"
                      multiple
                      formatText={v => `${v.code} - ${v.label}`}
                    />
                  </div>
                </div>
                <div>
                  <IconButton
                    edge="end"
                    aria-label="Cancel"
                    onClick={() => {
                      setViewState({ ...viewState, editIndex: -1, editNotif: undefined, editCurrentPerimeter: undefined });
                    }}
                  >
                    <Undo />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="Save"
                    color="primary"
                    onClick={() => {
                      let newNotifs = [...notifs];
                      newNotifs[viewState.editIndex] = viewState.editNotif;
                      setNotifs(newNotifs);
                    }}
                  >
                    <Save />
                  </IconButton>
                </div>
              </CardBody>
            </Card>
          );
        } else {
          const perimeters = notif.perimeter?.split(",") ?? [""];
          let perimeter = perimeters[0] !== "" ? perimeters.map(p => <Chip label={p} />) : <Trans>NotifType_PerimeterAll</Trans>;
          return (
            <Card style={{ width: "800px" }}>
              <CardBody style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Typography variant="subtitle1">{notifTypes.find(n => n.code === notif.userNotificationTypeCode)?.label}</Typography>
                  <Typography variant="subtitle2">{perimeter}</Typography>
                </div>
                <div>
                  <IconButton edge="end" aria-label="delete" style={{ color: "red" }} onClick={() => setNotifs(notifs.filter((n, i) => i != index))}>
                    <Delete />
                  </IconButton>
                  <IconButton edge="end" aria-label="edit" onClick={() => setViewState({ ...viewState, editIndex: index, editNotif: notif })}>
                    <Edit />
                  </IconButton>
                </div>
              </CardBody>
            </Card>
          );
        }
      })}
    </div>
  );
};

export default withStyles(style)(UserNotification);
