import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Button, Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { Assignment, Flag, LockOpen, Lock } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { CircularProgress, Chip, Avatar, Tooltip } from "@material-ui/core";
import { Done, Error, GetApp } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";

function SearchResultTable({ results, criteria, launchSearch, setThirdParty, exportThirdParty }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  if (!results) {
    return "";
  }

  if (!results.items) {
    results.items = [];
  }

  var pageSize = 10;
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    pageSize = getPref.Pref.ResultPageSize * 1;
  }

  var items = results.map(prop => {
    return {
      Identifier: prop.identifier,
      IsFromAltares: prop.isFromAltares,
      IsFromInfolegal: prop.isFromInfolegal,
      Status: prop.thirdPartyStatusCode,
      IsHeadQuarter: prop.isHeadQuarter,
      LongName: prop.longName,
      LegalIdentifier: prop.legalIdentifier,
      Address_Line1: prop.address_Line1,
      Address_Line2: prop.address_Line2,
      Address_ZipCode: prop.address_ZipCode,
      Address_City: prop.address_City,
      Address_CountryCode: prop.address_CountryCode,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button justIcon round simple onClick={() => setThirdParty(prop)} color="info" className="like">
            <Assignment />
          </Button>{" "}
        </div>
      )
    };
  });

  const reactTableColumns = [
    {
      Header: x => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={selectAll}
                onChange={e => {
                  var isSelectAll = e.currentTarget.checked;
                  setSelectAll(isSelectAll);
                  setSelectedIds(isSelectAll ? results.filter(tp => tp.identifier !== 0).map(tp => tp.identifier) : []);
                }}
              />
            }
            label={<Trans>Rows</Trans>}
          />
        );
      },
      accessor: "ChexBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.Identifier;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id === identifier)}
              onChange={() =>
                setSelectedIds(selectedIds.some(id => id === identifier) ? selectedIds.filter(id => id !== identifier) : [...selectedIds, identifier])
              }
            />
          );
        } else return <Checkbox disabled="true" />;
      }
    },
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Status</Trans>,
      accessor: "Status",
      Cell: rowInfo => {
        var statusIcon = <LockOpen style={{ color: "green" }} />;
        if (rowInfo.original.Status !== null && rowInfo.original.Status === "Closed") statusIcon = <Lock style={{ color: "red" }} />;
        var headQuarterIcon = "";
        if (rowInfo.original.IsHeadQuarter) headQuarterIcon = <Flag style={{ color: "orange" }} />;

        return (
          <div>
            {statusIcon}
            {headQuarterIcon}
          </div>
        );
      }
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "Identifier"
    },
    {
      Header: <Trans>Long Name</Trans>,
      accessor: "LongName"
    },
    {
      Header: <Trans>Legal Identifier</Trans>,
      accessor: "LegalIdentifier"
    },
    {
      Header: <Trans>Address Line 1</Trans>,
      accessor: "Address_Line1"
    },
    {
      Header: <Trans>Address Line 2</Trans>,
      accessor: "Address_Line2"
    },
    {
      Header: <Trans>City</Trans>,
      accessor: "Address_City"
    },
    {
      Header: <Trans>ZipCode</Trans>,
      accessor: "Address_ZipCode"
    },
    {
      Header: <Trans>Country</Trans>,
      accessor: "Address_CountryCode"
    }
  ];
  const ExportButton = ({ ids, exportStructure }) => {
    const [state, setState] = useState({ status: "idle", error: null });
  
    useEffect(() => setState({ status: "idle", error: null }), [ids]);
  
    let title, icon, textColor, avatarColor;
    switch (state.status) {
      case "download":
        title = <Trans>Download_inProgress</Trans>;
        icon = <CircularProgress size={20} />;
        textColor = "#999";
        avatarColor = "#FFF";
        break;
      case "downloadDone":
        title = <Trans>Download_done</Trans>;
        icon = <Done />;
        textColor = "#00CC00";
        avatarColor = "#00CC00";
        break;
      case "error":
        title = state.error;
        icon = <Error />;
        textColor = "#CC0000";
        avatarColor = "#CC0000";
        break;
      default:
        title = <Trans>Structure_Export</Trans>;
        icon = <GetApp />;
        textColor = "#999";
        avatarColor = "#999";
        break;
    }
  

    return (
      <Tooltip title={title}>
        <Chip
          key={"export"}
          avatar={<Avatar style={{ backgroundColor: avatarColor, color: "#FFF" }}>{icon}</Avatar>}
          label={ids.length}
          variant="outlined"
          onClick={() => {
            setState({ status: "download" });
            let labelList = labels();
            exportStructure(
              { labels: labelList, selectedIds: ids },
              () => setState({ status: "downloadDone" }),
              error => setState({ status: "error", error: error })
            );          }}
          style={{ borderColor: textColor, color: textColor }}
        />
      </Tooltip>
    );
  };
    var labels = function() {
      var listLabel = [];

      listLabel.push({ code: "Status", label: i18n._("Status") });
      listLabel.push({ code: "Identifier", label: i18n._("Id") });
      listLabel.push({ code: "LongName", label: i18n._("Long Name") });
      listLabel.push({ code: "LegalIdentifier", label: i18n._("Legal Identifier") });
      listLabel.push({ code: "Address_Line1", label: i18n._("Address Line 1") });
      listLabel.push({ code: "Address_Line2", label: i18n._("Address Line 2") });
      listLabel.push({ code: "Address_City", label: i18n._("City") });
      listLabel.push({ code: "Address_ZipCode", label: i18n._("ZipCode") });
      listLabel.push({ code: "Address_CountryCode", label: i18n._("Country") });
      
      return listLabel;
    };
  var exportBtn = <></>;
  if (selectedIds.length > 0 && exportThirdParty && typeof exportThirdParty === 'function') {
    exportBtn = <ExportButton  ids={selectedIds} exportStructure={exportThirdParty} />
  }

  return (
    <>
      <Paper>{exportBtn}</Paper>
      <ReactTable
        //manual
        data={items}
        //pages={items.pages}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    </>
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResultTable);
