import React from 'react';
import { setupI18n } from "@lingui/core"
import { t, Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core';
import thirdPartySearchCriteriaStyle from 'assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle';
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FieldText from "components/Common/FieldText";

function SearchCriteriasSimple({ showSearchCriteriaOnSideBar, criteria, setCriteria, launchSearch, cleanSearch, classes, defaultLang }) {
    var countrySimpleSearchWidth = 3;
    var simpleSearchWidth = 9;
    if (showSearchCriteriaOnSideBar) {
        countrySimpleSearchWidth = 12;
        simpleSearchWidth = 12;
    }

    const i18n = setupI18n();
    return (
        <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
                <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
                    <CountryAutocomplete
                        countryCode={criteria.country}
                        onChange={countryCode => setCriteria({ ...criteria, country: countryCode })}
                        defaultLang={defaultLang}
                        label={i18n._(t`Country`)}
                        isEditable
                    />
                </GridItem>
                <GridItem xs={simpleSearchWidth} sm={simpleSearchWidth} md={simpleSearchWidth}>
                    <FieldText
                        label={<Trans>Structure_Search_FreeText</Trans>}
                        id="criteriaFullTextSearch"
                        onChange={(e) => setCriteria({ ...criteria, freeSearch: e.target.value })}
                        value={criteria.freeSearch ? criteria.freeSearch : ''}
                        isEditable
                        fullWidth
                    />
                </GridItem>
            </GridContainer>
            <div className={classes.flexRight}>
                <Button
                    onClick={cleanSearch}>
                    <Trans> Clean </Trans>
                </Button>
                <Button
                    type="submit"
                    color="info"
                    onClick={launchSearch}>
                    <Trans> Search </Trans>
                </Button>
            </div>
        </form>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriasSimple);