import { Trans } from "@lingui/macro";
import { Breadcrumbs, Dialog, DialogActions, DialogContent, DialogTitle, Link, Popover, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Warning } from "@material-ui/icons";
import amcStyle from "assets/jss/material-dashboard-pro-react/components/amcStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as CustomerContractActions from "module/amc/store/actions/CustomerContract.Actions";
import * as JobActions from "module/amc/store/actions/Job.Actions";
import * as PhaseActions from "module/amc/store/actions/JobPhase.Actions";
import * as Actions from "module/amc/store/actions/Project.Actions";
import * as Types from "module/amc/store/actions/types";
import * as Tools from "module/tools";
import React from "react";
import { connect, useDispatch } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import CustomerContractDetail from "../components/customerContracts/CustomerContractDetail";
import JobDetail from "../components/jobs/JobDetail";
import JobPhaseDetail from "../components/jobs/JobPhaseDetail";
import ProjectDetail from "../components/projects/ProjectDetail";

function ProjectPopup({
  amc,
  defaultLang,
  closePopup,
  crupdate,
  crupdateJob,
  crupdateCustomerContract,
  generateNotificationJob,
  generateNotificationCustomerContract,
  setPhaseToNull,
  setJobToNull,
  setCustomerContractToNull,
  validate,
  validateJob,
  validateCustomerContract,
  classes
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  var showPopup = amc.showDetail["PROJECT"];
  var item = amc.currentItem["PROJECT"];
  var itemJob = amc.currentItem["JOB"];
  var itemCustomerContract = amc.currentItem["CUSTOMERCONTRACT"];
  var phaseItem = amc.currentItem["JOBPHASE"];
  var hasChanged =
    amc.hasChanged["JOB"] ||
    amc.hasChanged["JOBPHASE"] ||
    amc.hasChanged["JOBPHASEBUDGET"] ||
    amc.hasChanged["JOBPHASEFINALPROJECTION"] ||
    amc.hasChanged["PROJECT"] ||
    amc.hasChanged["CUSTOMERCONTRACT"];
  var warnings = amc.errors["PROJECT"];

  const isReadonly = typeof item !== "undefined" && !!item ? item.isReadonly : true;

  const open = Boolean(anchorEl);

  var errorPart = "";
  if (!!warnings && warnings.length > 0) {
    var errorDetails = warnings.map(errorMessage => {
      return <li>{Tools.getInternationalizedErrorMessage(defaultLang, errorMessage)}</li>;
    });

    errorPart = (
      <div>
        <Typography
          color="error"
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
          style={{ cursor: "pointer" }}
        >
          <Warning color="error" />
          &nbsp;
          <Trans>There are some errors</Trans>
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Typography color="error">
            <ul>{errorDetails}</ul>
          </Typography>
        </Popover>
      </div>
    );
  }

  var breadcrumbs = "";
  if (!!item) {
    breadcrumbs = (
      <div>
        <Trans>Project</Trans>&nbsp;({item.id})
      </div>
    );
  }

  var breadCrumbProject = "";
  var breadCrumbCustomerContract = "";
  var breadCrumbJob = "";
  var breadCrumbJobPhase = "";
  if (!!item) {
    if (!!phaseItem) {
      breadCrumbProject = (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            setPhaseToNull();
            setJobToNull();
          }}
        >
          <Trans> Project</Trans>&nbsp;({item.id})
        </Link>
      );
      breadCrumbJob = (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            setPhaseToNull();
          }}
        >
          <Trans> Job</Trans>&nbsp;({item.id})
        </Link>
      );
      breadCrumbJobPhase = (
        <Typography>
          <Trans>Phase</Trans>&nbsp;({phaseItem.code})
        </Typography>
      );
    } else if (!!itemJob) {
      breadCrumbProject = (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            setJobToNull();
          }}
        >
          <Trans> Project</Trans>&nbsp;({item.id})
        </Link>
      );
      breadCrumbJob = (
        <Typography>
          <Trans> Job</Trans>&nbsp;({itemJob.id})
        </Typography>
      );
    } else if (!!itemCustomerContract) {
      breadCrumbProject = (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCustomerContractToNull();
          }}
        >
          <Trans> Project</Trans>&nbsp;({item.id})
        </Link>
      );
      breadCrumbCustomerContract = (
        <Typography>
          <Trans> Customer Contract</Trans>&nbsp;({itemCustomerContract.identifier})
        </Typography>
      );
    } else {
      breadCrumbProject = (
        <Typography>
          <Trans>Project</Trans>&nbsp;({item.id})
        </Typography>
      );
    }
  }

  var buttonSave = "";
  if (!isReadonly && hasChanged) {
    if (itemJob) {
      buttonSave = (
        <Button onClick={crupdateJob} color="info">
          <Trans>Save</Trans>
        </Button>
      );
    } else if (itemCustomerContract) {
      buttonSave = (
        <Button onClick={crupdateCustomerContract} color="info">
          <Trans>Save</Trans>
        </Button>
      );
    } else {
      buttonSave = (
        <Button onClick={crupdate} color="info">
          <Trans>Save</Trans>
        </Button>
      );
    }
  }
  var buttonValidate = "";
  if (itemJob) {
    buttonValidate = (
      <Button onClick={validateJob} color="info">
        <Trans>Check Validity</Trans>
      </Button>
    );
  } else if (itemCustomerContract) {
    buttonValidate = (
      <Button onClick={validateCustomerContract} color="info">
        <Trans>Check Validity</Trans>
      </Button>
    );
  } else {
    buttonValidate = (
      <Button onClick={validate} color="info">
        <Trans>Check Validity</Trans>
      </Button>
    );
  }

  var buttonSendNotification = "";
  if (itemJob) {
    buttonSendNotification = (
      <Button onClick={generateNotificationJob} color="info">
        <Trans>Send Notification</Trans>
      </Button>
    );
  } else if (itemCustomerContract) {
    buttonSendNotification = (
      <Button onClick={generateNotificationCustomerContract} color="info">
        <Trans>Send Notification</Trans>
      </Button>
    );
  }

  var usualButton = "";
  if (itemJob) {
    usualButton = (
      <>
        {buttonSave}
        {buttonValidate}
        {buttonSendNotification}
        <Button onClick={setJobToNull} color="info">
          <Trans>Close</Trans>
        </Button>
      </>
    );
  } else if (itemCustomerContract) {
    usualButton = (
      <>
        {buttonSave}
        {buttonValidate}
        {buttonSendNotification}
        <Button onClick={setCustomerContractToNull} color="info">
          <Trans>Close</Trans>
        </Button>
      </>
    );
  } else if (!phaseItem) {
    usualButton = (
      <>
        {buttonSave}
        {buttonValidate}
        {buttonSendNotification}
        <Button onClick={closePopup} color="info">
          <Trans>Close</Trans>
        </Button>
      </>
    );
  } else {
    usualButton = (
      <Button onClick={setPhaseToNull} color="info">
        <Trans>Close Phase Detail</Trans>
      </Button>
    );
  }

  var detailComponent = <></>;
  if (!!item && typeof item !== "undefined") detailComponent = <ProjectDetail isReadonly={isReadonly} />;
  if (!!phaseItem && typeof phaseItem !== "undefined") {
    detailComponent = <JobPhaseDetail isReadonly={isReadonly} />;
  } else if (!!itemJob && typeof itemJob !== "undefined") {
    detailComponent = <JobDetail isReadonly={isReadonly} />;
  } else if (!!itemCustomerContract && typeof itemCustomerContract !== "undefined") {
    detailComponent = <CustomerContractDetail isReadonly={isReadonly} />;
  }

  var popup = "";
  if (showPopup) {
    popup = (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open
        onClose={closePopup}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle>
          <GridContainer>
            <GridItem xs="9" sm="9" md="9">
              <Breadcrumbs aria-label="Breadcrumb">
                {breadCrumbProject}
                {breadCrumbCustomerContract}
                {breadCrumbJob}
                {breadCrumbJobPhase}
              </Breadcrumbs>
            </GridItem>
            <GridItem xs="3" sm="3" md="3">
              {errorPart}
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {detailComponent}
        </DialogContent>
        <DialogActions>{usualButton}</DialogActions>
      </Dialog>
    );
  }

  return <div>{popup}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    crupdate: () => {
      dispatch(Actions.Crupdate());
    },
    crupdateJob: () => {
      dispatch(JobActions.Crupdate());
    },
    crupdateCustomerContract: () => {
      dispatch(CustomerContractActions.Crupdate());
    },
    generateNotificationJob: () => {
      dispatch(JobActions.GenerateNotification());
    },
    generateNotificationCustomerContract: () => {
      dispatch(CustomerContractActions.GenerateNotification());
    },
    validate: () => {
      dispatch(JobActions.Validate());
    },
    validateJob: () => {
      dispatch(Actions.Validate());
    },
    validateCustomerContract: () => {
      dispatch(CustomerContractActions.Validate());
    },
    closePopup: () => {
      dispatch({
        type: Types.AMC_END_EDITING,
        module: "JOB"
      });
      dispatch({
        type: Types.AMC_END_EDITING,
        module: "CUSTOMERCONTRACT"
      });
      dispatch({
        type: Types.AMC_END_EDITING,
        module: "PROJECT"
      });
    },
    setJobToNull: () => {
      dispatch(JobActions.SelectItem(null));
    },
    setCustomerContractToNull: () => {
      dispatch(CustomerContractActions.SelectItem(null));
    },
    setPhaseToNull: () => {
      dispatch(PhaseActions.SelectItem(null));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcStyle)(ProjectPopup));
