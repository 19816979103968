//lingui
import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/admin/adminStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { StructureErt, StructureSj, StructureUe } from "store/MasterValue/MasterValueTypes";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

function MigrationStructureSelection({ structureSjs, structureErts, structureUes, withUe, onChange, classes }) {
  const [selectedStructure, setSelectedStructure] = React.useState(null);
  const [selectedErts, setSelectedErts] = React.useState(null);
  const [selectedSjs, setSelectedSjs] = React.useState(null);
  const [selectedUes, setSelectedUes] = React.useState(null);
  const [selectedExcludedSjs, setSelectedExcludedSjs] = React.useState(null);
  const [selectedExcludedUes, setSelectedExcludedUes] = React.useState(null);

  var sjToExcludList = null;
  var ueToExcludList = null;

  var allStructures = [];
  if (!!structureErts) allStructures = allStructures.concat(structureErts);
  if (!!structureSjs) allStructures = allStructures.concat(structureSjs);
  if (!!structureUes && withUe) allStructures = allStructures.concat(structureUes);

  if (!!selectedErts && selectedErts.length > 0) {
    var sjs = structureSjs.filter((sj, index) => {
      return selectedErts.includes(sj.ertIdentifier);
    });
    sjToExcludList = sjs.map(item => {
      return { value: item.identifier, label: item.oldIdentifier + " - " + item.label + " (" + item.identifier + ")" };
    });
  }

  if (!!selectedSjs && selectedSjs.length > 0) {
    var ues = structureUes.filter((ue, index) => {
      return selectedSjs.includes(ue.sjIdentifier);
    });
    ueToExcludList = ues.map(item => {
      return { value: item.identifier, label: item.oldIdentifier + " - " + item.label + " (" + item.identifier + ")" };
    });
  }

  function SjsToExcludeChange(e) {
    var tabs = [];
    if (e !== null && e !== undefined) {
      e.map(item => {
        tabs.push(item);
      });
    }
    setSelectedExcludedSjs(tabs);
    if (onChange !== null && typeof onChange !== "undefined")
      onChange({
        erts: selectedErts,
        sjs: selectedSjs,
        ues: selectedUes,
        excludedUes: selectedExcludedUes,
        excludedSjs: tabs.map(item => {
          return item.value;
        })
      });
  }

  function UesToExcludeChange(e) {
    var tabs = [];
    if (e !== null && e !== undefined) {
      e.map(item => {
        tabs.push(item);
      });
    }
    setSelectedExcludedUes(tabs);
    if (onChange !== null && typeof onChange !== "undefined")
      onChange({
        erts: selectedErts,
        sjs: selectedSjs,
        ues: selectedUes,
        excludedSjs: selectedExcludedSjs,
        excludedUes: tabs.map(item => {
          return item.value;
        })
      });
  }

  var sJToExcludePart = "";
  if (!!sjToExcludList) {
    sJToExcludePart = (
      <>
        <GridItem xs={4} sm={4} md={4}>
          <Trans>Sjs à exclure</Trans>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Select
            isMulti={true}
            closeMenuOnSelect={false}
            options={sjToExcludList}
            placeholder={<Trans>Sjs to exclude</Trans>}
            menuPlacement="auto"
            menuPosition="fixed"
            onChange={SjsToExcludeChange}
            value={selectedExcludedSjs}
          />
        </GridItem>
      </>
    );
  }

  var uEToExcludePart = "";
  if (!!ueToExcludList) {
    uEToExcludePart = (
      <>
        <GridItem xs={4} sm={4} md={4}>
          <Trans>Ues à exclure</Trans>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Select
            isMulti={true}
            closeMenuOnSelect={false}
            options={ueToExcludList}
            placeholder={<Trans>Ues to exclude</Trans>}
            menuPlacement="auto"
            menuPosition="fixed"
            onChange={UesToExcludeChange}
            value={selectedExcludedUes}
          />
        </GridItem>
      </>
    );
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <Trans>Structures à traiter</Trans>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <OldStructureAutocompleteWithDefaultValue
                label={"Structures"}
                structures={allStructures}
                selectedValue={selectedStructure}
                onValueChange={value => {
                  var erts = [];
                  var sjs = [];
                  var ues = [];
                  var excludedSjs = [];
                  var excludedUes = [];
                  if (!!value && value.indexOf("ERT") > -1) erts.push(value);
                  if (!!value && value.indexOf("SJ") > -1) sjs.push(value);
                  if (!!value && value.indexOf("UE") > -1) ues.push(value);

                  setSelectedErts(erts);
                  setSelectedSjs(sjs);
                  setSelectedUes(ues);

                  if (onChange !== null && typeof onChange !== "undefined")
                    onChange({ erts: erts, sjs: sjs, ues: ues, excludedSjs: excludedSjs, excludedUes: excludedUes });
                }}
              />
            </GridItem>
            {sJToExcludePart}
            {uEToExcludePart}
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    structureSjs: state.MasterValueReducer[StructureSj],
    structureErts: state.MasterValueReducer[StructureErt],
    structureUes: state.MasterValueReducer[StructureUe]
  };
};

export default connect(mapStateToProps)(withStyles(amcSearchStyle)(MigrationStructureSelection));
