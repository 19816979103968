import "assets/jss/mdmcolas/common/awesomeSearch.css";
import React from "react";
import { BasicConfig, Builder, Utils as QbUtils, Query } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles

function ComplexSearchCriteria({ fields, queryValue, onChange }) {
  const config = {
    ...BasicConfig,
    fields: fields
  };

  const [tree, setTree] = React.useState(
    !!queryValue && typeof queryValue !== "undefined" ? QbUtils.checkTree(QbUtils.loadTree(queryValue), config) : null
  );
  const [conf, setConf] = React.useState(config);

  function RenderBuilder(props) {
    return (
      <div className="query-builder-container" style={{ padding: "10px" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    );
  }

  function RequestChange(immutableTree, config) {
    setConf(config);
    setTree(immutableTree);

    if (!!onChange && typeof onChange !== "undefined") onChange(immutableTree, QbUtils.sqlFormat(immutableTree, config));
  }

  return (
    <>
      <Query {...config} value={tree} onChange={RequestChange} renderBuilder={RenderBuilder} />
    </>
  );
}

export default ComplexSearchCriteria;
