import { SiteSearch } from "module/site/actions/SiteActions";
import { isArrayNullOrEmpty, isNullOrEmpty, isNum } from "../../../../tools";

export function ValidateGeneral(site, force) {
  return new Promise(resolve => {
    var errors = [];

    if (isNullOrEmpty(site.name)) {
      errors = [...errors, { step: 1, code: "NameMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.locationStatusCode)) {
      errors = [...errors, { step: 1, code: "StatusMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.address_Line1)) {
      errors = [...errors, { step: 1, code: "AddressLine1Missing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.address_City)) {
      errors = [...errors, { step: 1, code: "AddressCityMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.address_ZipCode)) {
      errors = [...errors, { step: 1, code: "AddressZipCodeMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.address_CountryCode)) {
      errors = [...errors, { step: 1, code: "AddressCountryMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.gpsCoordinates.latitude)) {
      errors = [...errors, { step: 1, code: "AddressGpsMissing", isMandatory: true }];
    }
    if (isNullOrEmpty(site.gpsCoordinates.longitude)) {
      errors = [...errors, { step: 1, code: "AddressGpsMissing", isMandatory: true }];
    }
    if (!isNum(site.gpsCoordinates.latitude)) {
      errors = [...errors, { step: 1, code: "LatitudeWrongFormat", isMandatory: true }];
    }
    if (!isNum(site.gpsCoordinates.longitude)) {
      errors = [...errors, { step: 1, code: "LongitudeWrongFormat", isMandatory: true }];
    }

    if (!errors.some(e => e.code === "AddressGpsMissing") && !force) {
      // rechercher un éventuel site à proximité pour éviter les doublons
      SiteSearch({
        searchMode: "Simple",
        criteriaGpsLat: site.gpsCoordinates.latitude,
        criteriaGpsLng: site.gpsCoordinates.longitude,
        criteriaGpsDist: 2
      })
        .then(responseJson => {
          if (responseJson.length > 0) {
            errors = [...errors, { step: 1, code: "ExistingLocationNearby", isMandatory: false, complexLinkedObject: responseJson }];
          }
          resolve({ site: site, errors: errors });
        })
        .catch(_ => {
          resolve({ site: site, errors: errors });
        });
    } else {
      resolve({ site: site, errors: errors });
    }
  });
}
export function ValidateType(site) {
  return new Promise(resolve => {
    var errors = [];

    if (isNullOrEmpty(site.locationNatureCode)) {
      errors = [...errors, { step: 2, code: "SiteNatureMissing", isMandatory: true }];
    }

    resolve({ site: site, errors: errors });
  });
}

export function ValidateStructures(site) {
  return new Promise(resolve => {
    var errors = [];

    if (isArrayNullOrEmpty(site.structures)) {
      errors = [...errors, { step: 4, code: "StructureMissing", isMandatory: true }];
    }

    resolve({ site: site, errors: errors });
  });
}
