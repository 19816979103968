import React, { useState } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import LogsSearchCriterias from "../components/logsExplorer/LogsSearchCriterias";
import LogsResult from "../components/logsExplorer/LogsResult";
import { SearchOutlined } from "@material-ui/icons";
import { FreeSearchAppInsightDatas } from "services/report/ReportHelper.jsx";

function LogsExplorer() {
  const [datas, setDatas] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  function Search(criterias) {
    setIsSearching(true);
    FreeSearchAppInsightDatas(criterias, datas => {
      setDatas(datas);
      setIsSearching(false);
    });
  }

  return (
    <>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <SearchOutlined />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <LogsSearchCriterias isSearching={isSearching} launchSearchHandler={Search} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <LogsResult isSearching={isSearching} datas={datas} />
        </CardBody>
      </Card>
    </>
  );
}

export default LogsExplorer;
