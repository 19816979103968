import { CircularProgress } from "@material-ui/core";
import CardError from "components/Card/CardError";
import HistoryContainer from "module/history/containers/HistoryContainer";
import React, { useEffect, useState } from "react";
import { WorkflowGetHistories } from "../store/actions";

const WorkflowHistory = ({ workflowId, defaultLang }) => {
  const [state, setState] = useState({ isLoading: false });

  useEffect(() => {
    if (workflowId) {
      setState({ isLoading: true, history: [] });
      WorkflowGetHistories(workflowId, h => setState({ isLoading: false, history: h }), e => setState({ isLoading: false, history: [], error: e }));
    }
  }, [workflowId]);

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer items={state.history} defaultLang={defaultLang} />;
};

export default WorkflowHistory;
