import { isNull } from "../../tools";
import { AAD_LOGIN_SUCCESS, AAD_LOGOUT_SUCCESS, SET_FIRSTLOAD, SET_USER_PROFILES } from "./Actions";

const initialState = {
  payload: null,
  user: null,
  profiles: [],
  origin: null
};

function Authentication(state = initialState, action) {
  switch (action.type) {
    case AAD_LOGIN_SUCCESS:
      if (isNull(action.payload.jwtAccessToken)) action.payload.jwtAccessToken = action.payload.accessToken;
      if (isNull(action.payload.jwtAccessToken)) action.payload.jwtAccessToken = action.payload.jwtIdToken;
      return { ...state, payload: action.payload, user: action.user, profiles: action.profiles, origin: "MSAL", firstLoad: true };
    case AAD_LOGOUT_SUCCESS:
      return { ...initialState };
    case SET_USER_PROFILES:
      return { ...state, user: action.user, profiles: action.profiles };
    case SET_FIRSTLOAD:
      return { ...state, firstLoad: action.firstLoad };
    default:
      return state;
  }
}

export default Authentication;
