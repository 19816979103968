import { Trans } from "@lingui/macro";
import { CircularProgress, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { HasRight } from "services/user/UserHelper";
import { isNullOrEmpty } from "tools";
import { OpenThirdParty } from "../store/actions";
import { ThirdPartySearch, ThirdPartyExport} from "./actions/ThirdPartyActions";
import SearchCriterias from "./part/search/SearchCriterias";
import SearchResult from "./part/search/SearchResult";
 
const ThirdPartyListing = ({ userInfo, openThirdParty, masterValues, groupId }) => {
  const initialCriteria = { searchMode: groupId ? "Extend" : "Simple", groupId : !groupId ? 0 : groupId};

  const [criteria, setCriteria] = useState(initialCriteria);
  const [state, setState] = useState({ isLoading: false, hasGroupIdCriteria: groupId && groupId > 0 });

  const cleanSearch = () => {
    setCriteria({ ...initialCriteria, criteriaCountry: userInfo.country });
  };

  useEffect(() => {
    if(!state.hasGroupIdCriteria){
      
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }

      newCriteria = {
        ...newCriteria,
        criteriaCountry: userInfo.country,
        pageSize: defaultPageSize
      };
    }

    setCriteria(newCriteria);
  }
  }, []);

  useEffect(() => {
    if (state.hasGroupIdCriteria) {
      launchSearch();
    }
  }, [groupId]);

  const launchSearch = () => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    ThirdPartySearch(
      !state.hasGroupIdCriteria ? criteria : initialCriteria,
      results => setState({...state , isLoading: false, searchResults: { ...results, pageSize: 10 } }),
      error => setState({...state , isLoading: false, error: error })
    );
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = (
      <SearchResult
        results={state.searchResults}
        criteria={criteria}
        launchSearch={launchSearch}
        setThirdParty={tp => {
          openThirdParty(tp.identifier);
        }}
        exportThirdParty={state.hasGroupIdCriteria ? ThirdPartyExport : null}
      />
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    var resultSize  = state.hasGroupIdCriteria ? 12 : 8;
    var mdSize  = state.hasGroupIdCriteria ? 12 : 9;
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={resultSize} sm={resultSize} md={mdSize}>
          {searchResult}
        </GridItem>
        {!state.hasGroupIdCriteria && (
          <GridItem xs={4} sm={4} md={3}>
            <SearchCriterias
              criteria={criteria}
              setCriteria={setCriteria}
              launchSearch={launchSearch}
              cleanSearch={cleanSearch}
              showSearchCriteriaOnSideBar={true}
              defaultLang={userInfo.language}
              masterValues={masterValues}
            />
          </GridItem>
        )}
      </GridContainer>
    );
  } else if (!state.hasGroupIdCriteria) {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        launchSearch={launchSearch}
        cleanSearch={cleanSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={userInfo.language}
        masterValues={masterValues}
      />
    );
  }

  var buttonCreationContent = "";
  if (HasRight("tp_columbus.edit") && !state.hasGroupIdCriteria) {
    buttonCreationContent = (
      <Button variant="contained" size="sm" onClick={() => openThirdParty(0)} color="info">
        <Add />
        <Trans>Columbus_Create_ThirdParty</Trans>
      </Button>
    );
  }

  return (
    <>
      <div>{buttonCreationContent}</div>
      <div>{layoutSearchResult}</div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    userInfo: state.AuthenticationReducer.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: tpId => {
      dispatch(OpenThirdParty(tpId, null));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyListing));
