import React from "react";
import HelpInfoView from "../views/InfoHelp/HelpInfo.jsx";
//lingui
import { Trans } from "@lingui/macro";
import InfoOutlined from "@material-ui/icons/HelpOutline";


const helpInfoRoutes = [
  {
    path: "/helpInfo",
    name: <Trans>Help</Trans>,
    icon: InfoOutlined,
    component: HelpInfoView
  }
];
export default helpInfoRoutes;
