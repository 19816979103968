import { Trans } from "@lingui/macro";
import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import cx from "classnames";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import Muted from "components/Typography/Muted";
import { isNullOrEmpty } from "module/tools";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { CloseThirdParty } from "../store/actions";
import { ThirdPartyCrupdate, ThirdPartyGet } from "./actions/ThirdPartyActions";
import DetailContent from "./part/detail/DetailContent";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";

const ThirdPartyDetail = ({ thirdPartyId, userInfo, masterValues, classes }) => {
  const initialState = { isLoading: false, isEditable: false, isGroupEditable : false };
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [thirdParty, setThirdParty] = useState();
  var [dialogBox, setDialogBox] = useState(null);

  useEffect(() => {
    setState(initialState);
    setThirdParty();
  }, [thirdPartyId]);

  if (isNullOrEmpty(thirdPartyId)) {
    return <></>;
  }

  const hasChanged = () => {
    return !!thirdParty.hasChanged || !!thirdParty.supplier?.hasChanged || !!thirdParty.customer?.hasChanged;
  };

  const detailPanel =
    classes.detailPanel +
    " " +
    cx({
      [classes.detailPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
    });

  const closeDetail = confirmClose => {
    if (hasChanged() && !confirmClose) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeDetail(true) });
    } else {
      setDialogBox(null);
      dispatch(CloseThirdParty(thirdPartyId));
    }
  };

  const saveThirdParty = () => {
    setState({ ...state, isLoading: true, error: null, validationErrors: null });
    ThirdPartyCrupdate(thirdParty)
      .then(tp => {
        setThirdParty(tp);
        setState({ ...state, isLoading: false, error: null, validationErrors: null });
      })
      .catch(e => setState({ ...state, isLoading: false, error: e, validationErrors: null }));
  };

  var dialogTitle = <>{thirdPartyId}</>;
  var dialogContent = <></>;
  var dialogActions = <></>;
  if (state.isLoading) {
    dialogContent = <CircularProgress />;
  } else if (state.error) {
    dialogContent = <CardError error={state.error} />;
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeDetail(false)} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );
  } else if (!thirdParty && thirdPartyId > 0) {
    setState({ ...state, isLoading: true });

    ThirdPartyGet(thirdPartyId).then(
      result => {
        setState({
          ...state,
          isLoading: false,
          isEditable: HasRight("tp_columbus.edit"),
          isGroupEditable: HasRight("tp_columbus.edit_groups")
        });
        setThirdParty(result);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  } else if (!thirdParty && thirdPartyId === 0) {
    let countryLang = "E";
    var country = masterValues[CommonCountry].find(c => c.codeIso2 === userInfo.country);
    if (country) {
      countryLang = country.languageCode;
    }

    var initThirdParty = {
      identifier: thirdPartyId,
      address_CountryCode: userInfo.country,
      languageCode: countryLang,
      warnings: [],
      contacts: [],
      legalIdentifiers: [],
      activitySectors: []
    };
    setState({
      ...state,
      isLoading: false,
      isEditable: HasRight("tp_columbus.edit"),
      isGroupEditable: HasRight("tp_columbus.edit_groups")
    });
    setThirdParty(initThirdParty);
  } else {
    var btnSave = "";
    if (hasChanged()) {
      btnSave = (
        <Button onClick={() => saveThirdParty()} color="info">
          <Trans> Save </Trans>
        </Button>
      );
    }

    var btnReload = "";
    btnReload = (
      <Button
        onClick={() => {
          setState({ ...state, validationErrors: null, error: null });
          setThirdParty(null);
        }}
      >
        <Trans> Reload </Trans>
      </Button>
    );

    dialogContent = (
      <div className={detailPanel}>
        <DetailContent
          thirdParty={thirdParty}
          setThirdParty={setThirdParty}
          isEditable={state.isEditable}
          defaultLang={userInfo.language}
          masterValues={masterValues}
          isGroupEditable={state.isGroupEditable}
        />
      </div>
    );
    dialogActions = (
      <DialogActions>
        {btnSave}
        {btnReload}
        <Button onClick={() => closeDetail(false)} color={btnSave === "" ? "info" : ""}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    );

    dialogTitle = (
      <span>
        {thirdParty.customLongName} ({thirdParty.identifier})
      </span>
    );
  }

  var dialog = (
    <Dialog open={true} onClose={() => closeDetail(false)} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">
          <Muted>{dialogTitle}</Muted>
        </Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {dialogContent}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );

  return (
    <>
      {dialog}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyDetail);
