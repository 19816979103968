import { Trans } from "@lingui/macro";
import React from "react";
import { connect } from "react-redux";
import { StructureErt, StructureSj, StructureUe, StructureUp } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty } from "../../tools";
import ItemAutoComplete from "./ItemAutoComplete";

function OldStructureAutocomplete({ structureTypes, value, onChange, masterValues }) {
  var structures = [];
  if (isArrayNullOrEmpty(structureTypes)) {
    structureTypes = ["ERT", "SJ", "UE", "UP"];
  }
  if (structureTypes.some(s => s === "ERT")) {
    structures = [
      ...structures,
      ...masterValues[StructureErt].map(s => {
        return { ...s, code: s.identifier, type: "ERT" };
      })
    ];
  }
  if (structureTypes.some(s => s === "SJ")) {
    structures = [
      ...structures,
      ...masterValues[StructureSj].map(s => {
        return { ...s, code: s.identifier, type: "SJ" };
      })
    ];
  }
  if (structureTypes.some(s => s === "UE")) {
    structures = [
      ...structures,
      ...masterValues[StructureUe].map(s => {
        return { ...s, code: s.identifier, type: "UE" };
      })
    ];
  }
  if (structureTypes.some(s => s === "UP")) {
    structures = [
      ...structures,
      ...masterValues[StructureUp].map(s => {
        return { ...s, code: s.identifier, type: "UP" };
      })
    ];
  }

  var selectedValue = !!value ? structures.find(s => s.code === value.structureId && s.type === value.structureType)?.code : null;

  return (
    <ItemAutoComplete
      items={structures}
      isEditable={true}
      selectedValue={selectedValue}
      onValueChange={c => {
        var str = structures.find(s => s.code === c);
        if (!!str) onChange(str.code, str.type);
      }}
      formatText={t => `${t.code} - ${t.label}`}
      maxResultNumber={50}
      isRequired={false}
      label={<Trans>Structure_Autocomplete</Trans>}
    />
  );

  // return (
  //     <Autocomplete
  //         id="structureAutocomplete"
  //         /*style={{ width: 300 }}*/
  //         getOptionLabel={(option) => (typeof option === 'string' ? option : option.text)}
  //         filterOptions={(x) => x}
  //         options={options}
  //         autoComplete
  //         includeInputInList
  //         filterSelectedOptions
  //         value={selectedValue}
  //         onChange={(event, newValue) => {
  //             setOptions(newValue ? [newValue, ...options] : options);
  //             setSelectedValue(newValue ? newValue : initialSelectedValue);
  //             onChange(newValue);
  //         }}
  //         onInputChange={(event, newInputValue) => {
  //             setSelectedValue({ ...selectedValue, text: newInputValue });
  //         }}
  //         renderInput={(params) => (
  //             <TextField
  //                 {...params}
  //                 margin="normal"
  //                 label={(<Trans>Structure_Autocomplete</Trans>)}
  //                 fullWidth
  //             />
  //         )}
  //         renderOption={(option) => {
  //             const parts = highlightText(option.text, selectedValue.text);

  //             return (
  //                 <Grid container alignItems="center">
  //                     <Grid item>
  //                         <LocationOnIcon className={classes.icon} />
  //                     </Grid>
  //                     <Grid item xs>
  //                         {parts.map((part, index) => (
  //                             <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
  //                                 {part.text}
  //                             </span>
  //                         ))}

  //                         {/*<Typography variant="body2" color="textSecondary">
  //                             Second text
  //                         </Typography>*/}
  //                     </Grid>
  //                 </Grid>
  //             );
  //         }}
  //     />
  // );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(OldStructureAutocomplete);
