import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../../store/actions";
import { isNull, isArrayNullOrEmpty } from "../../../tools";

import ReactTable from "react-table-6";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { Button, CircularProgress } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";

const TabSupplierPointOfSales = function({ supplierCtx, openThirdParty }) {
  var dispatch = useDispatch();
  var [isFirstTime, setIsFirstTime] = useState(true);
  var supplier = supplierCtx.supplier;
  var pointOfSales = supplierCtx.pointOfSales;

  useEffect(() => {
    if (isFirstTime) {
      dispatch(Actions.GetPointOfSalesBySupplierId(supplier.thirdPartyIdentifier));
      setIsFirstTime(false);
    }
  });

  ///------------------------------
  /// Crée le contenu (les lignes) de la table-react affichant les PaymentCharacteristics associés
  ///------------------------------
  function convertDataToReactTable(pointOfSales) {
    if (isNull(pointOfSales)) return [];

    return pointOfSales.map((prop, key) => {
      var pointOfSale = prop;
      return {
        identifier: pointOfSale.thirdPartyIdentifier,
        label: pointOfSale.label,
        upCode: pointOfSale.upCode,
        legalIdentifier: pointOfSale.tpLegalIdentifier,
        zipcode: pointOfSale.tpAddressZipCode,
        city: pointOfSale.tpAddressCity,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              onClick={() => {
                openThirdParty(pointOfSale.thirdPartyIdentifier);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>
          </div>
        )
      };
    });
  }

  if (isFirstTime)
    return (
      <center>
        <CircularProgress />
      </center>
    );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Points of sale</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={convertDataToReactTable(pointOfSales)}
              columns={[
                {
                  accessor: "actions",
                  width: 50,
                  sortable: false,
                  filterable: false
                },
                {
                  Header: <Trans>Identifier</Trans>,
                  accessor: "identifier",
                  filterable: true
                },
                {
                  Header: <Trans>Label</Trans>,
                  accessor: "label",
                  filterable: true
                },
                {
                  Header: <Trans>Code Up</Trans>,
                  accessor: "upCode",
                  filterable: true
                },
                {
                  Header: <Trans>Legal Identifier</Trans>,
                  accessor: "legalIdentifier",
                  filterable: true
                },
                {
                  Header: <Trans>Zip code</Trans>,
                  accessor: "zipcode",
                  filterable: true
                },
                {
                  Header: <Trans>City</Trans>,
                  accessor: "city",
                  filterable: true
                }
              ]}
              defaultPageSize={5}
              showPaginationBottom={true}
              showPageSizeOptions={false}
              className="-striped -highlight"
              showFilters={true}
              style={ReactTableStyle.main}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSupplierValueChanged: supplier => {
      dispatch(Actions.EditSupplier(supplier));
    },
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabSupplierPointOfSales));
