import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Checkbox, FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { TpThirdPartyCategory, TpThirdPartyStatus } from "store/MasterValue/MasterValueTypes";

function SearchCriteriasExtended({ showSearchCriteriaOnSideBar, criteria, setCriteria, launchSearch, cleanSearch, classes, defaultLang }) {
  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const { i18n } = useLingui();

  var thirdPartyTypeOptions = [
    { code: "isSupplier", label: i18n._(t`isSupplier`) },
    { code: "isPointOfSale", label: i18n._(t`isPointOfSale`) },
    { code: "isCustomer", label: i18n._(t`isCustomer`) },
    { code: "isBeneficiary", label: i18n._(t`isBeneficiary`) },
    { code: "isAccount", label: i18n._(t`isAccount`) }
  ];

  const setLocalCriteria = c => {
    if (
      (c.types && c.types.length > 0) ||
      (c.categories && c.categories.length > 0) ||
      (c.statuses && c.statuses.length > 0) ||
      (c.categories && c.categories.length > 0) ||
      (c.criteriaMdmId && c.criteriaMdmId.length > 0) ||
      (c.criteriaMigCode && c.criteriaMigCode.length > 0)
    ) {
      c = { ...c, localSearch: true, localSearchRequired: true };
    } else {
      c = { ...c, localSearchRequired: false };
    }

    setCriteria(c);
  };

  return (
    <form onSubmit={launchSearch} autoComplete="off">
      <GridContainer>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
                checked={!!criteria.localSearch}
                onChange={e => setCriteria({ ...criteria, localSearch: e.target.checked })}
                disabled={!!criteria.localSearchRequired}
              />
            }
            classes={{
              label: classes.label
            }}
            label={<Trans>Internal Search</Trans>}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <ItemAutoComplete
            multiple
            items={thirdPartyTypeOptions}
            selectedValue={criteria.types}
            onValueChange={e => setLocalCriteria({ ...criteria, types: e })}
            label={<Trans>Third Parties Type</Trans>}
            isEditable
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <MasterValueAutocomplete
            multiple
            mvType={TpThirdPartyCategory}
            selectedValue={criteria.categories}
            onValueChange={e => setLocalCriteria({ ...criteria, categories: e })}
            label={<Trans>Third Parties Category</Trans>}
            isEditable
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Mdm id</Trans>}
            id="criteriaMdmId"
            onChange={e => setLocalCriteria({ ...criteria, criteriaMdmId: e.target.value })}
            value={criteria.criteriaMdmId}
            formControlProps={{
              fullWidth: true
            }}
            type="number"
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>code Mig</Trans>}
            id="criteriaMigCode"
            onChange={e => setLocalCriteria({ ...criteria, criteriaMigCode: e.target.value })}
            value={criteria.criteriaMigCode}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Legal identifier</Trans>}
            id="criterialegalIdentifier"
            onChange={e => setCriteria({ ...criteria, criteriaLegalIdentifier: e.target.value })}
            value={criteria.criteriaLegalIdentifier}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Duns</Trans>}
            id="criteriaDuns"
            onChange={e => setCriteria({ ...criteria, criteriaDuns: e.target.value })}
            value={criteria.criteriaDuns}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Numéro JADE</Trans>}
            id="an8Iris3"
            onChange={e => setCriteria({ ...criteria, criteriaAn8Iris3: e.target.value })}
            value={criteria.criteriaAn8Iris3}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Long name</Trans>}
            id="criteriaLongName"
            onChange={e => setCriteria({ ...criteria, criteriaLongName: e.target.value })}
            value={criteria.criteriaLongName}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>

        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Address</Trans>}
            id="criteriaAddress"
            onChange={e => setCriteria({ ...criteria, criteriaAddress: e.target.value })}
            value={criteria.criteriaAddress}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>City</Trans>}
            id="criteriaCity"
            onChange={e => setCriteria({ ...criteria, criteriaCity: e.target.value })}
            value={criteria.criteriaCity}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CountryAutocomplete
            countryCode={criteria.criteriaTpCountry}
            label={<Trans>Country</Trans>}
            onChange={tpCountryCode => setCriteria({ ...criteria, criteriaTpCountry: tpCountryCode })}
            defaultLang={defaultLang}
            isEditable
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CountryAutocomplete
            countryCode={criteria.criteriaCountry}
            onChange={countryCode => setCriteria({ ...criteria, criteriaCountry: countryCode })}
            defaultLang={defaultLang}
            isEditable
          />
        </GridItem>

        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <MasterValueAutocomplete
            multiple
            mvType={TpThirdPartyStatus}
            selectedValue={criteria.statuses}
            onValueChange={e => setLocalCriteria({ ...criteria, statuses: e })}
            label={<Trans>ThirdParties_Statuses</Trans>}
            isEditable
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <FormControlLabel
            style={{ marginTop: 25, marginLeft: 0, marginBottom: 20, color: "black" }}
            value="start"
            control={
              <Switch
                color="secondary"
                size="small"
                checked={criteria.principalEstablishment}
                onChange={() =>
                  setCriteria({
                    ...criteria,
                    localSearch: true,
                    localSearchRequired: true,
                    principalEstablishment: !criteria.principalEstablishment
                  })
                }
              />
            }
            label={<Trans>TpCop_PrincipalEstablishment</Trans>}
            labelPlacement="start"
          />
        </GridItem>
      </GridContainer>
      <div className={classes.flexRight}>
        <Button onClick={cleanSearch}>
          <Trans> Clean </Trans>
        </Button>
        <Button type="submit" color="info" onClick={launchSearch}>
          <Trans> Search </Trans>
        </Button>
      </div>
    </form>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriasExtended);
