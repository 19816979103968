import { Trans } from "@lingui/macro";
import { Typography, withStyles } from "@material-ui/core";
import { ViewDay, ViewHeadline } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import SearchResultTable from "./SearchResultTable";

function SearchResult({ results, exportProduct, openProduct, maxExport }) {
  const [resultMode, setResultMode] = useState("table");

  var tabContent = "";
  var title = "";
  switch (resultMode) {
    case "table":
      title = <Trans>Table result</Trans>;
      tabContent = (
        <div className="table-result show">
          <SearchResultTable results={results} exportProduct={exportProduct} openProduct={openProduct} maxExport={maxExport}/>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <Card>
      <CardHeader icon>
        <div className="blockHeaderResult">
          {/*<div className="blockHeaderResultItem" >
                        <ToggleButtonGroup value={resultMode} exclusive onChange={(e) => setResultMode(e.currentTarget.value)}>
                            <ToggleButton value="summary">
                                <ViewDay />
                            </ToggleButton>
                            <ToggleButton value="table">
                                <ViewHeadline />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>*/}
          <div className="blockHeaderResultItem">
            <Typography ariant="display1">{title}</Typography>
          </div>
          <div className="blockHeaderResultItem">{/*menu*/}</div>
        </div>
      </CardHeader>
      <CardBody>{tabContent}</CardBody>
    </Card>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResult);
