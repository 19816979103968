/* eslint-disable no-use-before-define */
import { Chip, IconButton, Popover } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import FlagIcon from "@material-ui/icons/Flag";
import SettingsIcon from "@material-ui/icons/Settings";
import { Autocomplete } from "@material-ui/lab";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import React, { useEffect, useMemo, useState } from "react";
import { JobActivityLevel2 } from "store/MasterValue/MasterValueTypes";

export default function SiteActivities({ isEditable, site, setSite, defaultLang, masterValues }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const activityValues = masterValues[JobActivityLevel2].filter(c => c.isEnabled);

  const siteActivities = useMemo(() => {
    return site.activities.map(a => {
      return {
        code: a.activityCode,
        label: activityValues.find(mv => mv.code === a.activityCode)?.label,
        isMainActivity: a.isMainActivity
      };
    });
  }, [activityValues, site]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }

    // if (siteActivities.length > 0 && !siteActivities.some(v => !!v.isMainActivity)) {
    //   siteActivities[0].isMainActivity = true;
    // }
    // setSite({ ...site, activities: siteActivities });
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "siteActivities" : undefined;

  function setMasterActivity(activityCode) {
    setSite({
      ...site,
      activities: site.activities.map(a => {
        return { ...a, isMainActivity: a.activityCode === activityCode };
      })
    });
  }

  function setActivities(newActivities) {
    let act = site.activities.filter(a => newActivities.find(na => na.code === a.activityCode));
    let ac = site.activityChars ? site.activityChars.filter(na => act.find(a => na.locationActivityIdentifier === a.activityCode)) : [];
    newActivities
      .filter(na => !site.activities.find(a => na.code === a.activityCode))
      .map(na => (act = [...act, { activityCode: na.code, isMainActivity: false }]));

    if (act.length > 0 && !act.find(a => a.isMainActivity)) {
      act[0].isMainActivity = true;
    }

    console.log({ newActivities, siteActivities: site.activities, act });

    setSite({ ...site, activities: act, activityChars: ac });
  }

  return (
    <>
      <div className={classes.root}>
        {siteActivities
          .sort((a, b) => b.isMainActivity - a.isMainActivity)
          .map(activity => {
            if (!!activity.isMainActivity) {
              return <Chip icon={<FlagIcon />} label={activity.label} size="small" color="primary" />;
            }
            return (
              <Chip
                label={activity.label}
                onDelete={isEditable ? e => setMasterActivity(activity.code) : null}
                deleteIcon={isEditable ? <FlagIcon /> : ""}
                variant="outlined"
                size="small"
              />
            );
          })}
        {isEditable ? (
          <IconButton size="small" onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Autocomplete
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal
          }}
          value={siteActivities}
          onChange={(event, newValue) => {
            setActivities(newValue);
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          getOptionSelected={(o, v) => o.code === v.code}
          renderOption={(option, { selected }) => (
            <>
              <DoneIcon className={classes.iconSelected} style={{ visibility: selected ? "visible" : "hidden" }} />
              <span className={classes.color} style={{ backgroundColor: option.color }} />
              <div className={classes.text}>{GetMasterValueLabel(option, defaultLang)}</div>
              <CloseIcon className={classes.close} style={{ visibility: selected ? "visible" : "hidden" }} />
            </>
          )}
          options={activityValues.sort((a, b) => {
            // Display the selected labels first.
            let ai = site.activities.findIndex(v => v.activityCode === a);
            ai = ai === -1 ? site.activities.length + activityValues.findIndex(v => v.code === a) : ai;
            let bi = site.activities.findIndex(v => v.activityCode === b);
            bi = bi === -1 ? site.activities.length + activityValues.findIndex(v => v.code === b) : bi;
            return ai - bi;
          })}
          getOptionLabel={option => GetMasterValueLabel(option, defaultLang)}
          renderInput={params => <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus className={classes.inputBase} />}
        />
      </Popover>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 13
  },
  button: {
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6"
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  }
}));
