import { Trans } from "@lingui/macro";
import { Button, Chip, Dialog, DialogActions, DialogContent, Menu, MenuItem, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, ArrowDropDown, ArrowDropUp, Assignment, ErrorOutline, History, PermContactCalendar } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { ApimGetPromise } from "components/Common/ApimSender";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { addDays, formatISO, isFuture } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry, StructureSj, TpPaymentCharacteristicStatus } from "store/MasterValue/MasterValueTypes";
import { formatDate, isArray, nextOpenDay, toDate } from "tools";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";
import DialogPaymentCharacteristic from "../DialogPaymentCharacteristic";
import HistoPaymentCharacteristic from "../ThirdPartyDetail/HistoPaymentCharacteristic";

const TabSupplierPayment = function({
  thirdParty,
  supplier,
  supplierSjs,
  payChars,
  setPayChars,
  masterValues,
  userInfo,
  defaultLang,
  openThirdParty
}) {
  var [errors, setErrors] = useState([]);
  var [btnAddAnchor, setBtnAddAnchor] = useState();
  var [openHisto, setOpenHisto] = useState(false);

  useEffect(() => {
    if (payChars) {
      var beneficiaryIds = [
        ...new Set(
          payChars
            .filter(p => p.beneficiaryIdentifier && !p.beneficiary)
            .map(p => p.beneficiaryIdentifier)
            .filter(b => !!b)
        )
      ];
      if (beneficiaryIds.length > 0) {
        ApimGetPromise(`api/TpCopernic/Beneficiaries/${beneficiaryIds.join(",")}?withThirdParty=true`).then(bens => {
          let pcs = payChars.map(p => {
            if (p.beneficiaryIdentifier) {
              p.beneficiary = !isArray(bens) ? bens : bens.find(b => b.thirdPartyIdentifier === p.beneficiaryIdentifier);
            }
            return p;
          });
          setPayChars(pcs);
        });
      }
    }
  }, [payChars]);

  var canViewDetail = useMemo(() => {
    //return HasRight("thirdparty_paychar.view") || HasRight("thirdparty_paychar.edit") || HasRight("thirdparty_supplier.add_iban");
    return true;
  }, []);

  var isCreatable = useMemo(() => {
    if (userInfo.canAdd) {
      let sjCodes = [...new Set(supplierSjs.map(supplierSj => supplierSj.sj.sjCode))];
      let sjs = masterValues[StructureSj].filter(sj => sjCodes.includes(sj.identifier));
      let supplierErtIds = sjs.filter(sj => !!sj).map(sj => sj.ertIdentifier);
      return (
        userInfo.isAdmin ||
        (!isNull(userInfo.structures) &&
          userInfo.structures.erts.some(ert => supplierErtIds.includes(ert.identifier)) &&
          masterValues[CommonCountry].some(
            c => c.codeIso2 === thirdParty.countryCode && !!c.allowPayCharManualEdit && !isArrayNullOrEmpty(c.allowedPayCharTypes)
          ))
      );
    }
    return false;
  }, [userInfo, supplierSjs, masterValues, thirdParty.countryCode]);

  if (!payChars) return <></>;

  var editPayChar = payChars.find(p => !!p.showDialog);

  function renderPaymentCharacteristicsChip(payChar, paymentCharacteristicStatusCodes) {
    if (payChar && !isNullOrEmpty(payChar.sisScore)) {
      var splitted = payChar.sisScore.split("¤");
      var sisScore = splitted[0];
      var statusCode = splitted[1];
      var label = paymentCharacteristicStatusCodes.find(i => i.code === statusCode)?.label ?? "";
      if (isNaN(sisScore)) return <Chip style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#ffbf00" }} label={label} size="small" />;
      if (sisScore < 30)
        return (
          <Chip
            style={{ backgroundColor: "#ff2e00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
            size="small"
          />
        );
      if (sisScore >= 30 && sisScore <= 70)
        return (
          <Chip
            style={{ backgroundColor: "#ffbf00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
            size="small"
          />
        );
      return (
        <Chip
          style={{ backgroundColor: "#57d500" }}
          label={
            <span>
              {sisScore} - {label}
            </span>
          }
          size="small"
        />
      );
    }
    return <Trans>SIS NotComputed</Trans>;
  }

  function renderPaymentCharacteristicsReason(payChar) {
    if (payChar && payChar.sisComputedReasons && payChar.sisComputedReasons.length > 0) {
      return (
        <Tooltip
          title={
            <>
              {payChar.sisComputedReasons.map((v, key) => (
                <div key={key}>
                  <strong>{v.key}</strong> - {v.value}
                </div>
              ))}
            </>
          }
          placement="top"
        >
          <ErrorOutline />
        </Tooltip>
      );
    }
    return <span></span>;
  }

  const addPayChar = function(payCharType) {
    var sortedPayChars = SortPayCharsByStartOfValidityDate(payChars);
    let currentCountry = masterValues[CommonCountry].find(c => c.codeIso2 === thirdParty.countryCode);
    let canCheckSis = currentCountry.canCheckSis;
    let lastPayChar = sortedPayChars.length > 0 ? sortedPayChars[sortedPayChars.length - 1] : null;

    setPayChars([
      ...payChars,
      {
        identifier: 0,
        supplierIdentifier: supplier.thirdPartyIdentifier,
        paymentCharacteristicStatusCode: userInfo.isAdmin && !canCheckSis ? "Ok" : "Doubtful",
        paymentCharacteristicOriginCode: "Manual",
        thirdPartyContact: lastPayChar?.thirdPartyContact,
        thirdPartyContactId: lastPayChar?.thirdPartyContactId ?? 0,
        startOfValidityDate: formatISO(new Date()),
        endOfValidityDate: null,
        sisScore: userInfo.isAdmin && !canCheckSis ? 99 : 50,
        showDialog: true,
        action: "insert",
        minDate: lastPayChar ? nextOpenDay(addDays(toDate(lastPayChar.endOfValidityDate, defaultLang), 1)) : nextOpenDay(new Date()),
        paymentCharacteristicTypeCode: payCharType
      }
    ]);
  };

  const onPayCharOpen = function(payCharId) {
    var minDate = null;
    var maxDate = null;
    var sortedPayChars = SortPayCharsByStartOfValidityDate(payChars);
    sortedPayChars.map((p, idx) => {
      if (p.identifier === payCharId) {
        minDate = idx === 0 ? null : nextOpenDay(addDays(toDate(sortedPayChars[idx - 1].endOfValidityDate, defaultLang), 1));
        maxDate =
          idx === sortedPayChars.length - 1 ? null : nextOpenDay(addDays(toDate(sortedPayChars[idx + 1].startOfValidityDate, defaultLang), 1));
      }
    });

    setPayChars(
      payChars.map(p => {
        if (p.identifier === payCharId) {
          return { ...p, showDialog: true, minDate: minDate, maxDate: maxDate };
        }
        return { ...p, showDialog: false };
      })
    );
  };

  const onPayCharChange = function(payChar) {
    if (payChar.cancel) {
      if (payChar.action === "insert") payChars = payChars.filter(p => p.identifier > 0);
      else
        payChars = payChars.map(p => {
          return !!p.showDialog ? { ...p, showDialog: false } : p;
        });

      setPayChars(payChars);

      return;
    }

    setPayChars(
      payChars.map(p => {
        if (!!p.showDialog) {
          return { ...payChar, action: payChar.action ? payChar.action : "update", showDialog: false };
        }
        return p;
      })
    );
  };

  var btnAddPaymentChar = <></>;
  if (isCreatable && !payChars.some(p => p.identifier === 0)) {
    if (payChars.some(p => p.sisScore === 50)) {
      btnAddPaymentChar = (
        <Tooltip title={<Trans>PaymentCharacteristic_alreadyInProgress</Trans>} placement="bottom">
          <span>
            <Button id="payCharAddButton" aria-label="Add" disabled={true}>
              <Add />
              &nbsp;<Trans>Add</Trans>
            </Button>
          </span>
        </Tooltip>
      );
    } else if (payChars.some(p => !p.endOfValidityDate)) {
      btnAddPaymentChar = (
        <Tooltip title={<Trans>PaymentCharacteristic_mustSetEndValidityDate</Trans>} placement="bottom">
          <span>
            <Button id="payCharAddButton" aria-label="Add" disabled={true}>
              <Add />
              &nbsp;<Trans>Add</Trans>
            </Button>
          </span>
        </Tooltip>
      );
    } else {
      var country = masterValues[CommonCountry].find(c => c.codeIso2 === thirdParty.countryCode);
      if (country.allowedPayCharTypes.length > 1)
        btnAddPaymentChar = (
          <>
            <Button variant="contained" size="sm" onClick={e => setBtnAddAnchor(e.currentTarget)} color="info">
              <Add />
              &nbsp;<Trans>Add</Trans>
              {Boolean(btnAddAnchor) ? <ArrowDropUp /> : <ArrowDropDown />}
            </Button>
            <Menu
              id="menuCreate"
              anchorEl={btnAddAnchor}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              keepMounted
              open={Boolean(btnAddAnchor)}
              onClose={() => setBtnAddAnchor(null)}
              PaperProps={{
                style: {
                  width: "40ch"
                }
              }}
            >
              {country.allowedPayCharTypes.map(payCharType => {
                return (
                  <MenuItem
                    key={`pct_${payCharType}`}
                    onClick={() => {
                      addPayChar(payCharType);
                      setBtnAddAnchor(null);
                    }}
                  >
                    {payCharType}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        );
      else if (country.allowedPayCharTypes.length === 1) {
        var pcType = country.allowedPayCharTypes[0];
        btnAddPaymentChar = (
          <Tooltip
            title={
              <>
                <Trans>Add</Trans> {pcType}
              </>
            }
          >
            <Button id="payCharAddButton" aria-label="Add" onClick={c => addPayChar(pcType)}>
              <Add />
              &nbsp;<Trans>Add</Trans> {pcType}
            </Button>
          </Tooltip>
        );
      }
    }
  }
  var btnHisto = <></>;
  if (payChars && canViewDetail) {
    btnHisto = (
      <Tooltip title={<Trans>History</Trans>} placement="bottom">
        <span>
          <Button id="payCharHisto" aria-label="Histo" onClick={() => setOpenHisto(true)}>
            <History fontSize="small" />
            &nbsp;<Trans>History</Trans>
          </Button>
        </span>
      </Tooltip>
    );
  }
  ///------------------------------
  /// Crée le contenu (les lignes) de la table-react affichant les PaymentCharacteristics associés
  ///------------------------------
  function convertDataToReactTable() {
    if (isNull(payChars)) return [];
    var toDay = new Date();
    return payChars
      .filter(p => p.endOfValidityDate === null || new Date(p.endOfValidityDate) >= toDay)
      .map((prop, key) => {
        var lblType, lblName;
        if (isNull(prop.beneficiaryIdentifier)) {
          lblType = "SUPPLIER";
          lblName = <span title={thirdParty.longName}>{thirdParty.longName}</span>;
        } else if (prop.beneficiary) {
          lblType = prop.beneficiary.benificiaryTypeCode;
          lblName = canViewDetail ? (
            <div key={key} title={prop.beneficiary.thirdPartyLongName}>
              <Button justIcon round simple onClick={() => openThirdParty(prop.beneficiaryIdentifier)} color="info" className="like">
                <Assignment />
              </Button>
              {prop.beneficiary.thirdPartyLongName}
            </div>
          ) : (
            <span></span>
          );
        }
        return {
          identifier: prop.identifier,
          type: `${lblType} (${prop.paymentCharacteristicTypeCode})`,
          name: lblName,
          bankAccount: prop.bankAccount,
          bankCode: prop.bankCode,
          bankLabel: prop.bankLabel,
          sisScore: prop.sisScore + "¤" + prop.paymentCharacteristicStatusCode,
          sisComputedReasons: prop.sisComputedReasons,
          sisCheckDate: prop.sisCheckDate + " (UTC)",
          startOfValidityDate: formatDate(prop.startOfValidityDate, defaultLang),
          endOfValidityDate: prop.endOfValidityDate ? formatDate(prop.endOfValidityDate, defaultLang) : "",
          isContactFound: prop.thirdPartyContact,
          actions: canViewDetail ? (
            <div className="actions-right" key={key}>
              <Button justIcon round simple onClick={() => onPayCharOpen(prop.identifier)} color="info" className="like">
                <Assignment />
              </Button>
            </div>
          ) : (
            <span></span>
          )
        };
      });
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <GridContainer>
              <GridItem xs={8} sm={8} md={8}>
                <h4>
                  <Trans>PaymentCharacteristic</Trans>
                </h4>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                {btnAddPaymentChar}
                {btnHisto}
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div>
              <ReactTable
                data={convertDataToReactTable()}
                columns={[
                  {
                    Header: <Trans>Actions</Trans>,
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: <Trans>Type</Trans>,
                    accessor: "type"
                  },
                  {
                    Header: <Trans>Name</Trans>,
                    accessor: "name"
                  },
                  {
                    Header: <Trans>SIS Score</Trans>,
                    accessor: "sisScore",
                    Cell: row => (
                      <span style={{ display: "inline-flex" }}>
                        {renderPaymentCharacteristicsChip(
                          row.original,
                          masterValues[TpPaymentCharacteristicStatus].map(i => {
                            return { code: i.code, label: GetMasterValueLabel(i, defaultLang) };
                          })
                        )}
                        {renderPaymentCharacteristicsReason(row.original)}
                      </span>
                    )
                  },
                  {
                    Header: <Trans>StartDate</Trans>,
                    accessor: "startOfValidityDate",
                    sortMethod: (a, b) => {
                      if (a === b) {
                        return 0;
                      }
                      let dateA = toDate(a, defaultLang);
                      let dateB = toDate(a, defaultLang);

                      return dateA > dateB ? 1 : -1;
                    }
                  },
                  {
                    Header: <Trans>EndDate</Trans>,
                    accessor: "endOfValidityDate"
                  },
                  {
                    Header: <Trans>Status</Trans>,
                    accessor: "startOfValidityDate",
                    Cell: row => {
                      if (isFuture(toDate(row.value, defaultLang))) return <span>{"Futur"}</span>;
                      else return <span>{"Current"}</span>;
                    }
                  },
                  {
                    Header: <Trans>Contact</Trans>,
                    accessor: "isContactFound",
                    Cell: row =>
                      row.value && row.value.lastName ? (
                        <Tooltip title={`${row.value.lastName} ${row.value.firstName}`}>
                          <PermContactCalendar />
                        </Tooltip>
                      ) : (
                        <></>
                      ),
                    sortMethod: (a, b) => {
                      if (!a || !a.lastName) {
                        return -1;
                      }
                      if (!b || !b.lastName) {
                        return -1;
                      }
                      return a.lastName > b.lastName ? 1 : -1;
                    }
                  }
                ]}
                defaultPageSize={5}
                showPaginationBottom={false}
                showPageSizeOptions={false}
                className="-striped -highlight"
                showFilters={true}
                style={ReactTableStyle.main}
              />
            </div>
          </CardBody>
        </Card>
        <DialogPaymentCharacteristic
          paymentCharacteristic={editPayChar}
          supplier={supplier}
          supplierSjs={supplierSjs}
          thirdParty={thirdParty}
          setPaymentCharacteristic={onPayCharChange}
          requireWorkflowPayChar={true}
          errors={errors}
        />

        {openHisto === true ? (
          <Dialog open onClose={() => setOpenHisto(false)} fullWidth={true} maxWidth="md">
            <DialogContent>
              <HistoPaymentCharacteristic />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenHisto(false)} color="info">
                <Trans> Close </Trans>
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <></>
        )}
      </GridItem>
    </GridContainer>
  );
};

const SortPayCharsByStartOfValidityDate = function(payChars) {
  return payChars.sort(function(a, b) {
    if (a.startOfValidityDate < b.startOfValidityDate) return -1;
    else if (a.startOfValidityDate > b.startOfValidityDate) return 1;
    return 0;
  });
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    userInfo: {
      structures: state.AuthenticationReducer.user.structureTps,
      isAdmin: HasRight("thirdparty_supplier.edit"),
      canAdd: HasRight("thirdparty_supplier.add_iban"),
      canEdit: HasRight("thirdparty_supplier.edit_iban")
    },
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabSupplierPayment));
