import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isFunction, isNullOrEmpty } from "../../tools";
import GetMasterValueLabel from "../Common/MasterValueLabel";
import Muted from "../Typography/Muted";

function CountryAutocomplete({ isEditable, size, countryCode, onChange, error, countries, label, placeHolder, isRequired }) {
  var [value, setValue] = useState(null);
  var [options, setOptions] = useState(countries ? countries.map(i => ({ value: i.code, text: i.code + " - " + i.label })) : []);
  useEffect(() => {
    if (countries) {
      setOptions(
        countries.map(i => {
          return { value: i.code, text: i.code + " - " + i.label };
        })
      );
    } else {
      setOptions([]);
    }
  }, [countries]);

  useEffect(() => {
    setOptions(
      countries.map(i => {
        return { value: i.code, text: i.code + " - " + i.label };
      })
    );
  }, []);

  useEffect(() => {
    if (options) {
      setValue(options.find(c => c.value === countryCode));
    }
  }, [options, countryCode]);

  if (!countries) {
    return <CircularProgress size={30} />;
  }

  if (isEditable) {
    return (
      <Autocomplete
        clearOnEscape={!isRequired}
        disableClearable={!!isRequired}
        options={options}
        getOptionLabel={option => option.text}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (isFunction(onChange)) onChange(newValue ? newValue.value : null);
        }}
        value={value}
        renderInput={params => {
          let flag = value ? <ReactCountryFlag countryCode={value.value} svg /> : <></>;
          return (
            <TextField
              {...params}
              margin={size === "small" ? "none" : "normal"}
              size={size}
              fullWidth
              error={!isNullOrEmpty(error)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">{flag}</InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }}
              placeholder={placeHolder}
              label={label}
            />
          );
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.text, inputValue);
          const parts = parse(option.text, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }

  if (!countryCode) {
    return <Muted>Ø</Muted>;
  }

  var country = countries.find(c => c.codeIso2 === countryCode);
  return (
    <Muted>
      <ReactCountryFlag countryCode={countryCode} svg style={{ width: "1.5em", height: "1.5em" }} />
      &nbsp;
      {GetMasterValueLabel(country)}
    </Muted>
  );
}

const mapStateToProps = state => {
  return {
    countries: state.MasterValueReducer[CommonCountry]
  };
};

export default connect(mapStateToProps)(CountryAutocomplete);
