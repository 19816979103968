import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect, useDispatch } from "react-redux";

// custom components
import { Warning } from "@material-ui/icons";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import CustomerContractAddtionnalContractManagersPart from "./Parts/CustomerContractAddtionnalContractManagersPart";
import CustomerContractBudgetPart from "./Parts/CustomerContractBudgetPart";
import CustomerContractCustomerDatasPart from "./Parts/CustomerContractCustomerDatasPart";
import CustomerContractDatesPart from "./Parts/CustomerContractDatesPart";
import CustomerContractFlagsPart from "./Parts/CustomerContractFlagsPart";
import CustomerContractIdentifierAndSourcePart from "./Parts/CustomerContractIdentifierAndSourcePart";
import CustomerContractLinksPart from "./Parts/CustomerContractLinksPart";
import CustomerContractMainContactsPart from "./Parts/CustomerContractMainContactsPart";

function CustomerContractGeneralTab({ amc, isReadonly }) {
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var item = !!amc.currentItem["CUSTOMERCONTRACT"] ? amc.currentItem["CUSTOMERCONTRACT"] : {};
  var warnings = amc.errors["CUSTOMERCONTRACT"];
  var tabGeneral = <Trans>General</Trans>;
  if (!!warnings && typeof warnings !== "undefined" && warnings.some(w => w.code.split("/")[1] === "GENERAL"))
    tabGeneral = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabGeneral}
      </span>
    );
  var tabContacts = <Trans>Contacts</Trans>;
  if (!!warnings && typeof warnings !== "undefined" && warnings.some(w => w.code.split("/")[1] === "CONTACTS"))
    tabContacts = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabContacts}
      </span>
    );

  return (
    <GridContainer>
      <GridItem xs={2} sm={2} md={2}>
        <AntTabs
          orientation="vertical"
          variant="scrollable"
          value={tabIdx}
          onChange={(e, value) => setTabIdx(value)}
          aria-label="Vertical tabs customercontracts"
        >
          <AntTab label={tabGeneral} />
          <AntTab label={tabContacts} />
        </AntTabs>
      </GridItem>
      <GridItem xs={10} sm={10} md={10}>
        <TabPanel value={tabIdx} index={0}>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <CustomerContractIdentifierAndSourcePart isReadonly={isReadonly} />
              <CustomerContractCustomerDatasPart isReadonly={isReadonly} />
              <CustomerContractMainContactsPart isReadonly={isReadonly} />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomerContractLinksPart isReadonly={isReadonly} />
              <CustomerContractDatesPart isReadonly={isReadonly} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomerContractFlagsPart isReadonly={isReadonly} />
              <CustomerContractBudgetPart isReadonly={isReadonly} />
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIdx} index={1}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomerContractAddtionnalContractManagersPart isReadonly={isReadonly} />
            </GridItem>
          </GridContainer>
        </TabPanel>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractGeneralTab));
