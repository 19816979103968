import * as Actions from "./types";
import { SiteSearch } from "module/site/actions/SiteActions";

export function SearchSites(criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.SEARCH_BEGIN,
      criterias: criterias,
      searchScope: "sites"
    });

    SiteSearch(criterias)
      .then(responseJson => {
        dispatch({
          type: Actions.SEARCH_END,
          results: responseJson,
          criterias: criterias,
          searchScope: "sites"
        });
      })
      .catch(error => {
        dispatch({
          type: Actions.SEARCH_ERROR,
          error: error
        });
      });
  };
}
