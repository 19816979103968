import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, TextareaAutosize, CircularProgress } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Add, Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { Trans } from "@lingui/macro";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardError from 'components/Card/CardError';
import { HasRight } from "services/user/UserHelper";
import * as HelpInfoActions from 'module/HelpInfo/Store/actions/HelpInfo.Actions';
import { isObjectNullOrEmpty } from 'tools';

const HelpInfoListings = () => {
  const [state, setState] = useState({ isLoading: true, canEdit: false });
  const [isEditingPresentation, setIsEditingPresentation] = useState(false);
  const [editingNewsIndex, setEditingNewsIndex] = useState(null);
  const [editingLinksIndex, setEditingLinksIndex] = useState(null);

  const [helpInfoData, setHelpInfoData] = useState(null);

  useEffect(() => {
    const fetchHelpInfo = async () => {
      await HelpInfoActions.get(
        result => {
          setHelpInfoData(result); setState({ ...state, isLoading: false, jsonBackup: JSON.stringify(result) });
        },
        error => {
          console.log(error);
          setState({ ...state, isLoading: false, error: error });
        });
    };

    fetchHelpInfo();
  }, []);

  var isAdmin = HasRight("application.admin");

  const handleSavePresentation = () => {
    setIsEditingPresentation(false);
  };

  const handleSaveNews = () => {
    setEditingNewsIndex(null);
  };

  const handleSaveLinks = () => {
    setEditingLinksIndex(null);
  };

  const cancel = () => {
    setState({ ...state, isLoading: true, canEdit: false });
    setIsEditingPresentation(false)
    setEditingNewsIndex(null);
    setEditingLinksIndex(null);
    setHelpInfoData(
      JSON.parse(state.jsonBackup));
    setState({ ...state, isLoading: false, canEdit: false });
  };

  const save = () => {
    setState({ ...state, isLoading: true, canEdit: false });
    setIsEditingPresentation(false)
    setEditingNewsIndex(null);
    setEditingLinksIndex(null);
    HelpInfoActions.crupdate(helpInfoData, r =>
      setState({ ...state, isLoading: false, jsonBackup: JSON.stringify(helpInfoData) }),
      error => {
        console.log(error);
        setState({ ...state, isLoading: false, error: error });
      });
  };

  const addLink = () => {
    setHelpInfoData({ ...helpInfoData, links: [...helpInfoData.links, { index: helpInfoData.links.length + 1, title: "", value: "" }] });
    setEditingLinksIndex(helpInfoData.links.length + 1);
  };

  const addNews = () => {
    setHelpInfoData({ ...helpInfoData, news: [...helpInfoData.news, { index: helpInfoData.news.length + 1, title: "", value: "" }] });
    setEditingNewsIndex(helpInfoData.news.length + 1);
  };


  if (!!state.isLoading) {
    return <CircularProgress />;
  } else if (state.error) {
    return <CardError error={state.error} />;
  } else if (isObjectNullOrEmpty(helpInfoData)) {
    return <Typography>Aucun Actualité</Typography>;
  }
  var btnActions = "";

  if (!state.canEdit && isAdmin && JSON.stringify(helpInfoData) === state.jsonBackup) {
    btnActions = (
      <Button
        color="info"
        onClick={e => setState({ ...state, canEdit: true })}
      >
        <Trans>Edit</Trans>
      </Button>
    );
  }
  if (state.canEdit) {
    btnActions = (
      <>
        <Button
          color="info"
          onClick={save}
          hidden={JSON.stringify(helpInfoData) !== state.jsonBackup}
        >
          <Trans>Save</Trans>
        </Button>
        <Button
          color="danger"
          onClick={cancel}
        >
          <Trans>Cancel</Trans>
        </Button>
      </>
    );
  }

  var linksHeader = "";
  var newsHeader = "";
  linksHeader = (
    <GridContainer>
      <GridItem xs={8} sm={8} md={8}>
        <h4>
          <Trans>Links</Trans>
        </h4>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        {state.canEdit && <Button disabled={!!editingLinksIndex} onClick={() => addLink()}><Add /> <Trans>Add Link</Trans></Button>}
      </GridItem>
    </GridContainer>
  );
  newsHeader = (
    <GridContainer>
      <GridItem xs={8} sm={8} md={8}>
        <h4>
          <Trans>News</Trans>
        </h4>
      </GridItem>
      <GridItem xs={4} sm={4} md={4}>
        {state.canEdit && <Button disabled={!!editingNewsIndex} onClick={() => addNews()}><Add /> <Trans>Add News</Trans></Button>}
      </GridItem>
    </GridContainer>
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {btnActions}
      </Grid>
      {/* Presentation Section */}
      <Grid item xs={12}>
        <Card style={{ ...CardInlineStyle.card }}>
          <CardHeader style={{ ...CardInlineStyle.cardHeader }} icon>
            <h4><Trans>Presentation</Trans></h4>
          </CardHeader>
          <CardBody>
            <>
              <TextareaAutosize
                value={helpInfoData.presentation}
                onChange={(e) => setHelpInfoData({ ...helpInfoData, presentation: e.target.value })}
                readOnly={!state.canEdit || !isEditingPresentation}
                rowsMin={4}
                style={{
                  border: (!state.canEdit || !isEditingPresentation) ? 'none' : '1px solid #ccc',
                  resize: 'none', // Disable resizing
                  width: '100%', // Full width
                  outline: 'none' // Remove outline on focus
                }}
              />
              {state.canEdit && isEditingPresentation && <Button onClick={handleSavePresentation}><SaveIcon /></Button>}
              {state.canEdit && !isEditingPresentation && <Button onClick={() => setIsEditingPresentation(true)}><EditIcon /></Button>}
            </>
          </CardBody>
        </Card>
      </Grid>

      {/* News Section */}
      <Grid item xs={6}>
        <Card style={{ ...CardInlineStyle.card }}>
          <CardHeader style={{ ...CardInlineStyle.cardHeader }} icon>
            {newsHeader}
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table>
                {state.canEdit &&
                  <TableHead>
                    <TableRow>
                      <TableCell><Trans>Title</Trans></TableCell>
                      <TableCell><Trans>Content</Trans></TableCell>
                      <TableCell><Trans>Actions</Trans></TableCell>
                    </TableRow>
                  </TableHead>
                }
                <TableBody>
                  {helpInfoData && helpInfoData.news && helpInfoData.news.map((item) => (
                    <TableRow key={item.index}>
                      <TableCell>
                        {editingNewsIndex === item.index ? (
                          <TextField
                            value={item.title}
                            onChange={(e) => {
                              const newNews = [...helpInfoData.news];
                              newNews[item.index - 1].title = e.target.value;
                              setHelpInfoData({ ...helpInfoData, news: newNews });
                            }}
                            variant="outlined"
                            fullWidth
                            multiline
                          />
                        ) : (
                          <Typography>{item.title}</Typography>
                        )}
                      </TableCell>
                      <TableCell colSpan={state.canEdit ? "1" : "2"}>
                        <TextareaAutosize
                          value={item.value}
                          onChange={(e) => {
                            const newNews = [...helpInfoData.news];
                            newNews[item.index - 1].value = e.target.value;
                            setHelpInfoData({ ...helpInfoData, news: newNews });
                          }}
                          rowsMin={4}
                          rowsMax={4}
                          readOnly={!(state.canEdit && editingNewsIndex === item.index)}
                          style={{
                            border: (state.canEdit && editingNewsIndex === item.index) ? '1px solid #ccc' : 'none',
                            resize: 'none',
                            width: '100%',
                            outline: 'none',
                            overflow: (state.canEdit && editingNewsIndex === item.index) ? 'auto' : 'none'
                          }}
                        />
                      </TableCell>
                      {state.canEdit &&
                        <TableCell>
                          {editingNewsIndex === item.index ? (
                            <Button onClick={handleSaveNews}><SaveIcon /></Button>
                          ) : (
                            <Button disabled={!!editingLinksIndex} onClick={() => setEditingNewsIndex(item.index)}><EditIcon /></Button>
                          )}
                          <Button disabled={!!editingLinksIndex} onClick={() => { setHelpInfoData({ ...helpInfoData, news: helpInfoData.news.filter((news) => news.index !== item.index) }) }}><Delete /></Button>
                        </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Grid>

      {/* Links Section */}
      <Grid item xs={6}>
        <Card style={{ ...CardInlineStyle.card }}>
          <CardHeader style={{ ...CardInlineStyle.cardHeader }} icon>
            {linksHeader}
          </CardHeader>
          <CardBody>
            <TableContainer>
              <Table>
                {state.canEdit &&
                  <TableHead>
                    <TableRow>
                      <TableCell><Trans>Title</Trans></TableCell>
                      <TableCell><Trans>URL</Trans></TableCell>
                      <TableCell><Trans>Actions</Trans></TableCell>
                    </TableRow>
                  </TableHead>
                }
                <TableBody>
                  {helpInfoData && helpInfoData.links && helpInfoData.links.map((item) => (
                    <TableRow key={item.index}>
                      <TableCell>
                        {editingLinksIndex === item.index ? (
                          <TextField
                            value={item.title}
                            onChange={(e) => {
                              const newLinks = [...helpInfoData.links];
                              newLinks[item.index - 1].title = e.target.value;
                              setHelpInfoData({ ...helpInfoData, links: newLinks });
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        ) : (
                          <Typography>{item.title}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {editingLinksIndex === item.index ? (
                          <TextField
                            value={item.value}
                            onChange={(e) => {
                              const newLinks = [...helpInfoData.links];
                              newLinks[item.index - 1].value = e.target.value;
                              setHelpInfoData({ ...helpInfoData, links: newLinks });
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        ) : (
                          <Typography>
                            <a href={item.value} target="_blank" rel="noopener noreferrer">
                              {item.value}
                            </a>
                          </Typography>
                        )}
                      </TableCell>
                      {state.canEdit &&
                        <TableCell>
                          {editingLinksIndex === item.index ? (
                            <Button onClick={handleSaveLinks}><SaveIcon /></Button>
                          ) : (
                            <Button disabled={!!editingLinksIndex} onClick={() => setEditingLinksIndex(item.index)}><EditIcon /></Button>
                          )}
                          <Button disabled={!!editingLinksIndex} onClick={() => { setHelpInfoData({ ...helpInfoData, links: helpInfoData.links.filter((links) => links.index !== item.index) }) }}><Delete /></Button>

                        </TableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px",
    minHeight: "200px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};



export default withStyles(thirdPartySearchCriteriaStyle)(HelpInfoListings);
