import React from "react";
import { connect } from "react-redux";
import * as Actions from "module/amc/store/actions/Project.Actions";

import { CircularProgress } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import ProjectSearchCriteria from "module/amc/components/projects/ProjectSearchCriteria";
import ProjectTable from "module/amc/components/projects/ProjectTable";

import Button from "components/CustomButtons/Button.jsx";
import { Add } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";
import { isNullOrEmpty } from "../../tools";

import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import ProjectPopup from "./ProjectPopup";
import { HasRight } from "services/user/UserHelper";

function ProjectList({ amc, addItem }) {
  var searchResult = amc.results["PROJECT"];
  var isSearching = amc.searching["PROJECT"];

  var resultComponent = "";
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else resultComponent = <ProjectTable />;

  var buttonCreation = "";
  if (HasRight("amc.admin")) {
    buttonCreation = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" onClick={addItem} size="sm" color="info">
            <Add />
            <Trans>Add Project</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult) || isSearching) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {resultComponent}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <ProjectSearchCriteria showSearchCriteriaOnSideBar={true} />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = <ProjectSearchCriteria showSearchCriteriaOnSideBar={false} />;
  }

  return (
    <div>
      {buttonCreation}
      {layoutSearchResult}
      <ProjectPopup />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addItem: () => {
      dispatch(Actions.AddItem());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectList);
