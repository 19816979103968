import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { CheckCircleOutlined, ErrorOutlined, RefreshOutlined, Warning } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import { isArrayNullOrEmpty, isNull } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import CustomerContractGeneralTab from "./CustomerContractDetails/CustomerContractGeneralTab";

function CustomerContractDetail({ amc, isReadonly }) {
  var item = amc.currentItem["CUSTOMERCONTRACT"];
  var itemHasChanged = false;
  var itemSaveStatus = null;
  var itemHasWarnings = false;
  if (!!amc) {
    itemHasChanged = amc.hasChanged["CUSTOMERCONTRACT"];
    itemSaveStatus = amc.saveStatus["CUSTOMERCONTRACT"];
    itemHasWarnings = !isArrayNullOrEmpty(amc.errors["CUSTOMERCONTRACT"]);
  }

  var hasChanged = itemHasChanged;

  var tabStatus = buildTabs();
  var tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "CustomerContractGeneralTab":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(itemSaveStatus);
        var warningIcon = itemHasWarnings ? <Warning style={{ color: "orange" }} /> : "";
        if (itemHasChanged) star = " *";
        tabButton = (
          <span>
            {tab.title}
            {star}
          </span>
        );
        tabContent = <CustomerContractGeneralTab isReadonly={isReadonly} />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  return (
    <div style={{ width: "99%" }}>
      <CustomerContractGeneralTab isReadonly={isReadonly} />
    </div>
  );
}

function buildTabs() {
  return [
    {
      index: 0,
      title: <Trans>Customer contract General</Trans>,
      component: "CustomerContractGeneralTab",
      status: "neutral",
      ref: React.createRef()
    }
  ];
}

function GetSaveStatusIcon(saveStatus) {
  if (!isNull(saveStatus)) {
    switch (saveStatus) {
      case "PENDING":
        return <RefreshOutlined />;
      case "ERROR":
        return <ErrorOutlined style={{ color: "red" }} />;
      case "SUCCESS_RELOAD":
      case "SUCCESS":
        return <CheckCircleOutlined style={{ color: "green" }} />;
    }
  }
  return "";
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractDetail));
