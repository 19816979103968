// ##############################
// // // Header styles
// #############################

import {
    cardTitle,
    containerFluid,
    defaultFont,
    primaryColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    drawerWidth,
    drawerMiniWidth,
    transition
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const adminStyle = theme => ({
    ...customCheckboxRadioSwitch,
    IconButton: {
        color: "primary"
    },
    cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    formCategory: {
        marginBottom: "0",
        color: "#999999",
        fontSize: "14px",
        padding: "10px 0 10px"
    },
    center: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center"
    },
    flexRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    danger: {
        color: dangerColor + "!important"
    },
    detailPanel: {
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        overflow: "auto",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    detailPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    },
    appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1029",
        color: "#555555",
        border: "0",
        borderRadius: "3px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block"
    },
    cardSizeSmall: {
        width: 275
    },
    cardSizeMedium: {
        width: 500
    },
    cardSizeLarge: {
        width: 800
    },
    cardSizeFull: {
        width: '100%'
    },
    container: {
        ...containerFluid,
        minHeight: "50px"
    },
    flex: {
        flex: 1
    },
    flexColCenter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    title: {
        ...defaultFont,
        lineHeight: "30px",
        fontSize: "18px",
        borderRadius: "3px",
        textTransform: "none",
        color: "inherit",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem",
        margin: "0 !important",
        "&:hover,&:focus": {
            background: "transparent"
        }
    },
    logo: {
        padding: "15px 0px",
        margin: "0",
        display: "block",
        position: "relative",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "hsla(0,0%,100%,.3)"
        }
    },
    primary: {
        backgroundColor: primaryColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    info: {
        backgroundColor: infoColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    success: {
        backgroundColor: successColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    warning: {
        backgroundColor: warningColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    danger: {
        backgroundColor: dangerColor,
        color: "#FFFFFF",
        ...defaultBoxShadow
    },
    sidebarMinimize: {
        float: "left",
        padding: "0 0 0 15px",
        display: "block",
        color: "#555555"
    },
    sidebarMinimizeRTL: {
        padding: "0 15px 0 0 !important"
    },
    sidebarMiniIcon: {
        width: "20px",
        height: "17px"
    },
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh",
        "&:after": {
            display: "table",
            clear: "both",
            content: '" "'
        }
    },
    mainPanel: {
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: "70px",
        padding: "0px 15px",
        minHeight: "calc(100vh - 123px)"
    },
    container: { ...containerFluid },
    map: {
        marginTop: "70px"
    },
    mainPanelSidebarMini: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerMiniWidth}px)`
        }
    },
    mainPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    }
});
export default adminStyle;
