import { Trans } from "@lingui/macro";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ApimGetPromise } from "components/Common/ApimSender";
import { TabPanel } from "components/Common/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import * as MvType from "store/MasterValue/MasterValueTypes";
import TabCustomer from "./TabCustomer";
import TabSupplier from "./TabSupplier";
import TabThirdParty from "./TabThirdParty";

const DetailContent = ({ thirdParty, setThirdParty, isEditable, defaultLang, masterValues, classes, isGroupEditable }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [isLoading, setIsLoading] = useState("init");
  var dispatch = useDispatch();

  useEffect(() => {
    if (isLoading === "init") {
      setIsLoading("true");

      var mvTypesToLoad = [
        MvType.TpCompanyComplianceScore,
        MvType.TpContactType,
        MvType.TpCoupaActivity,
        MvType.TpIndustryCode,
        MvType.TpIndustryCodeRepository,
        MvType.TpLegalForm,
        MvType.TpLegalIdentifier,
        MvType.TpLiquidatorType,
        MvType.TpPaymentCharacteristicStatus,
        MvType.TpThirdPartyCategory,
        MvType.TpThirdPartyStatus
      ].filter(mvType => !masterValues[mvType]);
      var mvLoads = mvTypesToLoad.reduce(async (previousPromise, mvType, idx, array) => {
        await previousPromise;
        return ApimGetPromise("api/mastervalue/" + mvType).then(c => dispatch(addMasterValue(mvType, c, idx, array.length)));
      }, Promise.resolve());

      mvLoads.then(() => {
        setIsLoading(null);
      });
    }
  }, []);

  if (!!isLoading) {
    return <CircularProgress />;
  }

  const buildTabs = [
    {
      title: <Trans>Legal Datas</Trans>,
      component: "ThirdParty",
      status: "neutral"
    },
    {
      title: <Trans>Supplier</Trans>,
      component: "Supplier",
      status: "neutral"
    },
    {
      title: <Trans>Customer</Trans>,
      component: "Customer",
      status: "neutral"
    }
  ];

  let tabStatus = buildTabs;
  let tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "ThirdParty":
        tabButton = <span>{tab.title}</span>;
        tabContent = (
          <TabThirdParty
            thirdParty={thirdParty}
            setThirdParty={setThirdParty}
            classes={classes}
            masterValues={masterValues}
            defaultLang={defaultLang}
            isEditable={isEditable}
            isGroupEditable={isGroupEditable}
          />
        );
        break;
      case "Supplier":
        tabButton = <span>{tab.title}</span>;
        tabContent = (
          <TabSupplier
            thirdParty={thirdParty}
            setThirdParty={setThirdParty}
            classes={classes}
            masterValues={masterValues}
            defaultLang={defaultLang}
            isEditable={isEditable}
          />
        );
        break;
      case "Customer":
        tabButton = <span>{tab.title}</span>;
        tabContent = (
          <TabCustomer
            thirdParty={thirdParty}
            setThirdParty={setThirdParty}
            classes={classes}
            masterValues={masterValues}
            defaultLang={defaultLang}
            isEditable={isEditable}
          />
        );
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  let lockedBanner = <></>;
  if (thirdParty.isLocked) {
    lockedBanner = (
      <Alert severity="warning">
        <Trans> WF_LockedUntilApprobation </Trans>
      </Alert>
    );
  }

  return (
    <>
      {lockedBanner}
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs thirdParty">
        {tabs.map((t, idx) => (
          <Tab key={"thirdPartyTab" + idx} label={t.tabButton} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel value={tabIdx} index={idx} id={"thirdPartyTab" + idx} key={"thirdPartyTab" + idx}>
          {t.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

export default DetailContent;
