import { Trans } from "@lingui/macro";
import { CircularProgress, Divider, TextField, Typography } from "@material-ui/core";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import CardError from "components/Card/CardError";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import React, { useEffect, useState } from "react";
import { LocationCharacteristicType, LocationCharacteristicValue, LocationType } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import { GetCharacteristicValues } from "../../actions/SiteActions";

const SiteCharacteristics = ({ site, setSite, isEditable, defaultLang, masterValues }) => {
  const [state, setState] = useState({ isLoading: true });

  function loadCharacteristics() {
    setState({ ...state, isLoading: true, error: null });

    if (isNullOrEmpty(site.locationTypeCode)) {
      setState({ ...state, isLoading: false, charValues: [] });
    } else {
      GetCharacteristicValues(
        site.locationTypeCode,
        charTypes => {
          charTypes = charTypes.map(c => {
            let locationCharType = masterValues[LocationCharacteristicType].find(i => i.code === c.locationCharacteristicTypeCode);
            if (locationCharType) {
              locationCharType.values = masterValues[LocationCharacteristicValue].filter(i => i.locationCharTypeCode === locationCharType.code);
            }

            c.locationCharacteristicType = locationCharType;
            return c;
          });
          setState({ ...state, isLoading: false, charValues: charTypes });
        },
        e => setState({ ...state, isLoading: false, error: e })
      );
    }
  }

  useEffect(() => {
    if (site) {
      loadCharacteristics();
    } else {
      setState({ ...state, isLoading: false });
    }
  }, [masterValues, site.locationTypeCode]);

  var content = "";

  if (state.isLoading) {
    content = <CircularProgress />;
  } else if (state.error) {
    content = <CardError error={state.error} />;
  } else if (state.charValues) {
    content = (
      <div>
        {state.charValues.map((cv, idx) => {
          if (!isArrayNullOrEmpty(cv.locationCharacteristicType.values)) {
            let selectedValues = !site.characteristics
              ? []
              : site.characteristics.filter(c => c.locationCharTypeCode === cv.locationCharacteristicTypeCode).map(c => c.locationCharValueCode);
            if (isEditable) {
              let isMulti = cv.locationCharacteristicType.isMulti;
              return (
                <div key={idx}>
                  <ItemAutoComplete
                    isEditable
                    multiple={isMulti}
                    items={cv.locationCharacteristicType.values}
                    selectedValue={isMulti ? selectedValues : selectedValues[0]}
                    onValueChange={v => {
                      if (isMulti) {
                        let newValues = v.map(v1 => {
                          return {
                            locationCharTypeCode: cv.locationCharacteristicTypeCode,
                            locationCharValueCode: v1
                          };
                        });
                        setSite({
                          ...site,
                          characteristics: [
                            ...site.characteristics.filter(c => c.locationCharTypeCode !== cv.locationCharacteristicTypeCode),
                            ...newValues
                          ]
                        });
                      } else {
                        setSite({
                          ...site,
                          characteristics: [
                            ...site.characteristics.filter(c => c.locationCharTypeCode !== cv.locationCharacteristicTypeCode),
                            {
                              locationCharTypeCode: cv.locationCharacteristicTypeCode,
                              locationCharValueCode: v
                            }
                          ]
                        });
                      }
                    }}
                    label={GetMasterValueLabel(cv.locationCharacteristicType, defaultLang)}
                    size="small"
                  />
                </div>
              );
            }
            return (
              <div key={idx} style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography>{GetMasterValueLabel(cv.locationCharacteristicType, defaultLang)}</Typography>
                {selectedValues.map((v, vIdx) => {
                  let item = cv.locationCharacteristicType.values.find(r => r.code === v);
                  return (
                    <Typography key={vIdx} variant="subtitle2">
                      {GetMasterValueLabel(item, defaultLang)}
                    </Typography>
                  );
                })}
              </div>
            );
          }

          let value = !site.characteristics ? null : site.characteristics.find(c => c.locationCharTypeCode === cv.locationCharacteristicTypeCode);
          value = !isNullOrEmpty(value) ? value.text : "";
          return (
            <div key={idx}>
              <TextField
                label={GetMasterValueLabel(cv.locationCharacteristicType, defaultLang)}
                value={value}
                onChange={e =>
                  setSite({
                    ...site,
                    characteristics: [
                      ...site.characteristics.filter(c => c.locationCharTypeCode !== cv.locationCharacteristicTypeCode),
                      {
                        locationCharTypeCode: cv.locationCharacteristicTypeCode,
                        text: e.target.value
                      }
                    ]
                  })
                }
                fullWidth
                multiple={cv.locationCharacteristicType.isMulti}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <MasterValueAutocomplete
        isEditable={isEditable}
        mvType={LocationType}
        selectedValue={site.locationTypeCode}
        onValueChange={v => setSite({ ...site, locationTypeCode: v })}
        label={<Trans>Site_Type</Trans>}
        size="small"
      />
      <Divider />
      {content}
    </>
  );
};

export default SiteCharacteristics;
