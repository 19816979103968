import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function get(callbackFn, errorFn) {
  ApimGet("api/HelpInfo/",
    c => callbackFn(c),
    e => errorFn(e)
  );
}


export function crupdate(HelpInfoData, callbackFn, errorFn) {
  ApimPost(
    "api/HelpInfo/",
    HelpInfoData,
    c => callbackFn(c),
    e => errorFn(e)
  );
}
