import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import Tree from '../../Tree';

const TabTree = ({ structure, defaultLang }) => {
    return (
        <>
            <Tree structureId={structure.id} treeType="iris3" defaultLang={defaultLang} />
        </>
    );
};

export default withStyles(structureStyle)(TabTree);