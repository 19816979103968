import React from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions";
import { isArrayNullOrEmpty } from "../../../tools";

import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { FormLabel } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";

const TabPointOfSaleSupplier = function({ pointOfSaleCtx, openThirdParty, classes }) {
  var pointOfSale = pointOfSaleCtx.pointOfSale;
  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans> Supplier </Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Button onClick={() => openThirdParty(pointOfSale.supplierIdentifier)}>
            <Trans> Link </Trans>
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    pointOfSaleCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].pointOfSaleCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    }
    //onValueChanged: pointOfSale => {
    //    dispatch(Actions.EditPointOfSale(pointOfSale))
    //}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabPointOfSaleSupplier));
