import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  TpDematerializationType,
  TpFee,
  TpGroup,
  TpPaymentMethod,
  TpPaymentPeriod,
  TpScope,
  TpSupplierNature,
  TpSupplierStatus
} from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";

const TabSupplierGeneral = function({ supplierCtx, isEditable, onSupplierValueChanged, availableUes, availableSjs, masterValues, classes }) {
  var supplier = supplierCtx.supplier;
  const [ues, setUes] = useState([]);

  useEffect(() => {
    let lst = availableUes;
    if (!isEditable) {
      var ue = masterValues.StructureUe.find(ue => ue.identifier === supplier.ueIdentifier);
      lst = ue ? [ue] : [];
    }
    setUes(lst);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Common</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Short Name</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={isEditable}
                        id="shortName"
                        value={supplier.shortName}
                        onChange={e => {
                          var value = e.target.value;
                          if (value.length > 8) value = value.substring(0, 8);
                          onSupplierValueChanged({ ...supplier, shortName: value });
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Nature</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpSupplierNature}
                        prefixCode
                        selectedValue={supplier.supplierNatureCode}
                        onValueChange={value => onSupplierValueChanged({ ...supplier, supplierNatureCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Scope</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete isEditable={false} mvType={TpScope} prefixCode selectedValue={supplier.scopeCode} />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Ue</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <ItemAutoComplete
                        isEditable={isEditable}
                        isRequired
                        items={ues.map(ue => {
                          return { ...ue, code: ue.identifier };
                        })}
                        selectedValue={supplier.ueIdentifier}
                        onValueChange={v => onSupplierValueChanged({ ...supplier, ueIdentifier: v })}
                        formatText={ue => `${ue.identifier} - ${ue.oldIdentifier} - ${ue.label}`}
                        maxResultNumber={100}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Groupe</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpGroup}
                        prefixCode
                        selectedValue={supplier.groupCode}
                        onValueChange={value => onSupplierValueChanged({ ...supplier, groupCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Fee</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpFee}
                        prefixCode
                        selectedValue={supplier.feeCode}
                        onValueChange={value => onSupplierValueChanged({ ...supplier, feeCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={supplier.isHandicapSector}
                            disabled={!isEditable}
                            onChange={e => onSupplierValueChanged({ ...supplier, isHandicapSector: e.target.checked })}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={<Trans>Handicap sector</Trans>}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Default Payment Conditions</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Payment Period</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={
                          isEditable ||
                          (supplierCtx.isEditablePayPeriod &&
                            supplierCtx.supplierSjs.some(supSj => availableSjs.some(userSj => supSj.sj.sjCode === userSj.identifier)))
                        }
                        isRequired
                        mvType={TpPaymentPeriod}
                        prefixCode
                        selectedValue={supplier.paymentPeriodCode}
                        onValueChange={value => onSupplierValueChanged({ ...supplier, paymentPeriodCode: value, paymentPeriodForced: true })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Payment Method</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpPaymentMethod}
                        prefixCode
                        selectedValue={supplier.paymentMethodCode}
                        onValueChange={value => onSupplierValueChanged({ ...supplier, paymentMethodCode: value })}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <div>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Status</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Status</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <MasterValueAutocomplete
                      isEditable={isEditable}
                      isRequired
                      mvType={TpSupplierStatus}
                      prefixCode
                      selectedValue={supplier.supplierStatusCode}
                      onValueChange={value => onSupplierValueChanged({ ...supplier, supplierStatusCode: value })}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isActif}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, isActif: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans> supplier isActive </Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.mustBeSentToCoupa}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, mustBeSentToCoupa: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Must be sent to Coupa</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isCommandAuthorized}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, isCommandAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Command Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isReceptionAuthorized}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, isReceptionAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Reception Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isBillingRecordingAuthorized}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, isBillingRecordingAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Billing Record Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isPaymentAuthorized}
                          disabled={!isEditable}
                          onChange={e => onSupplierValueChanged({ ...supplier, isPaymentAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Payment Authorized</Trans>}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Dematerialization</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>DematerializationType</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <MasterValueAutocomplete
                      isEditable={isEditable}
                      isRequired
                      mvType={TpDematerializationType}
                      prefixCode
                      selectedValue={supplier.dematerializationTypeCode}
                      onValueChange={value => onSupplierValueChanged({ ...supplier, dematerializationTypeCode: value })}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null,
    availableUes: state.AuthenticationReducer.user?.structureTps?.ues,
    defaultLang: state.AuthenticationReducer.user.language,
    masterValues: state.MasterValueReducer,
    availableSjs: state.AuthenticationReducer.user?.structureTps?.sjs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSupplierValueChanged: supplier => {
      dispatch(Actions.EditSupplier(supplier));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabSupplierGeneral));
