import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";

import { Trans } from "@lingui/macro";
import Header from "./Header";
import TabRelation from "../common/TabRelation";
import TabTree from "../common/TabTree";
import { Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "components/Common/Tabs";
import IconTab from "../common/IconTab";
import TabHistory from "../common/TabHistory";
import { isNullOrEmpty } from "tools";

const MainCdg = ({ structure, setStructure, masterValues, defaultLang, errors, isEditable }) => {
  const [tabIdx, setTabIdx] = useState(0);

  var tabStatus = buildTabs(isNullOrEmpty(structure.identifiant));
  var tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "Tree":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={[]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabTree structure={structure} setStructure={setStructure} masterValues={masterValues} defaultLang={defaultLang} isEditable={isEditable} />
        );
        break;
      case "Relation":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={["RELATION"]} /> {tab.title}
          </span>
        );
        tabContent = (
          <TabRelation structure={structure} setStructure={setStructure} defaultLang={defaultLang} isEditable={isEditable} errors={errors} />
        );
        break;
      case "History":
        tabButton = (
          <span>
            <IconTab errors={errors} errorTypes={[]} /> {tab.title}
          </span>
        );
        tabContent = <TabHistory structure={structure} defaultLang={defaultLang} />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  return (
    <>
      <Header structure={structure} setStructure={setStructure} isEditable={isEditable} masterValues={masterValues} defaultLang={defaultLang} />
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs supplier">
        {tabs.map((t, idx) => (
          <Tab key={"ueTab" + idx} label={t.tabButton} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel key={"ueTabPanel" + idx} value={tabIdx} index={idx} id={"ueTab" + idx}>
          {t.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

function buildTabs(isCreation) {
  var tabs = [
    {
      index: 0,
      title: <Trans>Structure_Tab_Tree</Trans>,
      component: "Tree",
      status: "neutral"
    },
    {
      index: 1,
      title: <Trans>Structure_Tab_Relation</Trans>,
      component: "Relation",
      status: "neutral"
    }
  ];
  if (!isCreation) {
    tabs = [
      ...tabs,
      {
        title: <Trans>Structure_Tab_History</Trans>,
        component: "History",
        status: "neutral"
      }
    ];
  }
  return tabs;
}

export default withStyles(structureStyle)(MainCdg);
