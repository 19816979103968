import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { HasRight } from "services/user/UserHelper";
import { Trans } from "@lingui/macro";
import {
  HubbleParameter_CurrentMaintenance,
  HubbleParameter_PlannedMaintenanceFrom,
  HubbleParameter_PlannedMaintenanceTo
} from "constants/HubbleParameterConstants";

const style = {
  banner: { alignItems: "center", justifyContent: "center", height: "auto" },
  banner_content: { textAlign: "center", color: "#FFF", "padding-top": "5", "padding-bottom": "5" }
};
function InfoBanner({ hubbleParamsCtx }) {
  if (hubbleParamsCtx?.HubbleParams.some(h => h.code === HubbleParameter_CurrentMaintenance && h.value === "True") && HasRight("application.admin")) {
    return (
      <div className="banner" style={{ backgroundColor: "red", color: "white" }}>
        <div className="banner_content" style={{ textAlign: "center", fontWeight: "bold", paddingTop: "10px", paddingBottom: "10px" }}>
          <Trans> Hubble_Maintenance </Trans>
        </div>
      </div>
    );
  } else if (
    hubbleParamsCtx?.HubbleParams.some(h => h.code === HubbleParameter_PlannedMaintenanceFrom) &&
    hubbleParamsCtx?.HubbleParams.some(h => h.code === HubbleParameter_PlannedMaintenanceTo)
  ) {
    const plannedMaintenanceFrom = hubbleParamsCtx?.HubbleParams.find(h => h.code === HubbleParameter_PlannedMaintenanceFrom);
    const plannedMaintenanceTo = hubbleParamsCtx?.HubbleParams.find(h => h.code === HubbleParameter_PlannedMaintenanceTo);
    return (
      <div className="banner" style={{ backgroundColor: "orange" }}>
        <div className="banner_content" style={{ textAlign: "center", fontWeight: "bold", paddingTop: "10px", paddingBottom: "10px" }}>
          <Trans> Planned_Maintenance_From </Trans>
          {plannedMaintenanceFrom}
          <Trans> Planned_Maintenance_To </Trans>
          {plannedMaintenanceTo}
        </div>
      </div>
    );
  }
  return <></>;
}
const mapStateToProps = state => {
  return {
    hubbleParamsCtx: state.HubbleParamReducer
  };
};
export default connect(mapStateToProps)(withStyles(style)(InfoBanner));
