import React from "react";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { Typography } from "@material-ui/core";
import SearchCriteriasSimple from "./SearchCriteriasSimple";
import SearchCriteriasExtended from "./SearchCriteriasExtended";
import SearchCriteriasComplex from "./SearchCriteriasComplex";
import { Trans } from "@lingui/macro";

function SearchCriterias({ criteria, setCriteria, launchSearch, cleanSearch, showSearchCriteriaOnSideBar, searchModes, masterValues, defaultLang }) {
  if (!searchModes) {
    searchModes = ["Simple", "Extend", "Complex"];
  }

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  const simpleSearchClasses = ["simple-search"];
  const extendedSearchClasses = ["extended-search"];
  const complexSearchClasses = ["complex-search"];
  var searchTitle = "";

  var searchComponent = "";
  switch (criteria.searchMode) {
    case "Simple":
      searchComponent = (
        <SearchCriteriasSimple
          criteria={criteria}
          setCriteria={setCriteria}
          cleanSearch={cleanSearch}
          launchSearch={e => {
            e.preventDefault();
            launchSearch();
          }}
          showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
          defaultLang={defaultLang}
        />
      );
      simpleSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      searchComponent = (
        <SearchCriteriasExtended
          criteria={criteria}
          setCriteria={setCriteria}
          cleanSearch={cleanSearch}
          launchSearch={e => {
            e.preventDefault();
            launchSearch();
          }}
          showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar}
          masterValues={masterValues}
          defaultLang={defaultLang}
        />
      );
      extendedSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    case "Complex":
      searchComponent = <SearchCriteriasComplex defaultLang={defaultLang} />;
      complexSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Complex Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + criteria.searchMode);
      break;
  }

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <SearchOutlined />
        </CardIcon>
        <div className={blockHeaderSearchClasses.join(" ")}>
          <div className="blockHeaderSearchItem">
            {searchModes.length > 1 && (
              <ToggleButtonGroup value={criteria.searchMode} exclusive onChange={(e, mode) => setCriteria({ ...criteria, searchMode: mode })}>
                {searchModes.map(m => {
                  switch (m) {
                    case "Simple":
                      return (
                        <ToggleButton value="Simple">
                          <ViewDay />
                        </ToggleButton>
                      );
                    case "Extend":
                      return (
                        <ToggleButton value="Extend">
                          <ViewHeadline />
                        </ToggleButton>
                      );
                    case "Complex":
                      return (
                        <ToggleButton value="Complex">
                          <ViewQuilt />
                        </ToggleButton>
                      );
                    default:
                      return <></>;
                  }
                })}
              </ToggleButtonGroup>
            )}
          </div>
          <div className="blockHeaderSearchItem">
            <Typography ariant="display1">{searchTitle}</Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody>{searchComponent}</CardBody>
    </Card>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriterias);
