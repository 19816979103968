import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function MasterValueCrupdate(type, data, callbackFn, errorFn) {
  ApimPost("api/MasterValue/" + type, data, c => callbackFn(c), e => errorFn(e));
}

export function MasterValueCrupdateLanguage(type, code, data, callbackFn, errorFn) {
  ApimPost("api/MasterValue/Languages/" + type + "/" + code, data, c => callbackFn(c), e => errorFn(e));
}

export function MasterValueGetSupportedTypes(type, callbackFn, errorFn) {
  return ApimGet("/api/MasterValue/supported", c => callbackFn(c), e => errorFn(e));
}

export function MasterValueGetAll(type, callbackFn, errorFn) {
  return ApimGet("api/MasterValue/" + type + "/admin", c => callbackFn(c), e => errorFn(e));
}

export function MasterValueGetSpecs(type, callbackFn, errorFn) {
  return ApimGet("api/MasterValue/" + type + "/specs", c => callbackFn(c), e => errorFn(e));
}
