import { Trans } from "@lingui/macro";
import { Avatar, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, Paper, Tooltip, withStyles } from "@material-ui/core";
import { Assignment, GetApp, LockOpen, Lock, Error, Done, Warning } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";

function SearchResultTable({ results, openProduct, exportProduct, maxExport }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  if (!results) {
    return "";
  }

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  var pageSize = 10;
  if (getPref !== null) {
    pageSize = getPref.Pref.ResultPageSize * 1;
  }

  var items = results.map(r => {
    return {
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          {/*onClick={() => openStructure(r)}*/}
          <IconButton onClick={() => openProduct(r)} color="info" className="like">
            <Assignment />
          </IconButton>
        </div>
      ),
      id: r.identifier,
      code: r.code,
      ueIdentifier: r.ueIdentifier,
      commercialLabel: r.commercialLabel,
      segmentationLevel3: r.segmentationLevel3Identifier,
      segmentationLevel4: r.segmentationLevel4Identifier,
      isActive: r.isActive
    };
  });

  const reactTableColumns = [
    {
      Header: x => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={selectAll}
                onChange={e => {
                  var isSelectAll = e.currentTarget.checked;
                  setSelectAll(isSelectAll);
                  setSelectedIds(isSelectAll ? results.map(tp => tp.identifier) : []);
                }}
              />
            }
          />
        );
      },
      accessor: "CheckBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.id;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id === identifier)}
              onChange={() =>
                setSelectedIds(selectedIds.some(id => id === identifier) ? selectedIds.filter(id => id !== identifier) : [...selectedIds, identifier])
              }
            />
          );
        } else return <FormControlLabel disabled control={<Checkbox value="" />} />;
      }
    },
    {
      accessor: "actions",
      sortable: false,
      width: 50,
      filterable: false
    },
    {
      Header: <Trans>Identifiant</Trans>,
      accessor: "id",
      Cell: rowInfo => {
        var statusIcon = !!rowInfo.original.isActive ? <LockOpen style={{ color: "green" }} /> : <Lock style={{ color: "red" }} />;
        return (
          <div>
            {statusIcon}
            {rowInfo.original.id}
          </div>
        );
      }
    },
    {
      Header: <Trans>Code</Trans>,
      accessor: "code"
    },
    {
      Header: <Trans>Commercial_Label</Trans>,
      accessor: "commercialLabel"
    },
    {
      Header: <Trans>Segmentation_Level_3</Trans>,
      accessor: "segmentationLevel3"
    },
    {
      Header: <Trans>Segmentation_Level_4</Trans>,
      accessor: "segmentationLevel4"
    }
  ];

  var paper = <></>;
  if (selectedIds.length > 0) {
    paper = <ExportButton ids={selectedIds} exportProduct={exportProduct} maxExport={maxExport} />;
  }

  return (
    <>
      <Paper>{paper}</Paper>
      <ReactTable
        //manual
        data={items}
        //pages={items.nbOfPages}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    </>
  );
}

const ExportButton = ({ ids, exportProduct, maxExport }) => {
  const [state, setState] = useState({ status: "idle", error: null });

  useEffect(() => setState({ status: ids.length > maxExport ? "exportLimited" : "idle", error: null }), [ids]);

  let title, icon, textColor, avatarColor;
  switch (state.status) {
    case "download":
      title = <Trans>Download_inProgress</Trans>;
      icon = <CircularProgress size={20} />;
      textColor = "#999";
      avatarColor = "#FFF";
      break;
    case "downloadDone":
      title = <Trans>Download_done</Trans>;
      icon = <Done />;
      textColor = "#00CC00";
      avatarColor = "#00CC00";
      break;
    case "error":
      title = state.error;
      icon = <Error />;
      textColor = "#CC0000";
      avatarColor = "#CC0000";
      break;
    case "exportLimited":
      title = (
        <>
          <Trans>Structure_Export</Trans> (<Trans>Export Limited</Trans> {maxExport})
        </>
      );
      icon = <Warning />;
      textColor = "orange";
      avatarColor = "orange";
      break;
    default:
      title = <Trans>Structure_Export</Trans>;
      icon = <GetApp />;
      textColor = "#999";
      avatarColor = "#999";
      break;
  }

  return (
    <Tooltip title={title}>
      <Chip
        // key={"export_" + type}
        avatar={<Avatar style={{ backgroundColor: avatarColor, color: "#FFF" }}>{icon}</Avatar>}
        label={ids.length + " "}
        variant="outlined"
        onClick={() => {
          setState({ status: "download" });
          exportProduct(ids, () => setState({ status: "downloadDone" }), error => setState({ status: "error", error: error }));
        }}
        style={{ borderColor: textColor, color: textColor }}
      />
    </Tooltip>
  );
};

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResultTable);
