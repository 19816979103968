import { Trans } from "@lingui/macro";
import { IconButton, withStyles } from "@material-ui/core";
import { Assignment, LockOpen, Lock } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React from "react";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";

function SearchResultTable({ results, openCompany }) {
  if (!results) {
    return <Trans>NoResult</Trans>;
  }

  var pageSize = 10;
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    pageSize = getPref.Pref.ResultPageSize * 1;
  }

  var items = results.map(r => {
    return {
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <IconButton onClick={() => openCompany(r)} color="info" className="like">
            <Assignment />
          </IconButton>
        </div>
      ),
      Identifier: r.identifier,
      Status: r.companyStatusCode,
      LongName: r.longName,
      LegalIdentifier: r.legalIdentifier,
      Address_Line1: r.address_Line1,
      Address_Line2: r.address_Line2,
      Address_ZipCode: r.address_ZipCode,
      Address_City: r.address_City,
      Address_CountryCode: r.address_CountryCode
    };
  });

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Status</Trans>,
      accessor: "Status",
      Cell: rowInfo => {
        var statusIcon = <LockOpen style={{ color: "green" }} />;
        if (rowInfo.original.Status !== null && rowInfo.original.Status === "Closed") statusIcon = <Lock style={{ color: "red" }} />;

        return <div>{statusIcon}</div>;
      }
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "Identifier"
    },
    {
      Header: <Trans>Long Name</Trans>,
      accessor: "LongName"
    },
    {
      Header: <Trans>Legal Identifier</Trans>,
      accessor: "LegalIdentifier"
    },
    {
      Header: <Trans>Address Line 1</Trans>,
      accessor: "Address_Line1"
    },
    {
      Header: <Trans>Address Line 2</Trans>,
      accessor: "Address_Line2"
    },
    {
      Header: <Trans>City</Trans>,
      accessor: "Address_City"
    },
    {
      Header: <Trans>ZipCode</Trans>,
      accessor: "Address_ZipCode"
    },
    {
      Header: <Trans>Country</Trans>,
      accessor: "Address_CountryCode"
    }
  ];

  return (
    <ReactTable
      data={items}
      columns={reactTableColumns}
      defaultPageSize={pageSize}
      showPaginationBottom={true}
      className="-striped -highlight"
      style={ReactTableStyle.main}
    />
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(SearchResultTable);
