import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as ThirdPartyActions from "module/tpCopernic/store/actions";
import { isArrayNullOrEmpty } from "tools";

import ReactTable from "react-table-6";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, CircularProgress } from "@material-ui/core";
import { Assignment, Lock, LockOpen } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import { GetCompanyThirdParties } from "../../actions/CompanyActions";
import CardError from "components/Card/CardError";

const TabCompanyThirdParties = function({ company, openThirdParty }) {
  const [state, setState] = useState({ isLoading: false, thirdParties: [], error: null });

  useEffect(() => {
    let id = company?.identifier;
    if (id) {
      setState({ isLoading: true, thirdParties: [] });
      GetCompanyThirdParties(
        id,
        h => setState({ isLoading: false, thirdParties: h }),
        e => setState({ isLoading: false, thirdParties: [], error: e })
      );
    }
  }, []);

  if (state.isLoading) {
    return <CircularProgress />;
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans> Companies </Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={convertDataToReactTable(state.thirdParties, openThirdParty)}
              columns={reactTableColumns}
              defaultPageSize={5}
              showPaginationBottom={true}
              showPageSizeOptions={false}
              className="-striped -highlight"
              showFilters={true}
              style={ReactTableStyle.main}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

///------------------------------
/// Crée le contenu (les lignes) de la table-react affichant les thirdParties associés
///------------------------------
function convertDataToReactTable(thirdParties, openThirdParty) {
  if (isArrayNullOrEmpty(thirdParties)) return [];

  return thirdParties.map(prop => {
    return {
      Identifier: prop.identifier,
      Status: prop.thirdPartyStatusCode,
      LongName: prop.longName,
      LegalIdentifier: prop.legalIdentifier,
      Address_Line1: prop.address_Line1,
      Address_Line2: prop.address_Line2,
      Address_ZipCode: prop.address_ZipCode,
      Address_City: prop.address_City,
      Address_CountryCode: prop.address_CountryCode,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            id="CompanyThirdParties"
            justIcon
            round
            simple
            onClick={() => {
              let item = thirdParties.find(
                o =>
                  (o.identifier !== 0 && o.identifier === prop.identifier) ||
                  (o.legalIdentifier !== null &&
                    o.legalIdentifier !== undefined &&
                    o.legalIdentifier !== "" &&
                    o.legalIdentifier === prop.legalIdentifier) ||
                  (o.duns !== null && o.duns !== undefined && o.duns !== "" && o.duns === prop.duns)
              );
              openThirdParty(item.identifier);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>{" "}
        </div>
      )
    };
  });
}

const reactTableColumns = [
  {
    accessor: "actions",
    width: 50,
    sortable: false,
    filterable: false
  },
  {
    Header: <Trans>Status</Trans>,
    accessor: "Status",
    Cell: rowInfo => {
      var statusIcon = <LockOpen style={{ color: "green" }} />;
      if (rowInfo.original.Status !== null && rowInfo.original.Status === "Closed") statusIcon = <Lock style={{ color: "red" }} />;

      return <div>{statusIcon}</div>;
    }
  },
  {
    Header: <Trans>Id</Trans>,
    accessor: "Identifier"
  },
  {
    Header: <Trans>Long Name</Trans>,
    accessor: "LongName"
  },
  {
    Header: <Trans>Legal Identifier</Trans>,
    accessor: "LegalIdentifier"
  },
  {
    Header: <Trans>Address Line 1</Trans>,
    accessor: "Address_Line1"
  },
  {
    Header: <Trans>Address Line 2</Trans>,
    accessor: "Address_Line2"
  },
  {
    Header: <Trans>City</Trans>,
    accessor: "Address_City"
  },
  {
    Header: <Trans>ZipCode</Trans>,
    accessor: "Address_ZipCode"
  },
  {
    Header: <Trans>Country</Trans>,
    accessor: "Address_CountryCode"
  }
];
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(ThirdPartyActions.OpenThirdParty(thirdPartyId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabCompanyThirdParties));
