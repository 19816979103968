import { Trans } from "@lingui/macro";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import FieldText from "components/Common/FieldText";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { isNull, nullIfEmpty } from "tools";

const DialogCustomerSj = function({ openDialog, setOpenDialog, customerSjIndex, availableSjs, sjs, isEditable, defaultLang }) {
  if (isNull(customerSjIndex) || isNaN(customerSjIndex)) return <div />;

  //var detail = availableSjs[customerSjIndex].sj;
  // const isEditableSj = customerCtx.isEditableSj && (!thirdPartyClosed || customerCtx.isEditableClosed);

  const isEditableSj = isEditable;

  var sjLabel, sjOldIdentifier, sjNewIdentifier;
  var knownSj = availableSjs.find(s => s.identifier === customerSjIndex);
  if (!!knownSj) {
    sjLabel = knownSj.label;
    sjOldIdentifier = knownSj.oldIdentifier;
    sjNewIdentifier = knownSj.newIdentifier;
  }

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        <Trans>Detail</Trans>
      </DialogTitle>
      <DialogContent>
        <div>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Sj</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={false}
                id="sjCodeDetail"
                value={knownSj.identifier}
                //onChange={e => onSjValueChanged({ ...detail, sjCode: nullIfEmpty(e.target.value) })}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Label</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <Typography>{sjLabel}</Typography>
            </GridItem>
            {/* <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Code Iris3</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditableSj}
                id="iris3Detail"
                value={detail.aN8Iris3}
                onChange={e => onSjValueChanged({ ...detail, aN8Iris3: nullIfEmpty(e.target.value) })}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Code Iris2</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditableSj}
                id="iris2Detail"
                value={detail.aN8Iris2}
                onChange={e => onSjValueChanged({ ...detail, aN8Iris2: nullIfEmpty(e.target.value) })}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Code Non Iris</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditableSj}
                id="nonIrisDetail"
                value={detail.nonIrisId}
                onChange={e => onSjValueChanged({ ...detail, nonIrisId: nullIfEmpty(e.target.value) })}
              />
            </GridItem> 
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Cookie</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditableSj}
                id="cookieDetail"
                value={detail.cookie}
                onChange={e => onSjValueChanged({ ...detail, cookie: nullIfEmpty(e.target.value) })}
              />
            </GridItem>*/}
            {/* <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Old Identifier</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <Typography>{sjOldIdentifier}</Typography>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>New Identifier</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <Typography>{sjNewIdentifier}</Typography>
            </GridItem> */}
          </GridContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="info">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(thirdPartySearchCriteriaStyle)(DialogCustomerSj);
