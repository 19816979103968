import { Trans } from "@lingui/macro";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import ProfileDelegationSearchCriteria from "./part/ProfileDelegationSearchCriteria";
import { USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL } from "./store/types";
import * as Actions from "./store/ProfileDelegation.Actions";
import ProfileDelegationDetail from "./ProfileDelegationDetail";

function ProfileDelegations({ delegationCtx, getDetail, currentUserProfiles ,classes }) {
  const dispatch = useDispatch();

  const [criterias, setCriterias] = useState(delegationCtx.criterias);
  const [item, setItem] = useState(delegationCtx.item);

  let result = delegationCtx.result;

  const reactTableColumns = [
    {
      filterable: false,
      sorting: false,
      accessor: "actions",
      width: 50
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "id"
    },
    {
      Header: <Trans>Profile Code</Trans>,
      accessor: "codeProfile"
    },
    {
      Header: <Trans>Delegator</Trans>,
      accessor: "delegatorId"
    },
    {
      Header: <Trans>Delegate</Trans>,
      accessor: "delegateId"
    },
    {
      Header: <Trans>Date start</Trans>,
      accessor: "dateStart",
      Cell: rowInfo => format(parseISO(rowInfo.original.dateStart), "yyyy-MM-dd") 
    },
    {
      Header: <Trans>Date end</Trans>,
      accessor: "dateEnd",
      Cell: rowInfo => format(parseISO(rowInfo.original.dateEnd), "yyyy-MM-dd") 
    }
  ];

  const newDelegationButton = (
    <GridContainer>
      <GridItem xs={6} sm={6} md={6}>
        <Button variant="contained" size="sm" onClick={() => {setItem(null); getDetail({}, true)}} color="info" hidden={currentUserProfiles && currentUserProfiles.length ===0}>
          <Add />
          <Trans>New Workflow Delegation</Trans>
        </Button>
      </GridItem>
    </GridContainer>
  );

  let reactTable = "";
  if (!!result) {
    let items = ConvertDatas(result.items, getDetail);

    reactTable = (
      <Card>
        <CardBody>
          <ReactTable
            manual
            data={items}
            page={result.pageIndex}
            pages={result.nbOfPages}
            columns={reactTableColumns}
            sorted={[{ id: delegationCtx.criterias.orderProperty, desc: delegationCtx.criterias.isDescendingOrder }]}
            onPageChange={(state, instance) => {
              let tempCriteria = delegationCtx.criterias;
              tempCriteria.startPage = state;
              setCriterias({ ...delegationCtx.criterias, startPage: state });
              dispatch(Actions.Search(tempCriteria));
            }}
            onPageSizeChange={(state, instance) => {
              let tempCriteria = delegationCtx.criterias;
              tempCriteria.pageSize = state;
              setCriterias({ ...delegationCtx.criterias, pageSize: state });
              dispatch(Actions.Search(tempCriteria));
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              let tempCriteria = delegationCtx.criterias;
              tempCriteria.orderProperty = newSorted[0].id;
              tempCriteria.isDescendingOrder = newSorted[0].desc;
              setCriterias({ ...delegationCtx.criterias, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
              dispatch(Actions.Search(tempCriteria));
            }}
            defaultPageSize={result.pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      {<ProfileDelegationSearchCriteria />}
      {newDelegationButton}
      {reactTable}
      {<ProfileDelegationDetail currentUserProfiles={currentUserProfiles} setItem={setItem} />}
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(items, getDetail) {
  if (!items || items.length === 0) return [];

  let result = [];
  items.map((prop, key) => {
    result.push({
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              getDetail(prop, false);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>
        </div>
      ),
      ...prop
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    delegationCtx: state.UR_ProfileDelegation,
    currentUserProfiles: state.AuthenticationReducer.user.profiles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDetail: (item, isNew) => {
      dispatch({
        type: USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL,
        item: item,
        isNew: isNew
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ProfileDelegations));