import { Trans } from "@lingui/macro";
import { ArrowForward, Warning } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import DialogBox from "components/DialogBox/DialogBox";
import Wizard from "components/Wizard/Wizard";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import {
  CommonCountry,
  CommonTimeZone,
  JobActivityLevel2,
  LoadMasterValues,
  LocationCharacteristicType,
  LocationCharacteristicValue,
  LocationContactType,
  CommonCountryState
} from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import SiteStep1General from "../components/WizardSteps/SiteStep1General";
import SiteStep2Type from "../components/WizardSteps/SiteStep2Type";
import SiteStep3Contact from "../components/WizardSteps/SiteStep3Contact";
import SiteStep4Structure from "../components/WizardSteps/SiteStep4Structure";
import SiteStep5Caracteristique from "../components/WizardSteps/SiteStep5Caracteristique";
import * as Actions from "../store/actions";
import { ValidateGeneral, ValidateStructures, ValidateType } from "../store/actions/Site.Create";
import { SiteCreate } from "../actions/SiteActions";
import { OpenWorkflow } from "module/workflow/store/actions";
import { IconButton, Link, Tooltip } from "@material-ui/core";

function SiteWizard({ openSite, handleClose, defaultLang, needApprobation, masterValues, showCard, showStruct, setLocation, openWorkflow }) {
  const initialState = { activities: [], contacts: [], structures: [], characteristics: [], gpsCoordinates: { latitude: null, longitude: null } };
  var [hasChanged, setHasChanged] = useState(false);
  var [site, setSite] = useState(initialState);
  var [errors, setErrors] = useState([]);
  var [dialogBox, setDialogBox] = useState(null);
  const [isMvLoaded, setIsMvLoaded] = useState("init");

  useEffect(() => {
    if (isMvLoaded === "init") {
      setIsMvLoaded("true");
      LoadMasterValues([
        CommonTimeZone,
        CommonCountryState,
        LocationContactType,
        JobActivityLevel2,
        LocationCharacteristicType,
        LocationCharacteristicValue
      ]).then(() => setIsMvLoaded(null));
    }
  }, [isMvLoaded]);

  useEffect(() => {
    if (isNullOrEmpty(site.criteriaCountry)) {
      var defaultCountry = "FR";
      var localStorageHelper = new LocalStorageHelper();
      var getPref = localStorageHelper.get("Preferences");
      if (getPref !== null) {
        defaultCountry = getPref.InfoGeneral.Country;
      }

      setSite({ ...site, address_CountryCode: defaultCountry });
    }
  }, defaultLang);

  function handleSetSite(s) {
    setSite(s);
    setHasChanged(s !== initialState);
  }

  var steps = [
    {
      title: <Trans>Site_Create_General</Trans>,
      content: () => <SiteStep1General site={site} setSite={handleSetSite} errors={errors} defaultLang={defaultLang} />,
      validate: force => validateStep(1, () => ValidateGeneral(site, force))
    },
    {
      title: <Trans>Site_Create_Type</Trans>,
      content: () => <SiteStep2Type site={site} setSite={handleSetSite} errors={errors} defaultLang={defaultLang} />,
      validate: () => validateStep(2, () => ValidateType(site))
    },
    {
      title: <Trans>Site_Create_Contact</Trans>,
      content: () => <SiteStep3Contact site={site} setSite={handleSetSite} errors={errors} masterValues={masterValues} defaultLang={defaultLang} />
    },
    {
      title: <Trans>Site_Create_Structure</Trans>,
      content: () => <SiteStep4Structure site={site} setSite={handleSetSite} errors={errors} defaultLang={defaultLang} />,
      validate: () => validateStep(4, () => ValidateStructures(site)),
      hidden: !showStruct
    },
    {
      title: <Trans>Site_Create_Caracteristique</Trans>,
      content: () => (
        <SiteStep5Caracteristique site={site} setSite={handleSetSite} errors={errors} defaultLang={defaultLang} masterValues={masterValues} />
      )
    }
  ];

  function validateStep(stepId, validateFn) {
    return new Promise(resolve => {
      validateFn(site).then(result => {
        setSite(result.site);
        setErrors([...errors.filter(e => e.step !== stepId), ...result.errors]);
        resolve(result.errors.length === 0);
      });
    });
  }

  function handleSave(confirmSave, comment) {
    if (needApprobation && !confirmSave) {
      setDialogBox({
        type: "okCancel",
        message: <Trans> WF_ApprobationWillSent </Trans>,
        textbox: { title: <Trans> WF_AuthorComment </Trans>, rows: 2 },
        ok: text => {
          handleSave(true, text);
          setDialogBox(null);
        }
      });
      return;
    }

    // assign country default timezone
    let country = masterValues[CommonCountry].find(c => c.codeIso2 === site.address_CountryCode);
    let states = masterValues[CommonCountryState];
    let timeZoneCode = null;
    if (country) {
      if (site.address_StateCode) {
        let countryState = states.find(s => s.code === site.address_StateCode && s.countryCode === site.address_CountryCode);
        if (countryState) {
          timeZoneCode = countryState.timeZone;
        }
      }
      if (!timeZoneCode) {
        let timeZone = masterValues[CommonTimeZone].find(tz => tz.countryCode === site.address_CountryCode && tz.isCountryMain);
        if (timeZone) timeZoneCode = timeZone.code;
      }
    }
    if (timeZoneCode) {
      site = { ...site, timeZoneCode: timeZoneCode };
    }

    SiteCreate(
      { location: site, wkfComment: comment },
      newItem => {
        if ((newItem?.id ?? 0) === 0 && (newItem?.workflowLockId ?? 0) === 0) {
          console.log("API has returned ID = 0");
          return;
        }

        if (needApprobation) {
          setDialogBox({
            type: "ok",
            message: <Trans> WF_ApprobationWait </Trans>,
            ok: () => {
              setDialogBox(null);
              handleClose();
              openWorkflow("LocationCrupdate", newItem.workflowLockId);
            }
          });
          return;
        }
        if (!showStruct) {
          setLocation(newItem);
          handleClose();
        } else if (!isNullOrEmpty(newItem.id)) {
          handleClose();
          openSite(newItem.id);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  var cardWarning;
  if (!isArrayNullOrEmpty(errors)) {
    var errorMessage = errors.map((t, idx) => {
      if (t.code === "StructureMissing") {
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "red" }} />
              <span style={{ verticalAlign: "middle", color: "red" }}>
                <strong>
                  <Trans>Site Wizard Structure Missing</Trans>
                </strong>
              </span>
            </div>
          </div>
        );
      }
      if (t.code === "ExistingLocationNearby") {
        var links = generateLinkForExistingLocation(t.complexLinkedObject, handleClose, openSite);
        return (
          <div key={idx}>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>
                  <Trans>Site Wizard ExistNearBy</Trans>
                </strong>
              </span>
            </div>
            <ol>
              <Trans>Site Wizard ChooseOrContinue</Trans>
              {links}
            </ol>
          </div>
        );
      }
    });

    if (errorMessage.length > 0) {
      cardWarning = (
        <Card>
          <CardBody>{errorMessage}</CardBody>
        </Card>
      );
    }
  }

  if (!!isMvLoaded) {
    return <DialogBox dialogBox={{ type: "loading" }} />;
  }

  return (
    <>
      <Wizard
        title={<Trans>Site_Create_Title</Trans>}
        steps={steps}
        handleClose={handleClose}
        checkClose={() => !hasChanged}
        handleSave={handleSave}
        cardWarning={cardWarning}
        showCard={showCard}
      />
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

function generateLinkForExistingLocation(locations, handleClose, openLocation) {
  var links = locations.map(item => {
    return (
      <ul>
        <Tooltip
          placement="right"
          title={
            <>
              {item.address_Line1}
              <br />
              {item.address_ZipCode}
              <br />
              {item.address_City}
              <br />
              {item.address_CountryCode}
            </>
          }
        >
          <Link
            style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
            onClick={event => {
              handleClose();
              openLocation(item.id);
            }}
          >
            <IconButton
              style={{
                color: "orange"
              }}
            >
              <ArrowForward />
            </IconButton>
            <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>{item.name}</span>
          </Link>
        </Tooltip>
      </ul>
    );
  });
  return links;
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openSite: siteId => {
      dispatch(Actions.OpenSite(siteId));
    },
    openWorkflow: (workflowTypeCode, workflowId) => {
      dispatch(OpenWorkflow(workflowTypeCode, workflowId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteWizard);
