import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell, Button, IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import * as StructureActions from "module/structure/store/actions";
import { formatDate } from "tools";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import { Add, Close, Save, Edit, DeleteOutline } from "@material-ui/icons";
import { isNullOrEmpty } from "../../../../../tools";
import DateSelector from "../../../../../components/Common/DateSelector";
import { OperationType } from "store/MasterValue/MasterValueTypes";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import HeaderField from "./HeaderField";

const TabJournal = ({ structure, setStructure, isEditable, defaultLang, errors, masterValues }) => {
  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";
  const dispatch = useDispatch();
  const [journal, setjournal] = useState(null);
  const [journalCreate, setJournalCreate] = useState(null);
  const [journalEdit, setJournalEdit] = useState(null);

  useEffect(() => {
    setjournal(structure.journal);
  }, [structure]);

  const listOperationType = masterValues[OperationType];

  let creationRow = <></>;
  if (journalCreate) {
    creationRow = (
      <TableRow>
        <TableCell>
          <StructureAutocomplete
            isEditable
            structureTypes={["ERT", "SJ", "UE", "UP"]}
            value={journalCreate.structureOriginId}
            onChange={v => {
              v &&
                setJournalCreate({
                  ...journalCreate,
                  structureOriginId: v.id,
                  structureOrigin: {
                    identifiant: v.identifiant,
                    libelle: v.libelle
                  }
                });
            }}
            defaultLang={defaultLang}
            size="small"
            label={<Trans>Structure_Journal_Origin</Trans>}
            error={journalCreate.error}
          />
        </TableCell>
        <TableCell>
          <StructureAutocomplete
            isEditable
            structureTypes={["ERT", "SJ", "UE", "UP"]}
            value={journalCreate.structureCibleId}
            onChange={v => {
              v &&
                setJournalCreate({
                  ...journalCreate,
                  structureCibleId: v.id,
                  structureCible: {
                    identifiant: v.identifiant,
                    libelle: v.libelle
                  }
                });
            }}
            defaultLang={defaultLang}
            size="small"
            label={<Trans>Structure_Journal_Cible</Trans>}
            error={journalCreate.error}
          />
        </TableCell>
        <TableCell>
          <DateSelector
            value={journalCreate.operationDate}
            label={<Trans>Structure_DateStart</Trans>}
            onChange={d => setJournalCreate({ ...journalCreate, operationDate: d })}
            isEditable
            defaultLang={defaultLang}
          />
        </TableCell>
        <TableCell>
          <MasterValueAutocomplete
            isEditable={isEditable}
            mvType={OperationType}
            selectedValue={journalCreate.operationTypeCode}
            onValueChange={v => setJournalCreate({ ...journalCreate, operationTypeCode: v })}
            size="small"
          />
        </TableCell>
        <TableCell>
          <HeaderField
            isEditable={isEditable}
            value={journalCreate.commentaire}
            onChange={e => setJournalCreate({ ...journalCreate, commentaire: e.target.value })}
            label={<Trans>Structure_Journal_Comment</Trans>}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              if (isNullOrEmpty(journalCreate.structureOriginId) || isNullOrEmpty(journalCreate.structureCibleId)) setJournalCreate({ error: true });
              else {
                setStructure({
                  ...structure,
                  journal: [
                    ...structure.journal,
                    {
                      operationDate: journalCreate.operationDate,
                      structureOriginId: journalCreate.structureOriginId,
                      structureOrigin: {
                        identifiant: journalCreate.structureOrigin.identifiant,
                        structureTypeCode: journalCreate.structureOrigin.structureTypeCode,
                        libelle: journalCreate.structureOrigin.libelle
                      },
                      structureCibleId: journalCreate.structureCibleId,
                      structureCible: {
                        identifiant: journalCreate.structureCible.identifiant,
                        structureTypeCode: journalCreate.structureCible.structureTypeCode,
                        libelle: journalCreate.structureCible.libelle
                      },
                      operationTypeCode: journalCreate.operationTypeCode,
                      commentaire: journalCreate.commentaire
                    }
                  ]
                });
              }
              setJournalCreate(null);
            }}
          >
            <Save />
          </IconButton>
          <IconButton onClick={() => setJournalCreate(null)}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  let btnAdd = <></>;
  if (isEditable) {
    btnAdd = (
      <Button
        variant="contained"
        startIcon={<Add />}
        size="small"
        onClick={() =>
          setJournalCreate({
            operationDate: new Date(),
            error: null
          })
        }
      >
        <Trans>Structure_Journal_Add</Trans>
      </Button>
    );
  }

  return (
    <>
      {btnAdd}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Structure_Journal_Origin</Trans>
              </TableCell>
              <TableCell>
                <Trans>Structure_Journal_Cible</Trans>
              </TableCell>
              <TableCell>
                <Trans>Structure_Journal_OperationDate</Trans>
              </TableCell>
              <TableCell>
                <Trans>Structure_Journal_OperationType</Trans>
              </TableCell>
              <TableCell>
                <Trans>Structure_Journal_Comment</Trans>
              </TableCell>
              {isEditable ? <TableCell>&nbsp;</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {creationRow}
            {journal &&
              journal
                .sort(j => j.operationDate)
                .map((j, key) => {
                  if (journalEdit && journalEdit?.id === j.id) {
                    return (
                      <TableRow>
                        <TableCell>
                          <StructureAutocomplete
                            isEditable
                            structureTypes={["ERT", "SJ", "UE", "UP"]}
                            value={journalEdit.structureOriginId}
                            onChange={v => {
                              v &&
                                setJournalEdit({
                                  ...journalEdit,
                                  structureOriginId: v.id,
                                  structureOrigin: {
                                    identifiant: v.identifiant,
                                    structureTypeCode: v.structureTypeCode,
                                    libelle: v.libelle
                                  }
                                });
                            }}
                            defaultLang={defaultLang}
                            size="small"
                            label={<Trans>Structure_Journal_Origin</Trans>}
                          />
                        </TableCell>
                        <TableCell>
                          <StructureAutocomplete
                            isEditable
                            structureTypes={["ERT", "SJ", "UE", "UP"]}
                            value={journalEdit.structureCible.id}
                            onChange={v => {
                              v &&
                                setJournalEdit({
                                  ...journalEdit,
                                  structureCibleId: v.id,
                                  structureCible: {
                                    identifiant: v.identifiant,
                                    structureTypeCode: v.structureTypeCode,
                                    libelle: v.libelle
                                  }
                                });
                            }}
                            defaultLang={defaultLang}
                            size="small"
                            label={<Trans>Structure_Journal_Cible</Trans>}
                          />
                        </TableCell>
                        <TableCell>
                          <DateSelector
                            value={journalEdit.operationDate}
                            label={<Trans>Structure_DateStart</Trans>}
                            onChange={d => setJournalEdit({ ...journalEdit, operationDate: d })}
                            isEditable
                            defaultLang={defaultLang}
                          />
                        </TableCell>
                        <TableCell>
                          <MasterValueAutocomplete
                            isEditable={isEditable}
                            mvType={OperationType}
                            selectedValue={journalEdit.operationTypeCode}
                            onValueChange={v => setJournalEdit({ ...journalEdit, operationTypeCode: v })}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <HeaderField
                            isEditable={isEditable}
                            value={journalEdit.commentaire}
                            onChange={e => setJournalEdit({ ...journalEdit, commentaire: e.target.value })}
                            label={<Trans>Structure_Journal_Comment</Trans>}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setStructure({ ...structure, journal: [...journal.filter(jr => jr.id !== journalEdit.id), journalEdit] });
                              setJournalEdit(null);
                            }}
                          >
                            <Save />
                          </IconButton>
                          <IconButton onClick={() => setJournalEdit(null)}>
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  if (j !== undefined)
                    return (
                      <TableRow>
                        <TableCell>
                          <Button
                            onClick={() =>
                              dispatch(StructureActions.OpenStructure(j.structureOrigin.structureTypeCode, j.structureOrigin.identifiant))
                            }
                          >
                            {j.structureOrigin.identifiant} - {j.structureOrigin.libelle}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => dispatch(StructureActions.OpenStructure(j.structureCible.structureTypeCode, j.structureCible.identifiant))}
                          >
                            {j.structureCible.identifiant} - {j.structureCible.libelle}
                          </Button>
                        </TableCell>
                        <TableCell>{formatDate(j.operationDate, defaultLang)}</TableCell>
                        <TableCell>
                          {j.operationTypeCode &&
                            (defaultLang === "FR"
                              ? masterValues[OperationType].find(t => t.code === j.operationTypeCode)?.labels.fr
                              : masterValues[OperationType].find(t => t.code === j.operationTypeCode)?.labels.en)}
                        </TableCell>
                        <TableCell>{j.commentaire}</TableCell>
                        <TableCell>
                          {!isEditable ? (
                            <></>
                          ) : (
                            <>
                              <IconButton
                                onClick={() => {
                                  setJournalEdit(j);
                                }}
                              >
                                <Edit />
                              </IconButton>
                              {journal && journal.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    setStructure({ ...structure, journal: journal.filter(jr => jr.id !== j.id) });
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(structureStyle)(TabJournal);
