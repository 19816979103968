import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "tools";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";
import { CircularProgress } from "@material-ui/core";
import { GetCompanyHistory } from "../../actions/CompanyActions";

const TabCompanyHistory = function({ company, defaultLang }) {
  const [state, setState] = useState({ isLoading: false });

  useEffect(() => {
    let id = company?.identifier;
    if (id) {
      setState({ isLoading: true, history: [] });
      GetCompanyHistory(id, h => setState({ isLoading: false, history: h }), e => setState({ isLoading: false, history: [], error: e }));
    }
  }, []);

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer items={state.history} defaultLang={defaultLang} />;
};

const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabCompanyHistory));
