import * as Actions from "module/amc/store/actions/Worker.Actions";
import { isNull } from "module/tools";
import React from "react";
import { connect, useDispatch } from "react-redux";

import { FormLabel } from "@material-ui/core";
import { CheckCircleOutlined, ErrorOutlined, RefreshOutlined } from "@material-ui/icons";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import FieldText from "../../../../components/Common/FieldText";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

function WorkerDetail({ amc, isReadonly, itemChanged }) {
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var item = amc.currentItem["WORKER"];

  return (
    <div style={{ width: "99%" }}>
      <GridContainer>
        <GridItem xs={4}>
          <FormLabel>
            <Trans>Login</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8}>
          <FieldText isEditable={!isReadonly} id="login" value={item.login} onChange={e => itemChanged({ ...item, login: e.target.value })} />
        </GridItem>
        <GridItem xs={4}>
          <FormLabel>
            <Trans>First name</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8}>
          <FieldText
            isEditable={!isReadonly}
            id="firstName"
            value={item.firstName}
            onChange={e => itemChanged({ ...item, firstName: e.target.value })}
          />
        </GridItem>
        <GridItem xs={4}>
          <FormLabel>
            <Trans>Last name</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8}>
          <FieldText
            isEditable={!isReadonly}
            id="lastName"
            value={item.lastName}
            onChange={e => itemChanged({ ...item, lastName: e.target.value })}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

function GetSaveStatusIcon(saveStatus) {
  if (!isNull(saveStatus)) {
    switch (saveStatus) {
      case "PENDING":
        return <RefreshOutlined />;
      case "ERROR":
        return <ErrorOutlined style={{ color: "red" }} />;
      case "SUCCESS_RELOAD":
      case "SUCCESS":
        return <CheckCircleOutlined style={{ color: "green" }} />;
    }
  }
  return "";
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(WorkerDetail));
