import React from 'react';
import { connect } from 'react-redux';
import * as Actions from 'module/amc/store/actions/CustomerContract.Actions';
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from 'module/tools';

import { Tabs, Tab } from '@material-ui/core';
import { RefreshOutlined, ErrorOutlined, CheckCircleOutlined, Warning } from '@material-ui/icons';
import { TabPanel } from 'components/Common/Tabs';

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import JobGeneralTab from './jobDetails/JobGeneralTab';
import JobPhasesTab from './jobDetails/JobPhasesTab';
import JobPhasesFinalProjectionTab from './jobDetails/JobPhasesFinalProjectionTab';
import JobPhasesBudgetTab from './jobDetails/JobPhasesBudgetTab';

function JobDetail({ amc, isReadonly }) {
    const [tabIdx, setTabIdx] = React.useState(0);

    var item = amc.currentItem['JOB'];
    var itemJobHasChanged = false;
    var itemJobSaveStatus = null;
    var itemJobHasWarnings = false;
    var itemJobPhaseHasChanged = false;
    var itemJobPhaseSaveStatus = null;
    var itemJobPhaseHasWarnings = false;
    var itemJobPhaseBudgetHasChanged = false;
    var itemJobPhaseBudgetSaveStatus = null;
    var itemJobPhaseBudgetHasWarnings = false;
    var itemJobPhaseFinalProjectionHasChanged = false;
    var itemJobPhaseFinalProjectionSaveStatus = null;
    var itemJobPhaseFinalProjectionHasWarnings = false;
    if (!!amc) {
        itemJobHasChanged = amc.hasChanged['JOB'];
        itemJobSaveStatus = amc.saveStatus['JOB'];
        itemJobHasWarnings = !isArrayNullOrEmpty(amc.errors['JOB']) && amc.errors['JOB'].some(w => w.code.split('/')[0] === "JOB");
        itemJobPhaseHasChanged = amc.hasChanged['JOBPHASE'];
        itemJobPhaseSaveStatus = amc.saveStatus['JOBPHASE'];
        itemJobPhaseHasWarnings = !isArrayNullOrEmpty(amc.errors['JOBPHASE']) && amc.errors['JOBPHASE'].some(w => w.code.split('/')[0] === "JOBPHASE");
        itemJobPhaseBudgetHasChanged = amc.hasChanged['JOBPHASEBUDGET'];
        itemJobPhaseBudgetSaveStatus = amc.saveStatus['JOBPHASEBUDGET'];
        itemJobPhaseBudgetHasWarnings = !isArrayNullOrEmpty(amc.errors['JOBPHASEBUDGET']) && amc.errors['JOBPHASEBUDGET'].some(w => w.code.split('/')[0] === "JOBPHASEBUDGET");
        itemJobPhaseFinalProjectionHasChanged = amc.hasChanged['JOBPHASEFINALPROJECTION'];
        itemJobPhaseFinalProjectionSaveStatus = amc.saveStatus['JOBPHASEFINALPROJECTION'];
        itemJobPhaseFinalProjectionHasWarnings = !isArrayNullOrEmpty(amc.errors['JOBPHASEFINALPROJECTION']) && amc.errors['JOBPHASEFINALPROJECTION'].some(w => w.code.split('/')[0] === "JOBPHASEFINALPROJECTION");
    }


    var tabStatus = buildTabs();
    var tabs = tabStatus.map(tab => {
        var tabButton = <span>{tab.title}</span>;
        var tabContent = "";

        switch (tab.component) {
            case 'JobGeneralTab':
                var star = '';
                var saveStatusIcon = GetSaveStatusIcon(itemJobSaveStatus);
                var warningIcon = itemJobHasWarnings ? (<Warning style={{ 'color': 'red' }} />) : "";
                if (itemJobHasChanged) star = ' *';
                tabButton = <span>{saveStatusIcon}{warningIcon}{tab.title}{star}</span>
                tabContent = (
                    <JobGeneralTab isReadonly={isReadonly} />
                );
                break;
            case 'JobPhasesTab':
                var star = '';
                var saveStatusIcon = GetSaveStatusIcon(itemJobPhaseSaveStatus);
                var warningIcon = itemJobPhaseHasWarnings ? (<Warning style={{ 'color': 'red' }} />) : "";
                if (itemJobPhaseHasChanged) star = ' *';
                tabButton = <span>{saveStatusIcon}{warningIcon}{tab.title}{star}</span>
                tabContent = (
                    <JobPhasesTab isReadonly={isReadonly} />
                );
                break;
            case 'JobPhasesBudgetTab':
                var star = '';
                var saveStatusIcon = GetSaveStatusIcon(itemJobPhaseBudgetSaveStatus);
                var warningIcon = itemJobPhaseBudgetHasWarnings ? (<Warning style={{ 'color': 'red' }} />) : "";
                if (itemJobPhaseBudgetHasChanged) star = ' *';
                tabButton = <span>{saveStatusIcon}{warningIcon}{tab.title}{star}</span>
                tabContent = (
                    <JobPhasesBudgetTab isReadonly={isReadonly} />
                );
                break;
            case 'JobPhasesFinalProjection':
                var star = '';
                var saveStatusIcon = GetSaveStatusIcon(itemJobPhaseFinalProjectionSaveStatus);
                var warningIcon = itemJobPhaseFinalProjectionHasWarnings ? (<Warning style={{ 'color': 'red' }} />) : "";
                if (itemJobPhaseFinalProjectionHasChanged) star = ' *';
                tabButton = <span>{saveStatusIcon}{warningIcon}{tab.title}{star}</span>
                tabContent = (
                    <JobPhasesFinalProjectionTab isReadonly={isReadonly} />
                );
                break;
            default:
                break;
        }

        return {
            tabButton: tabButton,
            tabContent: tabContent,
            tabName: tab.component
        };
    });

    var activeTabIndex = !isNullOrEmpty(amc.activeTabName) ? tabs.findIndex(t => t.tabName === amc.activeTabName) : 0;



    return (
        <div style={{ width: '99%' }}>
            <>
                <Tabs
                    variant="scrollable"
                    value={tabIdx}
                    onChange={(e, value) => setTabIdx(value)}
                    aria-label="Tabs job"
                >
                    {tabs.map((t, idx) => <Tab key={'jobTab' + idx} label={t.tabButton} />)}
                </Tabs>
                {tabs.map((t, idx) => <TabPanel value={tabIdx} index={idx} id='jobTab'>{t.tabContent}</TabPanel>)}
            </>
        </div>
    );
}



function buildTabs() {
    return [
        {
            index: 0,
            title: <Trans>Job General</Trans>,
            component: 'JobGeneralTab',
            status: 'neutral',
            ref: React.createRef()
        },
        {
            index: 1,
            title: <Trans>Phases</Trans>,
            component: 'JobPhasesTab',
            status: 'neutral',
            ref: React.createRef()
        },
        {
            index: 2,
            title: <Trans>Phases Budget</Trans>,
            component: 'JobPhasesBudgetTab',
            status: 'neutral',
            ref: React.createRef()
        },
        {
            index: 3,
            title: <Trans>Phases Final Projection</Trans>,
            component: 'JobPhasesFinalProjection',
            status: 'neutral',
            ref: React.createRef()
        }
    ];
}


function GetSaveStatusIcon(saveStatus) {
    if (!isNull(saveStatus)) {
        switch (saveStatus) {
            case 'PENDING':
                return (<RefreshOutlined />);
            case 'ERROR':
                return (<ErrorOutlined style={{ color: 'red' }} />);
            case 'SUCCESS_RELOAD':
            case 'SUCCESS':
                return (<CheckCircleOutlined style={{ color: 'green' }} />);
        }
    }
    return '';
}

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        search: () => {
            dispatch(Actions.Search())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(JobDetail));