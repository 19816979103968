import * as Actions from './types';

export function OpenStructure(structureType, structureIdentifiant, structureToOpen) {
    return (dispatch) => {
        return dispatch({
            type: Actions.STRUCTURE_OPEN,
            structureIdentifiant,
            structureType,
            structureToOpen
        });
    };
}

export function CloseStructure(structureType, structureIdentifiant) {
    return (dispatch) => {
        return dispatch({
            type: Actions.STRUCTURE_CLOSE,
            structureType,
            structureIdentifiant
        });
    };
}