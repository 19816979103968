import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { isNullOrEmpty } from "../../../../../tools";
import { CircularProgress } from "@material-ui/core";
import CardError from "components/Card/CardError";
import HistoryContainer from "module/history/containers/HistoryContainer";
import { GetStructureHistory, GetStructureHistoryByWkfId } from "module/structure/actions/StructureActions";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Trans } from "@lingui/macro";

const TabHistory = ({ structure, defaultLang }) => {
  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";

  const [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    if (!isNullOrEmpty(structure.workflowOwnerId))
      GetStructureHistoryByWkfId(
        structure.workflowOwnerId,
        h => setState({ isLoading: false, history: h }),
        e => setState({ isLoading: false, history: [], error: e })
      );
    else
      GetStructureHistory(
        structure.structureTypeCode,
        structure.identifiant,
        h => setState({ isLoading: false, history: h }),
        e => setState({ isLoading: false, history: [], error: e })
      );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Structure_Part_History</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <HistoryContainer items={state.history} defaultLang={defaultLang} />
      </CardBody>
    </Card>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(structureStyle)(TabHistory);
