import React, { useCallback } from "react";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorOutlined from "@material-ui/icons/ErrorOutlined";
import Muted from "../Typography/Muted.jsx";

// style
import "assets/scss/material-dashboard-pro-react/react-web-tabs.css";

//Lingui
import { Trans } from "@lingui/macro";
import { createStyles, Tooltip, withStyles } from "@material-ui/core";
import { isNullOrEmpty } from "../../tools.jsx";
import { WarningOutlined } from "@material-ui/icons";

const commonStyles = theme =>
  createStyles({
    root: {},
    
    warning: {
      "& .MuiFormLabel-root.Mui-error": {
        color: "orange !important"
      },
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "orange !important"
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "orange !important"
      }
    }
  });

const FieldText = ({ id, isEditable, showErrorTooltip, label, value, error, warning, onChange, disabled, type, size, classes }) => {
  if (isNullOrEmpty(type)) type = "text";

  const handleChange = useCallback((event) => {
    onChange(event);
  }, [onChange]);

  if (isEditable) {
    let inputProps = {};
    if (error && showErrorTooltip) {
      inputProps = {
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={error}>
              <ErrorOutlined style={{ color: "red" }} />
            </Tooltip>
          </InputAdornment>
        )
      };
    } else if (warning && showErrorTooltip) {
      inputProps = {
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={warning}>
              <WarningOutlined style={{ color: "orange" }} />
            </Tooltip>
          </InputAdornment>
        )
      };
    }

    var cleanedValue = !value ? "" : value;
    if (label) {
      return (
        <FormControl fullWidth>
          <TextField
            disabled={!!disabled}
            className={warning ? classes.warning : null}
            margin={size === "small" ? "none" : "dense"}
            id={id}
            type={type}
            fullWidth
            label={label}
            value={cleanedValue}
            onChange={handleChange}
            error={error || warning}
            InputProps={inputProps}
            size={size}
          />
        </FormControl>
      );
    } else {
      return (
        <TextField
          disabled={!!disabled}
          className={warning ? classes.warning : null}
          margin={size === "small" ? "none" : "dense"}
          id={id}
          type={type}
          fullWidth
          value={cleanedValue}
          onChange={handleChange}
          error={error || warning}
          InputProps={inputProps}
          size={size}
        />
      );
    }
  } else {
    let text = isNullOrEmpty(value) ? (
      <em>
        <Trans>Empty</Trans>
      </em>
    ) : (
      value
    );
    if (error && showErrorTooltip) {
      text = (
        <Tooltip title={error}>
          <Muted>
            <ErrorOutlined style={{ color: "red" }} />
            {text}
          </Muted>
        </Tooltip>
      );
    } else if (warning && showErrorTooltip) {
      text = (
        <Tooltip title={warning}>
          <Muted>
            <WarningOutlined style={{ color: "orange" }} />
            {text}
          </Muted>
        </Tooltip>
      );
    } else {
      text = <Muted>{text}</Muted>;
    }

    if (label) {
      return (
        <>
          <FormControl>
            <InputLabel shrink>{label}</InputLabel>
            {text}
          </FormControl>
        </>
      );
    } else {
      return text;
    }
  }
};

export default withStyles(commonStyles)(FieldText);
