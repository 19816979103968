import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GenericServerAutocomplete from "components/Autocomplete/GenericServerAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import {
  CommonCurrency,
  JobContractualSetup,
  JobCustomerContractStatus,
  JobCustomerContractType,
  TpPaymentMethod,
  TpPaymentPeriod
} from "store/MasterValue/MasterValueTypes";

function CustomerContractLinksPart({ amc, itemChanged, isReadonly }) {
  var item = !!amc.currentItem["CUSTOMERCONTRACT"] ? amc.currentItem["CUSTOMERCONTRACT"] : {};
  var warnings = amc.errors["CUSTOMERCONTRACT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Links</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Status</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobCustomerContractStatus}
              selectedValue={item.customerContractStatusCode}
              isEditable={!isReadonly}
              prefixCode={true}
              onValueChange={e => itemChanged({ ...item, customerContractStatusCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Currency</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={CommonCurrency}
              selectedValue={item.currencyCode}
              isEditable={!isReadonly}
              prefixCode={true}
              onValueChange={e => itemChanged({ ...item, currencyCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERCONTRACTTYPECODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERCONTRACTTYPECODE")}>
              <Trans>Customer contract type</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobCustomerContractType}
              selectedValue={item.customerContractTypeCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CUSTOMERCONTRACTTYPECODE")}
              onValueChange={e => itemChanged({ ...item, customerContractTypeCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CONTRACTUALSETUPCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CONTRACTUALSETUPCODE")}>
              <Trans>Contractual Setup</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobContractualSetup}
              selectedValue={item.contractualSetupCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CONTRACTUALSETUPCODE")}
              onValueChange={e => itemChanged({ ...item, contractualSetupCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Billing Type</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="billingTypeCode"
              value={item.billingTypeCode}
              onChange={e => itemChanged({ ...item, billingTypeCode: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTMETHODCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTMETHODCODE")}>
              <Trans>Payment Method</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={TpPaymentMethod}
              selectedValue={item.paymentMethodCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTMETHODCODE")}
              onValueChange={e => itemChanged({ ...item, paymentMethodCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTPERIODCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTPERIODCODE")}>
              <Trans>Payment Delay</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={TpPaymentPeriod}
              selectedValue={item.paymentPeriodCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PAYMENTPERIODCODE")}
              onValueChange={e => itemChanged({ ...item, paymentPeriodCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PROJECT")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PROJECT")}>
              <Trans>Project</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GenericServerAutocomplete
              selectedValue={item.projectIdentifier}
              searchPartialUri={"api/Projects/Autocomplete/"}
              readOnly={isReadonly}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PROJECT")}
              onValueChange={e => itemChanged({ ...item, projectIdentifier: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Parent Project</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GenericServerAutocomplete
              selectedValue={item.parentProjectId}
              searchPartialUri={"api/Projects/Autocomplete/"}
              readOnly={isReadonly}
              onValueChange={e => itemChanged({ ...item, parentProjectId: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractLinksPart));
