import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { RootStore } from "store/RootStore";
import { ReportVisual } from "powerbi-report-component";
import { models } from "powerbi-client"; // Import from the dependency

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

function BaseReportVisual({ reportId, pageName, visualName, dataSelectionHandler }) {
  return <>In construction</>;

  // const configSelector = RootStore.getState().ConfigurationReducer;
  // const authentifSelector = RootStore.getState().AuthenticationReducer;
  // const tokenBearer = authentifSelector.payloadPbi.jwtAccessToken;

  // const layoutSettings = {
  //     background: models.BackgroundType.Transparent,
  //     layoutType: models.LayoutType.Custom,
  //     customLayout: {
  //         pageSize: {
  //             type: models.PageSizeType.Custom,
  //             width: 400,
  //             height: 300,
  //         },
  //         displayOption: models.DisplayOption.FitToWidth,
  //         pagesLayout: {
  //             ReportSsection: {
  //                 defaultLayout: {
  //                     displayState: {
  //                         mode: models.VisualContainerDisplayMode.Hidden,
  //                     },
  //                 },
  //                 visualsLayout: {
  //                     c4672311ba67631f9130: {
  //                         x: 1,
  //                         y: 1,
  //                         z: 1,
  //                         width: 400,
  //                         height: 300,
  //                         displayState: {
  //                             mode: models.VisualContainerDisplayMode.Visible,
  //                         },
  //                     },
  //                     VisualContainer2: {
  //                         displayState: {
  //                             mode: models.VisualContainerDisplayMode.Visible,
  //                         },
  //                     },
  //                 },
  //             },
  //         },
  //     },
  // };

  // const extraSettings = {
  //     filterPaneEnabled: false, //true
  //     navContentPaneEnabled: false, //true
  //     hideErrors: false, // Use this *only* when you want to override error experience i.e, use onError
  //     ...layoutSettings // layout config
  // };

  // return (
  //     <ReportVisual
  //         tokenType="Aad"
  //         accessToken={tokenBearer}
  //         embedUrl="https://app.powerbi.com/reportEmbed"
  //         embedId={reportId}
  //         reportMode="View"
  //         style={{ height: '300px', width: '700px', marginTop: '10px' }}
  //         pageName={pageName}
  //         visualName={visualName}
  //         extraSettings={extraSettings}
  //         onLoad={(report) => {
  //             report.getPages().then(function (reportPages) {
  //                 var page1 = reportPages[0];
  //                 page1.getVisuals().then(function (pageVisuals) {
  //                     var visuals = pageVisuals;
  //                 });

  //             });
  //         }
  //         }
  //         onSelectData={dataSelectionHandler}
  //     />
  // );
}
export default withStyles(dashboardStyle)(BaseReportVisual);
