import React from "react";
import { connect } from "react-redux";
import Tree from "./part/Tree";

function StructureTree({ defaultLang }) {
  return <Tree structureId="1" treeType="iris3" defaultLang={defaultLang} />;
}

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(StructureTree);
