import * as Actions from "module/amc/store/actions/Worker.Actions";
import * as Types from "module/amc/store/actions/types";
import React from "react";
import { connect } from "react-redux";

// custom components

import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcStyle from "assets/jss/material-dashboard-pro-react/components/amcStyle.jsx";
import WorkerDetail from "../components/workers/WorkerDetail";

function WorkerPopup({ amc, defaultLang, closePopup, crupdate, classes }) {
  var showPopup = amc.showDetail["WORKER"];
  var loading = amc.loading["WORKER"];
  var item = amc.currentItem["WORKER"];
  var errorPart = "";

  var buttonSave = "";
  buttonSave = (
    <Button onClick={crupdate} color="info">
      <Trans>Save</Trans>
    </Button>
  );

  var usualButton = "";
  usualButton = (
    <>
      {buttonSave}
      <Button onClick={closePopup} color="info">
        <Trans>Close</Trans>
      </Button>
    </>
  );

  var breadCrumb = "";
  if (!!item) {
    breadCrumb = (
      <Typography>
        <Trans>Employee</Trans>&nbsp;({item.login})
      </Typography>
    );
  }

  var popup = "";
  if (showPopup) {
    var popupContent = <CircularProgress />;
    if (!loading) {
      popupContent = <WorkerDetail />;
    }
    popup = (
      <Dialog
        fullWidth={false}
        maxWidth="lg"
        open
        onClose={closePopup}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaperCompact }}
        scroll="paper"
      >
        <DialogTitle>
          <GridContainer>
            <GridItem xs="9" sm="9" md="9">
              <Breadcrumbs aria-label="Breadcrumb">{breadCrumb}</Breadcrumbs>
            </GridItem>
            <GridItem xs="3" sm="3" md="3">
              {errorPart}
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {popupContent}
        </DialogContent>
        <DialogActions>{usualButton}</DialogActions>
      </Dialog>
    );
  }

  return <div>{popup}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    crupdate: () => {
      dispatch(Actions.Crupdate());
    },
    closePopup: () => {
      dispatch({
        type: Types.AMC_END_EDITING,
        module: "WORKER"
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcStyle)(WorkerPopup));
