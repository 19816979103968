import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FormLabel } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import React from "react";
import { CommonCountry, CommonLanguage } from "store/MasterValue/MasterValueTypes";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default function PrivateStep1({ thirdParty, setThirdParty, errors, masterValues, defaultLang }) {
  const { i18n } = useLingui();
  const civilityOptions = [{ code: "M", label: i18n._(t`Mister`) }, { code: "Mme", label: i18n._(t`Madame`) }, { code: "MM", label: i18n._(t`MisterMadame`) }];
  var error = code => errors.some(e => e.code === code);

  if (isNullOrUndefined(thirdParty.gpsCoordinates)) {
    thirdParty.gpsCoordinates = { latitude: null, longitude: null };
  }
  const cardGeneral = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Civility</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={error("privateTitleMissing")} required>
                  <Trans>Title</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <ItemAutoComplete
                  isEditable
                  isRequired
                  items={civilityOptions}
                  selectedValue={thirdParty.privateTitle}
                  onValueChange={v => setThirdParty({ ...thirdParty, privateTitle: v })}
                  error={error("privateTitleMissing")}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={error("privateFirstNameMissing")} required>
                  <Trans>First name</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  autocomplete="off"
                  margin="dense"
                  id="firsName"
                  type="text"
                  fullWidth
                  error={error("privateFirstNameMissing")}
                  value={thirdParty.privateFirstName}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 25) value = value.substring(0, 25);
                    setThirdParty({ ...thirdParty, privateFirstName: value });
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel error={error("privateLastNameMissing")} required>
                  <Trans>Last name</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  autocomplete="off"
                  margin="dense"
                  id="lastName"
                  error={error("privateLastNameMissing")}
                  type="text"
                  fullWidth
                  value={thirdParty.privateLastName}
                  onChange={e => {
                    var value = e.target.value;
                    if (value.length > 25) value = value.substring(0, 25);
                    setThirdParty({ ...thirdParty, privateLastName: value });
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Language</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <MasterValueAutocomplete
                  isEditable
                  mvType={CommonLanguage}
                  onValueChange={code => setThirdParty({ ...thirdParty, languageCode: code })}
                  selectedValue={thirdParty.languageCode}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  const errorZipCode = error("AddressZipCodeMissing");
  const errorCity = error("AddressCityMissing");

  const cardAddress = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Address</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center">
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("AddressLine1Missing")} required>
              <Trans>Line 1</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              autocomplete="off"
              margin="dense"
              id="line1"
              error={error("AddressLine1Missing")}
              type="text"
              fullWidth
              value={thirdParty.address_Line1}
              onChange={e => {
                var value = e.target.value;
                if (value.length > 40) value = value.substring(0, 40);
                setThirdParty({ ...thirdParty, address_Line1: value });
              }}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line 2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              margin="dense"
              id="line2"
              type="text"
              fullWidth
              value={thirdParty.address_Line2}
              onChange={e => {
                var value = e.target.value;
                if (value.length > 40) value = value.substring(0, 40);
                setThirdParty({ ...thirdParty, address_Line2: value });
              }}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={errorCity || errorZipCode} required>
              <Trans>City</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CityZipAutocomplete
              countryCode={thirdParty.address_CountryCode}
              zipCode={thirdParty.address_ZipCode}
              city={thirdParty.address_City}
              countyCode={thirdParty.address_CountyCode}
              stateCode={thirdParty.address_StateCode}
              zipCodeError={errorZipCode}
              cityError={errorCity}
              onZipCityChange={(zipCode, city) => {
                setThirdParty({ ...thirdParty, address_ZipCode: zipCode, address_City: city });
              }}
              onCountyStateChange={countyState => {
                setThirdParty({ ...thirdParty, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
              }}
              isEditable={true}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("AddressCountryMissing")} required>
              <Trans>Country</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CountryAutocomplete
              countryCode={thirdParty.address_CountryCode}
              onChange={codeIso2 => {
                var country = masterValues[CommonCountry].find(c => c.code === codeIso2);
                var lang = !country ? "E" : country.languageCode;
                if (thirdParty.address_CountryCode !== codeIso2 && (thirdParty.address_CountryCode === "FR" || codeIso2 === "FR"))
                  setThirdParty({ ...thirdParty, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2, languageCode: lang });
                else setThirdParty({ ...thirdParty, address_CountryCode: codeIso2, languageCode: lang });
              }}
              defaultLang={defaultLang}
              error={error("AddressCountryMissing")}
              isEditable
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>LatitudeLongitude</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GpsAutocomplete
              //latitudeError={checkError("LatitudeWrongFormat")}
              //longitudeError={checkError("LongitudeWrongFormat")}
              isEditable={true}
              defaultLang={defaultLang}
              source={thirdParty}
              setSource={c => {
                setThirdParty(c);
              }}
              error={error("GpsCoordinates")}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  return (
    <form autoComplete="off">
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={10}>
          {cardGeneral}
          {cardAddress}
        </GridItem>
      </GridContainer>
    </form>
  );
}
