import { ADD_HUBBLEPARAMS } from "./HubbleParamsActions";

const initialState = {
  HubbleParams: []
};

function HubbleParams(state = initialState, action) {
  switch (action.type) {
    case ADD_HUBBLEPARAMS:
      return {
        ...state,
        HubbleParams: action.hubbleParams
      };
    default:
      return state;
  }
}
export default HubbleParams;
