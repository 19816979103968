import * as Actions from "module/amc/store/actions/Job.Actions";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";

import LocalStorageHelper from "services/common/LocalStorageHelper";

import { Assignment } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

//lingui
import { Trans } from "@lingui/macro";

function JobTable({ amc, setCriterias, search, complexSearch, get, classes }) {
  var result = amc.results["JOB"];
  var criterias = amc.criterias["JOB"];
  const searchMode = !amc ? "Simple" : amc.searchMode["JOB"];

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "id"
    },
    {
      Header: <Trans>Id ERP</Trans>,
      accessor: "idErp"
    },
    {
      Header: <Trans>Id Local</Trans>,
      accessor: "applicationSourceIdentifier"
    },
    {
      Header: <Trans>Ue</Trans>,
      accessor: "ueIdentifier"
    },
    {
      Header: <Trans>Label</Trans>,
      accessor: "label"
    }
  ];

  var component = "";
  if (!!result) {
    var items = ConvertDatas(result, get);
    component = (
      <ReactTable
        manual
        data={items.tabItems}
        page={result.pageIndex}
        pages={result.nbOfPages}
        columns={reactTableColumns}
        sorted={[{ id: criterias.orderProperty, desc: criterias.isDescendingOrder }]}
        onPageChange={(state, instance) => {
          setCriterias({ ...criterias, startPage: state });
          if (searchMode !== "Complex") search();
          else complexSearch();
        }}
        onPageSizeChange={(state, instance) => {
          setCriterias({ ...criterias, pageSize: state });
          if (searchMode !== "Complex") search();
          else complexSearch();
        }}
        onSortedChange={(newSorted, column, shiftKey) => {
          setCriterias({ ...criterias, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
          if (searchMode !== "Complex") search();
          else complexSearch();
        }}
        defaultPageSize={result.pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
      />
    );
  }

  return (
    <div>
      <Card>
        <CardBody>{component}</CardBody>
      </Card>
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(result, getItem) {
  if (!result.items || result.items.length === 0) return [];

  var datas = {
    pages: result.nbOfPages,
    datas: result,
    tabItems: []
  };
  result.items.map((prop, key) => {
    datas.tabItems.push({
      ...prop,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              if (!!getItem) getItem(prop.id);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>{" "}
        </div>
      )
    });
  });

  return datas;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    complexSearch: () => {
      dispatch(Actions.ComplexSearch());
    },
    get: id => {
      dispatch(Actions.Get(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobTable));
