import {  CircularProgress, Grid } from "@material-ui/core";
import CardError from "components/Card/CardError";
import React, { useEffect, useState } from "react";
import { GroupGet } from "./actions/Group.Actions";
import GroupAutocomplete from "./GroupAutocomplete";

const GroupSelect = ({ groupId, setGroupId, withRoot, isEditable, label, error }) => {
  const [group, setGroup] = useState(null);
  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    if (!!withRoot && groupId === 0) {
      setGroup({ id: 0 });
    } else if (groupId > 0 && group?.id !== groupId) {
      GroupGet(groupId)
        .then(g => setGroup(g))
        .catch(setLoadError);
    } else {
      setGroup({ id: 0 });
    }
  }, [groupId]);

  if (loadError) return <CardError error={loadError} />;
  if (!group) return <CircularProgress />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <GroupAutocomplete
          groupId={groupId}
          setGroupId={setGroupId}
          isEditable={isEditable}
          label={label}
          err={error}
        />
      </Grid>
    </Grid>
  );
};

export default GroupSelect;
