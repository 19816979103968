import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
//
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//Icon
import StarIcon from "@material-ui/icons/Star";
import WorkIcon from "@material-ui/icons/Work";

//lingui JS
import { Trans } from "@lingui/macro";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: "15px"
  },
  taille: {
    width: "100%"
  },
  Margin: {
    marginTop: "10px"
  },
  LabelSize: {
    fontSize: "14px"
  }
});

function ProductOptions({ product, isEditable }) {
  var OptionProduct = <></>;
  if (Array.isArray(product.productOptions) && product.productOptions.length > 0) {
    OptionProduct = product.productOptions.map(option => {
      return (
        <div>
          <ListItem>
            <ListItemIcon>
              <bookmarkIcon />
            </ListItemIcon>
            <ListItemText inset secondary={option.value} />
          </ListItem>
        </div>
      );
    });
  }
  return (
    <>
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <ListItem>
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary={product.commercialLabel} />
                  <h5>
                    {" "}
                    <Trans>Id in MDM :</Trans>
                    {product.identifier}
                  </h5>
                </ListItem>
              </CardHeader>
              <CardBody>
                <GridContainer alignItems="center" justify="center">
                  <GridItem xs={6} sm={6} md={6}>
                    <Card>
                      <CardHeader
                        style={{
                          ...CardInlineStyle.cardHeader
                        }}
                        icon
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <StarIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Trans>Options</Trans>} />
                        </ListItem>
                      </CardHeader>
                      <CardBody>{OptionProduct}</CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  img: {
    width: "20px",
    height: "20px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  },
  FormLabel: {
    margin: "5px"
  },
  root: {
    width: "100%",
    marginTop: "20px"
  },
  taille: {
    width: "100%"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(ProductOptions);
