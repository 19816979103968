import React from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty, isNull } from "../../tools";

import TabPrivate from "./ThirdPartyDetail/TabPrivate";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";
import CardError from "components/Card/CardError";
//lingui
import { Trans } from "@lingui/macro";

// style
import "assets/scss/material-dashboard-pro-react/react-web-tabs.css";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyLegalDatas.css";
import "assets/scss/material-dashboard-pro-react/react-web-tabs.css";
import TabThirdPartyContact from "./ThirdPartyDetail/TabThirdPartyContact";

function ThirdPartyPrivateDatas({ thirdPartyCtx }) {
  const [tabIdx, setTabIdx] = React.useState(0);

  if (!isNull(thirdPartyCtx) && !isNull(thirdPartyCtx.error)) {
    return <CardError error={thirdPartyCtx.error} />;
  }

  return (
    <div class="divContainer">
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Communication</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabPrivate />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabThirdPartyContact />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyPrivateDatas));
