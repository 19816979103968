import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

import { withStyles, CircularProgress } from "@material-ui/core";

import { Trans } from "@lingui/macro";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import MasterValuesSearch from "./MasterValuesSearch";
import { MasterValueGetAll, MasterValueCrupdate } from "./actions/MasterValueActions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import MasterValuesGrid from "./MasterValuesGrid";
import { HubbleTables } from "store/IndexedDb/HubbleContextProvider";

const MasterValues = ({ masterValueReducer, classes, defaultLang }) => {
  const initState = { isLoading: false, error: null, data: null, masterValue: null, edit: null };
  const [state, setState] = useState(initState);

  const setMasterValueType = masterValue => {
    if (!masterValue) {
      setState(initState);
      return;
    }
    setState({ ...state, isLoading: true, masterValue: masterValue, editCode: null });
    state.edit = null;
    MasterValueGetAll(
      masterValue.type,
      c => setState({ ...state, isLoading: false, masterValue: masterValue, data: c }),
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  const masterValues = useMemo(() =>
    HubbleTables.filter(h => h.type === "MasterValue").map(element => {
      return { type: element.url, label: capitalize(element.name), group: "Reglementaires", reducerName: element.name };
    })
  );

  let grid = <></>;
  if (state.isLoading) {
    grid = <CircularProgress />;
  } else if (state.data && state.masterValue) {
    grid = (
      <MasterValuesGrid
        masterValueType={state.masterValue.type}
        items={state.data}
        itemChange={i => {
          MasterValueCrupdate(
            state.masterValue.type,
            i,
            c1 => {
              MasterValueGetAll(
                state.masterValue.type,
                c => {
                  setState({ ...state, data: c });
                },
                e => setState({ ...state, error: e })
              );
            },
            e => setState({ ...state, error: e })
          );
        }}
        masterValueReducer={masterValueReducer}
        defaultLang={defaultLang}
        setState={setState}
        parentState={state}
        setValue={setMasterValueType}
        mvType={HubbleTables}
      />
    );
  } else if (state.error) {
    grid = <CardError error={state.error} />;
  }

  return (
    <div className={classes.flexColCenter}>
      <Card className={classes.cardSizeMedium}>
        <CardHeader>
          <Trans> Admin_MasterValue_Search_Title </Trans>
        </CardHeader>
        <CardBody>
          <MasterValuesSearch masterValues={masterValues} value={state.masterValue} setValue={v => setMasterValueType(v)} />
        </CardBody>
      </Card>
      {grid}
    </div>
  );
};

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
const mapStateToProps = state => {
  return {
    masterValueReducer: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(adminStyle)(MasterValues));
