import React from 'react';
import { Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core';
import thirdPartySearchCriteriaStyle from 'assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle';
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

function SearchCriteriasSimple({ showSearchCriteriaOnSideBar, criteria, setCriteria, launchSearch, cleanSearch, classes, defaultLang }) {
    var countrySimpleSearchWidth = 3;
    var simpleSearchWidth = 9;
    if (showSearchCriteriaOnSideBar) {
        countrySimpleSearchWidth = 12;
        simpleSearchWidth = 12;
    }

    return (
        <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
                <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
                    <CountryAutocomplete
                        countryCode={criteria.criteriaCountry}
                        onChange={countryCode => setCriteria({ ...criteria, criteriaCountry: countryCode })}
                        defaultLang={defaultLang}
                        isEditable
                    />
                </GridItem>
                <GridItem xs={simpleSearchWidth} sm={simpleSearchWidth} md={simpleSearchWidth}>
                    <CustomInput
                        labelText={<Trans>Mdm id, Registration number, long name, address, ...</Trans>}
                        id="criteriaFullTextSearch"
                        onChange={(e) => setCriteria({ ...criteria, criteriaFullText: e.target.value })}
                        value={criteria.criteriaFullText}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </GridItem>
            </GridContainer>
            <div className={classes.flexRight}>
                <Button
                    onClick={cleanSearch}>
                    <Trans> Clean </Trans>
                </Button>
                <Button
                    type="submit"
                    color="info"
                    onClick={launchSearch}>
                    <Trans> Search </Trans>
                </Button>
            </div>
        </form>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriasSimple);