import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";

import { Grid } from "@material-ui/core";
import { setupI18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import { TpLegalIdentifier } from "store/MasterValue/MasterValueTypes";

function PartCompanies({ country, setCountry, classes }) {
  const i18n = setupI18n();
  return (
    <Grid container>
      <Grid item xs={8} sm={8} md={8}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Legal Identifier</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpLegalIdentifier}
              mvFilter={mv => mv.countryCode === country.codeIso2}
              selectedValue={country.companyLegalIdentifierStringFormat}
              onValueChange={v => setCountry({ ...country, companyLegalIdentifierStringFormat: v })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Tax Code</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpLegalIdentifier}
              mvFilter={mv => mv.countryCode === country.codeIso2}
              selectedValue={country.companyTaxCodeStringFormat}
              onValueChange={v => setCountry({ ...country, companyTaxCodeStringFormat: v })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(adminStyle)(PartCompanies);
