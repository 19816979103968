import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { Checkbox } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/icons
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import StarIcon from "@material-ui/icons/Star";
import WorkIcon from "@material-ui/icons/Work";
import logo from "assets/images/thermometer.png";

//import ThermostatIcon from '@mui/icons-material/Thermostat';
//lingui JS
import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import FieldText from "components/Common/FieldText";

function GeneralInformation({ product, isEditable }) {
  let classificationCard = (
    <>
      <div>
        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <ListItem button>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary={<Trans>Classification</Trans>} />
            </ListItem>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  {" "}
                  <Trans>Classification</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.classification} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>Product_Family</Trans>{" "}
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.productFamily} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  {" "}
                  <Trans>Product_Type</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.productType} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  {" "}
                  <Trans>Product_Origin</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.productOrigin} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>Product_Nature</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.productNature} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  {" "}
                  <Trans>Range</Trans>{" "}
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.range} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>Jde_Sub_Family</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.jdeSubFamily} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>InOut</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.inOut} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>CodeDechets</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.codeDechets} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <FormLabel>
                  <Trans>LibelleDechets</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <FieldText isEditable={isEditable} value={product.libelleDechets} size="small" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );

  let segmentationCard = (
    <>
      <div>
        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <ListItem button>
              <ListItemIcon>
                <PieChartIcon />
              </ListItemIcon>
              <ListItemText primary={<Trans>Product_Segmentation</Trans>} />
            </ListItem>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Product_SegLevel3</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.segmentationLevel3Identifier} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Product_SegLevel4</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.segmentationLevel4Identifier} size="small" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );

  let recipeCard = (
    <>
      <div>
        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <ListItem button>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary={<Trans>Recipe</Trans>} />
            </ListItem>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Bitumen_Type</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.bitumenType} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Granulometry</Trans>{" "}
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.granulometry} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Recycle</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.recycle} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Recycle_Rate</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.recycleRate} size="small" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );

  let priceCard = (
    <>
      <div>
        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <ListItem button>
              <ListItemIcon>
                <EuroIcon />
              </ListItemIcon>
              <ListItemText primary={<Trans>Catolag_Price</Trans>} />
            </ListItem>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Price</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.catalogPrice} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  {" "}
                  <Trans>Unit</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText isEditable={isEditable} value={product.unit} size="small" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );

  let tempCard = (
    <>
      <div>
        <Card>
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <ListItem button>
              <ListItemIcon>
                <img
                  src={logo}
                  alt="temperature"
                  style={{
                    ...CardInlineStyle.img
                  }}
                />
              </ListItemIcon>
              <ListItemText inset primary={<Trans>Temperature</Trans>} />
            </ListItem>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={7} sm={7} md={7}>
                <FormLabel>
                  {" "}
                  <Trans>Production_Temperature</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <FieldText isEditable={isEditable} value={product.productTemp} size="small" />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={7} sm={7} md={7}>
                <FormLabel>
                  {" "}
                  <Trans>Order_Temperature</Trans>{" "}
                </FormLabel>
              </GridItem>
              <GridItem xs={5} sm={5} md={5}>
                <FieldText isEditable={isEditable} value={product.orderTemp} size="small" />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    </>
  );

  let specificCard = (
    <>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <ListItem button>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Trans>Specific_Information</Trans>} />
                </ListItem>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      {" "}
                      <Trans>Product_In_Production</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <Checkbox checked={!!product.sleep} disabled />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      {" "}
                      <Trans>Product_Hardous_Material</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <Checkbox checked={!!product.hazardousMaterial} disabled />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Flag_For_Logical_Delete</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <Checkbox checked={!!product.isActive} disabled />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );

  return (
    <>
      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={6} sm={6} md={6}>
          {classificationCard}
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {segmentationCard}
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {recipeCard}
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {priceCard}
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {tempCard}
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {specificCard}
        </GridItem>
      </GridContainer>
    </>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  img: {
    width: "20px",
    height: "20px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  },
  FormLabel: {
    margin: "5px"
  },
  root: {
    width: "100%",
    marginTop: "20px"
  },
  taille: {
    width: "100%"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(GeneralInformation);
