import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { connect } from "react-redux";
import * as Actions from "../store/ProfileDelegation.Actions";

function ProfileDelegationSearchCriteria({ delegationCtx, searchDelegation, setCriterias, classes }) {
  const criterias = delegationCtx.criterias;

  const searchGridSize = 3;

  const launchSearch = e => {
    e.preventDefault();

    setCriterias({
      ...criterias,
      startPage: 0,
      pageSize: 10,
      orderProperty: "id",
      isDescendingOrder: false
    });
    
    searchDelegation();
  };

  const cleanSearchCriteria = e => {
    e.preventDefault();

    setCriterias({
      userLogin: "",
      validAtDate: "",
      startPage: 0,
      pageSize: 10,
      orderProperty: "id",
      isDescendingOrder: false
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div>
              <form onSubmit={launchSearch} autoComplete="off">
                <GridContainer>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                      labelText={<Trans>Other user login</Trans>}
                      id="criteriaUserLogin"
                      onChange={e => setCriterias({ ...criterias, userLogin: e.target.value })}
                      value={criterias.userLogin}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                        id="criteriaValidAtDate"
                        type="date"
                        onChange={e => setCriterias({ ...criterias, validAtDate: e.target.value })}
                        value={criterias.validAtDate}
                        formControlProps={{
                          fullWidth: true
                        }}
                    />
                  </GridItem>
                </GridContainer>
                <div className={classes.flexRight}>
                  <Button onClick={cleanSearchCriteria}>
                    <Trans> Clean </Trans>
                  </Button>
                  <Button type="submit" color="info" onClick={launchSearch}>
                    <Trans>Search</Trans>
                  </Button>
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    delegationCtx: state.UR_ProfileDelegation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchDelegation: () => {
      dispatch(Actions.Search());
    },
    setCriterias: criteria => {
      dispatch(Actions.SetCriterias(criteria));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ProfileDelegationSearchCriteria));