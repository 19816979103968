import * as Actions from '../actions/types';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import LocalStorageHelper from "services/common/LocalStorageHelper";

var localStorageHelper = new LocalStorageHelper();
var getPref = localStorageHelper.get("Preferences");
var defaultSearchMode = (!!getPref && !!getPref.Pref && !!getPref.Pref.Search) ? getPref.Pref.Search : 'Simple';
var defaultSearchCriteria = { pageSize: 10 };

const initialState = {
    criterias: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    complexCriterias: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    complexQuery: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    results: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT', 'JOBCHILD', 'CUSTOMERCONTRACTCHILD'],
    currentItem: ['JOB', 'JOBPHASE', 'JOBPHASEBUDGET', 'JOBPHASEFINALPROJECTION', 'CUSTOMERCONTRACT', 'PROJECT'],
    searchMode: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    showDetail: ['JOB', 'JOBPHASE', 'JOBPHASEBUDGET', 'JOBPHASEFINALPROJECTION', 'CUSTOMERCONTRACT', 'PROJECT'],
    searching: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT', 'JOBCHILD', 'CUSTOMERCONTRACTCHILD'],
    loading: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    waitingAction: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    errors: ['JOB', 'CUSTOMERCONTRACT', 'PROJECT'],
    hasChanged: ['JOB', 'JOBPHASE', 'JOBPHASEBUDGET', 'JOBPHASEFINALPROJECTION', 'CUSTOMERCONTRACT', 'PROJECT'],
    saveStatus: ['JOB', 'JOBPHASE', 'JOBPHASEBUDGET', 'JOBPHASEFINALPROJECTION', 'CUSTOMERCONTRACT', 'PROJECT']
};

initialState.criterias['JOB'] = defaultSearchCriteria;
initialState.criterias['CUSTOMERCONTRACT'] = defaultSearchCriteria;
initialState.criterias['PROJECT'] = defaultSearchCriteria;
initialState.criterias['WORKER'] = defaultSearchCriteria;

initialState.complexCriterias['JOB'] = "";
initialState.complexCriterias['CUSTOMERCONTRACT'] = "";
initialState.complexCriterias['PROJECT'] = "";

initialState.complexQuery['JOB'] = { "id": QbUtils.uuid(), "type": "group" };
initialState.complexQuery['CUSTOMERCONTRACT'] = { "id": QbUtils.uuid(), "type": "group" };
initialState.complexQuery['PROJECT'] = { "id": QbUtils.uuid(), "type": "group" };

initialState.searchMode['JOB'] = defaultSearchMode;
initialState.searchMode['CUSTOMERCONTRACT'] = defaultSearchMode;
initialState.searchMode['PROJECT'] = defaultSearchMode;

initialState.showDetail['JOB'] = false;;
initialState.showDetail['JOBPHASE'] = false;
initialState.showDetail['JOBPHASEBUGDET'] = false;
initialState.showDetail['JOBPHASEFINALPROJECTION'] = false;
initialState.showDetail['CUSTOMERCONTRACT'] = false;
initialState.showDetail['PROJECT'] = false;

initialState.searching['JOB'] = false;
initialState.searching['CUSTOMERCONTRACT'] = false;
initialState.searching['PROJECT'] = false;

initialState.loading['JOB'] = false;
initialState.loading['CUSTOMERCONTRACT'] = false;
initialState.loading['PROJECT'] = false;

initialState.waitingAction['JOB'] = false;
initialState.waitingAction['CUSTOMERCONTRACT'] = false;
initialState.waitingAction['PROJECT'] = false;

initialState.hasChanged['JOB'] = false;
initialState.hasChanged['JOBPHASE'] = false;
initialState.hasChanged['CUSTOMERCONTRACT'] = false;
initialState.hasChanged['PROJECT'] = false;

initialState.saveStatus['JOB'] = false;
initialState.saveStatus['JOBPHASE'] = false;
initialState.saveStatus['JOBPHASEBUGDET'] = false;
initialState.saveStatus['JOBPHASEFINALPROJECTION'] = false;
initialState.saveStatus['CUSTOMERCONTRACT'] = false;
initialState.saveStatus['PROJECT'] = false;

export default function AMC_Reducer(state = initialState, action) {
    switch (action.type) {

        case Actions.AMC_SET_CRITERIA:
            var criterias = state.criterias;
            criterias[action.module] = action.criterias
            return {
                ...state,
                criterias: criterias,
                showDetail: getProperty(state, 'showDetail', action.module, false),
                searching: getProperty(state, 'searching', action.module, false),
            };

        case Actions.AMC_SET_COMPLEX_CRITERIA:
            var complexCriterias = state.complexCriterias;
            complexCriterias[action.module] = action.complexCriterias;
            var complexQuery = state.complexQuery;
            complexQuery[action.module] = action.complexQuery;
            return {
                ...state,
                complexCriterias: complexCriterias,
                complexQuery: complexQuery,
                showDetail: getProperty(state, 'showDetail', action.module, false),
                searching: getProperty(state, 'searching', action.module, false),
            };

        case Actions.AMC_SET_SEARCHMODE:
            var searchModes = state.searchMode;
            searchModes[action.module] = action.searchMode
            return {
                ...state,
                searchMode: searchModes,
                showDetail: getProperty(state, 'showDetail', action.module, false),
                searching: getProperty(state, 'searching', action.module, false),
            };

        case Actions.AMC_CLEAR_ITEM_CHANGED:
            return {
                ...state,
                hasChanged: getProperty(state, 'hasChanged', action.module, false)
            };

        case Actions.AMC_ITEM_CHANGED:
            return {
                ...state,
                currentItem: getProperty(state, 'currentItem', action.module, action.item),
                hasChanged: getProperty(state, 'hasChanged', action.module, true)
            };

        case Actions.AMC_GET_BEGIN:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, true),
                loading: getProperty(state, 'loading', action.module, true),
            };

        case Actions.AMC_GET_END:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, true),
                loading: getProperty(state, 'loading', action.module, false),
                currentItem: getProperty(state, 'currentItem', action.module, action.item, false),
                errors: getProperty(state, 'errors', action.module, null)
            };

        case Actions.AMC_END_EDITING:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, false),
                loading: getProperty(state, 'loading', action.module, false),
                currentItem: getProperty(state, 'currentItem', action.module, null),
                hasChanged: getProperty(state, 'hasChanged', action.module, false),
                errors: getProperty(state, 'errors', action.module, action.error),
                results: getProperty(state, 'results', 'JOBCHILD', null),
                results: getProperty(state, 'results', 'CUSTOMERCONTRACTCHILD', null)
            };

        case Actions.AMC_UPDATE_BEGIN:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, true),
                loading: getProperty(state, 'loading', action.module, true)
            };

        case Actions.AMC_UPDATE_END:
            return {
                ...state,
                //showDetail: getProperty(state, 'showDetail', action.module, false),
                loading: getProperty(state, 'loading', action.module, false),
                hasChanged: getProperty(state, 'hasChanged', action.module, false),
                errors: getProperty(state, 'errors', action.module, null)
            };

        case Actions.AMC_ERROR:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, true),
                loading: getProperty(state, 'loading', action.module, false),
                errors: getProperty(state, 'errors', action.module, action.error),
            };

        case Actions.AMC_SEARCH_BEGIN:
            return {
                ...state,
                showDetail: getProperty(state, 'showDetail', action.module, false),
                searching: getProperty(state, 'searching', action.module, true),
            };

        case Actions.AMC_SEARCH_END:
            return {
                ...state,
                results: getProperty(state, 'results', action.module, action.results),
                showDetail: getProperty(state, 'showDetail', action.module, false),
                searching: getProperty(state, 'searching', action.module, false),
            };

        default:
            return state;
    }
}

function getProperty(state, property, module, value, override = true) {

    var currentProperty = state[property];
    currentProperty[module] = value;

    return currentProperty;
}