import React from "react";
//lingui
import { Trans } from "@lingui/macro";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { AccountCircleOutlined } from "@material-ui/icons";
import Profil from "views/Profil/Profil.jsx";
import HomePage from "module/home";

var dashboardRoutes = [
  {
    path: "/",
    name: <Trans>Dashboard</Trans>,
    icon: DashboardIcon,
    component: HomePage
  }
];
export default dashboardRoutes;
