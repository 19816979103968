import React, { useMemo, useState } from "react";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { Trans } from "@lingui/macro";
import { Checkbox, CircularProgress, IconButton, Typography, withStyles } from "@material-ui/core";
import { AddBox, LibraryBooks, VisibilitySharp } from "@material-ui/icons";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import { isNullOrEmpty } from "tools";
import MasterValuesDetail from "./MasterValuesDetail";
import { MasterValueCrupdate, MasterValueCrupdateLanguage, MasterValueGetAll } from "./actions/MasterValueActions";

const MasterValuesGrid = ({ masterValueType, items, masterValueReducer, defaultLang, classes, setState, parentState, mvType, isNew }) => {
  const [newCode, setNewCode] = useState(null);
  const [errorNewCode, setErrorNewCode] = useState(null);
  const [masterValue, setMasterValue] = useState(null);
  const [backupMasterValue, setBackupMasterValue] = useState(null);

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  var masterValueDetails = "";

  const handleButtonClick = (e, props) => {
    var currentMasterValue = items.find(x => x.code === props.original?.code); //x.masterValueType === masterValueType &&

    const { labels, ...currentMasterValueWithoutLabels } = currentMasterValue;
    setMasterValue({ ...currentMasterValue, masterValueType: masterValueType });
    setBackupMasterValue({
      masterValue: JSON.stringify(currentMasterValueWithoutLabels),
      labels: JSON.stringify(labels)
    });
  };

  const handleSave = () => {
    const { labels, ...cleanMasterValue } = masterValue;
    let isMasterValueChanged = JSON.stringify(cleanMasterValue) !== backupMasterValue.masterValue;
    let isLanguageChanged = JSON.stringify(labels) !== backupMasterValue.labels;

    Object.assign(cleanMasterValue, { label_FR: masterValue.labels.fr, label_EN: masterValue.labels.en });

    if (isLanguageChanged && isMasterValueChanged) {
      setState({ ...parentState, isLoading: true });

      MasterValueCrupdateLanguage(
        masterValueType, // Type
        masterValue.code, // Code
        labels, // Data
        result => {
          MasterValueCrupdate(
            masterValueType,
            cleanMasterValue,
            result => {
              MasterValueGetAll(
                masterValueType,
                c => {
                  setState({ ...parentState, data: c });
                  setMasterValue(null);
                },
                e => {
                  setState({ ...parentState, isLoading: false, error: e });
                }
              );
            },
            e => {
              setState({ ...parentState, isLoading: false, error: e });
            }
          );
        },
        e => {
          setState({ ...parentState, isLoading: false, error: e });
        }
      );
    } else if (isMasterValueChanged) {
      setState({ ...parentState, isLoading: true });
      MasterValueCrupdate(
        masterValueType,
        cleanMasterValue,
        result => {
          MasterValueGetAll(masterValueType, c => {
            setState({ ...parentState, data: c });
            setMasterValue(null);
          });
        },
        e => {
          setState({ ...parentState, isLoading: false, error: e });
        }
      );
    } else if (isLanguageChanged && masterValueType) {
      setState({ ...parentState, isLoading: true });
      MasterValueCrupdateLanguage(
        masterValueType,
        masterValue.code,
        labels,
        result => {
          MasterValueGetAll(
            masterValueType,
            c => {
              setState({ ...parentState, data: c });
              setMasterValue(null);
            },
            e => {
              setState({ ...parentState, isLoading: false, error: e });
            }
          );
        },
        e => {
          setState({ ...parentState, isLoading: false, error: e });
        }
      );
    }
    setMasterValue(null);
  };

  let baseResultColumnsHeader = useMemo(() => [
    {
      Header: "",
      accessor: "masterValueTypeInfo",
      filterable: false,
      Cell: props => (
        <IconButton onClick={e => handleButtonClick(e, props)}>
          <VisibilitySharp />
        </IconButton>
      )
    },
    {
      Header: <Trans>Code</Trans>,
      accessor: "code",
      filterable: true,
      cellType: "codeCell"
    },
    {
      Header: <Trans>CurrentLabel</Trans>,
      accessor: "label",
      filterable: true,
      cellType: "labelCell"
    },
    {
      Header: <Trans>Description</Trans>,
      accessor: "description",
      filterable: true,
      cellType: "customInput"
    },
    {
      Header: <Trans>Is Enabled</Trans>,
      accessor: "isEnabled",
      Cell: rowInfo => {
        return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isEnabled} />;
      },
      cellType: "checkbox",
      filterable: true
    },
    {
      Header: <Trans>old Identifier</Trans>,
      accessor: "oldIdentifier",
      cellType: "customInput",
      filterable: true
    }
  ]);

  let masterValues = useMemo(() => [
    { type: "CommonCurrency", label: "Currencies", group: "Reglementaires", reducerName: "currencies", columns: baseResultColumnsHeader },
    { type: "CommonLanguage", label: "Languages", group: "Reglementaires", reducerName: "languages", columns: baseResultColumnsHeader },
    {
      type: "TpCustomerNature",
      label: "Customer Natures",
      group: "ThirdParties",
      reducerName: "customerNatures",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>ThirdPartyCategory</Trans>,
          accessor: "thirdPartyCategoryCode",
          filterable: true,
          cellType: "selectorThirdPartyCategories"
        }
      ]
    },
    {
      type: "TpSupplierStatus",
      label: "Supplier Statuses",
      group: "ThirdParties",
      reducerName: "supplierStatuses",
      columns: baseResultColumnsHeader
    },
    {
      type: "TpSisReason",
      label: "SIS Reasons",
      group: "ThirdParties",
      reducerName: "sisReasons",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>SisBlockPayment</Trans>,
          accessor: "blockPayment",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.blockPayment} />;
          },
          cellType: "checkbox"
        },
        {
          Header: <Trans>SisMotifEnrolment</Trans>,
          accessor: "motifEnrolment",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.motifEnrolment} />;
          },
          cellType: "checkbox"
        }
      ]
    },
    {
      type: "TpCustomerStatus",
      label: "Customer Statuses",
      group: "ThirdParties",
      reducerName: "customerStatuses",
      columns: baseResultColumnsHeader
    },
    { type: "TpCoupaActivity", label: "Coupa Activities", group: "ThirdParties", reducerName: "coupaActivities", columns: baseResultColumnsHeader },
    { type: "TpFee", label: "Fees", group: "ThirdParties", reducerName: "fees", columns: baseResultColumnsHeader },
    {
      type: "TpGroup",
      label: "Groups",
      group: "ThirdParties",
      reducerName: "groups",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Parent Group Code</Trans>,
          accessor: "parentGroupCode",
          filterable: true,
          cellType: "selectorParentGroupCode"
        }
      ]
    },
    {
      type: "TpIndustryCode",
      label: "Industry Codes",
      group: "ThirdParties",
      reducerName: "industryCodes",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>isFromInfolegale</Trans>,
          accessor: "isFromInfolegale",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isFromInfolegale} />;
          },
          cellType: "checkbox"
        },
        {
          Header: <Trans>isFromAltares</Trans>,
          accessor: "isFromAltares",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isFromAltares} />;
          },
          cellType: "checkbox"
        },
        {
          Header: <Trans>Short Name</Trans>,
          accessor: "shortName",
          filterable: true,
          cellType: "customInput"
        }
      ]
    },
    {
      type: "TpIndustryCodeRepository",
      label: "Industry Code Repositories",
      group: "ThirdParties",
      reducerName: null,
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Customer Nature Public</Trans>,
          accessor: "publicNatureCode",
          filterable: true,
          cellType: "selectorPublicNatureCode"
        },
        {
          Header: <Trans> Customer Nature Private</Trans>,
          accessor: "privateNatureCode",
          filterable: true,
          cellType: "selectorPrivateNatureCode"
        },
        {
          Header: <Trans>Supplier Nature</Trans>,
          accessor: "supplierNatureCode",
          filterable: true,
          cellType: "selectorSupplierNatureCode"
        },
        {
          Header: <Trans>Type Industry Code</Trans>,
          accessor: "industryCodeType",
          filterable: true,
          cellType: "selectorIndustryCodeType"
        },
        {
          Header: <Trans>Payment Periods</Trans>,
          accessor: "paymentPeriodCode",
          filterable: true,
          cellType: "selectorPaymentPeriodCode"
        },
        {
          Header: <Trans>Fee Code</Trans>,
          accessor: "feeCode",
          filterable: true,
          cellType: "selectorFeeCode"
        },
        {
          Header: <Trans>Is Active</Trans>,
          accessor: "isActive",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isActive} />;
          },
          cellType: "checkbox"
        },
        {
          Header: <Trans>Is Handicap</Trans>,
          accessor: "isHandicap",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isHandicap} />;
          },
          cellType: "checkbox"
        }
      ]
    },
    { type: "TpLegalForm", label: "Legal Forms", group: "ThirdParties", reducerName: "legalForms", columns: baseResultColumnsHeader },
    {
      type: "TpLegalIdentifier",
      label: "Legal Identifiers",
      group: "ThirdParties",
      reducerName: "legalIdentifiers",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Country Code</Trans>,
          accessor: "countryCode",
          filterable: true,
          cellType: "selectorCountryCode"
        },
        {
          Header: <Trans>Is Mandatory for company</Trans>,
          accessor: "mandatoryCompany",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.mandatoryCompany} />;
          },
          cellType: "checkbox"
        },
        {
          Header: <Trans>Is Mandatory for thirdparty</Trans>,
          accessor: "mandatoryThirdParty",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.mandatoryThirdParty} />;
          },
          cellType: "checkbox"
        }
      ]
    },
    {
      type: "TpPaymentCharacteristicStatus",
      label: "PaymentCharacteristic Statuses",
      group: "ThirdParties",
      reducerName: "paymentCharacteristicStatuses",
      columns: baseResultColumnsHeader
    },
    { type: "TpPaymentPeriod", label: "Payment Periods", group: "ThirdParties", reducerName: "paymentPeriods", columns: baseResultColumnsHeader },
    {
      type: "TpPaymentMethod",
      label: "Payment Methods",
      group: "ThirdParties",
      reducerName: "paymentMethods",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Is Iban Mandatory</Trans>,
          accessor: "isIbanMandatory",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isIbanMandatory} />;
          },
          cellType: "checkbox"
        }
      ]
    },
    { type: "TpScope", label: "Scopes", group: "ThirdParties", reducerName: "scopes", columns: baseResultColumnsHeader },
    { type: "TpSecurization", label: "Securizations", group: "ThirdParties", reducerName: "securizations", columns: baseResultColumnsHeader },
    {
      type: "TpThirdPartyCategory",
      label: "ThirdParty Categories",
      group: "ThirdParties",
      reducerName: "thirdPartyCategories",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Is Individual</Trans>,
          accessor: "isIndividual",
          filterable: true,
          Cell: rowInfo => {
            return <Checkbox type="checkbox" className="checkbox" checked={rowInfo.original.isIndividual} />;
          },
          cellType: "checkbox"
        }
      ]
    },
    {
      type: "TpThirdPartyStatus",
      label: "ThirdParty Statuses",
      group: "ThirdParties",
      reducerName: "thirdPartyStatuses",
      columns: baseResultColumnsHeader
    },
    {
      type: "StructTag",
      label: "Structure tags",
      group: "Structures",
      reducerName: "structureTags",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>Structure_TagTypes</Trans>,
          accessor: "structureTagTypeCode",
          filterable: true,
          cellType: "selectorStructureTagType"
        }
      ]
    },
    { type: "StructTagType", label: "Structure tag types", group: "Structures", reducerName: "structureTagTypes", columns: baseResultColumnsHeader },
    {
      type: "StructureEnvironment",
      label: "Structure tags",
      group: "Structures",
      reducerName: "StructureEnvironments",
      columns: baseResultColumnsHeader
    },
    {
      type: "LocationActivityValue",
      label: "Location activity values",
      group: "Locations",
      reducerName: "locationActivityValues",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>LocationActivityTypes</Trans>,
          accessor: "locationActTypeCode",
          filterable: true,
          cellType: "selectorLocationActivityType"
        }
      ]
    },
    { type: "LocationActivityType", label: "Location activity types", group: "Locations", reducerName: "locationActivityTypes", columns: baseResultColumnsHeader }, 
    {
      type: "LocationCharacteristicValue",
      label: "Location characteristic values",
      group: "Locations",
      reducerName: "locationCharacteristicValues",
      columns: [
        ...baseResultColumnsHeader,
        {
          Header: <Trans>LocationCharTypes</Trans>,
          accessor: "locationCharTypeCode",
          filterable: true,
          cellType: "selectorLocationCharType",
          disabled: masterValue && (masterValue.isNew === null || masterValue.isNew === undefined || masterValue.isNew===false)
        }
      ]
    },
  ]);

  mvType.forEach(element => {
    masterValues.push({
      type: element.url,
      label: capitalize(element.name),
      group: "Reglementaires",
      reducerName: element.name,
      columns: baseResultColumnsHeader
    });
  });

  const create = () => {
    if (isNullOrEmpty(newCode) || items.some(i => i.code === newCode.toUpperCase())) {
      setErrorNewCode(true);
    } else {
      let code = newCode.toUpperCase();
      setErrorNewCode(null);
      if(masterValueType === "LocationCharacteristicValue"){
        setMasterValue({ code: code, isEnabled: true, masterValueType: masterValueType, labels: { fr: null, en: null }, isNew : true, locationCharValueCode: code });
      }else{
        setMasterValue({ code: code, isEnabled: true, masterValueType: masterValueType, labels: { fr: null, en: null }, isNew : true });
      }
      setBackupMasterValue({ masterValue: null, labels: null });
    }
  };
  var inputBox = <></>;
  let codes = ["green", "orange", "red"];
  inputBox = <CustomInput onChange={e => setNewCode(e.target.value)} value={newCode} error={errorNewCode} />;

  if (parentState.isLoading) {
    masterValueDetails = <CircularProgress />;
  } else if (masterValue) {
    const { labels, ...cleanMasterValue } = masterValue;
    let isMasterValueChanged = JSON.stringify(cleanMasterValue) !== backupMasterValue.masterValue;
    let isLanguageChanged = JSON.stringify(labels) !== backupMasterValue.labels;

    masterValueDetails = (
      <MasterValuesDetail
        masterValue={masterValue}
        setMasterValue={setMasterValue}
        masterValues={masterValues}
        canSave={isMasterValueChanged || isLanguageChanged}
        handleSave={handleSave}
        masterValueReducer={masterValueReducer}
        defaultLang={defaultLang}
      />
    );
  }

  return (
    <Card className={classes.margin}>
      <CardHeader icon>
        <div className="blockHeaderResult">
          <div className="blockHeaderResultItem">
            <IconButton className={classes.iconButton} aria-label="Menu">
              <LibraryBooks color="secondary" />
            </IconButton>
          </div>

          <div className="blockHeaderResultItem">
            <Typography variant="display2">
              <Trans> Result Search MasterValue </Trans>
            </Typography>
          </div>

          <div className="blockHeaderResultItem">
            <IconButton>
              <AddBox color="secondary" onClick={() => create()} />
            </IconButton>
            {inputBox}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <ReactTable
          className={classes.margin}
          data={items}
          columns={masterValues.find(mv => mv.type === masterValueType).columns}
          defaultPageSize={10}
          showPaginationBottom={true}
        />
        {masterValueDetails}
      </CardBody>
    </Card>
  );
};

export default withStyles(adminStyle)(MasterValuesGrid);
