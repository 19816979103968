import { Trans } from "@lingui/macro";
import { CardActions, Chip, CircularProgress, Dialog, DialogActions, DialogContent, FormLabel, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Refresh } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { TpCompanyComplianceScore } from "store/MasterValue/MasterValueTypes";
import { ComplianceCheck, ComplianceGet } from "../../actions/CompanyActions";

const CompanyPartCompliance = function({ company, setCompany, masterValues, defaultLang, isEditable }) {
  var [state, setState] = useState({ isLoading: false, error: null, compliance: null });
  var [showData, setShowData] = useState(false);

  var compliance = state && state.compliance ? state.compliance : company.compliance;
  let canCheckCompliance = company.isFromInfolegal;

  const check = () => {
    setState({ ...state, isLoading: true, compliance: null, error: null });

    ComplianceCheck(
      company.identifier,
      c => {
        setState({ ...state, isLoading: false, compliance: c });
      },
      e => {
        setState({ ...state, isLoading: false, error: e });
      }
    );
  };

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  if (!compliance) {
    if (!canCheckCompliance) {
      return (
        <Typography>
          <Trans>Company_Compliance_CountryDisabled</Trans>
        </Typography>
      );
    }

    if (isEditable) {
      return (
        <div style={{ textAlign: "center" }}>
          <Button color="info" onClick={check}>
            <Trans>Company_Compliance_ActivateScreen</Trans>
          </Button>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          <Trans>Company_Compliance_InsufficientRights</Trans>
        </div>
      );
    }
  }

  if (!state.compliance) {
    setState({ ...state, isLoading: true, compliance: null, error: null });

    ComplianceGet(
      company.identifier,
      c =>
        c
          ? setState({ ...state, isLoading: false, compliance: c })
          : setState({ ...state, isLoading: false, error: { message: "Unknown companyId" } }),
      e => setState({ ...state, isLoading: false, error: e })
    );

    return <></>;
  }

  let cardActions = <></>;
  let paperData = <></>;
  if (isEditable) {
    cardActions = (
      <CardActions>
        <Button onClick={() => setShowData(true)}>
          <Trans>Company_Compliance_ViewData</Trans>
        </Button>
        <Button onClick={check}>
          <Refresh />
          <Trans>Company_Compliance_Check</Trans>
        </Button>
      </CardActions>
    );
    if (state.compliance.data) {
      paperData = (
        <Dialog open={showData} onClose={() => setShowData(false)} maxWidth="lg">
          <DialogContent dividers>
            <pre>{JSON.stringify(JSON.parse(state.compliance.data), null, 2)}</pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowData(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  let chipColor = "#CCC";
  let mvScore = masterValues[TpCompanyComplianceScore].find(s => s.code === compliance.scoreCode);
  let chipText = GetMasterValueLabel(mvScore, defaultLang);
  if (compliance.scoreCode === "GREEN") {
    chipColor = "#00CC00";
  } else if (compliance.scoreCode === "ORANGE") {
    chipColor = "#FFA500";
  } else if (compliance.scoreCode === "RED") {
    chipColor = "#CC0000";
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Company_Compliance_Score</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Chip variant="outlined" size="small" style={{ color: chipColor, borderColor: chipColor }} label={chipText} />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Company_Compliance_States</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Typography>{compliance.state}</Typography>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Company_Compliance_AnalysisDate</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Typography>{compliance.dateAnalysis}</Typography>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Company_Compliance_LastCheckDate</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Typography>{compliance.dateLastCheck}</Typography>
                </GridItem>
              </GridContainer>
            </CardBody>
            {cardActions}
          </Card>
        </GridItem>
      </GridContainer>
      {paperData}
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(CompanyPartCompliance);
