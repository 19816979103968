import React from "react";
import { connect } from "react-redux";
import * as Actions from "module/amc/store/actions/Job.Actions";
import { emptyIfNull } from "module/tools";

// custom components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";

import { FormLabel } from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import FieldText from "components/Common/FieldText";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";

function JobAddressPart({ amc, itemChanged, isReadonly, defaultLang }) {
  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  if (isNullOrUndefined(item.gpsCoordinates)) {
    item.gpsCoordinates = { latitude: null, longitude: null };
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Address</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line 1</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="line1"
              value={emptyIfNull(item.address_Line1)}
              onChange={e => itemChanged({ ...item, address_Line1: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line 2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="line2"
              value={emptyIfNull(item.address_Line2)}
              onChange={e => itemChanged({ ...item, address_Line2: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Statistic Zip Code</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="line2"
              value={emptyIfNull(item.address_StatisticZipCode)}
              onChange={e => itemChanged({ ...item, address_StatisticZipCode: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>City</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CityZipAutocomplete
              countryCode={item ? item.address_CountryCode : ""}
              zipCode={item ? item.address_ZipCode : ""}
              city={item ? item.address_City : ""}
              countyCode={item ? item.address_CountyCode : ""}
              stateCode={item ? item.address_StateCode : ""}
              onZipCityChange={(zipCode, city) => {
                itemChanged({ ...item, address_ZipCode: zipCode, address_City: city });
              }}
              onCountyStateChange={countyState => {
                itemChanged({ ...item, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
              }}
              isEditable={!isReadonly}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Country</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CountryAutocomplete
              isEditable={!isReadonly}
              countryCode={item.address_CountryCode}
              onChange={e => itemChanged({ ...item, address_CountryCode: e })}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>LatitudeLongitude</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GpsAutocomplete
              // latitudeError={checkError("latitude")}
              // longitudeError={checkError("longitude")}
              isEditable={!isReadonly}
              defaultLang={defaultLang}
              source={item}
              setSource={c => {
                itemChanged(c);
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobAddressPart));
