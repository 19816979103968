import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { emptyIfNull } from "tools";

const TabCompanyAddress = function({ company }) {
  var isEditable = true;
  var isManual = !company.isFromInfolegal && !company.isFromAltares;

  return (
    <GridContainer>
      <GridItem xs={7} sm={7} md={7}>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Address</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Line 1</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyAddressline1"
                  value={emptyIfNull(company.address_Line1)}
                  //onChange={(e) => onCompanyValueChanged({ ...company, address_Line1: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Line 2</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyAddressline2"
                  value={emptyIfNull(company.address_Line2)}
                  //onChange={(e) => oncompanyValueChanged({ ...company, address_Line2: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Zip Code</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyAddressZipCode"
                  value={emptyIfNull(company.address_ZipCode)}
                  //onChange={(e) => oncompanyValueChanged({ ...company, address_ZipCode: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>City</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditable && isManual}
                  id="CompanyAddressCity"
                  value={emptyIfNull(company.address_City)}
                  //onChange={(e) => oncompanyValueChanged({ ...company, address_City: e.target.value })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Country</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <CountryAutocomplete countryCode={company.address_CountryCode} isEditable={false} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabCompanyAddress);
