import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index, id, ...other } = props;
    var localId = id;

    if (!localId)
        localId = 'id';

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`${localId}-${index}`}
            aria-labelledby={`${localId}-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const AntTabs = withStyles({
    root: {
        borderRight: '1px solid #e8e8e8'
    },
    indicator: {
        backgroundColor: 'rgba(0, 172, 193, 0.6)',
    },
})(Tabs);

const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(4),
        fontSize: 14,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            opacity: 1,
        },
        '&$selected': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {

        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

export { AntTabs, AntTab, TabPanel };