import * as Actions from "module/amc/store/actions/Job.Actions";
import * as Tools from "module/tools";
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect, useDispatch } from "react-redux";

// custom components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { FormLabel } from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

function JobDatesPart({ amc, masterValues, itemChanged, isReadonly }) {
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var warnings = amc.errors["JOB"];
  var dateInputProps = {};
  if (isReadonly)
    dateInputProps = {
      readOnly: { isReadonly },
      disabled: { isReadonly }
    };

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Dates</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={7} sm={7} md={7}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CREATIONDATE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CREATIONDATE")}>
              <Trans>Creation Date</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              format="dd/MM/yyyy"
              variant="inline"
              margin="normal"
              id="externalCreationDate"
              value={item.externalCreationDate}
              onChange={e => itemChanged({ ...item, externalCreationDate: Tools.dateToUtc(e) })}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PLANNEDSTARTDATE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/PLANNEDSTARTDATE")}>
              <Trans>Start Date</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              format="dd/MM/yyyy"
              variant="inline"
              margin="normal"
              id="plannedStartDate"
              value={item.plannedStartDate}
              onChange={e => itemChanged({ ...item, plannedStartDate: Tools.dateToUtc(e) })}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <FormLabel>
              <Trans>End Date</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              format="dd/MM/yyyy"
              variant="inline"
              margin="normal"
              id="plannedCompletionDate"
              value={item.plannedCompletionDate}
              onChange={e => itemChanged({ ...item, plannedCompletionDate: Tools.dateToUtc(e) })}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/LASTSTATUSMODIFICATIONDATE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/LASTSTATUSMODIFICATIONDATE")}>
              <Trans>Last Status Update</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <KeyboardDatePicker
              autoOk={true}
              disableToolbar
              format="dd/MM/yyyy"
              variant="inline"
              margin="normal"
              id="lastStatusModificationDate"
              value={item.lastStatusModificationDate}
              onChange={e => itemChanged({ ...item, lastStatusModificationDate: Tools.dateToUtc(e) })}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobDatesPart));
