import { ApimGet, ApimPost, ApimPostPromise } from "components/Common/ApimSender";

export function CompanyGet(companyIdentifier, callbackFn, errorFn) {
  ApimGet("api/TpCopernic/Companies/" + companyIdentifier, c => callbackFn(c), e => errorFn(e));
}

export function CompanySearch(criterias) {
  return ApimPostPromise("api/TpCopernic/Companies/search", criterias);
}

export function ComplianceCheck(companyIdentifier, callbackFn, errorFn) {
  return ApimGet(`api/TpCopernic/Companies/${companyIdentifier}/compliance/check`, countries => callbackFn(countries), e => errorFn(e));
}

export function ComplianceGet(companyIdentifier, callbackFn, errorFn) {
  return ApimGet(`api/TpCopernic/Companies/${companyIdentifier}/compliance`, countries => callbackFn(countries), e => errorFn(e));
}

export function GetCompanyThirdParties(companyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/Companies/" + companyId + "/thirdParties", callbackFn, errorFn);
}

///---------------------------------------------------
/// Get History
///---------------------------------------------------
export function GetCompanyHistory(companyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/Companies/" + companyId + "/histories", callbackFn, errorFn);
}
