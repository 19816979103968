import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import ProjectFlagsPart from "./Parts/ProjectFlagsPart";
import ProjectIdentifierAndSourcePart from "./Parts/ProjectIdentifierAndSourcePart";
import ProjectLinksPart from "./Parts/ProjectLinksPart";

function ProjectGeneralTab({ isReadonly }) {
  return (
    <GridContainer>
      <GridItem xs={6} sm={6} md={6}>
        <ProjectIdentifierAndSourcePart isReadonly={isReadonly} />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <ProjectLinksPart isReadonly={isReadonly} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <ProjectFlagsPart isReadonly={isReadonly} />
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(amcSearchStyle)(ProjectGeneralTab);
