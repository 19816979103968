import { ApimGet, ApimPost } from "components/Common/ApimSender";
import { HasRight } from "services/user/UserHelper";
import {
  CommonCurrency,
  JobActivityLevel3,
  JobNatureOfWork,
  JobSegmentationLevel1,
  JobStatus,
  LoadMasterValues
} from "store/MasterValue/MasterValueTypes";
import uuidv4 from "uuid/v4"; // Generate GUID
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import * as Actions from "./types";

export function SetComplexCriterias(complexQuery, criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_COMPLEX_CRITERIA,
      module: "JOB",
      complexCriterias: criterias,
      complexQuery: complexQuery
    });
  };
}

export function SetCriterias(criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_CRITERIA,
      module: "JOB",
      criterias: criterias
    });
  };
}

export function AddItem() {
  return (dispatch, getState) => {
    var item = { isReadOnly: false };
    if (HasRight("amc.add_job")) {
      item = { ...item, applicationSource: "IMPUTATION" };
    }
    dispatch({
      type: Actions.AMC_GET_END,
      module: "JOB",
      item: item
    });
  };
}

export function SelectItem(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_END,
      module: "JOB",
      item: item
    });
  };
}

export function SetSearchMode(searchMode) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_SEARCHMODE,
      module: "JOB",
      searchMode: searchMode
    });
  };
}

export function ItemChanged(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOB",
      item: item
    });
  };
}

export function AddPhase() {
  return (dispatch, getState) => {
    var localId = uuidv4();
    dispatch({
      type: Actions.AMC_GET_END,
      module: "JOBPHASE",
      item: { localId: localId }
    });
  };
}

export function AddPhaseBudget() {
  return (dispatch, getState) => {
    const item = getState().AMC_Reducer.currentItem["JOB"];
    if (!item.jobPhaseBudgets || typeof item.jobPhaseBudgets === "undefined") item.jobPhaseBudgets = [];
    var localId = uuidv4();
    item.jobPhaseBudgets.push({ localId: localId });

    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOB",
      item: item
    });
  };
}

export function AddPhaseFinalProjection() {
  return (dispatch, getState) => {
    const item = getState().AMC_Reducer.currentItem["JOB"];
    if (!item.jobPhaseFinalProjections || typeof item.jobPhaseFinalProjections === "undefined") item.jobPhaseFinalProjections = [];
    var localId = uuidv4();
    item.jobPhaseFinalProjections.push({ localId: localId });

    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOB",
      item: item
    });
  };
}

export function Crupdate() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "JOB"
    });

    const item = getState().AMC_Reducer.currentItem["JOB"];

    ApimPost(
      "api/Jobs",
      item,
      () => {
        dispatch({
          type: Actions.AMC_CLEAR_ITEM_CHANGED,
          module: "JOB"
        });
        dispatch({
          type: Actions.AMC_CLEAR_ITEM_CHANGED,
          module: "JOBPHASE"
        });
        dispatch({
          type: Actions.AMC_CLEAR_ITEM_CHANGED,
          module: "JOBPHASEBUDGET"
        });
        dispatch({
          type: Actions.AMC_CLEAR_ITEM_CHANGED,
          module: "JOBPHASEFINALPROJECTION"
        });
        dispatch({
          type: Actions.AMC_UPDATE_END,
          module: "JOB"
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "JOB",
          error: error.response.data
        });
      }
    );
  };
}

export function GenerateNotification() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "JOB"
    });

    const item = getState().AMC_Reducer.currentItem["JOB"];

    ApimPost(
      "api/Jobs/GenerateNotification",
      item,
      () => {
        dispatch({
          type: Actions.AMC_UPDATE_END,
          module: "JOB"
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "JOB",
          error: error.response.data
        });
      }
    );
  };
}

export function Validate() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "JOB"
    });

    const item = getState().AMC_Reducer.currentItem["JOB"];

    ApimPost(
      "api/Jobs/Validate",
      item,
      responseJson => {
        dispatch({
          type: Actions.AMC_ERROR,
          module: "JOB",
          error: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "JOB",
          error: error.response.data
        });
      }
    );
  };
}

export function Get(id) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_BEGIN,
      module: "JOB"
    });

    ApimGet(
      "api/Jobs/" + id,
      responseJson => {
        LoadMasterValues([CommonCurrency, JobActivityLevel3, JobNatureOfWork, JobStatus, JobSegmentationLevel1]).then(() => {
          dispatch({
            type: Actions.AMC_GET_END,
            module: "JOBPHASE",
            item: null
          });
          dispatch({
            type: Actions.AMC_GET_END,
            module: "JOB",
            item: responseJson
          });
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

export function ComplexSearch() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SEARCH_BEGIN,
      module: "JOB"
    });

    const criterias = getState().AMC_Reducer.complexCriterias["JOB"];
    const simpleCriterias = getState().AMC_Reducer.criterias["JOB"];
    var pagingAndOrderProperties = { pageSize: 10 };

    if (!!simpleCriterias) {
      pagingAndOrderProperties = {
        orderProperty: !!simpleCriterias.orderProperty && typeof simpleCriterias.orderProperty !== "undefined" ? simpleCriterias.orderProperty : null,
        isDescendingOrder: typeof simpleCriterias.isDescendingOrder !== "undefined" ? simpleCriterias.isDescendingOrder : true,
        startPage: !!simpleCriterias.startPage && typeof simpleCriterias.startPage !== "undefined" ? simpleCriterias.startPage : 0,
        pageSize: simpleCriterias.pageSize
      };
    }

    var objectCriteria = {
      sqlRequest: criterias,
      pagingAndOrderProperties: pagingAndOrderProperties
    };

    ApimPost(
      "api/Jobs/ComplexSearch",
      objectCriteria,
      responseJson => {
        dispatch({
          type: Actions.AMC_SEARCH_END,
          module: "JOB",
          results: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );

    //const complexCriterias = getState().AMC_Reducer.complexCriterias['JOB'];
  };
}

export function Search() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SEARCH_BEGIN,
      module: "JOB"
    });

    const searchMode = getState().AMC_Reducer.searchMode["JOB"];
    const criterias = getState().AMC_Reducer.criterias["JOB"];

    const queryString = ConvertCriteriaToQueryString(criterias, searchMode);
    ApimGet(
      "api/Jobs" + queryString,
      responseJson => {
        dispatch({
          type: Actions.AMC_SEARCH_END,
          module: "JOB",
          results: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria, searchMode) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.criteriaFullText) && searchMode === "Simple") {
    queryParams.push("criterias.fullText=" + criteria.criteriaFullText);
  } else if (searchMode !== "Simple") {
    if (!isNullOrEmpty(criteria.mdmId)) {
      queryParams.push("criterias.mdmId=" + criteria.mdmId);
    }
    if (!isNullOrEmpty(criteria.ertCode)) {
      queryParams.push("criterias.ertCode=" + criteria.ertCode);
    }
    if (!isNullOrEmpty(criteria.sjCode)) {
      queryParams.push("criterias.sjCode=" + criteria.sjCode);
    }
    if (!isNullOrEmpty(criteria.ueCode)) {
      queryParams.push("criterias.ueCode=" + criteria.ueCode);
    }
    if (!isNullOrEmpty(criteria.label)) {
      queryParams.push("criterias.label=" + criteria.label);
    }
    if (!isNullOrEmpty(criteria.erpIdentifier)) {
      queryParams.push("criterias.erpIdentifier=" + criteria.erpIdentifier);
    }
    if (!isNullOrEmpty(criteria.localId)) {
      queryParams.push("criterias.localId=" + criteria.localId);
    }
    if (!isNullOrEmpty(criteria.analyticalReference)) {
      queryParams.push("criterias.analyticalReference=" + criteria.analyticalReference);
    }
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("pagingAndOrder.orderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("pagingAndOrder.isDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("pagingAndOrder.startPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("pagingAndOrder.pageSize=" + criteria.pageSize);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
