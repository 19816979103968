import React from "react";
import WorkflowList from "../module/workflow/containers/WorkflowList";
import Search from "@material-ui/icons/Search";
//lingui
import { Trans } from "@lingui/macro";

const workflowRoutes = [
  {
    path: "/workflows",
    name: <Trans>Workflow Search</Trans>,
    short: <Trans>Workflow Search</Trans>,
    icon: Search,
    useAsComponentInMenu: true,
    component: WorkflowList
  }
];

export default workflowRoutes;
