import React, { useEffect, useState } from "react";
import SupervisionGlobalContainer from "../common/SupervisionGlobalContainer";
import SupervisionGlobalProcess from "../common/SupervisionGlobalProcess";
import SupervisionGlobalItem from "../common/SupervisionGlobalItem";
import { isNullOrEmpty } from "tools";
import { SearchAppInsightDatasForGlobal } from "services/report/ReportHelper";
import { CheckService } from "services/report/ReportHelper";

function InfolegalePart() {
  const [statusFiles, setStatusFiles] = useState("SEARCHING");
  const [statusServices, setStatusServices] = useState("SEARCHING");
  const [statusServiceSarch, setStatusServiceSearch] = useState("SEARCHING");
  const [statusServiceDetail, setStatusServiceDetail] = useState("SEARCHING");

  useEffect(() => {
    SearchAppInsightDatasForGlobal("EventHubTrigger_Annonce", 24, datas => {
      if (!isNullOrEmpty(datas) && datas.length > 0) {
        setStatusFiles("OK");
      } else setStatusFiles("EMPTY");
    });

    CheckService("INFOLEGALE", datas => {
      setStatusServices(datas.statusGolbal);
      setStatusServiceSearch(datas.statusSearch);
      setStatusServiceDetail(datas.statusDetail);
    });
  }, []);

  return (
    <>
      <SupervisionGlobalContainer name="Infolegale">
        <>
          <SupervisionGlobalProcess name="Statut du lien" status={statusServices}>
            <SupervisionGlobalItem name="Service de recherche" status={statusServiceSarch} />
            <SupervisionGlobalItem name="Service de récupération d'un tiers" status={statusServiceDetail} />
          </SupervisionGlobalProcess>
          <SupervisionGlobalProcess name="Récupération fichiers quotidiens" status={statusFiles} />
        </>
      </SupervisionGlobalContainer>
    </>
  );
}

export default InfolegalePart;
