import { useMsal } from "@azure/msal-react";
import { Trans } from "@lingui/macro";
import {
  Avatar,
  Chip,
  Collapse,
  Drawer,
  Hidden,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  withStyles
} from "@material-ui/core";
import { AccountCircleOutlined, Close, Delete, ExpandLess, ExpandMore, History } from "@material-ui/icons";
import logo from "assets/images/hubble.png";
import image from "assets/images/visuel_colas.jpg";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import cx from "classnames";
import HeaderLinks from "components/Header/HeaderLinks";
import React, { useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Logout } from "services/authentication/MsalAuth";
import { HasRight } from "services/user/UserHelper";
import { RootStore } from "store/RootStore";
import { isNull, isNullOrEmpty } from "tools";
// import * as Actions from "/store/actions";
import { OpenThirdParty } from "../../module/tpCopernic/store/actions";
import { OpenStructure } from "../../module/structure/store/actions";
import { OpenSite } from "../../module/site/store/actions";
import { removeHistoryThirdParty, removeHistoryStructure, removeHistorySite } from "store/History/HistoryActions";

const Sidebar = ({ classes, routes, drawerOpen, setDrawerOpen, rtlActive, miniFixed, thirdPartiesReducer, structuresReducer, sitesReducer }) => {
  const [miniActive, setMiniActive] = useState(miniFixed);
  var mini = !miniFixed && miniActive;
  const brand = <SidebarBrand classes={classes} miniActive={mini} rtlActive={rtlActive} />;

  const drawerPaper = cx(classes.drawerPaper, {
    [classes.drawerPaperMini]: mini,
    [classes.drawerPaperRTL]: rtlActive
  });
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? "left" : "right"}
          open={drawerOpen}
          classes={{
            paper: drawerPaper + " " + classes["blackBackground"]
          }}
          onClose={() => setDrawerOpen(false)}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={cx(classes.sidebarWrapper, {
              [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
            })}
            headerLinks={<HeaderLinks />}
            rtlActive={rtlActive}
            classes={classes}
            routes={routes}
            miniActive={false}
            thirdPartiesReducer={thirdPartiesReducer}
            structuresReducer={structuresReducer}
            sitesReducer={sitesReducer}
          />
          <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          onMouseOver={() => setMiniActive(miniFixed)}
          onMouseOut={() => setMiniActive(true)}
          anchor={rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes["blackBackground"]
          }}
        >
          {brand}
          <SidebarWrapper
            classes={classes}
            routes={routes}
            miniActive={mini}
            rtlActive={rtlActive}
            thirdPartiesReducer={thirdPartiesReducer}
            structuresReducer={structuresReducer}
            sitesReducer={sitesReducer}
          />
          {image !== undefined ? <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} /> : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

const SidebarWrapper = ({ classes, routes, miniActive, rtlActive, thirdPartiesReducer, structuresReducer, sitesReducer }) => {
  return (
    <div
      className={cx(classes.sidebarWrapper, {
        [classes.drawerPaperMini]: miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
      })}
    >
      <SidebarUser classes={classes} miniActive={miniActive} rtlActive={rtlActive} />
      <SidebarLinks
        classes={classes}
        routes={routes}
        rtlActive={rtlActive}
        miniActive={miniActive}
        thirdPartiesReducer={thirdPartiesReducer}
        structuresReducer={structuresReducer}
        sitesReducer={sitesReducer}
      />
    </div>
  );
};

const SidebarBrand = ({ classes, miniActive, rtlActive }) => {
  const logoNormal = cx(classes.logoNormal, {
    [classes.logoNormalSidebarMini]: miniActive,
    [classes.logoNormalSidebarMiniRTL]: rtlActive && miniActive,
    [classes.logoNormalRTL]: rtlActive
  });
  const logoMini = cx(classes.logoMini, {
    [classes.logoMiniRTL]: rtlActive
  });
  const logoClasses = classes.logo;
  var environmentChip = "";
  var pageTitle = "Hubble";

  let environment;
  if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
    environment = { label: "LOCAL", color: "#CCCCCC" };
  } else {
    environment = { label: process.env.REACT_APP_ENV_NAME, color: process.env.REACT_APP_ENV_COLOR };
  }

  if (!isNullOrEmpty(environment?.label) && environment.label !== "E4") {
    environmentChip = <Chip label={environment.label} style={{ marginLeft: 15, backgroundColor: environment.color }} />;
    pageTitle += " - " + environment.label;
  }
  document.title = pageTitle;

  return (
    <div className={logoClasses}>
      <NavLink to="/" className={logoMini}>
        <img src={logo} alt="logo" className={classes.img} />
      </NavLink>
      <NavLink to="/" className={logoNormal}>
        <span style={{ color: "#00acc1" }}>Hub</span>ble
        {environmentChip}
      </NavLink>
    </div>
  );
};

const SidebarUser = ({ classes, miniActive, rtlActive }) => {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);

  let graphUser = RootStore.getState().AuthenticationReducer.payload;
  let hubbleUser = RootStore.getState().AuthenticationReducer.user;
  var userInitiales = hubbleUser.firstName
    ? hubbleUser.firstName[0] + hubbleUser.lastName[0]
    : graphUser
    ? (graphUser.givenName[0] + graphUser.surname[0]).toUpperCase()
    : "AA";

  let userFullName = graphUser
    ? cleanDisplayName(graphUser.displayName)
    : hubbleUser
    ? { primary: hubbleUser.firstName + " " + hubbleUser.lastName }
    : { primary: "Anonymous" };

  return (
    <div>
      <List>
        <ListItem onClick={() => setOpen(!open)} className={classes.itemHover}>
          <ListItemIcon>
            <Avatar src={graphUser.picture}>{userInitiales}</Avatar>
          </ListItemIcon>
          {!miniActive && (
            <ListItemText
              primary={userFullName.primary}
              secondary={userFullName.secondary}
              secondaryTypographyProps={{ style: { color: "white", fontStyle: "italic", fontSize: "0.7rem" } }}
            />
          )}
          {!miniActive && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }} onClick={() => Logout(instance)} className={cx(classes.itemHover)}>
              <ListItemIcon className={classes.itemIcon}>
                <Close />
              </ListItemIcon>
              {!miniActive && <ListItemText primary={<Trans>LogOut</Trans>} />}
            </ListItem>
            <ListItem sx={{ pl: 4 }} component={NavLink} to="/profile/myProfile" className={cx(classes.itemHover)}>
              <ListItemIcon className={classes.itemIcon}>
                <AccountCircleOutlined />
              </ListItemIcon>
              {!miniActive && <ListItemText primary={<Trans>My Profile</Trans>} />}
            </ListItem>
          </List>
        </Collapse>

        {/* <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink to={"#"} className={classes.itemLink + " " + classes.userCollapseButton} onClick={() => setOpen(!open)}>
            <ListItemText
              primary={userFullName}
              secondary={<b className={caret + " " + classes.userCaret + " " + (open ? classes.caretActive : "")} />}
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </NavLink>
          <Collapse in={open} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              <ListItem className={classes.collapseItem}>
                <NavLink to={"#"} className={classes.itemLink + " " + classes.userCollapseLinks} onClick={Logout}>
                  <span className={collapseItemMini}>{<Trans>LO</Trans>}</span>

                  <ListItemText primary={<Trans> LogOut</Trans>} disableTypography={true} className={collapseItemText} />
                </NavLink>
              </ListItem>
              <ListItem className={classes.collapseItem}>
                <NavLink to="/profile" className={classes.itemLink + " " + classes.userCollapseLinks} activeClassName={classes["blue"]}>
                  <span className={collapseItemMini}>{<Trans>MP</Trans>}</span>
                  <ListItemText primary={<Trans>My Profile</Trans>} disableTypography={true} className={collapseItemText} />
                </NavLink>
              </ListItem>
            </List>
          </Collapse>
        </ListItem> */}
      </List>
    </div>
  );
};

const SidebarLinks = ({ classes, routes, miniActive, rtlActive, thirdPartiesReducer, structuresReducer, sitesReducer }) => {
  const location = useLocation();
  const appSelector = useSelector(s => s.AuthenticationReducer);
  const rights = appSelector.user.rights;
  const isMdmAdmin = rights && rights.some(r => r === "application.admin");
  var dispatch = useDispatch();
  return (
    <List className={classes.list}>
      {routes
        .filter(r => !isNull(r) && r.path.split("/").length > 1 && r.path.split("/")[1] === location.pathname.split("/")[1])
        .filter(r => isMdmAdmin || !r.mustHaveRights || rights.some(ri => ri === r.mustHaveRights))
        .map((prop, key) => {
          if (!!prop.role) {
            if (!HasRight(prop.role)) return null;
          }
          if (prop.redirect || (prop.hideInMenu !== null && prop.hideInMenu)) {
            return null;
          }
          if (prop.collapse) {
            return (
              <SidebarCollapse
                key={key}
                classes={classes}
                icon={prop.icon}
                views={prop.views}
                name={prop.name}
                miniActive={miniActive}
                rtlActive={rtlActive}
              />
            );
          }
          const navLinkClasses = classes.itemLink;
          const itemText = cx(classes.itemText, {
            [classes.itemTextMini]: miniActive,
            [classes.itemTextMiniRTL]: rtlActive && miniActive,
            [classes.itemTextRTL]: rtlActive
          });
          const itemIcon = cx(classes.itemIcon, {
            [classes.itemIconRTL]: rtlActive
          });
          if (prop.useAsComponentInMenu) {
            var ThirdPartiesHistory = "";
            if (thirdPartiesReducer !== null && thirdPartiesReducer !== undefined && thirdPartiesReducer.length > 0) {
              ThirdPartiesHistory = thirdPartiesReducer.map((thirdParty, key) => {
                return (
                  <List key={key} dense={true} className={classes.list}>
                    <ListItem className={classes.item}>
                      <Tooltip
                        placement="right"
                        title={
                          <>
                            {thirdParty.longName} ({thirdParty.identifier})<br />
                            <strong>
                              <Trans>LegalIdentifier</Trans>
                            </strong>{" "}
                            {thirdParty.legalIdentifier}
                            <br />
                            <strong>
                              <Trans>Duns</Trans>
                            </strong>{" "}
                            {thirdParty.duns}
                            <br />
                            <strong>
                              <Trans>City</Trans>
                            </strong>{" "}
                            {thirdParty.address_City} - {thirdParty.address_CountryCode}
                          </>
                        }
                      >
                        <Link className={classes.itemLinkHistory} onClick={e => dispatch(OpenThirdParty(thirdParty.identifier))}>
                          <span>
                            <History fontSize="small" color="inherit" />
                          </span>
                          <span className={classes.TextStyle}> {thirdParty.identifier + "=>" + thirdParty.longName}</span>
                        </Link>
                      </Tooltip>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" label="Delete" onClick={e => dispatch(removeHistoryThirdParty(thirdParty))}>
                          <Delete color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                );
              });
            }
          }
          if (prop.useAsComponentSiteInMenu) {
            var sitesHistory = "";
            if (sitesReducer !== null && sitesReducer !== undefined && sitesReducer.length > 0) {
              sitesHistory = sitesReducer.map((site, key) => {
                if (site.identifier !== 0) {
                  return (
                    <List key={key} dense={true} className={classes.list}>
                      <ListItem className={classes.item}>
                        <Link className={classes.itemLinkHistory} onClick={e => dispatch(OpenSite(site.identifier))}>
                          <span>
                            <History fontSize="small" color="inherit" />
                          </span>
                          <span className={classes.TextStyle}> {site.identifier + "=>" + site.name}</span>
                        </Link>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="Delete" onClick={e => dispatch(removeHistorySite(site))}>
                            <Delete color="primary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  );
                }
              });
            }
          }
          if (prop.useAsComponentStructureInMenu) {
            var structuresHistory = "";
            if (structuresReducer !== null && structuresReducer !== undefined && structuresReducer.length > 0) {
              structuresHistory = structuresReducer.map((structure, key) => {
                if (structure.id !== 0) {
                  return (
                    <List key={key} dense={true} className={classes.list}>
                      <ListItem className={classes.item}>
                        <Link
                          className={classes.itemLinkHistory}
                          onClick={e => dispatch(OpenStructure(structure.structureType, structure.identifiant))}
                        >
                          <span>
                            <History fontSize="small" color="inherit" />
                          </span>
                          <span className={classes.TextStyle}> {structure.identifiant + "=>" + structure.libelle}</span>
                        </Link>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="Delete" onClick={event => dispatch(removeHistoryStructure(structure))}>
                            <Delete color="primary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  );
                }
              });
            }
          }
          if (prop.useAsComponentProductInMenu) {
            var productsHistory = "";
            var products = RootStore.getState().HistoryReducer.Products;
            if (products !== null && products !== undefined) {
              productsHistory = products.map((product, key) => {
                if (product.identifier !== 0) {
                  return (
                    <List key={key} dense={true} className={classes.list}>
                      <ListItem className={classes.item}>
                        <NavLink to={`/products?identifier=${product.identifier}`} className={classes.itemLinkHistory}>
                          <span>
                            <History fontSize="small" color="inherit" />
                          </span>
                          <span className={classes.TextStyle}>{product.identifier + "=>" + product.labelCommercial}</span>
                        </NavLink>
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="Delete" onClick={event => this.handleProductDelete(product, event)}>
                            <Delete color="primary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  );
                }
              });
            }
          }

          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses} activeClassName={classes["blue"]}>
                <ListItemIcon className={itemIcon}>{typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}</ListItemIcon>
                <ListItemText primary={prop.name} disableTypography={true} className={itemText} />
              </NavLink>
              {ThirdPartiesHistory}
              {productsHistory}
              {sitesHistory}
              {structuresHistory}
            </ListItem>
          );
        })}
    </List>
  );
};

const SidebarCollapse = ({ classes, icon, views, name, miniActive, rtlActive }) => {
  const [open, setOpen] = useState(false);

  const navLinkClasses = classes.itemLink;
  const itemText = cx(classes.itemText, {
    [classes.itemTextMini]: miniActive,
    [classes.itemTextMiniRTL]: rtlActive,
    [classes.itemTextRTL]: rtlActive
  });
  const collapseItemText = cx(classes.collapseItemText, {
    [classes.collapseItemTextMini]: miniActive,
    [classes.collapseItemTextMiniRTL]: rtlActive && miniActive,
    [classes.collapseItemTextRTL]: rtlActive
  });
  const itemIcon = cx(classes.itemIcon, {
    [classes.itemIconRTL]: rtlActive
  });
  const caret = cx(classes.caret, {
    [classes.caretRTL]: rtlActive
  });
  return (
    <ListItem className={classes.item}>
      <NavLink to={"#"} className={navLinkClasses} onClick={() => setOpen(!open)}>
        <ListItemIcon className={itemIcon}>{typeof icon === "string" ? <Icon>{icon}</Icon> : icon}</ListItemIcon>
        <ListItemText
          primary={name}
          secondary={<b className={caret + " " + (open ? classes.caretActive : "")} />}
          disableTypography={true}
          className={itemText}
        />
      </NavLink>
      <Collapse in={open} unmountOnExit>
        <List className={classes.list + " " + classes.collapseList}>
          {views.map((prop, key) => {
            if (prop.redirect) {
              return null;
            }
            const navLinkClasses = classes.collapseItemLink;
            const collapseItemMini = cx(classes.collapseItemMini, {
              [classes.collapseItemMiniRTL]: rtlActive
            });
            return (
              <ListItem key={key} className={classes.collapseItem}>
                <NavLink to={prop.path} className={navLinkClasses} activeClassName={classes["blue"]}>
                  <span className={collapseItemMini}>{prop.mini}</span>
                  <ListItemText primary={prop.name} disableTypography={true} className={collapseItemText} />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </ListItem>
  );
};

const cleanDisplayName = name => {
  if (name.indexOf("(") > 0) {
    var primary = name.substring(0, name.indexOf("("));
    var secondary = name.substring(name.indexOf("(") + 1, name.lastIndexOf(")"));
    return { primary, secondary };
  }
  return { primary: name };
};
const mapStateToProps = state => {
  return {
    thirdPartiesReducer: state.HistoryReducer.ThirdParties,
    structuresReducer: state.HistoryReducer.Structures,
    sitesReducer: state.HistoryReducer.Sites
  };
};

export default connect(mapStateToProps)(withStyles(sidebarStyle)(Sidebar));
