import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { StructureErt, StructureSj, StructureUe } from "store/MasterValue/MasterValueTypes";

function CustomerContractSearchCriteria({
  showSearchCriteriaOnSideBar,
  amc,
  structureUes,
  structureSjs,
  structureErts,
  setCriterias,
  setSearchMode,
  search,
  classes
}) {
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var defaultSearchMode = "Simple";
  if (!!getPref && !!getPref.Pref && !!getPref.Pref.Search) {
    defaultSearchMode = getPref.Pref.Search;
  }

  const criterias = amc.criterias["CUSTOMERCONTRACT"];

  var allStructures = [];
  if (!!structureErts) allStructures = allStructures.concat(structureErts);
  if (!!structureSjs) allStructures = allStructures.concat(structureSjs);
  if (!!structureUes) allStructures = allStructures.concat(structureUes);

  var selectedStructure = null;
  if (!!criterias.ertCode) selectedStructure = criterias.ertCode;
  if (!!criterias.sjCode) selectedStructure = criterias.sjCode;
  if (!!criterias.ueCode) selectedStructure = criterias.ueCode;

  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  var countrySimpleSearchWidth = 3;
  var simpleSearchWidth = 9;
  var divMultiSelectStyle = { width: "100%" };
  if (showSearchCriteriaOnSideBar) {
    countrySimpleSearchWidth = 12;
    simpleSearchWidth = 12;
    divMultiSelectStyle = { width: "100%", marginTop: "10px" };
  }

  function launchSearch(e) {
    e.preventDefault();
    search();
  }

  function cleanSearchCriterias(e) {
    e.preventDefault();
    var newCriterias = { pageSite: criterias.pageSite, criteriaFullText: "", criteriaMdmId: "" };
    setCriterias(newCriterias);
  }

  return (
    <form onSubmit={launchSearch} autoComplete="off">
      <GridContainer>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Mdm id</Trans>}
            id="criteriaMdmId"
            onChange={e => {
              setCriterias({ ...criterias, mdmId: e.target.value });
            }}
            value={criterias.mdmId}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Label</Trans>}
            id="label"
            onChange={e => {
              setCriterias({ ...criterias, label: e.target.value });
            }}
            value={criterias.label}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>ERP Identifier</Trans>}
            id="label"
            onChange={e => {
              setCriterias({ ...criterias, erpIdentifier: e.target.value });
            }}
            value={criterias.erpIdentifier}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <CustomInput
            labelText={<Trans>Local Id</Trans>}
            id="label"
            onChange={e => {
              setCriterias({ ...criterias, localId: e.target.value });
            }}
            value={criterias.localId}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}></GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}></GridItem>
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          <OldStructureAutocompleteWithDefaultValue
            label={"Structures"}
            structures={allStructures}
            selectedValue={selectedStructure}
            onValueChange={value => {
              var ert = null;
              var sj = null;
              var ue = null;
              if (!!value && value.indexOf("ERT") > -1) ert = value;
              if (!!value && value.indexOf("SJ") > -1) sj = value;
              if (!!value && value.indexOf("UE") > -1) ue = value;
              setCriterias({ ...criterias, ertCode: ert, sjCode: sj, ueCode: ue });
            }}
          />
        </GridItem>
      </GridContainer>
      <div className={classes.flexRight}>
        <Button onClick={cleanSearchCriterias}>
          <Trans> Clean </Trans>
        </Button>
        <Button type="submit" color="info" onClick={launchSearch}>
          <Trans>Search</Trans>
        </Button>
      </div>
    </form>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    structureSjs: state.MasterValueReducer[StructureSj],
    structureErts: state.MasterValueReducer[StructureErt],
    structureUes: state.MasterValueReducer[StructureUe]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    setSearchMode: searchMode => {
      dispatch(Actions.SetSearchMode(searchMode));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractSearchCriteria));
