import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import {
  TpDematerializationType,
  TpFee,
  TpGroup,
  TpPaymentMethod,
  TpPaymentPeriod,
  TpScope,
  TpSupplierNature,
  TpSupplierStatus
} from "store/MasterValue/MasterValueTypes";

const TabSupplierGeneral = function({ supplier, setSupplier, isEditable, classes }) {
  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Common</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Short Name</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={isEditable}
                        id="shortName"
                        value={supplier.shortName}
                        onChange={e => {
                          var value = e.target.value;
                          if (value.length > 8) value = value.substring(0, 8);
                          setSupplier({ ...supplier, shortName: value });
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Nature</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpSupplierNature}
                        prefixCode
                        selectedValue={supplier.supplierNatureCode}
                        onValueChange={value => setSupplier({ ...supplier, supplierNatureCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Scope</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        mvType={TpScope}
                        prefixCode
                        selectedValue={supplier.scopeCode}
                        onValueChange={value => setSupplier({ ...supplier, scopeCode: value })}
                        isRequired
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Fee</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpFee}
                        prefixCode
                        selectedValue={supplier.feeCode}
                        onValueChange={value => setSupplier({ ...supplier, feeCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={supplier.isHandicapSector}
                            disabled={!isEditable}
                            onChange={e => setSupplier({ ...supplier, isHandicapSector: e.target.checked })}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={<Trans>Handicap sector</Trans>}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Default Payment Conditions</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Payment Period</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpPaymentPeriod}
                        prefixCode
                        selectedValue={supplier.paymentPeriodCode}
                        onValueChange={value => setSupplier({ ...supplier, paymentPeriodCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Payment Method</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpPaymentMethod}
                        prefixCode
                        selectedValue={supplier.paymentMethodCode}
                        onValueChange={value => setSupplier({ ...supplier, paymentMethodCode: value })}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <div>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Status</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>Status</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <MasterValueAutocomplete
                      isEditable={isEditable}
                      isRequired
                      mvType={TpSupplierStatus}
                      prefixCode
                      selectedValue={supplier.supplierStatusCode}
                      onValueChange={value => setSupplier({ ...supplier, supplierStatusCode: value })}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isActif}
                          disabled={!isEditable}
                          onChange={e => setSupplier({ ...supplier, isActif: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans> supplier isActive </Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isCommandAuthorized}
                          disabled={!isEditable}
                          onChange={e => setSupplier({ ...supplier, isCommandAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Command Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isReceptionAuthorized}
                          disabled={!isEditable}
                          onChange={e => setSupplier({ ...supplier, isReceptionAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Reception Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isBillingRecordingAuthorized}
                          disabled={!isEditable}
                          onChange={e => setSupplier({ ...supplier, isBillingRecordingAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Billing Record Authorized</Trans>}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                          checked={supplier.isPaymentAuthorized}
                          disabled={!isEditable}
                          onChange={e => setSupplier({ ...supplier, isPaymentAuthorized: e.target.checked })}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={<Trans>Payment Authorized</Trans>}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Dematerialization</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <FormLabel>
                      <Trans>DematerializationType</Trans>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={8}>
                    <MasterValueAutocomplete
                      isEditable={isEditable}
                      isRequired
                      mvType={TpDematerializationType}
                      prefixCode
                      selectedValue={supplier.dematerializationTypeCode}
                      onValueChange={value => setSupplier({ ...supplier, dematerializationTypeCode: value })}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabSupplierGeneral);
