import withStyles from "@material-ui/core/styles/withStyles";
import workflowStyle from "assets/jss/material-dashboard-pro-react/components/workflowStyle.jsx";
import * as Actions from "module/workflow/store/actions/Workflow.Actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoadMasterValues, WorkflowStatus, WorkflowStep, WorkflowType } from "store/MasterValue/MasterValueTypes";
import { CloseWorkflow } from "../store/actions/Workflow.Actions";
import StructureUe from "./StructureUeWorkflow/StructureUe";
import StructureSj from "./StructureUeWorkflow/StructureSj";
import PayCharCreation from "./payCharWorkflow/PayCharCreation";
import RequestSupplierCreation from "./purchaserWorkflow/RequestSupplierCreation";
import LongNameShortening from "./ThirdPartyWorkflow/LongNameShortening";
import InfolegaleMerge from "./ThirdPartyWorkflow/InfolegaleMerge";
import LocationCrupdate from "./LocationWorkflow/LocationCrupdate";

function WorkflowDetail({ workflowTypeCode, workflowId, closeWorkflow, masterValues, currentUser, defaultLang }) {
  const [state, setState] = useState({ isLoading: false, globalErrors: null, errors: [] });
  const [workflow, setWorkflow] = useState(null);

  const loadWorkflow = (wkfTypeCode, wkfId) => {
    setState({ ...state, workflowBackup: null, isLoading: true, globalErrors: null, errors: [] });
    LoadMasterValues([WorkflowStatus, WorkflowStep, WorkflowType]).then(() => {
      Actions.GetWorkflow(
        wkfTypeCode,
        wkfId,
        c => {
          setState({ ...state, isLoading: false, workflowBackup: JSON.stringify(c) });
          if (!c.identifier) c = { identifier: wkfId };
          setWorkflow(c);
        },
        e => {
          setState({ ...state, isLoading: false, errors: e });
        }
      );
    });
  };

  useEffect(() => {
    if (workflowId > 0) {
      loadWorkflow(workflowTypeCode, workflowId);
    } else if (workflowId === 0) {
      var user = currentUser;
      var wkf = {
        label: "Demande de Référencement d'un nouveau fournisseur",
        workflowStatusCode: "Started",
        workflowTypeCode: "SupplierRequest",
        creationUser: user.identifier,
        workflowSupplier: {
          applicantName: user.firstName + " " + user.lastName,
          applicantLogin: user.identifier,
          applicantFunction: user.function
        }
      };
      setState({ ...state, workflowBackup: JSON.stringify(wkf), isLoading: false, globalErrors: null, errors: [] });
      setWorkflow(wkf);
    } else {
      setWorkflow(null);
    }
  }, [workflowId]);

  const abortWorkflow = wkf => {
    if (wkf) {
      setWorkflow(wkf);
    } else wkf = workflow;
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.SaveWorkflow(wkf)
      .then(w => {
        setWorkflow(w);
        Actions.AbortWorkflow({ ...w, comment: wkf.comment })
          .then(c => {
            if (c.workflowProcessStatus === 0) {
              loadWorkflow(c.workflow.workflowTypeCode, c.workflow.identifier);
            } else {
              setState({ ...state, isLoading: false, errors: c.errorMessages });
            }
          })
          .catch(e => {
            setState({ ...state, isLoading: false, globalErrors: e });
          });
      })
      .catch(e => {
        setState({ ...state, isLoading: false, globalErrors: e });
      });
  };

  const validateWorkflow = wkf => {
    if (wkf) {
      setWorkflow(wkf);
    } else wkf = workflow;
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    if (wkf.identifier === 0) {
      wkf = { ...wkf, identifier: 0, creationUser: currentUser.identifier, workflowStatusCode: "Started" };
    }

    Actions.SaveWorkflow(wkf)
      .then(w => {
        Actions.ValidateWorkflow({ ...w, comment: wkf.comment })
          .then(c => {
            if (c.workflowProcessStatus === 0) {
              loadWorkflow(c.workflow.workflowTypeCode, c.workflow.identifier);
            } else {
              setWorkflow(w);
              setState({ ...state, isLoading: false, errors: c.errorMessages });
            }
          })
          .catch(e => {
            setWorkflow(w);
            setState({ ...state, isLoading: false, globalErrors: e });
          });
      })
      .catch(e => {
        setState({ ...state, isLoading: false, globalErrors: e });
      });
  };

  const saveDraft = w => {
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.SaveWorkflow({ ...w, identifier: 0, creationUser: currentUser.identifier, workflowStatusCode: "Started" })
      .then(c => loadWorkflow(c.workflowTypeCode, c.identifier))
      .catch(e => setState({ ...state, isLoading: false, globalErrors: e }));
  };

  const deleteDraft = () => {
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.DeleteDraftWorkflow(workflow, c => closeWorkflow(), e => setState({ ...state, isLoading: false, globalErrors: e }));
  };

  if (!workflow) return <></>;

  if (workflow.workflowTypeCode === "StructureUe")
    return (
      <StructureUe
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );

  if (workflow.workflowTypeCode === "StructureSj")
    return (
      <StructureSj
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );

  if (workflow.workflowTypeCode === "PayCharCreation")
    return (
      <PayCharCreation
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );

  if (workflow.workflowTypeCode === "SupplierRequest")
    return (
      <RequestSupplierCreation
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        currentUser={currentUser}
        errors={state.errors}
        saveDraft={saveDraft}
        deleteDraft={deleteDraft}
      />
    );

  if (workflow.workflowTypeCode === "LongNameShortening")
    return (
      <LongNameShortening
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        currentUser={currentUser}
        errors={state.errors}
      />
    );
  if (workflow.workflowTypeCode === "InfolegaleMerge")
    return (
      <InfolegaleMerge
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        currentUser={currentUser}
        errors={state.errors}
      />
    );

  if (workflow.workflowTypeCode === "LocationCrupdate")
    return (
      <LocationCrupdate
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );

  return <></>;
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    currentUser: state.AuthenticationReducer.user,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeWorkflow: () => dispatch(CloseWorkflow())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(workflowStyle)(WorkflowDetail));
