import en from "locale/en/messages";
import fr from "locale/fr/messages";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect, useDispatch } from "react-redux";

//lingui
import { setupI18n } from "@lingui/core";
import { t } from "@lingui/macro";

// custom components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import { Checkbox, FormControlLabel } from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

function CustomerContractFlagsPart({ amc, masterValues, itemChanged, isReadonly, defaultLang }) {
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  const i18n = setupI18n({ catalogs: { fr: fr, en: en }, language: defaultLang });

  var item = !!amc.currentItem["CUSTOMERCONTRACT"] ? amc.currentItem["CUSTOMERCONTRACT"] : {};
  var warnings = amc.errors["CUSTOMERCONTRACT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Flags</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <FormControlLabel
              value={item.isEnabled}
              control={
                <Checkbox checked={item.isEnabled} disabled={isReadonly} onChange={e => itemChanged({ ...item, isEnabled: e.target.checked })} />
              }
              label={i18n._(t`Is Enabled`)}
              labelPlacement="start"
            />
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            <FormControlLabel
              value={item.isNotification}
              control={
                <Checkbox
                  checked={item.isNotification}
                  disabled={isReadonly}
                  onChange={e => itemChanged({ ...item, isNotification: e.target.checked })}
                />
              }
              label={i18n._(t`Is Notification`)}
              labelPlacement="start"
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractFlagsPart));
