import React from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "module/amc/store/actions/Job.Actions";

import Button from "components/CustomButtons/Button.jsx";
import { Add } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import JobSearchCriteria from "module/amc/components/jobs/JobSearchCriteria";
import JobTable from "module/amc/components/jobs/JobTable";
import JobPopUp from "./JobPopup";

//lingui
import { Trans } from "@lingui/macro";
import { isNullOrEmpty } from "../../tools";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import { HasRight } from "services/user/UserHelper";

function JobList({ amc, addItem, classes }) {
  const dispatch = useDispatch();

  var searchResult = amc.results["JOB"];
  var isSearching = amc.searching["JOB"];

  var resultComponent = "";
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else resultComponent = <JobTable />;

  var buttonCreation = "";

  if (HasRight("amc.admin") || HasRight("amc.add_job")) {
    buttonCreation = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" onClick={addItem} size="sm" color="info">
            <Add />
            <Trans>Add Job</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult) || isSearching) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {resultComponent}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <JobSearchCriteria showSearchCriteriaOnSideBar={true} />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = <JobSearchCriteria showSearchCriteriaOnSideBar={false} />;
  }

  return (
    <div>
      {buttonCreation}
      {layoutSearchResult}
      <JobPopUp />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addItem: () => {
      dispatch(Actions.AddItem());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobList));
