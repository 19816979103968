import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import {
    transition,
    cardTitle,
    dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const amcStyle = theme => ({
    ...customCheckboxRadioSwitch,
    cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    formCategory: {
        marginBottom: "0",
        color: "#999999",
        fontSize: "14px",
        padding: "10px 0 10px"
    },
    center: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center"
    },
    registerButton: {
        float: "right"
    },
    danger: {
        color: dangerColor + "!important"
    },
    dialogPaper: {
        minHeight: '95vh',
        maxHeight: '95vh',
    },
    dialogPaperCompact: {
    },
    detailPanel: {
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        overflow: "auto",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    detailPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    },
    Success: {
        backgroundColor: green[600],
    },
    Error: {
        backgroundColor: red[600],
    },
});

export default amcStyle;
