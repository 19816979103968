import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Checkbox, CircularProgress, FormLabel, IconButton, Link, List, ListItem, TextareaAutosize, TextField } from "@material-ui/core";
import { CloudUpload, Delete } from "@material-ui/icons";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import { ApimGetDownloadFile, ApimUploadFile } from "components/Common/ApimSender";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { TpSupplierNature } from "store/MasterValue/MasterValueTypes";

const CardInlineStyle = {
  card: {
    marginTop: 0
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const labelStyle = {
  color: "red"
};

function RequestProperties({ workflow, request, setRequest, errors, isReadOnly, currentUser }) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const labelRightGridItemSize = 5,
    inuptRightGridItemSize = 7;

  const error = code => errors && errors.some(e => e.code === code);

  const { i18n } = useLingui();
  const availableContextCodes = {
    items: [
      { code: "consultation", label: i18n._(t`Workflow_Supplier_ContextConsult`) },
      { code: "advancePurchase", label: i18n._(t`Workflow_Supplier_ContextPurchase`) }
    ]
  };

  function getDocumentUrl() {
    if (!request.documentUrl) {
      return <></>;
    }

    const substringOnMiddle = (str, start, end) => {
      return str.substring(0, start) + "..." + str.substring(str.length - end);
    };

    var txtDocumentUrl = request.documentUrl.length > 50 ? substringOnMiddle(request.documentUrl, 15, 15) : request.documentUrl;

    var deleteButton = "";
    if (
      workflow.workflowStatusCode === "Started" ||
      (workflow.workflowStatusCode === "Aborted" && workflow.creationUser === currentUser.identifier)
    ) {
      deleteButton = (
        <Button
          justIcon
          round
          simple
          color="info"
          className="like"
          onClick={() => {
            setRequest({ ...request, documentUrl: null });
          }}
        >
          <Delete />
        </Button>
      );
    }

    return (
      <ListItem style={{ paddingTop: "2px", paddingBottom: "2px" }}>
        <Link
          style={{ cursor: "pointer" }}
          onClick={event => {
            ApimGetDownloadFile("api/UploadFile/purchaserdocuments/" + request.documentUrl, request.documentUrl);
          }}
        >
          {txtDocumentUrl}
        </Link>
        &nbsp;
        {deleteButton}
      </ListItem>
    );
  }

  var selectDocument = "";
  var contentAttachedDocument = "";

  var uploadButton = "";
  if (workflow.workflowStatusCode === "Started" || workflow.workflowStatusCode === "Aborted") {
    uploadButton = (
      <IconButton style={{ color: "#00acc1" }} variant="contained" component="label">
        <CloudUpload />
        <input
          type="file"
          style={{ display: "none" }}
          onChange={event => {
            if (!event.target.files || event.target.files.length === 0) return;

            setIsUploading(true);

            ApimUploadFile(
              "api/UploadFile/Upload/purchaserdocuments",
              event.target.files[0],
              newFile => {
                var documents = newFile.split("|");
                setRequest({ ...request, documentUrl: documents[0] });
                setIsUploading(false);
              },
              e => {
                setIsUploading(false);
                setUploadError(e);
              }
            );
          }}
        />
      </IconButton>
    );
  }

  if (isUploading) {
    contentAttachedDocument = (
      <center>
        <CircularProgress />
      </center>
    );
  } else if (uploadError) {
    contentAttachedDocument = <CardError error={uploadError} />;
  } else {
    selectDocument = uploadButton;
    contentAttachedDocument = <List>{getDocumentUrl()}</List>;
  }

  var newSupplierReasonPart = <></>;
  if (request.isReferencedSupplierExist) {
    newSupplierReasonPart = (
      <GridContainer>
        <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
          <FormLabel error={error("description")}>
            <Trans>New Supplier Reason</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
          <TextareaAutosize
            style={{ width: "100%" }}
            rowsMin={4}
            rowsMax={10}
            margin="dense"
            id="newSupplierReason"
            type="text"
            readOnly={isReadOnly}
            value={request.newSupplierReason}
            onChange={event => {
              setRequest({ ...request, newSupplierReason: event.target.value });
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
  var isAdvancePurchase = <></>;

  if (request.contextCode === "advancePurchase") {
    isAdvancePurchase = (
      <FormLabel style={labelStyle}>
        <Trans>Workflow_Supplier_ContextPurchase_Warning</Trans>
      </FormLabel>
    );
  }

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Request purpose</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel error={error("description")}>
                <Trans>Needed description</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              <TextareaAutosize
                style={{ width: "100%" }}
                rowsMin={4}
                rowsMax={10}
                margin="dense"
                id="description"
                type="text"
                readOnly={isReadOnly}
                value={request.description}
                onChange={event => setRequest({ ...request, description: event.target.value })}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel error={error("purchaseFamilyCode")}>
                <Trans>Purchase family</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              <MasterValueAutocomplete
                isEditable={!isReadOnly}
                mvType={TpSupplierNature}
                selectedValue={request.purchaseFamilyCode}
                onValueChange={v => setRequest({ ...request, purchaseFamilyCode: v })}
                formatText={i => `${i.code} - ${i.label}`}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel>
                <Trans>Does a referenced supplier exist ?</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              <Checkbox
                margin="dense"
                id="isReferencedSupplierExist"
                checked={request.isReferencedSupplierExist}
                onChange={event => setRequest({ ...request, isReferencedSupplierExist: event.target.checked })}
              />
            </GridItem>
          </GridContainer>
          {newSupplierReasonPart}
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel error={error("contextCode")}>
                <Trans>Request follows up</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              <ItemAutoComplete
                isEditable={!isReadOnly}
                items={availableContextCodes.items}
                selectedValue={request.contextCode}
                onValueChange={v => setRequest({ ...request, contextCode: v })}
              />
              {isAdvancePurchase}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel error={error("amount")}>
                <Trans>Amount excluding tax (euros)</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              <TextField
                margin="dense"
                id="amount"
                type="number"
                fullWidth
                disabled={isReadOnly}
                value={request.amount ?? ""}
                onChange={event => setRequest({ ...request, amount: event.target.value })}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={labelRightGridItemSize} sm={labelRightGridItemSize} md={labelRightGridItemSize}>
              <FormLabel error={error("documentUrl")}>
                <Trans>Supporting document</Trans>
              </FormLabel>
              {selectDocument}
            </GridItem>
            <GridItem xs={inuptRightGridItemSize} sm={inuptRightGridItemSize} md={inuptRightGridItemSize}>
              {contentAttachedDocument}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}

export default RequestProperties;
