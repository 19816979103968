import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { FileHelper } from "services/common/FileHelper";

export function ImageBlobLoader({ container, fileName, alt, onClick, className }) {
  const [file, setFile] = useState(null);

  if (!file) {
    var fileHelper = new FileHelper();
    fileHelper.Download(container, fileName, f => setFile(f));
    return <CircularProgress />;
  }

  var src = URL.createObjectURL(file);
  return (
    <img
      src={src}
      alt={alt}
      onClick={e => {
        if (!!onClick) {
          onClick(e, src);
        }
      }}
      className={className}
    />
  );
}
