import { makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Muted from "components/Typography/Muted";
import { SiteSearch } from "module/site/actions/SiteActions";
import { debounce } from "module/tools";
import React, { useEffect, useState } from "react";
import { isArrayNullOrEmpty } from "tools";

const LocationAutoComplete = ({
  isEditable,
  isRequired,
  selectedValue,
  onValueChange,
  label,
  placeHolder,
  multiple,
  error,
  size,
  disabled,
  readOnly,
  filterCriterias
}) => {
  const [value, setValue] = useState(!!multiple ? [] : null);
  const [options, setOptions] = useState([]);

  const useStyles = makeStyles(theme =>
    size === "small"
      ? {
          inputRoot: {
            fontSize: "14px"
          }
        }
      : {}
  );
  const styles = {
    roundLabel: {
      borderRadius: "16px",
      background: "#e0e0e0",
      padding: "6px 6px",
      fontSize: "14px",
      justifyContent: "center",
      boxSizing: "border-box",
      border: "none",
      height: "32px",
      alignItems: "center",
      color: "#000000de"
    }
  };
  const classes = useStyles(size);

  const search = async keyword => {
    if (keyword.length < 3) {
      return;
    }

    debounce(keyword => {
      SiteSearch({ ...filterCriterias, criteriaFullText: keyword }).then(response => {
        const opt = response.map(r => {
          return {
            value: r.id,
            text: `${r.name} - ${r.address_City}`
          };
        });
        setOptions(opt);
      });
    }, 500)(keyword);
  };

  const getSite = async id => {
    if (!id) {
      return null;
    }

    return SiteSearch({ criteriaMdmId: id, keepSiteNaturesEmpty: true });
  };

  useEffect(() => {
    if (selectedValue && !value) {
      getSite(selectedValue).then(r => {
        if (!isArrayNullOrEmpty(r)) {
          let opt = { value: r[0].id, text: `${r[0].name} - ${r[0].address_City}` };
          setValue(opt);
          setOptions([opt]);
        }
      });
    }
  }, []);

  if (isEditable) {
    return (
      <Autocomplete
        classes={classes}
        clearOnEscape={!isRequired}
        disableClearable={isRequired}
        disabled={!!disabled}
        multiple={!!multiple}
        options={options}
        getOptionLabel={option => option.text}
        onInputChange={(_, newInputValue) => {
          search(newInputValue);
        }}
        onChange={(_, newValue) => {
          setValue(newValue);
          onValueChange(
            newValue
              ? !!multiple
                ? newValue.map(v => {
                    return v.value;
                  })
                : newValue.value
              : null
          );
        }}
        value={value}
        renderInput={params => {
          return (
            <TextField
              {...params}
              margin={size === "small" ? "none" : "normal"}
              fullWidth
              error={error}
              label={!!label ? label : null}
              placeholder={placeHolder}
            />
          );
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.text, inputValue);
          const parts = parse(option.text, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }

  if (!selectedValue || (multiple && isArrayNullOrEmpty(selectedValue))) {
    return <Typography>Ø</Typography>;
  }

  if (multiple) {
    var item = options.filter(c => selectedValue.some(s => s === c.code));
    if (isArrayNullOrEmpty(item)) {
      return <Typography>Ø</Typography>;
    }

    return (
      <>
        {item.map((i, idx) =>
          readOnly ? (
            <div style={{ margin: "9px" }} key={idx}>
              <span style={styles.roundLabel}>{i}</span>
            </div>
          ) : (
            <span key={idx} style={styles.roundLabel}>
              {i}
            </span>
          )
        )}
      </>
    );
  } else {
    var item = options.find(c => c.code === selectedValue);
    if (!item) {
      return <Typography>Ø</Typography>;
    }

    return <Muted>{item}</Muted>;
  }
};

export default LocationAutoComplete;
