import { Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { CloudUpload, Settings } from "@material-ui/icons";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import CustomTable from "components/Table/CustomTable";
import { SiteUpdate } from "module/site/actions/SiteActions";
import { SiteGetDetailed } from "module/site/actions/SiteActions";
import { GetStructuresWithoutChildren } from "module/structure/actions/StructureActions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isNullOrEmpty } from "tools";

const LocationMigrate = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState(null);
  const [structureIds, setStructureIds] = useState([]);
  const [structures, setStructures] = useState([]);
  const uploadFileRef = useRef();

  useEffect(() => {
    GetStructuresWithoutChildren(structureIds, r => setStructures(r), e => {});
  }, [structureIds]);

  if (!isNullOrEmpty(locations)) {
    var locationRun = locations.find(l => l.migrateStatus === "ready");
    if (locationRun) {
      if (!isNullOrEmpty(structures)) {
        locationRun.structures = locationRun.structures.map(s => {
          return {
            structureId: structures.find(f => f.identifiant === s.structureId).id,
            isMainStructure: s.isMainStructure
          };
        });
      }
      SiteGetDetailed(
        locationRun.id,
        s => {
          s = { ...s, ...locationRun };
          SiteUpdate(
            s,
            () => {
              let nextId = false;
              setLocations(
                locations.map(l => {
                  if (l.identifier === locationRun.identifier) {
                    nextId = true;
                    return { ...l, migrateStatus: "done" };
                  }
                  if (nextId) {
                    nextId = false;
                    return { ...l, migrateStatus: "ready" };
                  }
                  return l;
                })
              );
            },
            e => {
              setLocations(
                locations.map(l => {
                  if (l.identifier === locationRun.identifier) return { ...l, migrateStatus: "error", migrateError: e.message };
                  return l;
                })
              );
            }
          );
        },
        e => {
          setLocations(
            locations.map(l => {
              if (l.identifier === locationRun.identifier) return { ...l, migrateStatus: <CardError error={e} /> };
              return l;
            })
          );
        }
      );
      setLocations(
        locations.map(l => {
          if (l.identifier === locationRun.identifier) return { ...l, migrateStatus: "inProgress" };
          return l;
        })
      );
    } else if (!locations.some(l => l.migrateStatus === "inProgress") && !!isLoading) {
      setIsLoading(false);
    }
  }

  const extractFile = e => {
    e.preventDefault();
    setIsLoading(true);
    var file = e.target.files[0];
    if (!file.name.toLowerCase().endsWith(".csv")) return;
    e.target.files[0].text().then(txt => {
      var lines = txt.split("\r\n");
      var locationsToMigrate = [];
      // on appéle la liste des structures hors de la boucle pour effectuer le mapping des ID
      let structureIdentifiers = [];
      for (var i = 1; i < lines.length; i++) {
        let line = lines[i];
        let columns = line.split(";");
        let ids = isNullOrEmpty(columns[Column_LOCATION_STRUCTURES]) ? [] : columns[Column_LOCATION_STRUCTURES].split("|");
        structureIdentifiers = structureIdentifiers.concat(ids);
      }
      setStructureIds(structureIdentifiers);
      // on saute la 1ère ligne (headers)
      for (var i = 1; i < lines.length; i++) {
        var line = lines[i];
        if (isNullOrEmpty(line)) continue;

        var columns = line.split(";");

        var gps = columns[Column_GPS_COORDINATES].split("/");

        var location = {
          migrateStatus: "",
          identifier: columns[Column_IDENTIFIER],
          name: columns[Column_NAME],
          address_Line1: columns[Column_ADDRESS_LINE1],
          address_Line2: columns[Column_ADDRESS_LINE2],
          address_City: columns[Column_ADDRESS_CITY],
          address_ZipCode: columns[Column_ADDRESS_ZIPCODE],
          address_StatisticZipCode: columns[Column_ADDRESS_STATISTICZIPCODE],
          address_StateCode: isNullOrEmpty(columns[Column_ADDRESS_STATE]) ? null : columns[Column_ADDRESS_STATE],
          address_CountyCode: isNullOrEmpty(columns[Column_ADDRESS_COUNTY]) ? null : columns[Column_ADDRESS_COUNTY],
          address_CountryCode: isNullOrEmpty(columns[Column_ADDRESS_COUNTRY]) ? null : columns[Column_ADDRESS_COUNTRY],
          gpsCoordinates:
            gps.length === 2 ? { latitude: parseFloat(gps[0].replace(",", ".")), longitude: parseFloat(gps[1].replace(",", ".")) } : null,
          locationNatureCode: isNullOrEmpty(columns[Column_LOCATION_NATURE]) ? null : columns[Column_LOCATION_NATURE],
          locationStatusCode: isNullOrEmpty(columns[Column_LOCATION_STATUS]) ? null : columns[Column_LOCATION_STATUS],
          locationTypeCode: isNullOrEmpty(columns[Column_LOCATION_TYPE]) ? null : columns[Column_LOCATION_TYPE],
          activities: isNullOrEmpty(columns[Column_LOCATION_ACTIVITIES])
            ? []
            : columns[Column_LOCATION_ACTIVITIES].split("|").map((a, idx) => {
                return { activityCode: a, isMainActivity: idx === 0 };
              }),
          structures: isNullOrEmpty(columns[Column_LOCATION_STRUCTURES])
            ? []
            : columns[Column_LOCATION_STRUCTURES].split("|").map((s, idx) => {
                return {
                  structureId: s,
                  isMainStructure: idx === 0
                };
              })
        };
        locationsToMigrate.push(location);
      }
      setLocations(locationsToMigrate);
      setIsLoading(false);
    });
  };

  const migrate = () => {
    setIsLoading(true);
    setLocations(
      locations.map((l, idx) => {
        if (idx === 0) return { ...l, migrateStatus: "ready" };
        return l;
      })
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          {!!isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography>
                Pour importer en masse les modifications de sites, utiliser le fichier CSV exporté par le moteur de recherche de sites.
              </Typography>
              <div>
                <Button size="small" color="info" onClick={() => uploadFileRef.current.click()}>
                  <CloudUpload /> Upload file
                </Button>
                <input type="file" accept="csv" onChange={e => extractFile(e)} ref={uploadFileRef} style={{ display: "none" }} />
                {isNullOrEmpty(locations) ? (
                  <></>
                ) : (
                  <Button size="small" color="info" onClick={() => migrate()}>
                    <Settings /> Apply migration
                  </Button>
                )}
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <LocationMigrateTable locations={locations} />
    </>
  );
};

const LocationMigrateTable = ({ locations }) => {
  const columns = useMemo(() => [
    { Header: "Statut", accessor: "migrateStatus" },
    {
      Header: "Identifier",
      accessor: "identifier"
    },
    { Header: "Name", accessor: "name" },
    { Header: "Address", accessor: "address_Line1" },
    { Header: "ZipCode", accessor: "address_ZipCode" },
    { Header: "City", accessor: "address_City" },
    { Header: "Country", accessor: "address_CountryCode" }
  ]);

  // migrateStatus: "Ready",
  // identifier: columns[Column_IDENTIFIER],
  // name: columns[Column_NAME],
  // address_Line1: columns[Column_ADDRESS_LINE1],
  // address_Line2: columns[Column_ADDRESS_LINE2],
  // address_City: columns[Column_ADDRESS_CITY],
  // address_ZipCode: columns[Column_ADDRESS_ZIPCODE],
  // address_StatisticZipCode: columns[Column_ADDRESS_STATISTICZIPCODE],
  // address_StateCode: columns[Column_ADDRESS_STATE],
  // address_CountyCode: columns[Column_ADDRESS_COUNTY],
  // address_CountryCode: columns[Column_ADDRESS_COUNTRY],
  // gpsCoordinates: gps.length === 1 ? { Latitude: gps[0], longitude: gps[1] } : null,
  // locationStatusCode: isNullOrEmpty(columns[Column_LOCATION_STATUS]) ? null : columns[Column_LOCATION_STATUS],
  // locationTypeCode: isNullOrEmpty(columns[Column_LOCATION_TYPE]) ? null : columns[Column_LOCATION_TYPE],
  // activities: isNullOrEmpty(columns[Column_LOCATION_ACTIVITIES])
  // structures: isNullOrEmpty(columns[Column_LOCATION_STRUCTURES])

  if (isNullOrEmpty(locations)) return <></>;

  return (
    <Card>
      <CardContent></CardContent>
      <CustomTable columns={columns} data={locations} />
    </Card>
  );
};

const Column_IDENTIFIER = 0;
const Column_NAME = 1;
const Column_ADDRESS_LINE1 = 2;
const Column_ADDRESS_LINE2 = 3;
const Column_ADDRESS_CITY = 4;
const Column_ADDRESS_ZIPCODE = 5;
const Column_ADDRESS_STATISTICZIPCODE = 6;
const Column_ADDRESS_STATE = 7;
const Column_ADDRESS_COUNTY = 8;
const Column_ADDRESS_COUNTRY = 9;
const Column_GPS_COORDINATES = 10;
const Column_LOCATION_NATURE = 11;
const Column_LOCATION_STATUS = 12;
const Column_LOCATION_TYPE = 13;
const Column_LOCATION_ACTIVITIES = 14;
const Column_LOCATION_STRUCTURES = 15;

export default LocationMigrate;
