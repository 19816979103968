import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Warning } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect } from "react-redux";
import JobAddressPart from "./Parts/JobAddressPart";
import JobAddtionnalContractManagersPart from "./Parts/JobAddtionnalContractManagersPart";
import JobAddtionnalForemenPart from "./Parts/JobAddtionnalForemenPart";
import JobBudgetPart from "./Parts/JobBudgetPart";
import JobDatesPart from "./Parts/JobDatesPart";
import JobFlagsPart from "./Parts/JobFlagsPart";
import JobIdentifierAndSourcePart from "./Parts/JobIdentifierAndSourcePart";
import JobLinksPart from "./Parts/JobLinksPart";
import JobMainContactsPart from "./Parts/JobMainContactsPart";

function JobGeneralTab({ amc, masterValues, isReadonly }) {
  const [tabIdx, setTabIdx] = React.useState(0);

  var warnings = amc.errors["JOB"];
  var tabGeneral = <Trans>General</Trans>;
  if (!!warnings && warnings.some(w => w.code.split("/")[1] === "GENERAL"))
    tabGeneral = (
      <span>
        <Warning style={{ color: "red" }} />
        {tabGeneral}
      </span>
    );
  var tabAddress = <Trans>Address</Trans>;
  if (!!warnings && warnings.some(w => w.code.split("/")[1] === "ADDRESS"))
    tabAddress = (
      <span>
        <Warning style={{ color: "red" }} />
        {tabAddress}
      </span>
    );
  var tabContacts = <Trans>Contacts</Trans>;
  if (!!warnings && warnings.some(w => w.code.split("/")[1] === "CONTACTS"))
    tabContacts = (
      <span>
        <Warning style={{ color: "red" }} />
        {tabContacts}
      </span>
    );

  var dateInputProps = {};
  if (isReadonly)
    dateInputProps = {
      readOnly: { isReadonly },
      disabled: { isReadonly }
    };

  return (
    <GridContainer>
      <GridItem xs={2} sm={2} md={2}>
        <AntTabs orientation="vertical" variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Vertical tabs job">
          <AntTab label={tabGeneral} />
          <AntTab label={tabAddress} />
          <AntTab label={tabContacts} />
        </AntTabs>
      </GridItem>
      <GridItem xs={10} sm={10} md={10}>
        <TabPanel value={tabIdx} index={0}>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <JobIdentifierAndSourcePart isReadonly={isReadonly} />
              <JobDatesPart isReadonly={isReadonly} />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <JobLinksPart isReadonly={isReadonly} />
              <JobMainContactsPart isReadonly={isReadonly} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <JobFlagsPart isReadonly={isReadonly} />
              <JobBudgetPart isReadonly={isReadonly} />
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIdx} index={1}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <JobAddressPart isReadonly={isReadonly} />
            </GridItem>
          </GridContainer>
        </TabPanel>
        <TabPanel value={tabIdx} index={2}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <JobAddtionnalContractManagersPart isReadonly={isReadonly} />
              <JobAddtionnalForemenPart isReadonly={isReadonly} />
            </GridItem>
          </GridContainer>
        </TabPanel>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobGeneralTab));
