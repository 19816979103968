import { ApimGet } from "components/Common/ApimSender";
import { isNullOrEmpty } from "tools";

export function GetAllCityInseeCodes(callbackFn, errorFn) {
  ApimGet("api/CityInseeCodes/all", callbackFn, errorFn);
}

export function SearchCityInseeCodes(criterias, callbackFn, errorFn) {
  var urlParameters = [];

  if (!isNullOrEmpty(criterias["code"])) {
    urlParameters.push("Code=" + criterias["code"]);
  }
  if (!isNullOrEmpty(criterias["zipCode"])) {
    urlParameters.push("ZipCode=" + criterias["zipCode"]);
  }
  if (!isNullOrEmpty(criterias["name"])) {
    urlParameters.push("Name=" + criterias["name"]);
  }
  if (!isNullOrEmpty(criterias["freeSearch"])) {
    urlParameters.push("FreeSearch=" + criterias["freeSearch"]);
  }

  ApimGet("api/CityInseeCodes?" + urlParameters.join("&"), callbackFn, errorFn);
}
