import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Actions from "./store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";

import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Breadcrumbs, Typography } from "@material-ui/core";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import { Trans } from "@lingui/macro";
import CompanyPartGeneral from "./part/detail/CompanyPartGeneral";
import CompanyPartAddress from "./part/detail/CompanyPartAddress";
import CompanyPartThirdParties from "./part/detail/CompanyPartThirdParties";
import CompanyPartCompliance from "./part/detail/CompanyPartCompliance";
import { Lock, LockOpen } from "@material-ui/icons";
import { CompanyGet } from "./actions/CompanyActions";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTab, AntTabs, TabPanel } from "../../../components/Common/Tabs";
import { ApimGetPromise } from "components/Common/ApimSender";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import * as MvType from "store/MasterValue/MasterValueTypes";
import CompanyPartHistory from "./part/detail/CompanyPartHistory";

const CompanyDetail = ({ companyIdentifier, masterValues, isEditable, tabIndex, defaultLang }) => {
  var dispatch = useDispatch();
  const [state, setState] = useState({ isLoading: false });
  const [tabIdx, setTabIdx] = useState(0);
  const [company, setCompany] = useState();
  const [showCloseWithoutSave, setShowCloseWithoutSave] = useState(false);

  useEffect(() => {
    setCompany();
    setTabIdx(tabIndex);

    var mvTypesToLoad = [MvType.TpCompanyComplianceScore].filter(mvType => !masterValues[mvType]);

    var mvLoads = mvTypesToLoad.reduce(async (previousPromise, mvType, idx, array) => {
      await previousPromise;

      return ApimGetPromise("api/mastervalue/" + mvType).then(c => dispatch(addMasterValue(mvType, c, idx, array.length)));
    }, Promise.resolve());

    mvLoads.then(() => {
      setState({ isLoading: false });
    });
  }, [companyIdentifier, tabIndex]);

  if (!companyIdentifier) {
    return "";
  }

  const closeDetail = confirmClose => {
    if (hasChanged() && !confirmClose) {
      setShowCloseWithoutSave(true);
    } else {
      setShowCloseWithoutSave(false);
      setCompany(null);
      dispatch(Actions.CloseCompany(companyIdentifier));
    }
  };

  const hasChanged = () => {
    return JSON.stringify(company) !== state.backupCompany;
  };

  const saveCompany = () => {
    //setState({ ...state, isLoading: true, error: null, validationErrors: null });
    //StructureCrupdate(
    //    structure,
    //    (s) => {
    //        setStructure(s);
    //        setState({ ...state, isLoading: false, error: null, validationErrors: null, backupStructure: JSON.stringify(s) });
    //    },
    //    (e) => setState({ ...state, isLoading: false, error: e, validationErrors: null }),
    //    (e) => setState({ ...state, isLoading: false, error: null, validationErrors: e }));
  };

  var dialogTitle = (
    <Typography color="textPrimary">
      <Trans>Company Detail</Trans>
    </Typography>
  );
  var dialogContent = "";
  var dialogActions = "";
  if (state.isLoading) {
    dialogContent = <CircularProgress />;
  } else if (state.error) {
    dialogContent = <CardError error={state.error} />;
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeDetail(false)} color="primary">
          {" "}
          Close{" "}
        </Button>
      </DialogActions>
    );
  } else if (!company) {
    setState({ ...state, isLoading: true });

    CompanyGet(
      companyIdentifier,
      result => {
        setState({ ...state, isLoading: false, backupCompany: JSON.stringify(result) });
        setCompany(result);
      },
      e => setState({ ...state, isLoading: false, error: e })
    );

    return "";
  } else {
    var btnSave = "";
    if (hasChanged()) {
      btnSave = (
        <Button onClick={() => saveCompany()} color="info">
          <Trans> Save </Trans>
        </Button>
      );
    }

    var btnReload = "";
    btnReload = (
      <Button
        onClick={() => {
          setState({ ...state, validationErrors: null, error: null });
          setCompany(null);
        }}
      >
        <Trans> Reload </Trans>
      </Button>
    );

    let content = (
      <>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Address</Trans>} />
              <AntTab label={<Trans>ThirdParties</Trans>} />
              <AntTab label={<Trans>Company_Compliance</Trans>} />
              <AntTab label={<Trans>History</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <CompanyPartGeneral company={company} setCompany={setCompany} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <CompanyPartAddress company={company} setCompany={setCompany} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <CompanyPartThirdParties company={company} setCompany={setCompany} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <CompanyPartCompliance
                company={company}
                setCompany={setCompany}
                masterValues={masterValues}
                isEditable={isEditable}
                defaultLang={defaultLang}
              />
            </TabPanel>
            <TabPanel value={tabIdx} index={4}>
              <CompanyPartHistory company={company} defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </>
    );

    var statusIcon = <LockOpen style={{ color: "green" }} />;
    if (company.companyStatusCode !== null && company.companyStatusCode === "Closed") statusIcon = <Lock style={{ color: "red" }} />;
    dialogTitle = (
      <>
        {statusIcon}
        <Typography color="textPrimary">
          <Trans>Company Detail</Trans>&nbsp;({company.identifier} - {company.longName})
        </Typography>
      </>
    );

    dialogContent = (
      <div className="divContainer">
        <form autoComplete="off">{content}</form>
      </div>
    );
    dialogActions = (
      <DialogActions>
        {btnSave}
        {btnReload}
        <Button onClick={() => closeDetail(false)} color={btnSave === "" ? "info" : ""}>
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    );
  }

  var dialog = (
    <Dialog open={true} onClose={() => closeDetail(false)} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">{dialogTitle}</Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {dialogContent}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );

  return (
    <>
      {dialog}
      <Dialog open={showCloseWithoutSave} onClose={() => setShowCloseWithoutSave(false)}>
        <DialogContent>
          <DialogContentText>
            <Trans> ConfirmCloseWithoutSave </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDetail(true)} color="info">
            <Trans> Yes </Trans>
          </Button>
          <Button onClick={() => setShowCloseWithoutSave(false)}>
            <Trans> No </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(structureStyle)(CompanyDetail);
