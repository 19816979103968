import React from "react";
import withClearCacheche from "./clearCache";
import MainApp from "./MainApp";

const ClearCacheComponent = withClearCacheche(MainApp);

function App() {
  return <ClearCacheComponent />;
}

export default App;
