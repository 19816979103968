import { ApimGet, ApimPost } from "components/Common/ApimSender";
import * as Actions from "./types";

export function GetPointOfSale(thirdPartyId) {
  return (dispatch, getState) => {
    alert("GetPointOfSale Not Implemented");
    // ApimGet(
    //   "api/TpCopernic/PointOfSales/" + thirdPartyId + "/withSupplier",
    //   responseJson => {
    //     dispatch({
    //       type: Actions.TP_COPERNIC_POINTOFSALE_GET,
    //       pointOfSale: responseJson,
    //       isEditable: HasRight("thirdparty_supplier.edit"),
    //       isEditableDemat: HasRight("thirdparty_supplier.edit_demat"),
    //       isEditableCoupa: HasRight("thirdparty_supplier.edit_coupa"),
    //       isEditableClosed: HasRight("thirdparty_supplier.edit_closed")
    //     });
    //   },
    //   error => {
    //     console.error(error);
    //     dispatch({
    //       type: Actions.TP_COPERNIC_POINTOFSALE_ERROR,
    //       error: error
    //     });
    //   }
    // );
  };
}

function crupdatePointOfSale(pointOfSale, reloadThirdParty, dispatch) {
  ApimPost(
    "api/TpCopernic/PointOfSales",
    pointOfSale,
    responseJson => {
      dispatch({
        type: Actions.TP_COPERNIC_POINTOFSALE_SAVE_END,
        successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
        pointOfSale: responseJson
      });
    },
    error => {
      console.error(error);
      dispatch({
        type: Actions.TP_COPERNIC_POINTOFSALE_ERROR,
        error: error
      });
    }
  );
}

export function SavePointOfSale(pointOfSale, thirdParty, reloadThirdParty) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_POINTOFSALE_SAVE_BEGIN
    });

    if (thirdParty?.identifier === 0) {
      ApimPost(
        "api/TpCopernic/ThirdParties",
        thirdParty,
        responseJson => {
          pointOfSale.thirdPartyIdentifier = responseJson.identifier;
          crupdatePointOfSale(pointOfSale, reloadThirdParty, dispatch);
        },
        error => {
          console.error(error);
          dispatch({
            type: Actions.TP_COPERNIC_POINTOFSALE_ERROR,
            error: error
          });
        }
      );
    } else {
      crupdatePointOfSale(pointOfSale, reloadThirdParty, dispatch);
    }
  };
}

export function EditPointOfSale(pointOfSale) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_POINTOFSALE_EDITVALUE,
      pointOfSale: pointOfSale
    });
  };
}

/// ------------------------------------------------------------------------------------------------------
/// COUPA POINT OF SALE ACTIONS
/// ------------------------------------------------------------------------------------------------------
export function PointOfSaleCoupaGet(pointOfSaleId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/PointOfSales/" + pointOfSaleId + "/Coupa",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_GET,
          coupa: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_ERROR,
          error: error
        });
      }
    );
  };
}

export function PointOfSaleCoupaSave(pointOfSaleId, coupa) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_SAVE_BEGIN
    });

    const url = "api/TpCopernic/PointOfSales/" + pointOfSaleId + "/Coupa";
    ApimPost(
      url,
      coupa,
      () =>
        ApimGet(
          url,
          coupa => {
            dispatch({
              type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_SAVE_END,
              coupa: coupa
            });
          },
          error => {
            console.error(error);
            dispatch({
              type: Actions.TP_COPERNIC_POINTOFSALE_ERROR,
              error
            });
          }
        ),
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_POINTOFSALE_ERROR,
          error
        });
      }
    );
  };
}

export function PointOfSaleCoupaEdit(coupa) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_EDIT,
      coupa: coupa
    });
  };
}

export function PointOfSaleCoupaGroupAdd(containGroup) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_POINTOFSALE_COUPA_GROUP_ADD,
      containGroup
    });
  };
}
