import * as Actions from "./types";

export function ItemChanged(job, item) {
  if (!job.jobPhaseBudgets) job.jobPhaseBudgets = [];

  var index = -1;
  if (!!item.identifier && typeof item.identifier !== "undefined")
    index = job.jobPhaseBudgets
      .map(function(e) {
        return e.identifier;
      })
      .indexOf(item.identifier);
  if (index < 0 && !!item.localId && typeof item.localId !== "undefined")
    index = job.jobPhaseBudgets
      .map(function(e) {
        return e.localId;
      })
      .indexOf(item.localId);

  if (index > -1) {
    job.jobPhaseBudgets[index] = item;
  } else job.jobPhaseBudgets.push(item);

  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOBPHASEBUDGET",
      item: item
    });
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOB",
      item: job
    });
  };
}

export function DeletePhase(job) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "JOB",
      item: job
    });
  };
}
