import { Trans } from "@lingui/macro";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { AttachMoneyRounded, BusinessRounded, Delete, Person, SaveAltOutlined } from "@material-ui/icons";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import React, { useEffect, useState } from "react";
import { TpContactType } from "store/MasterValue/MasterValueTypes";
import uuidv4 from "uuid/v4"; // Generate GUID
import { isNullOrEmpty } from "../../../tools";

function CardContact({
  contact,
  setContact,
  deleteContact,
  isEditable,
  isDeletable,
  isSwipable,
  lockContactType,
  lockContactDetails,
  countryPhoneCode,
  masterValues,
  errors,
  defaultLang
}) {
  const [state, setState] = useState({
    showConfirm: false
  });

  useEffect(() => {
    if (contact) {
      contact.details = contact.details.map(d => {
        return { ...d, localId: uuidv4() };
      });
      setState(s => ({
        ...s,
        contact: contact,
        jsonBackup: JSON.stringify(contact),
        mode: (isSwipable && isEditable) || !isEditable ? "VIEW" : "EDIT"
      }));
    }
  }, [contact, isEditable, isSwipable]);

  function changeContact(c) {
    if (!isSwipable) {
      setContact(c);
    } else {
      setState({
        ...state,
        contact: c
      });
    }
  }

  const error = code => errors && errors.some(e => e.code === code);

  var cardContent = <></>;
  var btnActions = <></>;

  if (isNullOrEmpty(contact.firstName) && isNullOrEmpty(contact.lastName) && !isSwipable && !isEditable) {
    cardContent = (
      <Typography>
        <Trans>Contact_Not_Set</Trans>
      </Typography>
    );
  } else {
    var fieldName,
      fieldContactType = "",
      fieldDetails = "";
    if (state.mode === "EDIT") {
      fieldName = (
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              margin="none"
              id="firstName"
              type="text"
              fullWidth
              value={state.contact.firstName}
              onChange={e => changeContact({ ...state.contact, firstName: e.target.value })}
              label={<Trans>First name</Trans>}
              error={error("FIRSTNAME")}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              margin="none"
              id="lastName"
              type="text"
              fullWidth
              value={state.contact.lastName}
              onChange={e => changeContact({ ...state.contact, lastName: e.target.value })}
              label={<Trans>Last name</Trans>}
              error={error("LASTNAME")}
            />
          </Grid>
        </Grid>
      );

      if (!lockContactType) {
        fieldContactType = (
          <FormControl fullWidth>
            <InputLabel>
              <Trans>ContactType</Trans>
            </InputLabel>
            <Select
              value={state.contact.contactTypeCode}
              inputProps={{
                name: "contactTypeCode",
                id: "contactTypeCode"
              }}
              onChange={e =>
                changeContact({
                  ...state.contact,
                  contactTypeCode: e.target.value
                })
              }
            >
              {masterValues[TpContactType].map((contactType, idx) => {
                var contactTypeIcon = "";
                return (
                  <MenuItem value={contactType.code} key={idx}>
                    {contactTypeIcon} {GetMasterValueLabel(contactType, defaultLang)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }

      var fieldDetailsGrid = contact.details.map((d, key) => {
        var detailValue = "";
        if (d.contactDetailTypeCode === "MAIL") {
          let errorMail = error("MAIL");
          detailValue = (
            <TextField
              margin="none"
              type="text"
              fullWidth
              value={d.value}
              onChange={e =>
                changeContact({
                  ...state.contact,
                  details: state.contact.details.map(cd => {
                    if (cd.localId === d.localId) {
                      cd.value = e.target.value;
                    }
                    return cd;
                  })
                })
              }
              InputLabelProps={{ shrink: true }}
              error={errorMail}
              label={<Trans>Contact Detail Mail</Trans>}
            />
          );
        } else {
          let errorPhone = error("PHONE");
          let errorPhoneCode = error("PHONE_CODE");
          detailValue = (
            <div style={{ display: "flex" }}>
              <TextField
                margin="none"
                type="text"
                fullWidth
                value={d.phoneCode}
                onChange={e =>
                  changeContact({
                    ...state.contact,
                    details: state.contact.details.map(cd => {
                      if (cd.localId === d.localId) {
                        cd.phoneCode = e.target.value;
                      }
                      return cd;
                    })
                  })
                }
                InputLabelProps={{ shrink: true }}
                error={errorPhoneCode}
                label={<Trans>PhoneCode</Trans>}
                style={{ flex: "1 1 0" }}
              />
              <TextField
                margin="none"
                type="text"
                fullWidth
                value={d.value}
                onChange={e =>
                  changeContact({
                    ...state.contact,
                    details: state.contact.details.map(cd => {
                      if (cd.localId === d.localId) {
                        cd.value = e.target.value;
                      }
                      return cd;
                    })
                  })
                }
                InputLabelProps={{ shrink: true }}
                error={errorPhone}
                label={<Trans>PhoneNumber</Trans>}
                style={{ flex: "3 1 0" }}
              />
            </div>
          );
        }

        var detailType = "";
        if (!lockContactDetails) {
          detailType = (
            <Select
              name="contactDetailTypeCode"
              value={d.contactDetailTypeCode}
              inputProps={{
                name: "contactDetailTypeCode",
                id: "contactDetailTypeCode"
              }}
              onChange={e =>
                changeContact({
                  ...state.contact,
                  details: state.contact.details.map(cd => {
                    if (cd.localId === d.localId) {
                      var previousCode = cd.contactDetailTypeCode;
                      cd.contactDetailTypeCode = e.target.value;
                      if (cd.contactDetailTypeCode === "MAIL") {
                        cd.phoneCode = "";
                      } else if (previousCode === "MAIL") {
                        cd.phoneCode = countryPhoneCode;
                      }
                    }
                    return cd;
                  })
                })
              }
            >
              <MenuItem value="MAIL">Email</MenuItem>
              <MenuItem value="PHONE">Phone</MenuItem>
              <MenuItem value="FAX">Print</MenuItem>
            </Select>
          );
        } else {
          detailType = d.contactDetailTypeCode;
        }

        var detailActions = "";
        if (!lockContactDetails) {
          detailActions = (
            <Grid item xs={1} sm={1} md={1}>
              <IconButton
                onClick={() => {
                  changeContact({
                    ...state.contact,
                    details: state.contact.details.filter(cd => cd.localId !== d.localId)
                  });
                }}
              >
                <Delete />
              </IconButton>
            </Grid>
          );
        }

        return (
          <Grid container key={key} spacing={2} alignItems="flex-end">
            <Grid item xs={2} sm={2} md={2}>
              {detailType}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <TextField
                margin="none"
                type="text"
                fullWidth
                value={d.title}
                onChange={e =>
                  changeContact({
                    ...state.contact,
                    details: state.contact.details.map(cd => {
                      if (cd.localId === d.localId) {
                        cd.title = e.target.value;
                      }
                      return cd;
                    })
                  })
                }
                label={<Trans>Title</Trans>}
              />
            </Grid>
            <Grid item xs={lockContactDetails ? 7 : 6} sm={lockContactDetails ? 7 : 6} md={lockContactDetails ? 7 : 6}>
              {detailValue}
            </Grid>
            {detailActions}
          </Grid>
        );
      });

      var btnAddContactDetail = "";
      if (!lockContactDetails) {
        btnAddContactDetail = (
          <IconButton
            onClick={() =>
              changeContact({
                ...state.contact,
                details: [
                  ...state.contact.details,
                  {
                    localId: uuidv4(),
                    title: "Principal",
                    contactDetailTypeCode: "PHONE",
                    phoneCode: countryPhoneCode,
                    value: ""
                  }
                ]
              })
            }
          >
            +
          </IconButton>
        );
      }

      fieldDetails = (
        <div>
          {fieldDetailsGrid}
          {btnAddContactDetail}
        </div>
      );
    } else {
      fieldName = (
        <Typography variant="h5">
          {contact.firstName} {contact.lastName}
        </Typography>
      );

      let contactType = masterValues[TpContactType].find(c => c.code === contact.contactTypeCode);
      fieldContactType = <Typography>{GetMasterValueLabel(contactType, defaultLang)}</Typography>;

      fieldDetails = contact.details.map((d, key) => {
        var phoneCode = d.phoneCode;
        var value = d.value;

        return (
          <Grid container key={key}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography>{d.contactDetailTypeCode}</Typography>
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
              <Typography>
                {d.title} - {phoneCode} {value}
              </Typography>
            </Grid>
          </Grid>
        );
      });
    }

    if (isSwipable && isEditable) {
      if (state.mode === "EDIT") {
        btnActions = (
          <>
            <Button
              onClick={e => {
                if (JSON.stringify(state.contact) !== state.jsonBackup) {
                  setContact(state.contact);
                } else {
                  setState({ ...state, mode: "VIEW" });
                }
              }}
            >
              <Trans>Save</Trans>
            </Button>
            <Button
              onClick={() => {
                setState({
                  ...state,
                  mode: "VIEW",
                  contact: JSON.parse(state.jsonBackup)
                });
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
          </>
        );
      } else {
        var btnDelete = "";
        if (isDeletable) {
          btnDelete = (
            <Button onClick={() => setState({ ...state, showConfirm: true })}>
              <Trans>Delete</Trans>
            </Button>
          );
        }
        btnActions = (
          <>
            <Button onClick={e => setState({ ...state, mode: "EDIT" })}>
              <Trans>Edit</Trans>
            </Button>
            {btnDelete}
          </>
        );
      }
    }
    cardContent = (
      <>
        {fieldName}
        {fieldContactType}
        {fieldDetails}
      </>
    );
  }

  return (
    <>
      <Card id="myCard">
        <CardContent>{cardContent}</CardContent>
        <CardActions>{btnActions}</CardActions>
      </Card>
      <Dialog open={state.showConfirm} onClose={() => setState({ ...state, showConfirm: false })}>
        <DialogContent>
          <DialogContentText>
            <Trans> ConfirmCloseWithoutSave </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteContact} color="info">
            <Trans> Yes </Trans>
          </Button>
          <Button onClick={() => setState({ ...state, showConfirm: false })}>
            <Trans> No </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function GetContactTypeIcon(contactTypeCode) {
  switch (contactTypeCode) {
    case "GENERAL":
      return <BusinessRounded />;
    case "COMPTA":
      return <AttachMoneyRounded />;
    case "REPO_EBX":
      return <SaveAltOutlined />;
    default:
      return <Person />;
  }
}

export default CardContact;
