import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Undo from "@material-ui/icons/Undo";
import Save from "@material-ui/icons/Save";
import Create from "@material-ui/icons/Create";

const editModes = {
  view: props => (
    <IconButton onClick={props.onEdit}>
      <Create />
    </IconButton>
  ),
  edit: props => (
    <>
      <IconButton onClick={props.onSave}>
        <Save />
      </IconButton>

      <IconButton onClick={props.onCancel}>
        <Undo />
      </IconButton>
    </>
  )
};

export default function ActionsCell(props) {
  const {
    mode,
    actions: { onEdit, onCancel, onSave }
  } = props.columnProps.rest;
  const Buttons = editModes[mode];
  return <Buttons onEdit={() => onEdit(props.index)} onCancel={onCancel} onSave={() => onSave(props.index)} />;
}
