import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const MasterValuesSearch = ({ masterValues, value, setValue }) => {
    return (<>
        <Autocomplete
            options={masterValues}
            clearOnEscape
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            value={value ? masterValues.find(o => o.type === value.type) : null}
            groupBy={v => v.group}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                    />
                );
            }}
            renderOption={(option, { inputValue }) => {
                const matches = match(option.label, inputValue);
                const parts = parse(option.label, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    </>);
};

export default MasterValuesSearch;