import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TpCustomerNature, TpCustomerStatus, TpDematerializationType, TpGroup, TpScope, TpSecurization } from "store/MasterValue/MasterValueTypes";

const TabCustomerGeneral = function({ customer, setCustomer, isEditable, availableUes, masterValues }) {
  const [ues, setUes] = useState([]);

  useEffect(() => {
    let lst = availableUes;
    if (!isEditable) {
      var ue = masterValues.StructureUe.find(ue => ue.identifier === customer.ueIdentifier);
      lst = ue ? [ue] : [];
    }
    setUes(lst);
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Common</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Nature</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpCustomerNature}
                        prefixCode
                        selectedValue={customer.customerNatureCode}
                        onValueChange={value => setCustomer({ ...customer, customerNatureCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Scope</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        mvType={TpScope}
                        prefixCode
                        selectedValue={customer.scopeCode}
                        onValueChange={value => setCustomer({ ...customer, scopeCode: value })}
                        isRequired
                      />
                    </GridItem>

                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Securitization</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpSecurization}
                        prefixCode
                        selectedValue={customer.securizationCode}
                        onValueChange={value => setCustomer({ ...customer, securizationCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Code Col</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={isEditable}
                        id="codeCol"
                        value={customer.codeCol}
                        onChange={e => setCustomer({ ...customer, codeCol: e.target.value })}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Ue</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <ItemAutoComplete
                        isEditable={isEditable}
                        isRequired
                        items={ues.map(ue => {
                          return { ...ue, code: ue.identifier };
                        })}
                        selectedValue={customer.ueIdentifier}
                        onValueChange={v => setCustomer({ ...customer, ueIdentifier: v })}
                        formatText={ue => `${ue.identifier} - ${ue.oldIdentifier} - ${ue.label}`}
                        maxResultNumber={100}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Dematerialisation</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormLabel>
                        <Trans>Type of dematerialisation</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpDematerializationType}
                        prefixCode
                        selectedValue={customer.dematerializationTypeCode}
                        onValueChange={value => setCustomer({ ...customer, dematerializationTypeCode: value })}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormLabel>
                        <Trans>Contact dematerialisation</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FieldText
                        isEditable={isEditable}
                        id="dematContact"
                        value={customer.dematerializationContact}
                        onChange={e => setCustomer({ ...customer, dematerializationContact: e.target.value })}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FormLabel>
                        <Trans>Email dematerialisation </Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <FieldText
                        isEditable={isEditable}
                        id="dematEmail"
                        value={customer.dematerializationEmail}
                        onChange={e => setCustomer({ ...customer, dematerializationEmail: e.target.value })}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Status</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Status</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <MasterValueAutocomplete
                        isEditable={isEditable}
                        isRequired
                        mvType={TpCustomerStatus}
                        prefixCode
                        selectedValue={customer.customerStatusCode}
                        onValueChange={value => setCustomer({ ...customer, customerStatusCode: value })}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    availableUes: state.AuthenticationReducer.user?.structureTpc?.ues
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabCustomerGeneral));
