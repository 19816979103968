import * as Actions from './types';

export function OpenProduct(productId) {
    return (dispatch) => {
        return dispatch({
            type: Actions.PRODUCT_OPEN,
            productId: productId
        });
    };
}

export function CloseProduct(productId) {
    return (dispatch) => {
        return dispatch({
            type: Actions.PRODUCT_CLOSE,
            productId
        });
    };
}