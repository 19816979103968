/* eslint-disable no-use-before-define */
import { Trans } from "@lingui/macro";
import { Chip, CircularProgress, IconButton, Popover, Tooltip } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import { ErrorOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import FlagIcon from "@material-ui/icons/Flag";
import SettingsIcon from "@material-ui/icons/Settings";
import { Autocomplete } from "@material-ui/lab";
import { ApimGetPromise } from "components/Common/ApimSender";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { extractErrorMessage, isNullOrEmpty } from "module/tools";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import { StructMagnitude } from "store/MasterValue/MasterValueTypes";

export default function PartMagnitude({ isEditable, structure, setStructure, masterValues, errors, defaultLang }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!masterValues[StructMagnitude]) {
      setIsLoading(true);
      ApimGetPromise("api/magnitude").then(v => {
        dispatch(addMasterValue(StructMagnitude, v));
        setIsLoading(false);
      });
    }
  }, [masterValues]);

  const magnitudes = masterValues[StructMagnitude];
  const structMagnitudes = useMemo(() => {
    if (!magnitudes) return [];
    return structure.magnitudeCodes.map(a => {
      return {
        code: a.magnitudeCode,
        label: a.magnitudeCode + " - " + magnitudes.find(mv => mv.code === a.magnitudeCode)?.label,
        isPrincipal: a.isPrincipal
      };
    });
  }, [magnitudes, structure]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_, reason) => {
    if (reason === "toggleInput") {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "structureMagnitudes" : undefined;

  function setPrincipal(magnitudeCode) {
    setStructure({
      ...structure,
      magnitudeCodes: structure.magnitudeCodes.map(a => {
        return { ...a, isPrincipal: a.magnitudeCode === magnitudeCode };
      })
    });
  }

  function setMagnitudeCodes(newCodes) {
    let tmp = structure.magnitudeCodes.filter(a => newCodes.find(na => na.code === a.magnitudeCode));
    newCodes
      .filter(na => !structure.magnitudeCodes.find(a => na.code === a.magnitudeCode))
      .map(na => (tmp = [...tmp, { magnitudeCode: na.code, isPrincipal: false }]));

    if (tmp.length > 0 && !tmp.find(a => a.isPrincipal)) {
      tmp[0].isPrincipal = true;
    }

    setStructure({ ...structure, magnitudeCodes: tmp });
  }

  const getLabel = magnitudeCode => {
    var magnitude = magnitudes.find(m => m.code === magnitudeCode);
    if (!magnitude) return "Unknown";
    return `${magnitude.code} - ${magnitude.label}`;
  };

  var output;

  if (!magnitudes || !!isLoading) output = <CircularProgress />;
  else {
    console.log(structMagnitudes);
    output = (
      <>
        <div className={classes.root}>
          {structMagnitudes
            .sort((a, b) => b.isPrincipal - a.isPrincipal)
            .map(sm => {
              if (!!sm.isPrincipal) {
                return <Chip icon={<FlagIcon />} label={sm.label} size="small" color="primary" />;
              }
              return (
                <Chip
                  label={sm.label}
                  onDelete={isEditable ? e => setPrincipal(sm.code) : null}
                  deleteIcon={isEditable ? <FlagIcon /> : ""}
                  variant="outlined"
                  size="small"
                />
              );
            })}
          {isEditable ? (
            <IconButton size="small" onClick={handleClick}>
              <SettingsIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Autocomplete
            open
            onClose={handleClose}
            multiple
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal
            }}
            value={structMagnitudes}
            onChange={(event, newValue) => {
              setMagnitudeCodes(newValue);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            getOptionSelected={(o, v) => o.code === v.code}
            renderOption={(option, { selected }) => {
              return (
                <>
                  {selected && <DoneIcon className={classes.iconSelected} />}
                  <span className={classes.color} style={{ backgroundColor: option.color }} />
                  <div className={classes.text}>{getLabel(option.code)}</div>
                  {selected && <CloseIcon className={classes.close} />}
                </>
              );
            }}
            options={magnitudes.sort((a, b) => {
              // Display the selected labels first.
              let ai = structure.magnitudeCodes.findIndex(v => v.magnitudeCode === a);
              ai = ai === -1 ? structure.magnitudeCodes.length + magnitudes.findIndex(v => v.code === a) : ai;
              let bi = structure.magnitudeCodes.findIndex(v => v.magnitudeCode === b);
              bi = bi === -1 ? structure.magnitudeCodes.length + magnitudes.findIndex(v => v.code === b) : bi;
              return ai - bi;
            })}
            getOptionLabel={option => getLabel(option.code)}
            renderInput={params => <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus className={classes.inputBase} />}
          />
        </Popover>
      </>
    );
  }

  let errorMessage = extractErrorMessage(errors, "LINK_MAGNITUDE", defaultLang);
  if (!isNullOrEmpty(errorMessage)) {
    output = (
      <div style={{ display: "flex", border: "1px solid red" }}>
        <Tooltip title={errorMessage}>
          <ErrorOutlined style={{ color: "red" }} />
        </Tooltip>
        {output}
      </div>
    );
  }

  return (
    <>
      <GridItem xs={4} sm={4} md={4}>
        <Muted>
          <Trans>Structure_Magnitude</Trans>
        </Muted>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        {output}
      </GridItem>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 13
  },
  button: {
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6"
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  }
}));
