import { Trans } from "@lingui/macro";
import { CardActions, Menu, MenuItem, Tooltip, withStyles } from "@material-ui/core";
import { Add, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { formatISO } from "date-fns";
import DialogPaymentCharacteristic from "module/tpCopernic/components/DialogPaymentCharacteristic";
import React, { useEffect, useState } from "react";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty, nextOpenDay } from "tools";

const RequestSupplierPayChar = ({ show, payChar, setPayChar, thirdParty, isEditable, masterValues, errors, classes }) => {
  var [editPayChar, setEditPayChar] = useState(undefined);
  var [btnAddAnchor, setBtnAddAnchor] = useState();
  var [country, setCountry] = useState(null);

  useEffect(() => {
    if (isNullOrEmpty(thirdParty)) {
      setCountry(null);
      return;
    }
    const tpCountry = masterValues[CommonCountry].find(c => c.codeIso2 === thirdParty.countryCode);
    setCountry(tpCountry);
  }, [thirdParty]);
 
  var BtnAddPaymentChar = () => {
    let button = <></>;
if(!isNullOrEmpty(country)){
    if (country.allowedPayCharTypes.length > 1) {
      button = (
        <>
          <Button onClick={e => setBtnAddAnchor(e.currentTarget)}>
            <Add />
            &nbsp;<Trans>Add</Trans>
            {Boolean(btnAddAnchor) ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
          <Menu
            id="menuCreate"
            anchorEl={btnAddAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(btnAddAnchor)}
            onClose={() => setBtnAddAnchor(null)}
            PaperProps={{
              style: {
                width: "40ch"
              }
            }}
          >
            {country.allowedPayCharTypes.map(payCharType => {
              return (
                <MenuItem
                  key={`pct_${payCharType}`}
                  onClick={() => {
                    addPayChar(payCharType);
                    setBtnAddAnchor(null);
                  }}
                >
                  {payCharType}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      );
    } else if (country.allowedPayCharTypes.length === 1) {
      var pcType = country.allowedPayCharTypes[0];
      button = (
        <Tooltip
          title={
            <>
              <Trans>Add</Trans> {pcType}
            </>
          }
        >
          <Button id="payCharAddButton" aria-label="Add" onClick={c => addPayChar(pcType)}>
            <Add />
            &nbsp;<Trans>Add</Trans> {pcType}
          </Button>
        </Tooltip>
      );
    }
  }
    return button;
  };

  var CardPayChar = () => {
    if (isNullOrEmpty(payChar?.bankAccount)) {
      return (
        <Card classes={{ root: classes.card }}>
          <CardBody>
            <Trans>WF_SupplierRequest_YouCanAddPayChar</Trans>
          </CardBody>
          <CardActions>
            <BtnAddPaymentChar />
          </CardActions>
        </Card>
      );
    }

    const isIban = payChar.paymentCharacteristicTypeCode === "IBAN";
    return (
      <Card classes={{ root: classes.card }}>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              {!isIban ? <Trans>PayChar_Bacs_BankAccount</Trans> : <Trans>PayChar_Iban_BankAccount</Trans>}
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {payChar.bankAccount}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              {!isIban ? <Trans>PayChar_Bacs_BankCode</Trans> : <Trans>PayChar_Iban_BankCode</Trans>}
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {payChar.bankCode}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              {!isIban ? <Trans>PayChar_Bacs_BankLabel</Trans> : <Trans>PayChar_Iban_BankLabel</Trans>}
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {payChar.bankLabel}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Trans>PayChar_Contact</Trans>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {!payChar.thirdPartyContact && <span>Ø</span>}
              {payChar.thirdPartyContact && (
                <>
                  <div>
                    {payChar.thirdPartyContact.lastName} {payChar.thirdPartyContact.firstName}
                  </div>
                  {payChar.thirdPartyContact.details.map((d, idx) => {
                    return (
                      <div key={`payCharContact_${idx}`}>
                        {d.contactDetailTypeCode === "MAIL" && <div>✉️ {d.value}</div>}
                        {d.contactDetailTypeCode === "PHONE" && <div>📞 {d.value}</div>}
                      </div>
                    );
                  })}
                </>
              )}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Trans>PaymentCharacteristic_Beneficiary</Trans>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {!payChar.beneficiary?.thirdParty && <span>Ø</span>}
              {payChar.beneficiary?.thirdParty && (
                <div>
                  <div>
                    {payChar.beneficiary.thirdParty.identifier} / {payChar.beneficiary.thirdParty.legalIdentifier}
                  </div>
                  <div>{payChar.beneficiary.thirdParty.longName}</div>
                </div>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardActions>
          {isEditable && (
            <Button onClick={() => setEditPayChar(payChar)}>
              <Trans>Edit</Trans>
            </Button>
          )}
        </CardActions>
      </Card>
    );
  };

  const addPayChar = payCharType => {
    if (!country) {
      return;
    }
    let canCheckSis = country.canCheckSis;
    let isAdmin = HasRight("thirdparty_supplier.edit");

    setEditPayChar({
      identifier: 0,
      supplierIdentifier: 0,
      paymentCharacteristicStatusCode: isAdmin && !canCheckSis ? "Ok" : "Doubtful",
      paymentCharacteristicOriginCode: "Manual",
      thirdPartyContactId: 0,
      startOfValidityDate: formatISO(new Date()),
      endOfValidityDate: null,
      sisScore: isAdmin && !canCheckSis ? 99 : 50,
      action: "insert",
      minDate: nextOpenDay(new Date()),
      paymentCharacteristicTypeCode: payCharType
    });
  };

  const savePayChar = pc => {
    setEditPayChar(undefined);
    setPayChar(pc);
  };

  if (!show || !thirdParty || !country || !country.canCheckSis) {
    return <></>;
  }

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>WFF_PayChar</Trans>
          </h4>
        </CardHeader>
        <CardPayChar />
      </Card>
      {editPayChar && (
        <DialogPaymentCharacteristic
          paymentCharacteristic={editPayChar}
          supplierSjs={[]}
          thirdParty={thirdParty}
          setPaymentCharacteristic={pc => {
            if (pc.cancel) setEditPayChar(undefined);
            else savePayChar(pc);
          }}
          requireWorkflowPayChar={false}
          errors={errors}
        />
      )}
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(RequestSupplierPayChar);
