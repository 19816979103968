import Axios from "axios";

export default async function Geocode(line1, line2, zipCode, city, countryCode) {
  var subscriptionKey = process.env.REACT_APP_AZURE_MAP_KEY;

  var query = line1 + (!!line2 ? ", " + line2 : "") + ", " + zipCode + " " + city;
  var geocodeUrlTemplate =
    "https://atlas.microsoft.com/search/address/json?subscription-key={subscription-key}&api-version=1&query={query}&countrySet={countrySet}&view=Auto";

  var manageCountry = (countryCode, zipCode) => {
    if (countryCode !== "FR") return countryCode;

    if (zipCode.startsWith("971")) return "GP";
    if (zipCode.startsWith("972")) return "MQ";
    if (zipCode.startsWith("973")) return "GF";
    if (zipCode.startsWith("974")) return "RE";
    if (zipCode.startsWith("975")) return "PM";
    if (zipCode.startsWith("976")) return "YT";
    return "FR";
  };

  var geocodeRequest = geocodeUrlTemplate
    .replace("{query}", encodeURIComponent(query))
    .replace("{countrySet}", manageCountry(countryCode, zipCode))
    .replace("{subscription-key}", subscriptionKey);

  return Axios.get(geocodeRequest)
    .then(r => {
      if (r.status === 200) {
        return r.data;
      } else if (r.status === 204) {
        return [];
      }
    })
    .then(response => {
      if (response && response.results && response.results.length > 0) {
        let results = response.results.filter(r => r.type === "Point Address" || r.type === "Street" || r.type === "Address Range");
        return results.map(res => {
          let address = res.address;
          let position = res.position;

          return {
            line1: !!address.streetNameAndNumber
              ? address.streetNameAndNumber
              : (!!address.streetNumber ? address.streetNumber + " " : "") + address.streetName,
            zipCode: address.postalCode,
            city: address.municipality,
            countryCode: address.countryCode,
            latitude: position.lat,
            longitude: position.lon
          };
        });
      }
      return [];
    });
}
