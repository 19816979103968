import React from "react";
import { connect } from "react-redux";

// custom components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Select from "react-select";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { TextField } from "@material-ui/core";

function LogsSearchCriterias({ ...props }) {
  const { launchSearchHandler, isSearching, classes } = props;

  var endDate = new Date(Date.now());
  var startDate = new Date(Date.now());
  startDate.setHours(startDate.getHours() - 2);

  const [searchCriterias, setSearchCriterias] = React.useState({
    startDate: convertDateToLocalFormat(startDate),
    endDate: convertDateToLocalFormat(endDate)
  });

  var launchSearch = e => {
    e.preventDefault();
    if (!!launchSearchHandler) launchSearchHandler(searchCriterias);
  };

  function convertDateToLocalFormat(valueToConvert) {
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(valueToConvert);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(valueToConvert);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(valueToConvert);
    let hour = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(valueToConvert);
    let minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(valueToConvert);

    var iso = year + "-" + month + "-" + day + "T" + hour + ":" + minute;

    return iso;
  }

  var typeOptions = [
    { value: "REQUEST", label: <Trans>Request</Trans> },
    { value: "TRACE", label: <Trans>Trace</Trans> },
    { value: "CUSTOMEVENT", label: <Trans>Custom event</Trans> },
    { value: "DEPENDENCY", label: <Trans>Dependency</Trans> }
  ];

  var successOptions = [
    { value: null, label: <Trans>Both</Trans> },
    { value: true, label: <Trans>True</Trans> },
    { value: false, label: <Trans>False</Trans> }
  ];

  return (
    <form onSubmit={launchSearch} autoComplete="off">
      <GridContainer>
        <GridItem xs={3}>
          <TextField
            label="Start date"
            id="datetime-start"
            type="datetime-local"
            value={searchCriterias.startDate}
            onChange={e => {
              setSearchCriterias({ ...searchCriterias, startDate: e.target.value });
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <TextField
            label="End date"
            id="datetime-enddate"
            type="datetime-local"
            value={searchCriterias.endDate}
            onChange={e => {
              setSearchCriterias({ ...searchCriterias, endDate: e.target.value });
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomInput
            labelText={<Trans>Event Name Part</Trans>}
            id="eventName"
            onChange={e => {
              setSearchCriterias({ ...searchCriterias, filter: e.target.value });
            }}
            value={searchCriterias.filter}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={3}>
          <Select
            closeMenuOnSelect={true}
            options={typeOptions}
            placeholder={<Trans>Type</Trans>}
            menuPlacement="auto"
            menuPosition="fixed"
            onChange={e => {
              setSearchCriterias({ ...searchCriterias, type: e });
            }}
            value={searchCriterias.type}
          />
        </GridItem>
        <GridItem xs={3}>
          <Select
            closeMenuOnSelect={true}
            options={successOptions}
            placeholder={<Trans>Is Success</Trans>}
            menuPlacement="auto"
            menuPosition="fixed"
            onChange={e => {
              setSearchCriterias({ ...searchCriterias, success: e });
            }}
            value={searchCriterias.success}
          />
        </GridItem>
      </GridContainer>
      <div className={classes.flexRight}>
        <Button
          onClick={e => {
            e.preventDefault();
          }}
        >
          <Trans> Clean </Trans>
        </Button>
        <Button type="submit" color="info" disabled={isSearching} onClick={launchSearch}>
          <Trans>Search</Trans>
        </Button>
      </div>
    </form>
  );
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(LogsSearchCriterias));
