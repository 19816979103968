import { Trans } from '@lingui/macro';
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from 'components/Common/FieldText';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from 'module/amc/store/actions/CustomerContract.Actions';
import React from 'react';
import { connect } from 'react-redux';

function CustomerContractBudgetPart({ amc, itemChanged, isReadonly }) {

    var item = !!amc.currentItem['CUSTOMERCONTRACT'] ? amc.currentItem['CUSTOMERCONTRACT'] : {};

    var dateInputProps = {};
    if (isReadonly)
        dateInputProps = {
            readOnly: { isReadonly },
            disabled: { isReadonly },
        };

    return (
        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <h4><Trans>Budgets</Trans></h4>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={7} sm={7} md={7}>
                        <FormLabel><Trans>Alloted Amount</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="allotedAmount"
                            value={item.allotedAmount}
                            type="number"
                            onChange={
                                (e) => itemChanged({ ...item, allotedAmount: e.target.value })
                            }
                        />
                    </GridItem>
                    <GridItem xs={7} sm={7} md={7}>
                        <FormLabel><Trans>Colas Amount</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="colasAmount"
                            value={item.colasAmount}
                            type="number"
                            onChange={
                                (e) => itemChanged({ ...item, colasAmount: e.target.value })
                            }
                        />
                    </GridItem>
                    <GridItem xs={7} sm={7} md={7}>
                        <FormLabel><Trans>Warranty Holdback Percentage</Trans></FormLabel>
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        <FieldText
                            isEditable={!isReadonly}
                            id="warrantyHoldbackPercentage"
                            value={item.warrantyHoldbackPercentage}
                            type="number"
                            onChange={
                                (e) => itemChanged({ ...item, warrantyHoldbackPercentage: e.target.value })
                            }
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer,
        masterValues: state.MasterValueReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        itemChanged: item => {
            dispatch(Actions.ItemChanged(item))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(CustomerContractBudgetPart));