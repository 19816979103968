import React from 'react';

// tabs
import TabSiteStructure from './SiteDetail/TabSiteStructure';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function SiteStructure({ site, setSite, defaultLang, isEditable, openStructure }) {
    return (
        <div className='divContainer'>
            <form autoComplete="off">
                <TabSiteStructure site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} openStructure={openStructure} />
            </form>
        </div >
    );
};

export default withStyles(thirdPartySearchCriteriaStyle)(SiteStructure);