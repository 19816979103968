import { ApimGet, ApimGetPromise, ApimMultipleGet, ApimPost } from "components/Common/ApimSender";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { HasOneOfRights, HasRight } from "services/user/UserHelper";
import Geocode from "services/masterdata/AzureMapsHelper";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import * as MvType from "store/MasterValue/MasterValueTypes";
import { isNull, isNullOrEmpty } from "tools";
import * as Actions from "./types";

export function EditThirdParty(thirdParty) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_EDITVALUE,
      thirdParty: thirdParty
    });
  };
}

export function OpenThirdParty(thirdPartyId, previousThirdPartyId, activeTabName) {
  return LoadThirdParty("ID", thirdPartyId, previousThirdPartyId, activeTabName);
}

export function OpenUnreferencedThirdParty(thirdParty) {
  if (thirdParty.countryCode === "FR" && !isNullOrEmpty(thirdParty.legalIdentifier)) {
    // load thirdParty with SIRET
    return LoadThirdParty("SIRET", thirdParty.legalIdentifier);
  }
  return LoadThirdParty("DUNS", thirdParty.duns);
}

// Origin : ID, DUNS, SIRET
function LoadThirdParty(origin, thirdPartyId, previousThirdPartyId, activeTabName) {
  return (dispatch, getState) => {
    //var realThirdPartyId = isNaN(thirdPartyId) && thirdPartyId.indexOf(":") > -1 ? 0 : thirdPartyId;
    dispatch({
      type: isNull(previousThirdPartyId) ? Actions.TP_COPERNIC_THIRDPARTY_OPEN : Actions.TP_COPERNIC_THIRDPARTY_RELOAD,
      previousThirdPartyId: isNull(previousThirdPartyId) ? thirdPartyId : previousThirdPartyId,
      thirdPartyIdentifier: origin === "ID" ? thirdPartyId : 0
    });

    // load MasterValues
    var masterValues = getState().MasterValueReducer;
    var mvTypesToLoad = [
      MvType.TpCompanyComplianceScore,
      MvType.TpContactType,
      MvType.TpCoupaActivity,
      MvType.TpIndustryCode,
      MvType.TpIndustryCodeRepository,
      MvType.TpLegalForm,
      MvType.TpLegalIdentifier,
      MvType.TpLiquidatorType,
      MvType.TpPaymentCharacteristicStatus,
      MvType.TpThirdPartyCategory,
      MvType.TpThirdPartyStatus
    ].filter(mvType => !masterValues[mvType]);
    var mvLoads = mvTypesToLoad.reduce(async (previousPromise, mvType, idx, array) => {
      await previousPromise;
      return ApimGetPromise("api/mastervalue/" + mvType).then(c => dispatch(addMasterValue(mvType, c, idx, array.length)));
    }, Promise.resolve());
    mvLoads.then(() => {
      // load ThirdParty

      var localStorageHelper = new LocalStorageHelper();
      var getPref = localStorageHelper.get("Preferences");
      var checkErrors = false;
      if (getPref !== null && !isNull(getPref.ThirdPartyPref.checkErrors)) {
        checkErrors = getPref.ThirdPartyPref.checkErrors;
      }

      var mainUrl = "";
      switch (origin) {
        case "ID":
          mainUrl = "api/TpCopernic/ThirdParties/" + thirdPartyId;
          break;
        case "DUNS":
          mainUrl = "api/TpCopernic/ThirdParties/fromExternal/Altares/" + thirdPartyId;
          break;
        case "SIRET":
          mainUrl = "api/TpCopernic/ThirdParties/fromExternal/Infolegale/" + thirdPartyId;
          break;
        default:
          break;
      }
      var urlActions = [
        {
          url: mainUrl + "?checkPointOfSale=true&includeMig=true&checkErrors=" + checkErrors,
          name: "thirdParty"
        }
      ];

      if (thirdPartyId === 0 || origin === "DUNS" || origin === "SIRET") {
        // external repository
      } else {
        urlActions.push({
          url: "api/TpCopernic/Companies/byThirdPartyId/" + thirdPartyId,
          name: "company"
        });
        urlActions.push({
          url: "api/TpCopernic/ThirdParties/" + thirdPartyId + "/contacts",
          name: "thirdPartyContacts"
        });
        urlActions.push({
          url: "api/TpCopernic/Suppliers/" + thirdPartyId,
          name: "supplier"
        });
        urlActions.push({
          url: "api/TpCopernic/Suppliers/" + thirdPartyId + "/PaymentCharacteristics",
          name: "paymentCharacteristics"
        });
        urlActions.push({
          url: "api/TpCopernic/Suppliers/" + thirdPartyId + "/sjs",
          name: "supplierSjs"
        });
        urlActions.push({
          url: "api/TpCopernic/PointOfSales/" + thirdPartyId + "?withThirdPartyData=true",
          name: "pointOfSale"
        });
        urlActions.push({
          url: "api/TpCopernic/Customers/" + thirdPartyId,
          name: "customer"
        });
        urlActions.push({
          url: "api/TpCopernic/Customers/" + thirdPartyId + "/sjs",
          name: "customerSjs"
        });
        urlActions.push({
          url: "api/TpCopernic/Accounts/" + thirdPartyId,
          name: "account"
        });
        urlActions.push({
          url: "api/TpCopernic/Beneficiaries/" + thirdPartyId,
          name: "beneficiary"
        });
      }

      var payload = {
        applicationAdmin: HasRight("application.admin"),
        thirdPartyIsEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
        thirdPartyIsEditableGroup: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit", "tp_copernic.edit_groups"]),
        thirdPartyIsEditableClosed: HasOneOfRights(["thirdparty_supplier.edit_closed", "thirdparty_customer.edit_closed"]),
        supplierIsEditable: HasRight("thirdparty_supplier.edit"),
        supplierIsEditableDemat: HasRight("thirdparty_supplier.edit_demat"),
        supplierIsEditableCoupa: HasRight("thirdparty_supplier.edit_coupa"),
        supplierCanAddSj: HasRight("thirdparty_supplier.add_sj"),
        supplierIsEditableSj: HasRight("thirdparty_supplier.edit_sj"),
        supplierIsEditablePayChar: HasRight("thirdparty_supplier.edit_iban"),
        customerIsEditable: HasRight("thirdparty_customer.edit"),
        customerIsEditableDemat: HasRight("thirdparty_customer.edit_demat"),
        customerCanAddSj: HasRight("thirdparty_customer.add_sj"),
        customerIsEditableSj: HasRight("thirdparty_customer.edit_sj"),
        accountIsEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
        beneficiaryIsEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
        beneficiaryIsEditableSj: HasRight("thirdparty_supplier.edit_sj"),
        beneficiaryCanAddSj: HasRight("thirdparty_supplier.add_sj"),
        supplierIsEditablePayPeriod: HasRight("thirdparty_supplier.edit_payper")
      };
      var hasException = false;
      ApimMultipleGet(
        urlActions,
        async () => {
          if (await UpdateGpsCoordinates(payload.thirdParty.thirdParty)) {
            ApimPost("api/TpCopernic/ThirdParties", payload.thirdParty.thirdParty);
          }

          dispatch({
            type: Actions.TP_COPERNIC_THIRDPARTY_READY,
            activeTabName,
            payload
          });
        },
        exception => {
          hasException = true;
          dispatch({
            type: Actions.TP_COPERNIC_THIRDPARTY_ERROR,
            error: exception
          });
        },
        single => {
          if (single.data !== null && single.data !== undefined) payload[single.name] = single.data;
        }
      );
    });
  };
}

export function CloseThirdParty() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_CLOSE
    });
  };
}

export function RefreshThirdPartyForm(thirdPartyId) {
  return LoadThirdParty("ID", thirdPartyId, thirdPartyId);
}

export function RefreshFromExternalRepository(thirdPartyId) {
  return (dispatch, getState) => {
    ApimPost(
      "api/TpCopernic/ThirdParties/" + thirdPartyId + "/RefreshFromExternalRepository",
      null,
      r => {
        dispatch(LoadThirdParty("ID", thirdPartyId, thirdPartyId));
      },
      exception => {
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_ERROR,
          error: exception
        });
      }
    );
  };
}

export function ThirdPartySave(thirdParty) {
  return async (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_SAVE_BEGIN
    });

    // await UpdateGpsCoordinates(thirdParty);

    ApimPost(
      "api/TpCopernic/ThirdParties",
      thirdParty,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_SAVE_END,
          thirdParty: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_ERROR,
          error: error
        });
      }
    );
  };
}

async function UpdateGpsCoordinates(thirdParty) {
  var wasUpdated = false;

  if (
    !!thirdParty &&
    !!thirdParty.identifier &&
    !isNaN(thirdParty.identifier) &&
    thirdParty.identifier > 0 &&
    (!thirdParty.gpsCoordinates || !thirdParty.gpsCoordinates.latitude)
  ) {
    var results = await Geocode(
      thirdParty.address_Line1,
      thirdParty.address_Line2,
      thirdParty.address_ZipCode,
      thirdParty.address_City,
      thirdParty.address_CountryCode
    );

    /*
    + filtre sur la ville et Ou ZipCode
    + filtre sur résultat de recherche Ligne 1 Adresse = Ligne 1 résultat de recherche 
    => Sinon le 1er résultat par défaut
    */

    if (results.length > 0) {
      var selectedIndex = undefined;
      var testResults = results.map(function(result) {
        return {
          latitude: result.latitude,
          longitude: result.longitude,
          eqCityZip:
            (!!result.city && !!thirdParty.address_City && result.city.toLowerCase() === thirdParty.address_City.toLowerCase()) ||
            (!!result.zipCode && !!thirdParty.address_ZipCode && result.zipCode.toLowerCase() === thirdParty.address_ZipCode.toLowerCase()),
          eqAddressLine1:
            (!!result.line1 && !!thirdParty.address_Line1 && result.line1.toLowerCase() === thirdParty.address_Line1.toLowerCase()) ||
            (!!result.line1 && !!thirdParty.address_Line2 && result.line1.toLowerCase() === thirdParty.address_Line2.toLowerCase())
        };
      });

      for (var x = 0; x < testResults.length; x++) {
        if (testResults[x].eqCityZip && testResults[x].eqAddressLine1) {
          selectedIndex = x;
          break;
        }
      }

      if (selectedIndex === undefined) {
        for (var x = 0; x < testResults.length; x++) {
          if (testResults[x].eqCityZip) {
            selectedIndex = x;
            break;
          }
        }

        if (selectedIndex === undefined) selectedIndex = 0;
      }

      thirdParty.gpsCoordinates = {
        latitude: results[selectedIndex].latitude,
        longitude: results[selectedIndex].longitude
      };

      wasUpdated = true;
    }
  }

  return wasUpdated;
}

///---------------------------------------------------
/// Get History
///---------------------------------------------------
export function GetThirdPartyHistory(thirdPartyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/ThirdParties/" + thirdPartyId + "/histories", callbackFn, errorFn);
}
