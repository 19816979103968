import { Trans } from "@lingui/macro";
import { FormLabel, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import FieldText from "../../../../components/Common/FieldText";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { nullIfEmpty } from "../../../tools";
import { StructureSj } from "store/MasterValue/MasterValueTypes";

const DialogCustomerSj = function({ customerSj, setCustomerSj, isEditableSj, allSjs, availableSjs }) {
  const sj = useMemo(() => {
    if (!customerSj) return null;
    return allSjs.find(s => s.identifier === customerSj.sjCode);
  }, [customerSj]);

  if (!customerSj) return <></>;

  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Sj</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <FieldText
            isEditable={false}
            id="sjCodeDetail"
            value={customerSj.sjCode}
            onChange={e => setCustomerSj({ ...customerSj, sjCode: nullIfEmpty(e.target.value) })}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Label</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Typography>{sj.label}</Typography>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Code Iris3</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <FieldText
            isEditable={isEditableSj}
            id="iris3Detail"
            value={customerSj.aN8Iris3}
            onChange={e => setCustomerSj({ ...customerSj, aN8Iris3: nullIfEmpty(e.target.value) })}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Code Iris2</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <FieldText
            isEditable={isEditableSj}
            id="iris2Detail"
            value={customerSj.aN8Iris2}
            onChange={e => setCustomerSj({ ...customerSj, aN8Iris2: nullIfEmpty(e.target.value) })}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Code Non Iris</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <FieldText
            isEditable={isEditableSj}
            id="nonIrisDetail"
            value={customerSj.nonIrisId}
            onChange={e => setCustomerSj({ ...customerSj, nonIrisId: nullIfEmpty(e.target.value) })}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Cookie</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <FieldText
            isEditable={isEditableSj}
            id="cookieDetail"
            value={customerSj.cookie}
            onChange={e => setCustomerSj({ ...customerSj, cookie: nullIfEmpty(e.target.value) })}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>Old Identifier</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Typography>{sj.oldIdentifier}</Typography>
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          <FormLabel>
            <Trans>New Identifier</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8} sm={8} md={8}>
          <Typography>{sj.newIdentifier}</Typography>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    availableSjs: state.AuthenticationReducer.user.structureTps.sjs,
    allSjs: state.MasterValueReducer[StructureSj]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(DialogCustomerSj));
