import { Trans } from "@lingui/macro";
import { Button, Card, CardActions, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import KeyValueEditor from "components/Common/KeyValueEditor";
import { extractErrorMessage } from "module/tools";
import React, { useEffect, useState, useMemo } from "react";
import { TpLegalIdentifier } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import { GroupCrupdate } from "../../actions/Group.Actions";
import GroupSelect from "../../GroupSelect";

const GroupDetail = ({ group, setGroup, reloadGroups, defaultLang, isGroupEditable }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [errorCodes, setErrorCodes] = useState([]);
  const [backup, setBackup] = useState(null);
  const [unknownError, setUnknownError] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const errorMsg = useMemo(() => {
    var error = extractErrorMessage(errorCodes, "PARENT", defaultLang);
    if (error !== "")
      return (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      );
    return <></>;
  }, [errorCodes]);

  useEffect(() => {
    setUnknownError(extractErrorMessage(errorCodes, "Unknown", "FR"));
  }, [errorCodes]);

  useEffect(() => {
    if (group && (!backup || groupId !== group.id)) {
      const { hasChanged, ...g } = group;
      setBackup(JSON.stringify(g));
      setErrorCodes([]);
      setGroupId(group.id);
    }
  }, [group]);

  if (apiError) return <CardError error={apiError} />;
  if (!group) return <></>;
  if (isLoading) {
    return <CircularProgress />;
  }

  const setGroupValue = g => {
    const { hasChanged, ...g1 } = g;
    setGroup({ ...g1, hasChanged: JSON.stringify(g1) !== backup });
  };

  const saveGroup = g => {
    GroupCrupdate(g)
      .then(() => {reloadGroups(false); setErrorCodes([]);})
      .catch(e => {
        if (e?.response?.status === 400) {
          setErrorCodes(e.response.data);
        } else {
          setApiError(e);
        }
      });
  };
if(group){
  return (
    <>
      {errorMsg}    
      <Card>
        <CardBody>
          <div>
            {!!isGroupEditable ? (
              <FieldText
                label={<Trans>Group_Name</Trans>}
                value={group.label}
                onChange={e => setGroupValue({ ...group, label: e.target.value })}
                fullWidth
                error={extractErrorMessage(errorCodes, "LABEL", defaultLang)}
                showErrorTooltip
                isEditable={true}
              />
            ) : (
              <div>
                <label>{<Trans>Group_Name</Trans>}</label>
                <br />
                <label> {group.label}</label>
              </div>
            )}
          </div>
          <div>
            <GroupSelect
              label={<Trans>Group_Parent</Trans>}
              groupId={group.parentId}
              setGroupId={groupId => {setGroupValue({ ...group, parentId: groupId }); setErrorCodes([])}}
              isEditable={isGroupEditable}
              error={extractErrorMessage(errorCodes, "PARENT", defaultLang)}
            />
          </div>
          <div>
            <CountryAutocomplete
              label={<Trans>Country</Trans>}
              countryCode={group.countryCode}
              onChange={codeIso2 => setGroupValue({ ...group, countryCode: codeIso2, legalIdentifiers: [] })}
              isEditable={isGroupEditable}
              isRequired
              error={extractErrorMessage(errorCodes, "COUNTRY", defaultLang)}
            />
          </div>
          <div>
            <KeyValueEditor
              cardLabel={<Trans>Legal identifiers</Trans>}
              keyMasterValue={TpLegalIdentifier}
              keyMasterValueFilter={mv => mv.countryCode === group.countryCode}
              keyCollectionKey="code"
              keyCollectionValue="label"
              keyPropertyName="legalIdentifierCode"
              valuePropertyName="value"
              items={group.legalIdentifiers}
              onValueChanged={items => setGroupValue({ ...group, legalIdentifiers: items })}
              isEditable={isGroupEditable}
              maxlength={20}
            />
          </div>
        </CardBody>
        <CardActions>
          {!!group.hasChanged && (
            <Button color="primary" onClick={() => saveGroup(group)}>
              <Trans>Save</Trans>
            </Button>
          )}
          <Button onClick={() => {setGroup(null); setErrorCodes([])}}>
            <Trans>Cancel</Trans>
          </Button>
          {!isNullOrEmpty(unknownError) && (
            <Alert onClose={() => setUnknownError(null)} severity="error">
              {unknownError}
            </Alert>
          )}
        </CardActions>
      </Card>
    </>
  );
}
else
{
  return <></>
}
};

export default GroupDetail;
