import { CircularProgress } from "@material-ui/core";
import CardError from "components/Card/CardError";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { HasRight } from "services/user/UserHelper";
import * as HistoryActions from "store/History/HistoryActions";
import { isNullOrEmpty } from "tools";
import { StructureExport, StructureSearch } from "./actions/StructureActions";
import BtnCreateStructure from "./part/BtnCreateStructure";
import SearchCriterias from "./part/search/SearchCriterias";
import SearchResult from "./part/search/SearchResult";
import * as StructureActions from "./store/actions";

function StructureListing({ openStructure, defaultLang }) {
  const getDefaultCountry = () => {
    var defaultCountry = "FR";
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    if (getPref !== null) {
      defaultCountry = getPref.InfoGeneral.Country;
    }
    return defaultCountry;
  };

  const initialCriteria = { country: getDefaultCountry(), gpsLatitude: null, gpsLongitude: null, fullText: true };
  const [criteria, setCriteria] = useState(initialCriteria);
  const [state, setState] = useState({ isLoading: false });

  const cleanSearch = () => {
    setCriteria(initialCriteria);
  };

  useEffect(() => {
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }

      newCriteria = { ...newCriteria, pageSize: defaultPageSize, country: getDefaultCountry() };
    }

    setCriteria(newCriteria);
  }, []);

  const launchSearch = criteria => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    StructureSearch(
      criteria,
      results => setState({ isLoading: false, searchResults: results }),
      error => setState({ isLoading: false, error: error })
    );
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = (
      <SearchResult results={state.searchResults} openStructure={openStructure} exportStructure={StructureExport} defaultLang={defaultLang} />
    );
  }

  var btnCreation = (HasRight("structure.edit") ||
    HasRight("structure.edit_sj") ||
    HasRight("structure.edit_ue") ||
    HasRight("structure.edit_up") ||
    HasRight("structure.edit_ep")) && <BtnCreateStructure defaultLang={defaultLang} />;

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriterias
            criteria={criteria}
            setCriteria={setCriteria}
            search={launchSearch}
            cleanSearch={cleanSearch}
            showSearchCriteriaOnSideBar={true}
            defaultLang={defaultLang}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        cleanSearch={cleanSearch}
        search={launchSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={defaultLang}
      />
    );
  }

  return (
    <div>
      {btnCreation}
      {layoutSearchResult}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openStructure: structure => {
      dispatch(StructureActions.OpenStructure(structure.structureType, structure.identifiant));
      dispatch(HistoryActions.addHistoryStructure(structure));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StructureListing);
