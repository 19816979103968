import React from "react";
import * as Actions from "module/amc/store/actions/Worker.Actions";
import { connect, useDispatch } from "react-redux";

// custom components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// helpers

// core components
import { SearchOutlined } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

function WorkerSearchCriteria({ amc, setCriterias, search, classes }) {
  const dispatch = useDispatch();

  const criterias = amc.criterias["WORKER"];

  var searchGridSize = 4;

  function launchSearch(e) {
    e.preventDefault();
    search();
  }

  function cleanSearchCriterias(e) {
    e.preventDefault();
    var newCriterias = { pageSite: criterias.pageSite, criteriaFullText: "", criteriaMdmId: "" };
    setCriterias(newCriterias);
  }

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <SearchOutlined />
        </CardIcon>
        <form onSubmit={launchSearch} autoComplete="off">
          <GridContainer>
            <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
              <CustomInput
                labelText={<Trans>Login</Trans>}
                id="criteriaMdmId"
                onChange={e => {
                  setCriterias({ ...criterias, login: e.target.value });
                }}
                value={criterias.mdmId}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
              <CustomInput
                labelText={<Trans>First name</Trans>}
                id="label"
                onChange={e => {
                  setCriterias({ ...criterias, firstName: e.target.value });
                }}
                value={criterias.label}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
              <CustomInput
                labelText={<Trans>Last name</Trans>}
                id="erpIdentifier"
                onChange={e => {
                  setCriterias({ ...criterias, lastName: e.target.value });
                }}
                value={criterias.erpIdentifier}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </GridContainer>
          <div className={classes.flexRight}>
            <Button onClick={cleanSearchCriterias}>
              <Trans> Clean </Trans>
            </Button>
            <Button type="submit" color="info" onClick={launchSearch}>
              <Trans>Search</Trans>
            </Button>
          </div>
        </form>
      </CardHeader>
      <CardBody></CardBody>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    setSearchMode: searchMode => {
      dispatch(Actions.SetSearchMode(searchMode));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(WorkerSearchCriteria));
