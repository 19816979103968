import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "@lingui/macro";
import { CircularProgress, Divider, FormLabel, Link, Typography } from "@material-ui/core";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { connect } from "react-redux";
import { CommonTimeZone, LocationContactType, LocationNature, LocationStatus } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import SiteActivities from "./SiteActivities";
import SiteCharacteristics from "./SiteCharacteristics";
import SiteContact from "./SiteContact";
import LocationAutoComplete from "components/Autocomplete/LocationAutoComplete";
import { SiteSearch } from "module/site/actions/SiteActions";
import Button from "components/CustomButtons/Button";
import { OpenSite } from "module/site/store/actions";
import Muted from "components/Typography/Muted";

const TabSiteGeneral = function({ site, setSite, defaultLang, isEditable, masterValues, errors, openSite }) {
  const checkError = code => {
    if (!isArrayNullOrEmpty(errors)) {
      if (code === "latitude") {
        var err = errors.find(e => e.code === code);
        if (!isNullOrEmpty(err)) return { value: true, message: err.messageFr };
      }
      if (code === "longitude") {
        var err = errors.find(e => e.code === code);
        if (!isNullOrEmpty(err)) return { value: true, message: err.messageFr };
      }
    } else return null;
  };

  var componentSiteActivities = (
    <SiteActivities masterValues={masterValues} site={site} setSite={setSite} isEditable={isEditable} defaultLang={defaultLang} />
  );

  var cardGeneral = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>General informations</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={isEditable} id="name" value={site.name} onChange={e => setSite({ ...site, name: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Site_Status</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              isEditable={isEditable}
              mvType={LocationStatus}
              selectedValue={site.locationStatusCode}
              onValueChange={v => setSite({ ...site, locationStatusCode: v })}
              size="small"
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Site Activities</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {componentSiteActivities}
          </GridItem>
          <GridItem style={{ marginTop: 10 }} xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Site_Nature</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              isEditable={isEditable}
              mvType={LocationNature}
              selectedValue={site.locationNatureCode}
              onValueChange={v => setSite({ ...site, locationNatureCode: v })}
              size="small"
            />
          </GridItem>
          {site.locationNatureCode === "COLAS" && <SiteNatureChildren site={site} openSite={openSite} />}
          {site.locationNatureCode !== "COLAS" && <SiteNatureParent site={site} setSite={setSite} isEditable={isEditable} />}
        </GridContainer>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line1</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="line1"
              value={site.address_Line1}
              onChange={e => setSite({ ...site, address_Line1: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="line2"
              value={site.address_Line2}
              onChange={e => setSite({ ...site, address_Line2: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>City</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CityZipAutocomplete
              countryCode={site ? site.address_CountryCode : ""}
              zipCode={site ? site.address_ZipCode : ""}
              city={site ? site.address_City : ""}
              countyCode={site ? site.address_CountyCode : ""}
              stateCode={site ? site.address_StateCode : ""}
              onZipCityChange={(zipCode, city) => {
                setSite({ ...site, address_ZipCode: zipCode, address_City: city });
              }}
              onCountyStateChange={countyState => {
                setSite({ ...site, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
              }}
              isEditable={isEditable}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Country</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CountryAutocomplete
              countryCode={site.address_CountryCode}
              onChange={codeIso2 => {
                if (site.address_CountryCode !== codeIso2 && (site.address_CountryCode === "FR" || codeIso2 === "FR"))
                  setSite({ ...site, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                else setSite({ ...site, address_CountryCode: codeIso2 });
              }}
              defaultLang={defaultLang}
              isEditable={isEditable}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>LatitudeLongitude</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GpsAutocomplete
              latitudeError={checkError("latitude")}
              longitudeError={checkError("longitude")}
              isEditable={isEditable}
              defaultLang={defaultLang}
              source={site}
              setSource={c => {
                setSite(c);
              }}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>ReceptionPhoneNumber</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={isEditable}
              id="receptionPhoneNumber"
              value={site.receptionPhoneNumber}
              onChange={e => setSite({ ...site, receptionPhoneNumber: e.target.value })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  var cardType = (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>What_time_is_it</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <Typography variant="h5">{site.timeZone ? site.timeZone.localTime : ""}</Typography>
          <MasterValueAutocomplete
            isEditable={isEditable}
            mvType={CommonTimeZone}
            selectedValue={site.timeZoneCode}
            onValueChange={v => setSite({ ...site, timeZoneCode: v })}
            size="small"
            sortByText
          />
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Site Type</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <SiteCharacteristics site={site} setSite={setSite} defaultLang={defaultLang} isEditable={isEditable} masterValues={masterValues} />
        </CardBody>
      </Card>
    </>
  );

  var cardContact = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Site Contacts</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <SiteContact
          contacts={site.contacts}
          setContacts={c => {
            setSite({ ...site, contacts: c });
          }}
          contactTypes={masterValues[LocationContactType]}
          isEditable={isEditable}
          siteId={site.id}
        />
      </CardBody>
    </Card>
  );

  return (
    <div>
      <GridContainer>
        <GridItem xs={8} sm={8} md={8}>
          {cardGeneral}
        </GridItem>
        <GridItem xs={4} sm={4} md={4}>
          {cardType}
        </GridItem>
      </GridContainer>
      {cardContact}
    </div>
  );
};

const SiteNatureParent = ({ site, setSite, isEditable }) => {
  return (
    <>
      <GridItem style={{ marginTop: 10 }} xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>Site_Nature_Parent</Trans>
        </FormLabel>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        <LocationAutoComplete
          isEditable={isEditable}
          selectedValue={site.locationNatureParentId}
          onValueChange={v => {
            setSite({ ...site, locationNatureParentId: v });
          }}
          size="small"
          filterCriterias={{ siteNatures: ["COLAS"], siteStatuses: ["ACTIVE"] }}
        />
      </GridItem>
    </>
  );
};

const SiteNatureChildren = ({ site, openSite }) => {
  const [state, setState] = useState({ isLoading: false, children: [] });

  useEffect(() => {
    setState({ isLoading: true, children: [] });
    if (site.locationNatureCode) {
      SiteSearch({ siteNatureParentId: site.id, keepSiteNaturesEmpty: true })
        .then(res => {
          setState({ isLoading: false, children: res });
        })
        .catch(e => {
          setState({ isLoading: false, children: [] });
        });
    }
  }, [site.locationNatureCode]);

  return (
    <>
      <GridItem style={{ marginTop: 10 }} xs={4} sm={4} md={4}>
        <FormLabel>
          <Trans>Site_Nature_Children</Trans>
        </FormLabel>
      </GridItem>
      <GridItem style={{ marginTop: 10 }} xs={8} sm={8} md={8}>
        {state.isLoading && <CircularProgress />}
        {!state.isLoading &&
          !isArrayNullOrEmpty(state.children) &&
          state.children.map((child, index) => (
            <div key={index}>
              <Link onClick={() => openSite(child.id)}>
                {child.name} - {child.address_City}
              </Link>
            </div>
          ))}
        {!state.isLoading && isArrayNullOrEmpty(state.children) && <Muted>Ø</Muted>}
      </GridItem>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapDispatchToProps = dispatch => {
  return {
    openSite: siteId => {
      dispatch(OpenSite(siteId));
    }
  };
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabSiteGeneral);
