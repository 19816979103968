import { detect, fromNavigator } from "@lingui/detect-locale";
import { i18n } from "@lingui/core";

const defaultLocale = "en";

//const I18nLoad = (locale) => {
//    import(`locale/${locale}/messages`)
//        .catch(() => import(`locale/${defaultLocale}/messages`))
//        .then(result => {
//            i18n.load(locale, result.messages);
//            i18n.activate(locale);
//        });
//};

export async function I18nLoad(locale) {
  locale = locale.toLowerCase();
  const { messages } = await import(`locale/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

export function GetLocale() {
  const detectedLocale = detect(
    //fromUrl("lang"), // for example http://localhost:3000/?lang=es
    //fromStorage(LOCAL_STORAGE_KEY),
    fromNavigator(), // from system settings
    () => defaultLocale
  );

  return detectedLocale.substring(0, 2);
}
