import { ApimGet, ApimGetPromise, ApimMultiplePost, ApimPost, ApimPostPromise, ApimPostDownloadFile } from "components/Common/ApimSender";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import { format } from "date-fns";

export function ThirdPartyGet(thirdPartyIdentifier) {
  return ApimGetPromise("api/TpColumbus/ThirdParties/" + thirdPartyIdentifier);
}

export function ThirdPartySearch(criterias, callbackFn, errorFn) {
  const queryString = criterias.searchMode === "Simple" ? ConvertCriteriaToSimpleQueryString(criterias) : ConvertCriteriaToQueryString(criterias);
  return ApimGet("api/TpColumbus/ThirdParties" + queryString, countries => callbackFn(countries), e => errorFn(e));
}

// Convert Criteria To Simple Query String
function ConvertCriteriaToSimpleQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (typeof criteria.criteriaFullText !== "undefined" && criteria.criteriaFullText !== null && criteria.criteriaFullText !== "") {
    queryParams.push("FreeSearch=" + criteria.criteriaFullText);
  }
  if (criteria.criteriaCountry !== null && criteria.criteriaCountry !== "") {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }

  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.push("MdmId=" + criteria.criteriaMdmId);
  }
  if (!isNullOrEmpty(criteria.criteriaMigCode)) {
    queryParams.push("MigCode=" + criteria.criteriaMigCode);
  }
  if (!isNullOrEmpty(criteria.criteriaLegalIdentifier)) {
    queryParams.push("LegalIdentifier=" + criteria.criteriaLegalIdentifier);
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryParams.push("LineAddress=" + criteria.criteriaAddress);
  }
  if (!isArrayNullOrEmpty(criteria.categories)) {
    queryParams.push("Category=" + criteria.categories.join("|"));
  }
  if (!isNullOrEmpty(criteria.criteriaLongName)) {
    queryParams.push("LongName=" + criteria.criteriaLongName);
  }
  if (!isNullOrEmpty(criteria.criteriaCity)) {
    queryParams.push("City=" + criteria.criteriaCity);
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryParams.push("Country=" + criteria.criteriaCountry);
  }
  if (!isNullOrEmpty(criteria.localSearch)) {
    queryParams.push("LocalSearch=" + criteria.localSearch);
  }
  if (!isNullOrEmpty(criteria.groupId)) {
    queryParams.push("GroupId=" + criteria.groupId);
  }
  if (!isArrayNullOrEmpty(criteria.types)) {
    for (var i = 0; i < criteria.types.length; i++) {
      var type = criteria.types[i];
      switch (type) {
        case "isCustomer":
          queryParams.push("CustomerSearch=true");
          break;
        case "isSupplier":
          queryParams.push("SupplierSearch=true");
          break;
        case "isPointOfSale":
          queryParams.push("PointOfSaleSearch=true");
          break;
        case "isBeneficiary":
          queryParams.push("BeneficiarySearch=true");
          break;
        case "isAccount":
          queryParams.push("AccountSearch=true");
          break;
        default:
          break;
      }
    }
  }

  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("StartPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("PageSize=" + criteria.pageSize);
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}

export function ThirdPartyCrupdate(thirdParty) {
  return new Promise((callbackFn, errorFn) => {
    ApimPostPromise("api/TpColumbus/ThirdParties", thirdParty)
      .then(tp => {
        var urlData = [];
        if (thirdParty.customer) {
          if (!!thirdParty.customer.hasChanged) {
            urlData.push({ url: "api/TpColumbus/Customers", data: { ...thirdParty.customer, thirdPartyId: tp.identifier } });
          }
          (thirdParty.customer.customerSubsidiaries ?? [])
            //.filter(c => !!c.hasChanged)
            .map(cs =>
              urlData.push({
                url: `api/TpColumbus/Customers/${tp.identifier}/subsidiaries`,
                data: { ...cs, customerId: tp.identifier }
              })
            );
        }
        if (thirdParty.supplier) {
          if (!!thirdParty.supplier.hasChanged) {
            urlData.push({ url: "api/TpColumbus/Suppliers", data: { ...thirdParty.supplier, thirdPartyId: tp.identifier } });
          }
          (thirdParty.supplier.supplierSubsidiaries ?? [])
            //.filter(c => !!c.hasChanged)
            .map(cs =>
              urlData.push({
                url: `api/TpColumbus/Suppliers/${tp.identifier}/subsidiaries`,
                data: { ...cs, supplierId: tp.identifier }
                // ,
              })
            );
        }

        ApimMultiplePost(urlData, () => callbackFn(tp), e => errorFn(e));
      })
      .catch(e => errorFn(e));
  });
}

export function ThirdPartyAutocomplete(thirdParty, callbackFn, errorFn) {
  ApimPost("api/TpColumbus/ThirdPartyValidation", thirdParty, c => callbackFn(c), e => errorFn(e));
}

export function ThirdPartyValidate(thirdParty, workflowStep, callbackFn, errorFn) {
  ApimPost("api/TpColumbus/ThirdPartyValidation/" + workflowStep, thirdParty, c => callbackFn(c), e => errorFn(e));
}

export function GetThirdPartyHistory(thirdPartyId, callbackFn, errorFn) {
  // ApiHistoryGet("api/Changes/get/TpColThirdParties/" + thirdPartyId, c => callbackFn(c), e => errorFn(e));
}

export function ThirdPartyExport(elementsToExport, callbackFn, errorFn) {
  var now = format(new Date(), "yyyyMMddHHmmss");
  var fileName = "exportParGroup";//group.label + "_" + group.id + "_" + now + ".xlsx";
  ApimPostDownloadFile("api/TpColumbus/ThirdParties/export", elementsToExport, fileName + ".xlsx", callbackFn, errorFn);
}
