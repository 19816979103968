import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { Card, CardContent } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Trans } from "@lingui/macro";
import { extractErrorMessage } from '../../../../tools';
import HeaderField from '../common/HeaderField';
import Muted from 'components/Typography/Muted';
import StructureAutocomplete from 'components/Autocomplete/StructureAutocomplete';

const Header = ({ structure, setStructure, isEditable, masterValues, defaultLang, errors }) => {
    const comp = structure.company;
    const setComp = v => setStructure({ ...structure, company: v });

    const cardLeft = (
        <>
            <Card
                style={{ ...CardInlineStyle.tpCard }}
                variant="outlined">
                <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
                    <GridContainer>
                        <HeaderField
                            isEditable={false}
                            value={structure.identifiant}
                            label={(<Trans>Structure_Identifiant</Trans>)}
                        />
                        <HeaderField
                            isEditable={isEditable}
                            value={structure.libelle}
                            onChange={e => setStructure({ ...structure, libelle: e.target.value })}
                            label={(<Trans>Structure_Libelle</Trans>)}
                            error={extractErrorMessage(errors, "STR_LIBELLE", defaultLang)}
                            showErrorTooltip
                        />
                        <HeaderField
                            isEditable={isEditable}
                            value={comp.idJdeIss}
                            onChange={e => setComp({ ...comp, idJdeIss: e.target.value })}
                            label={(<Trans>Structure_IdJdeIss</Trans>)}
                            fieldType="number"
                            error={extractErrorMessage(errors, "COMP_IDJDEISS", defaultLang)}
                            showErrorTooltip
                        />
                    </GridContainer>
                </CardContent>
            </Card>
        </>
    );

    const cardRight = (
      <>
        <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
          <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <Muted>
                  <Trans>LegalEntity</Trans>
                </Muted>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
              <StructureAutocomplete
                isEditable={isEditable}
                structureTypes={["SJ"]}
                value={comp.sjLegalEntityId}
                onChange={e => setComp({ ...comp, sjLegalEntityId : (e !== undefined && e !== null ? e.id : "")})}
                size="small"
                error={extractErrorMessage(errors, "COMP_LEGAL_ENTITY", defaultLang)}
                showErrorTooltip
              />
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      </>
    );

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>{cardLeft}</GridItem>
            <GridItem xs={6} sm={6} md={6}>{cardRight}</GridItem>
        </GridContainer>
    );
};

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
        color: 'white'
    },
    tpCard: {
        margin: '10px 0'
    },
    tpCardHeader: {
        padding: '8px 16px'
    },
    tpCardContent: {
        padding: '8px 16px',
        fontSize: '0.8rem'
    }
};

export default withStyles(structureStyle)(Header);