import { Trans } from "@lingui/macro";
import { FormLabel, TextField } from "@material-ui/core";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { extractErrorMessage } from "module/tools";
import React from "react";
import { LocationStatus } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default function SiteStep1General({ site, setSite, errors, defaultLang }) {
  var error = code => errors.some(e => e.code === code);

  const checkError = code => {
    if (!isArrayNullOrEmpty(errors)) {
      if (code === "LatitudeWrongFormat") {
        var err = errors.find(e => e.code === code);
        if (!isNullOrEmpty(err)) return { value: true, message: "" };
      } else {
        var err = errors.find(e => e.code === "AddressGpsMissing");
        if (!isNullOrEmpty(err)) return { value: true, message: "" };
      }
      if (code === "LongitudeWrongFormat") {
        var err = errors.find(e => e.code === code);
        if (!isNullOrEmpty(err)) return { value: true, message: "" };
      } else {
        var err = errors.find(e => e.code === "AddressGpsMissing");
        if (!isNullOrEmpty(err)) return { value: true, message: "" };
      }
    } else return null;
  };

  var cardInfos = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Infos</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center">
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("NameMissing")} required>
              <Trans>Name</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              autocomplete="off"
              margin="dense"
              id="name"
              error={error("NameMissing")}
              type="text"
              fullWidth
              value={site.name}
              onChange={e => setSite({ ...site, name: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("StatusMissing")} required>
              <Trans>Site Status</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={LocationStatus}
              selectedValue={site.locationStatusCode}
              onValueChange={code => setSite({ ...site, locationStatusCode: code })}
              error={error("StatusMissing")}
              isEditable
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>ReceptionPhoneNumber</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              autocomplete="off"
              margin="dense"
              id="name"
              type="text"
              fullWidth
              value={site.receptionPhoneNumber}
              onChange={e => setSite({ ...site, receptionPhoneNumber: e.target.value })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  var cardAddress = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Address</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center">
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("AddressLine1Missing")} required>
              <Trans>Line 1</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              autocomplete="off"
              margin="dense"
              id="line1"
              error={error("AddressLine1Missing")}
              type="text"
              fullWidth
              value={site.address_Line1}
              onChange={e => setSite({ ...site, address_Line1: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Line 2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <TextField
              margin="dense"
              id="line2"
              type="text"
              fullWidth
              value={site.address_Line2}
              onChange={e => setSite({ ...site, address_Line2: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("AddressCityMissing") || error("AddressZipCodeMissing")} required>
              <Trans>City</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CityZipAutocomplete
              stateCode={site.address_StateCode}
              countryCode={site.address_CountryCode}
              zipCode={site.address_ZipCode}
              city={site.address_City}
              zipCodeError={extractErrorMessage(errors, "AddressZipCodeMissing", defaultLang)}
              cityError={extractErrorMessage(errors, "AddressCityMissing", defaultLang)}
              onZipCityChange={(zipCode, city) => {
                setSite({ ...site, address_ZipCode: zipCode, address_City: city });
              }}
              onCountyStateChange={countyState => {
                setSite({ ...site, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
              }}
              isEditable
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel error={error("AddressCountryMissing")} required>
              <Trans>Country</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <CountryAutocomplete
              countryCode={site.address_CountryCode}
              onChange={codeIso2 => {
                if (site.address_CountryCode !== codeIso2 && (site.address_CountryCode === "FR" || codeIso2 === "FR"))
                  setSite({ ...site, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                else setSite({ ...site, address_CountryCode: codeIso2 });
              }}
              defaultLang={defaultLang}
              error={error("AddressCountryMissing")}
              isEditable
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>LatitudeLongitude</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GpsAutocomplete
              latitudeError={checkError("LatitudeWrongFormat")}
              longitudeError={checkError("LongitudeWrongFormat")}
              isEditable={true}
              defaultLang={defaultLang}
              source={site}
              setSource={c => {
                setSite(c);
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  return (
    <>
      {cardInfos}
      {cardAddress}
    </>
  );
}
