import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";

import NavPills from "components/NavPills/NavPills";
import CountryPartMain from "./part/CountryPartMain";
import CountryPartRegion from "./part/CountryPartRegion";

function CountryDetail({ country, setCountry, defaultLang, masterValues }) {
  var tabs = [
    {
      tabButton: "Pays",
      tabContent: <CountryPartMain country={country} setCountry={setCountry} defaultLang={defaultLang} masterValues={masterValues} />
    },
    {
      tabButton: "Regions",
      tabContent: <CountryPartRegion country={country} setCountry={setCountry} masterValues={masterValues} defaultLang={defaultLang} />
    }
  ];

  return (
    <>
      <NavPills tabs={tabs} />
    </>
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(adminStyle)(CountryDetail));
