import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions';

import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import FieldText from "../../../../components/Common/FieldText";
import BaseSelect from '../../../../components/Common/BaseSelector';
import { Checkbox, FormControlLabel, FormLabel, Tooltip } from '@material-ui/core';
import { Check } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from '@lingui/macro';
import { isArrayNullOrEmpty } from '../../../tools';

const TabPointOfSaleGeneral = function ({ thirdPartyCtx, isEditable, pointOfSaleCtx, availableUps, onValueChanged, classes }) {
    var thirdParty = thirdPartyCtx.thirdParty;
    var pointOfSale = pointOfSaleCtx.pointOfSale;
    
    var up = (<span></span>);
    if (thirdParty.thirdPartyCategoryCode === "COL") {
        var upDisplay = "";
        if (isEditable) {
            upDisplay = (
                <BaseSelect
                    items={availableUps}
                    displayedProperties={{
                        keyProperty: 'identifier',
                        valueProperty: 'label'
                    }}
                    transformValue={(item) => { return item.identifier + " - " + item.oldIdentifier + " - " + item.label; }}
                    value={pointOfSale.upCode}
                    onChange={(e) => onValueChanged({ ...pointOfSale, upCode: e.target.value })}
                    isSearchable={true}
                />
            );
        }
        else if (pointOfSale.up !== null && pointOfSale.up !== undefined) {
            upDisplay = (
                <Tooltip
                    title={pointOfSale.up.label}
                    placement="bottom">
                    <span>
                        {pointOfSale.up.identifier} - {pointOfSale.up.oldIdentifier}
                    </span>
                </Tooltip>
            );
        }
        else {
            upDisplay = (
                <span></span>
            );
        }

        up = (
            <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                    <FormLabel><Trans>Up</Trans></FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                    {upDisplay}
                </GridItem>
            </GridContainer>
        );
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card
                                style={{
                                    ...CardInlineStyle.card
                                }}
                            >
                                <CardHeader
                                    style={{
                                        ...CardInlineStyle.cardHeader
                                    }}
                                    icon>
                                    <h4><Trans>Common</Trans></h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={4}>
                                            <FormLabel><Trans>Label</Trans></FormLabel>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={8}>
                                            <FieldText
                                                isEditable={isEditable}
                                                id="labelPointOfSale"
                                                value={pointOfSale.label}
                                                onChange={(e) => onValueChanged({ ...pointOfSale, label: e.target.value })}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {up}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card
                                style={{
                                    ...CardInlineStyle.card
                                }}
                            >
                                <CardHeader
                                    style={{
                                        ...CardInlineStyle.cardHeader
                                    }}
                                    icon>
                                    <h4><Trans>Status</Trans></h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                        checked={pointOfSale.isActif}
                                                        disabled={!isEditable}
                                                        onChange={(e) => onValueChanged({ ...pointOfSale, isActif: e.target.checked })}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={<Trans>Actif</Trans>}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

const mapStateToProps = state => {
    return {
        thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
        pointOfSaleCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].pointOfSaleCtx : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onValueChanged: pointOfSale => {
            dispatch(Actions.EditPointOfSale(pointOfSale))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabPointOfSaleGeneral));