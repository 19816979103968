import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect } from "react-redux";

// custom components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { FormLabel } from "@material-ui/core";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GenericServerAutocomplete from "../../../../../../components/Autocomplete/GenericServerAutocomplete";

function CustomerContractMainContactsPart({ amc, masterValues, itemChanged, isReadonly }) {
  var localStorageHelper = new LocalStorageHelper();
  var item = !!amc.currentItem["CUSTOMERCONTRACT"] ? amc.currentItem["CUSTOMERCONTRACT"] : {};

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Main contacts</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Main contract manager</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GenericServerAutocomplete
              selectedValue={item.mainContractManager}
              searchPartialUri={"api/JobEmployees/Autocomplete/"}
              readOnly={isReadonly}
              onValueChange={e => itemChanged({ ...item, mainContractManager: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractMainContactsPart));
