import { Trans } from "@lingui/macro";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { History, Lock, LockOpen } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import FieldText from "components/Common/FieldText";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SupervisionDialog from "module/SupervisionDialog";
import { SupplierSjUpdate } from "module/tpCopernic/store/actions/ThirdParty.Supplier.Actions";
import React, { useState } from "react";
import { connect } from "react-redux";
import { TpPaymentMethod, TpPaymentPeriod } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, nullIfEmpty } from "tools";

const DialogSupplierSj = function({
  openDialog,
  setOpenDialog,
  supplierSjIndex,
  onSjValueChanged,
  availableSjs,
  sjs,
  classes,
  isEditable,
  isApplicationAdmin
}) {
  const [openSupervision, setOpenSupervision] = useState(false);

  if (isNull(supplierSjIndex) || isNaN(supplierSjIndex)) return <div />;

  var detail = sjs[supplierSjIndex].sj;
  const isEditableSj = isEditable;
  const isSupervisableSj = isApplicationAdmin;

  var sjLabel, sjOldIdentifier, sjNewIdentifier;
  var knownSj = availableSjs.find(s => s.identifier === detail.sjCode);
  if (!!knownSj) {
    sjLabel = knownSj.label;
    sjOldIdentifier = knownSj.oldIdentifier;
    sjNewIdentifier = knownSj.newIdentifier;
  }

  return (
    <>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Trans>Detail</Trans>
        </DialogTitle>
        <DialogContent>
          <div>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Sj</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={false}
                  id="sjCodeDetail"
                  value={detail.sjCode}
                  onChange={e => onSjValueChanged({ ...detail, sjCode: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Label</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Typography>{sjLabel}</Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Code Iris3</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditableSj}
                  id="iris3Detail"
                  value={detail.aN8Iris3}
                  onChange={e => onSjValueChanged({ ...detail, aN8Iris3: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Code Iris2</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditableSj}
                  id="iris2Detail"
                  value={detail.aN8Iris2}
                  onChange={e => onSjValueChanged({ ...detail, aN8Iris2: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Code Non Iris</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditableSj}
                  id="nonIrisDetail"
                  value={detail.nonIrisId}
                  onChange={e => onSjValueChanged({ ...detail, nonIrisId: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Cookie</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <FieldText
                  isEditable={isEditableSj}
                  id="cookieDetail"
                  value={detail.cookie}
                  onChange={e => onSjValueChanged({ ...detail, cookie: nullIfEmpty(e.target.value) })}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Old Identifier</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Typography>{sjOldIdentifier}</Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>New Identifier</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Typography>{sjNewIdentifier}</Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Payment Period</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={7} sm={7} md={7}>
                <MasterValueAutocomplete
                  isEditable={isEditableSj}
                  mvType={TpPaymentPeriod}
                  selectedValue={detail.paymentPeriodCode}
                  onValueChange={code => onSjValueChanged({ ...detail, paymentPeriodCode: code })}
                  prefixCode
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1} className={classes.forceNoPadding}>
                <IconButton
                  disabled={!isEditableSj}
                  onClick={() => onSjValueChanged({ ...detail, paymentPeriodForced: !detail.paymentPeriodForced })}
                >
                  {detail.paymentPeriodForced ? <Lock /> : <LockOpen />}
                </IconButton>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>Payment Method</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={7} sm={7} md={7}>
                <MasterValueAutocomplete
                  isEditable={isEditableSj}
                  mvType={TpPaymentMethod}
                  selectedValue={detail.paymentMethodCode}
                  onValueChange={code => onSjValueChanged({ ...detail, paymentMethodCode: code })}
                  prefixCode
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1} className={classes.forceNoPadding}>
                <IconButton
                  disabled={!isEditableSj}
                  onClick={() => onSjValueChanged({ ...detail, paymentMethodForced: !detail.paymentMethodForced })}
                >
                  {detail.paymentMethodForced ? <Lock /> : <LockOpen />}
                </IconButton>
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>IsEligibleToDemat</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Checkbox
                  disabled={!isEditableSj}
                  id="isEligibleToDematDetail"
                  checked={detail.isEligibleToDemat}
                  onChange={e =>
                    onSjValueChanged({
                      ...detail,
                      isEligibleToDemat: e.target.checked
                    })
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </DialogContent>
        <DialogActions>
          {isSupervisableSj ? (
            <IconButton onClick={() => setOpenSupervision(true)}>
              <History />
            </IconButton>
          ) : (
            <></>
          )}
          <Button onClick={() => setOpenDialog(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      {isSupervisableSj && !!openSupervision ? (
        <Dialog open onClose={() => setOpenSupervision(false)}>
          <DialogContent>
            <SupervisionDialog
              objectType="supplierSj"
              objectId={`${detail.supplierIdentifier}|${detail.sjCode}`}
              query={`type == 'GeneratedColfluxEvent' and data has '<SupplierV2 ' and data has ' Identifier="${detail.aN8Iris3}" '`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSupervision(false)} color="info">
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null,
    availableSjs: state.AuthenticationReducer.user.structureTps.sjs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSjValueChanged: sj => {
      dispatch(SupplierSjUpdate(sj));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(DialogSupplierSj));
