import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";

// core components
import Button from "components/CustomButtons/Button";

import pagesHeaderStyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx";
import logo from "assets/images/hubble.png";
import { Chip } from "@material-ui/core";

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDrawerToggle = () => {
    //this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    //     if (e.history.location.pathname !== e.location.pathname) {
    //       this.setState({ open: false });
    //     }
  }
  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    var environmentChip = "";
    var pageTitle = "Hubble";

    const envName = process.env.REACT_APP_ENV_NAME;
    const envColor = process.env.REACT_APP_ENV_COLOR;

    if (envName && envName !== "E4" && envColor) {
      environmentChip = <Chip label={envName} style={{ marginLeft: 15, backgroundColor: envColor }} />;
      pageTitle += " - " + envName;
    }

    document.title = pageTitle;

    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex}>
              <Button href="#" className={classes.title} color="transparent">
                <img src={logo} alt="logo" className={classes.img} />
                <span style={{ color: "#00acc1" }}>Hub</span>ble
                {environmentChip}
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <Button href="#" className={classes.title} color="transparent">
                <img src={logo} alt="logo" className={classes.img} />
                <span style={{ color: "#00acc1" }}>Hub</span>ble
                {environmentChip}
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <Button className={classes.sidebarButton} color="transparent" justIcon aria-label="open drawer" onClick={this.handleDrawerToggle}>
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              ></Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
