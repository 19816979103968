import React from "react";
import { Warning } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import parse from "autosuggest-highlight/parse";
import diacritic from "diacritic";

import { isNull, isNullOrEmpty, isArrayNullOrEmpty } from "tools";
export { isNull, isNullOrEmpty, isArray, isArrayNullOrEmpty, emptyIfNull, nullIfEmpty, dateToUtc, nextOpenDay } from "tools";

export function displayWarning(warnings, path) {
  if (isArrayNullOrEmpty(warnings)) return "";

  var item = warnings.find(w => w.path === path);
  if (!isNull(item)) {
    return (
      <Tooltip title={item.description}>
        <Warning style={{ color: "orange" }} />
      </Tooltip>
    );
  }
  return "";
}

export function displayWarningFromErrorMessages(warnings, path, defaultLang) {
  if (isArrayNullOrEmpty(warnings)) return "";

  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";

  var item = warnings.find(w => w.code === path);
  if (!isNull(item)) {
    return (
      <Tooltip title={defaultLang === "FR" ? item.descriptionFr : item.descriptionEn}>
        <Warning style={{ color: "red" }} />
      </Tooltip>
    );
  }
  return "";
}

export function getErrorMessage(warnings, path) {
  if (isArrayNullOrEmpty(warnings)) return null;

  var item = warnings.find(w => w.code === path);
  return item;
}

export function getErrorStateFromErrorMessages(warnings, path) {
  if (isArrayNullOrEmpty(warnings)) return false;

  var item = warnings.find(w => w.code === path);
  if (!isNull(item)) {
    return true;
  }
  return false;
}

export function highlightText(text, query) {
  if (!("string" === typeof query && "string" === typeof text)) {
    return [];
  }

  let t = text.toLowerCase();
  let q = query.trim().toLowerCase();
  const ql = q.length;

  if (!(0 < ql && 0 < t.length)) {
    return [];
  }

  t = diacritic.clean(t);
  q = diacritic.clean(q);

  let r = [];

  let i = t.indexOf(q);
  while (i !== -1) {
    r.push([i, i + ql]);
    i = t.indexOf(q, i + ql);
  }

  return parse(text, r);
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map);
}

export function dateToText(datetimeValue) {
  if (!datetimeValue) return null;

  const dateParsed = new Date(datetimeValue);

  const year = dateParsed.getFullYear();
  const month = dateParsed.getMonth() + 1;
  const date = dateParsed.getDate();

  const stringDate = year + "-" + month + "-" + date;

  return dateParsed.toString();
}

export function getInternationalizedErrorMessage(defaultLang, errorMessage) {
  var message = "";

  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";

  if (!isNullOrEmpty(errorMessage)) {
    message = defaultLang === "FR" ? errorMessage.descriptionFr : errorMessage.descriptionEn;
  }

  return message;
}

export function extractErrorMessage(errors, code, defaultLang) {
  if (isArrayNullOrEmpty(errors)) return "";
  let error = errors.find(e => e.code === code);
  if (!error) return "";
  return defaultLang === "EN" ? error.descriptionEn : error.descriptionFr;
}

export function removeProperty(object, propertyName) {
  return (propertyName, { [propertyName]: _, ...object }) => object;
}

export function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
